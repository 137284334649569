<template>
	<div id="main-wrapper" class="inner-pages why-page safety-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('safetyFund.title')}}</h2>
				<p>{{$t('safetyFund.heading')}}</p>
			</div>
		</div>
		<div class="section why-bel">
			<div class="wrapper">
				<div class="content-sec wow fadeIn">
					<div class="title-holder t-center ">
						<h3>{{$t('safetyFund.safety')}}</h3>
					</div>
					<p  class="t-center">{{$t('safetyFund.investor')}} <br>
					{{$t('safetyFund.protect')}} </p>
				</div>
			</div>
		</div>
		<div class="section safe-sec">
			<div class="wrapper2">
				<div class="title-holder t-white t-center">
					<h3>{{$t('safetyFund.title1')}}</h3>
					<p>{{$t('safetyFund.strict')}}</p>
				</div>
				<div class="safe-content clearfix wow fadeIn">
					<div class="left-col col f-left wow fadeInLeft" data-wow-delay="0.6s">
						<div class="content">
							<div class="text-holder">
								<h3>{{$t('safetyFund.responsible')}}</h3>
								<p>{{$t('safetyFund.client')}}</p>
							</div>
							<div class="img-holder">
								<img src="assets/images/safe1.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
						</div>
						<div class="content">
							<div class="text-holder">
								<h3>{{$t('safetyFund.audit')}}<br> {{$t('safetyFund.review')}}</h3>
								<p>{{$t('safetyFund.internal')}}</p>
							</div>
							<div class="img-holder">
								<img src="assets/images/safe3.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
						</div>
						<div class="content">
							<div class="text-holder">
								<h3>{{$t('safetyFund.negativeBalance')}}</h3>
								<p>{{$t('safetyFund.pTag')}} </p>
							</div>
							<div class="img-holder">
								<img src="assets/images/safe4.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
						</div>
					</div>
					<div class="right-col col f-right wow fadeInRight" data-wow-delay="0.6s">
						<div class="content">
							<div class="img-holder">
								<img src="assets/images/safe2.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
							<div class="text-holder">
								<h3>{{$t('safetyFund.funds')}}</h3>
								<p>{{$t('safetyFund.companyFunds')}}</p>
							</div>
						</div>
						<div class="content">
							<div class="img-holder">
								<img src="assets/images/safe4.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
							<div class="text-holder">
								<h3>{{$t('safetyFund.policies')}}</h3>
								<p>{{$t('safetyFund.secure')}}</p>
							</div>
						</div>
						<div class="content">
							<div class="img-holder">
								<img src="assets/images/safe6.webp" alt="AAAFx" title="AAAFx" width="95" height="95" class="fluid-img" />
							</div>
							<div class="text-holder">
								<h3>{{$t('safetyFund.investorFund')}}</h3>
								<p>{{$t('safetyFund.perPerson')}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section comm-sec">
			<div class="wrapper clearfix">
				<div class="content f-right wow fadeInRight">
					<div class="title-holder">
						<h5>{{$t('safetyFund.security')}} </h5>
						<br>
						<p>{{$t('safetyFund.awareness')}}</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm1.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>{{$t('safetyFund.never')}}</strong> {{$t('safetyFund.click')}}</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm2.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>{{$t('safetyFund.never')}}</strong> {{$t('safetyFund.unSecured')}}</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm3.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>{{$t('safetyFund.never')}}</strong> {{$t('safetyFund.otps')}}</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm4.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>{{$t('safetyFund.never')}}</strong> {{$t('safetyFund.websites')}}</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm5.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>{{$t('safetyFund.never')}}</strong> {{$t('safetyFund.leave')}}</p>
					</div>
					<div class="text-holder">
						<div class="img-holder">
							<img src="assets/images/comm6.webp" alt="AAAFx" title="AAAFx" width="39" height="38" class="fluid-img" />
						</div>
						<p><strong>{{$t('safetyFund.never')}}</strong> {{$t('safetyFund.twoFa')}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>