<template>
  <div id="main-wrapper" class="inner-pages forex-page mt4-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li class="active"><router-link to="/mt4">{{$t('mt4PageData.MT4')}}</router-link></li>
            <!-- <li><router-link to="/mt5">{{$t('mt4PageData.MT5')}}</router-link></li> -->
            <!-- <li><router-link to="/act-trader">{{$t('mt4PageData.ActTrader')}}</router-link></li> -->
            <li><router-link to="/zulu">{{$t('mt4PageData.ZuluTrade')}}</router-link></li>
          </ul>
        </div>
      </div>

      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{$t('mt4PageData.MetaTrade4')}}</h2>
        <div class="img-holder">
          <img src="assets/images/mt4-img.webp" alt="AAAFx" title="AAAFx" width="726" height="394" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h6>
            {{$t('mt4PageData.MetaTrade4DescOne')}}
          </h6>
          <br />
          <p>
            {{$t('mt4PageData.MetaTrade4DescTwo')}}
          </p>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper">
        <div class="content-sec">
          <div class="img-holder wow fadeInLeft">
            <img src="assets/images/mt4-img1.webp" alt="AAAFx" title="AAAFx" width="700" height="412" class="fluid-img" />
          </div>
          <div class="content-holder wow fadeInRight">
            <img src="assets/images/mt4-ico1.webp" alt="AAAFx" title="AAAFx" width="371" height="95" class="fluid-img" />
            <br /><br />
            <p>
              {{$t('mt4PageData.MetaTrade4DescThree')}}
              
            </p>
          </div>
          <div class="btn-holder wow fadeIn">
            <a
              :href="static_vars.platformLinks.mt4.desktop"
              class="btn btn-grey"
              title="MT4 Desktop"
            >
              <img src="assets/images/desk-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{
                static_vars.platformLinks.mt4.desktop == "javascript:void(0);"
                  ? "Coming Soon"
                  : "Download MT4 Desktop"
              }}
            </a>
            <a
              :href="static_vars.platformLinks.mt4.mac"
              class="btn btn-grey"
              title="MT4 Mac"
            >
              <img src="assets/images/desk-ico2.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{
                static_vars.platformLinks.mt4.mac == "javascript:void(0);"
                  ? "Coming Soon"
                  : "Download MT4 Mac"
              }}
            </a>
            <a
              :href="static_vars.platformLinks.mt4.web"
              class="btn btn-grey"
              title="MT4 Web"
              target="_blank"
            >
              <img src="assets/images/desk-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />             
              {{ (static_vars.platformLinks.mt4.web == "javascript:void(0);" || static_vars.platformLinks.mt4.web == "")   ? "Coming Soon"   : "Web Based MT4" }}
            </a>
          </div>
        </div>
        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="desktop ^= true" :class="desktop ? 'active' : ''">
              {{$t('mt4PageData.Steps_to_download_MT4_Desktop')}}
            </h3>
            <div class="content" v-show="desktop">
              <p>
                {{$t('mt4PageData.Steps_to_download_MT4_Desktop_point1')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Desktop_point2')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Desktop_point3')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Desktop_point4')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Desktop_point5')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Desktop_point6')}} <br />
              </p>
            </div>
            <h3 v-on:click="uninstalldesktop ^= true" :class="uninstalldesktop ? 'active' : ''">
              {{$t('mt4PageData.Steps_to_uninstall_MT4_Desktop')}}
            </h3>
            <div class="content" v-show="uninstalldesktop">
              <p>
                {{$t('mt4PageData.Steps_to_uninstall_MT4_Desktop_point1')}} <br />
                {{$t('mt4PageData.Steps_to_uninstall_MT4_Desktop_point2')}} <br />
                {{$t('mt4PageData.Steps_to_uninstall_MT4_Desktop_point3')}} <br />
              </p>
            </div>
            <h3 v-on:click="web ^= true" :class="web ? 'active' : ''">
              {{$t('mt4PageData.Steps_to_log_into_Webbased_MT4')}}
            </h3>
            <div class="content" v-show="web">
              <p>
                {{$t('mt4PageData.Steps_to_log_into_Webbased_MT4_point1')}} <br />
                {{$t('mt4PageData.Steps_to_log_into_Webbased_MT4_point2')}} <br />
                {{$t('mt4PageData.Steps_to_log_into_Webbased_MT4_point3')}} <br />
                {{$t('mt4PageData.Steps_to_log_into_Webbased_MT4_point4')}} <br />
              </p>
            </div>
          </div>
        </div>
        <div class="section-small">
          <div class="content-sec mt4-phone">
            <div class="content-holder wow fadeInLeft">
              <img src="assets/images/mt4-ico2.webp" alt="AAAFx" title="AAAFx" width="371" height="95" class="fluid-img" />
              <br /><br />
              <p>
                {{$t('mt4PageData.AAAFx_mobile_trading_application_description')}}
              </p>
              <div class="btn-holder wow fadeIn">
                <a
                  :href="static_vars.platformLinks.mt4.android"
                  class="btn btn-grey"
                  title="MT4 Android"
                >
                  <img src="assets/images/phone-ico2.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.mt4.android ==
                    "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download MT4 Android"
                  }}
                </a>
                <a
                  :href="static_vars.platformLinks.mt4.ios"
                  class="btn btn-grey"
                  title="MT4 iOS"
                >
                  <img src="assets/images/phone-ico1.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.mt4.ios == "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download MT4 iOS"
                  }}
                </a>
              </div>
            </div>
            <div class="img-holder wow fadeInRight">
              <img src="assets/images/mt4-img2.webp" alt="AAAFx" title="AAAFx" width="650" height="392" class="fluid-img" />
            </div>
          </div>
        </div>

        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="android ^= true" :class="android ? 'active' : ''">
              {{$t('mt4PageData.Steps_to_download_MT4_Android')}}
            </h3>
            <div class="content" v-show="android">
              <p>
                {{$t('mt4PageData.Steps_to_download_MT4_Android_point1')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Android_point2')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Android_point3')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Android_point4')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_Android_point5')}}
              </p>
            </div>
            <h3 v-on:click="unistallandroid ^= true" :class="unistallandroid ? 'active' : ''">
              {{$t('mt4PageData.Steps_to_uninstall_MT4_Android')}}
            </h3>
            <div class="content" v-show="unistallandroid">
              <p>
                {{$t('mt4PageData.Steps_to_uninstall_MT4_Android_point1')}} <br />
                {{$t('mt4PageData.Steps_to_uninstall_MT4_Android_point2')}} <br />
                {{$t('mt4PageData.Steps_to_uninstall_MT4_Android_point3')}}
              </p>
            </div>
            <h3 v-on:click="ios ^= true" :class="[{ active: ios }]">
              {{$t('mt4PageData.Steps_to_download_MT4_iOS')}}
            </h3>
            <div class="content" v-show="ios">
              <p>
                {{$t('mt4PageData.Steps_to_download_MT4_iOS_point1')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_iOS_point2')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_iOS_point3')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_iOS_point4')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_iOS_point5')}} <br />
                {{$t('mt4PageData.Steps_to_download_MT4_iOS_point6')}}
              </p>
            </div>
            <h3 v-on:click="unistallios ^= true" :class="[{ active: unistallios }]">
              {{$t('mt4PageData.Steps_to_uninstall_MT4_iOS')}}
            </h3>
            <div class="content" v-show="unistallios">
              <p>
                {{$t('mt4PageData.Steps_to_uninstall_MT4_iOS_point1')}} <br />
                {{$t('mt4PageData.Steps_to_uninstall_MT4_iOS_point2')}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h3>
            {{$t('mt4PageData.take_trading_experience_to_next_level')}}:
          </h3>
        </div>
        <div class="img-holder t-center">
          <img src="assets/images/mt4-img3.webp" alt="AAAFx" title="AAAFx" width="1157" height="358" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <div class="content-sec mt4-phone why-mt4">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>{{$t('mt4PageData.Why_AAAFxs_MT4')}}</h3>
            </div>
            <ul class="listing-check">
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point1')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point2')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point3')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point4')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point5')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point6')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point7')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point8')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point9')}}
              </li>
              <li>
                {{$t('mt4PageData.Why_AAAFxs_MT4_point10')}}
              </li>
            </ul>
          </div>
          <div class="img-holder wow fadeInRight">
            <img src="assets/images/mt4-img4.webp" alt="AAAFx" title="AAAFx" width="580" height="407" class="fluid-img" />
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow" >{{$t('mt4PageData.capsOpenLiveAccount')}}</a>
              <a :href="siteurl2" target="_blank" class="btn btn-blue" >{{$t('mt4PageData.capsOpenDemoAccount')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Support: false,
      Finance: false,
      Administrator: false,
      desktop: false,
      uninstalldesktop: false,
      web: false,
      android: false,
      unistallandroid: false,
      ios: false,
      unistallios: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
};
</script>
