<template>
  <div id="main-wrapper" class="inner-pages forex-page mt4-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li><router-link to="/mt4">{{ $t("platforms.submenu.mt4.title") }}</router-link></li>
            <li><router-link to="/zulu">{{ $t("tools.submenu.zulu.title") }}</router-link></li>
          </ul>
        </div>
      </div>

      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>
          
          <b-button v-b-tooltip.hover title="Coming Soon...">
						{{ $t("acttraderpage.title") }}*
					</b-button>
        </h2>
        <div class="img-holder">
          <img src="assets/images/act-img1.webp"  alt="AAAFx" title="AAAFx" class="fluid-img" width="728" height="395" />
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h6>{{ $t("acttraderpage.para1") }}</h6>
          <br />
          <p>{{ $t("acttraderpage.para2") }}</p>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper2">
        <div class="content-sec act-desk">
          <div class="img-holder wow fadeInLeft">
            <img src="assets/images/act-img2.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="695" height="319" />
          </div>
          <div class="content-holder wow fadeInRight">
            <img src="assets/images/act-ico1.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="298" height="120" />
            <br /><br />
            <p>{{ $t("acttraderpage.para3") }}</p>

            <div class="btn-holder wow fadeIn">
              <a
                :href="static_vars.platformLinks.actTrader.desktop"
                class="btn btn-grey"
                title="ActTrader Desktop"
              >
                <img src="assets/images/desk-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
                {{
                  static_vars.platformLinks.actTrader.desktop ==
                  "javascript:void(0);"
                    ? "Coming Soon"
                    : "Download ActTrader Desktop"
                }}
              </a>
              <a
                :href="static_vars.platformLinks.actTrader.web"
                class="btn btn-grey"
                title="ActTrader Web"
              >
                <img src="assets/images/desk-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
                {{
                  static_vars.platformLinks.actTrader.web ==
                  "javascript:void(0);"
                    ? "Coming Soon"
                    : "Download ActTrader Web"
                }}
              </a>
            </div>
          </div>
        </div>
        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="desktop ^= true" :class="[{ active: desktop }]">
              {{ $t("acttraderpage.questionnaire.question1.heading") }}
            </h3>
            <div class="content" v-show="desktop">
              <p>
                {{ $t("acttraderpage.questionnaire.question1.point") }} <br />
                {{ $t("acttraderpage.questionnaire.question1.point1") }} <br />
                {{ $t("acttraderpage.questionnaire.question1.point2") }} <br />
                {{ $t("acttraderpage.questionnaire.question1.point3") }} <br />
                {{ $t("acttraderpage.questionnaire.question1.point4") }} <br />
                {{ $t("acttraderpage.questionnaire.question1.point5") }} <br />
                {{ $t("acttraderpage.questionnaire.question1.point6") }} <br />
                {{ $t("acttraderpage.questionnaire.question1.point7") }} <br />
              </p>
            </div>
            <h3
              v-on:click="uninstalldesktop ^= true"
              :class="[{ active: uninstalldesktop }]"
            >
            {{ $t("acttraderpage.questionnaire.question2.heading") }}
            </h3>
            <div class="content" v-show="uninstalldesktop">
              <p>
                {{ $t("acttraderpage.questionnaire.question2.point") }} <br />
                {{ $t("acttraderpage.questionnaire.question2.point1") }}
                <br />
                {{ $t("acttraderpage.questionnaire.question2.point2") }}
              </p>
            </div>
            <h3 v-on:click="web ^= true" :class="[{ active: web }]">
              {{ $t("acttraderpage.questionnaire.question3.heading") }}
            </h3>
            <div class="content" v-show="web">
              <p>
                {{ $t("acttraderpage.questionnaire.question3.point") }} <a href="#">{{ $t("acttraderpage.questionnaire.question3.aTag") }}</a> <br />
                {{ $t("acttraderpage.questionnaire.question3.point1") }} <br />
                {{ $t("acttraderpage.questionnaire.question3.point2") }} <br />
                {{ $t("acttraderpage.questionnaire.question3.point3") }} <br />
              </p>
            </div>
          </div>
        </div>
        <div class="section-small">
          <div class="content-sec mt4-phone act-phone">
            <div class="content-holder wow fadeInLeft">
              <img src="assets/images/act-ico2.webp" alt="AAAFx" width="240" height="125" class="fluid-img" />
              <br /><br />
              <p>{{$t('acttraderpage.journey')}}</p>
              <div class="btn-holder wow fadeIn">
                <a
                  :href="static_vars.platformLinks.actTrader.android"
                  class="btn btn-grey"
                  title="ActTrader Android"
                >
                  <img src="assets/images/phone-ico2.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.actTrader.android ==
                    "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download ActTrader Android"
                  }}
                </a>
                <a
                  :href="static_vars.platformLinks.actTrader.ios"
                  class="btn btn-grey"
                  title="ActTrader iOS"
                >
                  <img src="assets/images/phone-ico1.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.actTrader.ios ==
                    "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download ActTrader iOS"
                  }}
                </a>
              </div>
            </div>
            <div class="img-holder wow fadeInRight">
              <img src="assets/images/act-img3.webp" alt="AAAFx" title="AAAFx" width="650" height="414" class="fluid-img" />
            </div>
          </div>
        </div>

        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="android ^= true" :class="[{ active: android }]">
              {{ $t("acttraderpage.questionnaire.question4.heading") }}
            </h3>
            <div class="content" v-show="android">
              <p>
                {{ $t("acttraderpage.questionnaire.question4.point") }} <br />
                {{ $t("acttraderpage.questionnaire.question4.point1") }}
                <br />
                {{ $t("acttraderpage.questionnaire.question4.point2") }} <br />
                {{ $t("acttraderpage.questionnaire.question4.point3") }} <br />
                {{ $t("acttraderpage.questionnaire.question4.point4") }} <br />
                {{ $t("acttraderpage.questionnaire.question4.point5") }}
              </p>
            </div>
            <h3
              v-on:click="unistallandroid ^= true"
              :class="[{ active: unistallandroid }]"
            >
            {{ $t("acttraderpage.questionnaire.question5.heading") }}
            </h3>
            <div class="content" v-show="unistallandroid">
              <p>
                {{ $t("acttraderpage.questionnaire.question5.point") }} <br />
                {{ $t("acttraderpage.questionnaire.question5.point1") }} <br />
                {{ $t("acttraderpage.questionnaire.question5.point2") }} <br />
              </p>
            </div>
            <h3 v-on:click="ios ^= true" :class="[{ active: ios }]">
              {{ $t("acttraderpage.questionnaire.question6.heading") }}
            </h3>
            <div class="content" v-show="ios">
              <p>
                {{ $t("acttraderpage.questionnaire.question6.point") }} <br />
                {{ $t("acttraderpage.questionnaire.question6.point1") }} <br />
                {{ $t("acttraderpage.questionnaire.question6.point2") }} <br />
                {{ $t("acttraderpage.questionnaire.question6.point3") }}
              </p>
            </div>
            <h3
              v-on:click="unistallios ^= true"
              :class="[{ active: unistallios }]"
            >
            {{ $t("acttraderpage.questionnaire.question7.heading") }}
            </h3>
            <div class="content" v-show="unistallios">
              <p>
                {{ $t("acttraderpage.questionnaire.question7.point") }} <br />
                {{ $t("acttraderpage.questionnaire.question7.point1") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h3>
            {{$t('acttraderpage.heading')}}
          </h3>
          <br />
          <p>{{$t('acttraderpage.features')}}</p>
        </div>
        <div class="img-holder t-center">
          <img src="assets/images/act-img4.webp" alt="AAAFx" title="AAAFx" width="1127" height="287" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <div class="content-sec mt4-phone why-mt4 why-mt5">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h5>
                {{$t('acttraderpage.platform')}}
              </h5>
            </div>
            <p>{{$t('acttraderpage.depth')}}</p>
            <ul class="listing-check">
              <li>{{$t('acttraderpage.ul.li')}}</li>
              <li>{{$t('acttraderpage.ul.li1')}}</li>
              <li>{{$t('acttraderpage.ul.li2')}}</li>
              <li>
                {{$t('acttraderpage.ul.li3')}}
              </li>
              <li>
                {{$t('acttraderpage.ul.li4')}}
              </li>
            </ul>
            <br />
            <p>{{$t('acttraderpage.tradingWindow')}}</p>
            <ul class="listing-check">
              <li>{{$t('acttraderpage.ul.liTag')}}</li>
              <li>{{$t('acttraderpage.ul.liTag1')}}</li>
              <li>
                {{$t('acttraderpage.ul.liTag2')}}
              </li>
              <li>{{$t('acttraderpage.ul.liTag3')}}</li>
            </ul>
            <p>{{$t('acttraderpage.instument')}}</p>
            <ul class="listing-check">
              <li>{{$t('acttraderpage.ul.liTag4')}}</li>
              <li>{{$t('acttraderpage.ul.liTag5')}}</li>
              <li>{{$t('acttraderpage.ul.liTag6')}}</li>
              <li>{{$t('acttraderpage.ul.liTag7')}}</li>
            </ul>
          </div>
          <div class="img-holder wow fadeInRight">
            <img src="assets/images/act-img5.webp" alt="AAAFx" title="AAAFx" width="575" height="365" class="fluid-img" />
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                >{{$t('acttraderpage.liveAccount')}}</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >{{$t('acttraderpage.demoAccount')}}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      desktop: false,
      uninstalldesktop: false,
      web: false,
      android: false,
      unistallandroid: false,
      ios: false,
      unistallios: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
};
</script>
