<template>
  <div id="main-wrapper" class="inner-pages why-page server-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{$t('ourServicesPage.heading')}}</h2>
        <p>{{$t('ourServicesPage.headingDescription')}}</p>
      </div>
    </div>
    <div class="section why-bel equi-sec">
      <div class="wrapper">
        <div class="wow fadeIn">
          <div class="title-holder t-center">
            <h3>{{$t('ourServicesPage.EquinixDataCenters')}}</h3>
          </div>
          <h6>
            {{$t('ourServicesPage.EquinixDataCentersDescription')}}
          </h6>
          <br />
          <div class="title-holder wow fadeIn t-center">
            <h3>{{$t('ourServicesPage.What_are_the_advantages_of_Equinix')}}</h3>
          </div>
          <div class="equinix-listing t-center">
            <div class="equinix-holder wow fadeIn">
              <div class="img-holder">
                <img src="assets/images/eq1.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>{{$t('ourServicesPage.FastestExecution')}}</h3>
              <p>
                {{$t('ourServicesPage.FastestExecutionDescription')}}
              </p>
            </div>
            <div class="equinix-holder wow fadeIn" data-wow-delay="0.3s">
              <div class="img-holder">
                <img src="assets/images/eq2.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>{{$t('ourServicesPage.Low_latency_connection')}}</h3>
              <p>
                {{$t('ourServicesPage.Low_latency_connection_description')}}
              </p>
            </div>
            <div class="equinix-holder wow fadeIn" data-wow-delay="0.6s">
              <div class="img-holder">
                <img src="assets/images/eq3.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>{{$t('ourServicesPage.Uninterrupted_trading')}}</h3>
              <p>
                {{$t('ourServicesPage.Uninterrupted_trading_description')}}
              </p>
            </div>
            <div class="equinix-holder wow fadeIn" data-wow-delay="0.9s">
              <div class="img-holder">
                <img src="assets/images/eq4.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>{{$t('ourServicesPage.Low_slippage')}}</h3>
              <p>
                {{$t('ourServicesPage.Low_slippage_description')}}
              </p>
            </div>
            <div class="equinix-holder wow fadeIn" data-wow-delay="1.2s">
              <div class="img-holder">
                <img src="assets/images/eq5.webp" alt="AAAFx" title="AAAFx" width="126" height="126" class="fluid-img" />
              </div>
              <h3>{{$t('ourServicesPage.Easy_connectivity')}}</h3>
              <p>
                {{$t('ourServicesPage.Easy_connectivity_description')}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section equi-what">
      <div class="wrapper">
        <div class="mockup-holder wow fadeIn">
          <img src="assets/images/mockup-holder.webp" alt="AAAFx" title="AAAFx" width="864" height="511" class="fluid-img" />
          <div class="video-holder">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8NvjI0lCUqE?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="section equi-accord">
      <div class="wrapper">
        <div class="accordion-holder wow fadeIn">
          <h3 v-on:click="Showing1 ^= true" :class="[{active : Showing1}]">{{$t('ourServicesPage.What_is_Equinix')}}</h3>
          <div class="content" v-show="Showing1">
            <div class="text-holder">
              <p>
                {{$t('ourServicesPage.What_is_Equinix_desone')}}
                <br/><br/>
                {{$t('ourServicesPage.What_is_Equinix_destwo')}}
              </p>
            </div>
            <div class="img-holder img-holder-spacing">
              <img src="assets/images/equi1.webp" alt="AAAFx" title="AAAFx" width="338" height="216" class="fluid-img" />
            </div>
          </div>
          <h3 v-on:click="Showing2 ^= true"  :class="[{active : Showing2}]">{{$t('ourServicesPage.What_is_the_LD4_Data_Center')}}</h3>
          <div class="content" v-show="Showing2">
            <p>
              {{$t('ourServicesPage.What_is_the_LD4_Data_Center_descOne')}}
              <br />
              {{$t('ourServicesPage.What_is_the_LD4_Data_Center_descTwo')}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        Showing1:false,
        Showing2:false,
      }
    }
  }

</script>