<template>
  <div>
    <Form class="form" @submit="submit">
      <div class="">
        <div class="">
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">Asset Class</label>
              <Field
                class="form-control"
                as="select"
                name="segment"
                rules="required"
                v-model="segment"
                @change="(ev) => getSymbol(ev.target.value)"
              >
                <option value="" disabled>Please select an option</option>
                <option value="forex">Forex</option>
                <option value="commd">Commodities</option>
                <option value="indic">Indices</option>
                <option value="crypto">Crypto Currencies</option>
                <option value="stocks">Stocks</option>
              </Field>
              <ErrorMessage name="segment" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">Account Base Currency</label>
              <Field
                class="form-control"
                as="select"
                name="basecurrency"
                rules="required"
                v-model="basecurrency"
              >
                <option value="USD">USD</option>
                <option value="GBP">GBP</option>
                <option value="AUD">AUD</option>
                <option value="EUR">EUR</option>
              </Field>
              <ErrorMessage name="basecurrency" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">Symbol</label>
              <Field
                class="form-control"
                as="select"
                name="symbol"
                rules="required"
                v-model="symbol"
              >
                <option value="" disabled>Select Symbol</option>
                <option v-for="symbol of isins" :value="symbol" :key="symbol">
                  {{ symbol }}
                </option>
              </Field>
              <ErrorMessage name="symbol" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">Trade Size(in lots)</label>
              <Field
                class="form-control"
                type="number"
                name="tradesize"
                rules="required|numeric"
                v-model="tradesize"
              />
              <ErrorMessage name="tradesize" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="btn-holder">
              <button type="submit" class="btn btn-bluey">Calculate</button>
            </div>
            <div v-if="submitted && message == ''">
              <p>
                <strong>Margin Required: </strong>{{ basecurrency }}
                {{ commsionCYR.toFixed(2) }}
              </p>
              <p><strong>Maximum Leverage: </strong>{{ commsionCYRLev }}x</p>
            </div>
            <div v-else>
              <p>
                <strong>{{ message }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { Const } from "@/plugins/vue-provider-cache/const.mod";

export default {
  name: "Calulate-Margin-global",
  data() {
    return {
      isins: [],
      segment: "",
      tradesize: 0,
      symbol: "",
      commission: 0,
      basecurrency: "USD",
      noofdays: 0,
      commsionCYR: 0,
      commsionCYRLev: "-",
      commsionCYPRF: 0,
      commsionCYPRFLev: "-",
      submitted: false,
      message: "",
      exch:{
        'forex': this.static_vars.pair_list.forex,
        'commd': this.static_vars.pair_list.commodities,
        'indic': this.static_vars.pair_list.indices,
        'crypto' : this.static_vars.pair_list.cryptos,
        'stocks' : this.static_vars.pair_list.stocks,
      }
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  computed: {
    get_currencies() {
      try {
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCurrencies();
      } catch (ex) {
        return [];
      }
    },
    get_indices() {
      try {
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getIndicesAll();
      } catch (ex) {
        return [];
      }
    },
    get_crypto_pair(){
      try {
        this.$store.getters[Const.GET_HEARTBEATS]['priceFeed']
        return this.$store.getters[Const.GET_PROVIDERS]['priceFeed']
          .iCache.getCryptosAll()
      } catch (ex) {
        return []
      }
    },
    get_stock(){
      try {
        this.$store.getters[Const.GET_HEARTBEATS]['priceFeed']
        return this.$store.getters[Const.GET_PROVIDERS]['priceFeed']
          .iCache.getStocksAll()
      } catch (ex) {
        return []
      }
    },
    get_commodities() {
      try {
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCommodityAll();
      } catch (ex) {
        return [];
      }
    },
    getAllIsins() {
      try {
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.asKeysValueArrayAll();
      } catch (ex) {
        return [];
      }
    },
  },
  methods: {
    submit() {
      this.calculateComm();
    },
    getSymbol(getSymbolFor) {
      if (getSymbolFor == "commd") {
        this.isins = Object.keys(this.get_commodities);
      }
      if (getSymbolFor == "forex") {
        this.isins = Object.keys(this.get_currencies);
      }
      if (getSymbolFor == "indic") {
        this.isins = Object.keys(this.get_indices);
      }
      if (getSymbolFor == "crypto") {
        this.isins = Object.keys(this.get_crypto_pair);
      }
      if (getSymbolFor == "stocks") {
        this.isins = Object.keys(this.get_stock);
      }
    },
    calculateComm() {
      this.submitted = true;
      this.message = "";
      //selected symbol
      let symb = this.getAllIsins[this.symbol];
      //Data will come here with all live price
      if (parseFloat(this.tradesize) >= parseFloat(symb.other.Min_Trade_Size)) {
        // let contractSize = symb.other.Contract_Size;

        let contractSize = (this.exch[this.segment][this.symbol] || {}).contract_size || symb.other.Contract_Size;
        let Leverage = (this.exch[this.segment][this.symbol] || {}).leverage || symb.other.levRT;

        contractSize = contractSize.match(/(\d+)/)[0];

        this.commsionCYRLev = Leverage;
        this.commsionCYPRFLev = 500;

        if (symb.other.type.toLowerCase() == "indicies") {
          let currencyPrice = this.currencyConverstionSecond(this.symbol);

          this.commsionCYR = parseFloat(
            (this.tradesize * symb.B * currencyPrice) / this.commsionCYRLev
          );

          this.commsionCYPRF = parseFloat(
            (this.tradesize * symb.B * currencyPrice) / this.commsionCYPRFLev
          );
        } else {
          let currencyPrice = this.currencyConverstion(this.symbol);
          this.commsionCYR = parseFloat(
            (this.tradesize * contractSize * currencyPrice) /
              this.commsionCYRLev
          );
          this.commsionCYPRF = parseFloat(
            (this.tradesize * contractSize * currencyPrice) /
              this.commsionCYPRFLev
          );
        }
      } else {
        this.message =
          "Minimum trade size should be " +
          parseFloat(symb.other.Min_Trade_Size);
      }
    },
    currencyConverstion(selectedSymbol) {
      //all_currencies_price will get all currecies prices

      let secondCurrency = selectedSymbol.substring(0, 3);

      if (secondCurrency == this.basecurrency) {
        return 1;
      } else if (this.getAllIsins[secondCurrency + this.basecurrency]) {
        return this.getAllIsins[secondCurrency + this.basecurrency].A;
      } else if (this.getAllIsins[this.basecurrency + secondCurrency]) {
        return 1 / this.getAllIsins[this.basecurrency + secondCurrency].A;
      } else {
        let secontoUSD = 0;
        let accBasetoUSD = 0;
        if (this.getAllIsins[secondCurrency + "USD"]) {
          secontoUSD = this.getAllIsins[secondCurrency + "USD"].A;
        } else if (this.getAllIsins["USD" + secondCurrency]) {
          secontoUSD = 1 / this.getAllIsins["USD" + secondCurrency].A;
        }

        if (this.basecurrency == "USD") {
          accBasetoUSD = 1;
        } else if (this.getAllIsins[this.basecurrency + "USD"]) {
          accBasetoUSD = 1 / this.getAllIsins[this.basecurrency + "USD"].A;
        } else if (this.getAllIsins["USD" + this.basecurrency]) {
          accBasetoUSD = this.getAllIsins["USD" + this.basecurrency].A;
        }

        return accBasetoUSD * secontoUSD;
      }
    },
    currencyConverstionSecond(selectedSymbol) {
      //all_currencies_price will get all currecies prices

      let secondCurrency = selectedSymbol.substring(3, 6);

      if (secondCurrency == this.basecurrency) {
        return 1;
      } else if (this.getAllIsins[secondCurrency + this.basecurrency]) {
        return this.getAllIsins[secondCurrency + this.basecurrency].A;
      } else if (this.getAllIsins[this.basecurrency + secondCurrency]) {
        return 1 / this.getAllIsins[this.basecurrency + secondCurrency].A;
      } else {
        let secontoUSD = 0;
        let accBasetoUSD = 0;
        if (this.getAllIsins[secondCurrency + "USD"]) {
          secontoUSD = this.getAllIsins[secondCurrency + "USD"].A;
        } else if (this.getAllIsins["USD" + secondCurrency]) {
          secontoUSD = 1 / this.getAllIsins["USD" + secondCurrency].A;
        }

        if (this.basecurrency == "USD") {
          accBasetoUSD = 1;
        } else if (this.getAllIsins[this.basecurrency + "USD"]) {
          accBasetoUSD = 1 / this.getAllIsins[this.basecurrency + "USD"].A;
        } else if (this.getAllIsins["USD" + this.basecurrency]) {
          accBasetoUSD = this.getAllIsins["USD" + this.basecurrency].A;
        }

        return accBasetoUSD * secontoUSD;
      }
    },
  },
  created() {
    // this.getSymbol("forex");
  },
};
</script>
