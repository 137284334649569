<template>
	<div>
		<div id="chartdiv" ref="chartdiv" style="height: 450px"></div>
	</div>
</template>
<script>
// import * as am4core from "../../../../node_modules/@amcharts/amcharts4/core"
// import * as am4charts from "../../../../node_modules/@amcharts/amcharts4/charts"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;
am4core.options.commercialLicense = true;
export default {
    name: 'Buy-Sell-Depth-chart',
    // props:['buySellType'],
    data() {
        return {
            chart:{}
        }
    },
    props:['chart_data'],
    methods:{
        generateDailyData(data) {
            return data.map(val=>{
                let date = new Date(val.date)
                date.setHours(0, 0, 0, 0);
                val.date = date ;
                // if(date.getFullYear() >= new Date().getFullYear() - 2){
                return val
                // }
            })
        },
        InitializeChart(){
            this.loading = true
            var chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

            // Create daily series and related axes
            var dateAxis1 = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis1.renderer.grid.template.location = 0;
            dateAxis1.renderer.minGridDistance = 40;

            var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());

            var series1 = chart.series.push(new am4charts.ColumnSeries());
            series1.dataFields.valueY = "value";
            series1.dataFields.dateX = "date";
            var columnTemplate = series1.columns.template;
            columnTemplate.width = 20;
            columnTemplate.fill = am4core.color("#5a5");
            columnTemplate.strokeOpacity = 0;
            chart.seriesContainer.resizable = false;
            // am4charts.categoryAxis.renderer.cellStartLocation = 0;
            // am4charts.categoryAxis.renderer.cellEndLocation = 0.5;
            // series.columns.template.width = am4core.percent(100);

            // chart.events.on("datavalidated", function () {
            //     dateAxis1.zoomToDates(new Date().setFullYear(new Date().getFullYear() - 2), new Date(), false, true);
            // });

            // series1.data = this.chart_data;
            series1.data = this.generateDailyData(this.chart_data);
            series1.xAxis = dateAxis1;
            series1.yAxis = valueAxis1;
            series1.tooltipText = "{dateX}: [bold]{valueY}[/]";

            // Add cursor
            chart.cursor = new am4charts.XYCursor();
            this.loading = false
            this.chart = chart
            // chart.data = this.chart_data
        }
    },
    // beforeDestroy() {
    //     if (this.chart) {
    //         this.chart.dispose();
    //     }
    // },
    created(){
        // console.log('chart_data',JSON.stringify(this.chart_data))
        let _that = this
        setTimeout(function(){ _that.InitializeChart() }, 1000);
    }
}
</script>
