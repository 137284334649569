export default {
  locales: {
    it: "Italian",
  },
  fatca: "FATCA",
  crs: "CRS",
  acttrader: "ActTrader",
  compairacc: "Confronta i conti",
  ecnacc: "CONTO ECN",
  openacc: "Aprire un conto",
  crypto: "Criptovalute",
  stock: "Azioni",
  forex: "Forex",
  indices: "Indici",
  commodities: "Materie prime",
  negativebalance: "Protezione del saldo negativo",
  minorderdest: "Distanza minima dell'ordine",
  stopout: "stop-out",
  margincall: "Margin Call",
  yes: "Sì",
  no: "No",
  lot: "lotto",
  usd: "USD",
  eur: "EUR",
  gbp: "GBP",
  aud: "AUD",
  basecurrency: "Valute di base",
  upto: "fino a",
  mindeposit: "Min. Deposito",
  account: "Conto",
  zulu: "zulu",
  zulu_trade: "Zulu_Trade",
  plus: "più",
  max: "max",
  min: "min",
  leverage: "Leva",
  per: "per",
  commission: "Commissione",
  minpip: "pip min.",
  spreads: "Spread",
  spread: "Diffusione",
  ecn: "ECN",
  keyinfo: "Documenti informativi chiave",
  mifid: "Legislazione MIFID",
  privacypolicy: "Informativa sulla privacy",
  contact: "Contatti 24/5",
  webtrader: "Web-Trader",
  login: "Accesso",
  joinNow: "Iscriviti ora",
  aboutAAAfx: {
    name: "Informazioni su AAAFx",
    submenu: {
      why: {
        title: "Perché AAAFx",
        tagline: "Conoscere chi siamo",
      },
      safety: {
        title: "Sicurezza dei fondi",
        tagline: "Noi la copriamo le spalle",
      },
      servers: {
        title: "I nostri server",
        tagline: "I migliori sistemi per ottenere i migliori risultati",
      },
      regulations: {
        title: "Regolamenti e licenze",
        tagline: "Trading affidabile e sicuro",
      },
      legalDocs: {
        title: "Documenti legali",
        tagline: "Leggere tutti i documenti legali",
      },
      careers: {
        title: "Carriera",
        tagline: "Si unisca al nostro team",
      },
      contactUs: {
        title: "Contatti",
        tagline: "Saremo lieti di ascoltarla",
      },
    },
    title:
      "Triple A Experts Investment Services Single Member SA è autorizzata e regolamentata dalla Commissione ellenica per il mercato dei capitali (id:2/540/17.2.2010) con sede legale presso Triple A Experts Investment Services Single Member S. A, 580 Vouliagmenis Avenue 16452, Argyroupoli Attica.",
  },
  trading: {
    name: "Trading",
    submenu: {
      products: {
        title: "Prodotti commerciali",
        tagline: "Strumenti di trading",
      },
      accountTypes: {
        title: "Tipi di conti",
        tagline: "Scegli ciò che si adatta alle tue esigenze",
      },
      tradingConditions: {
        title: "Condizioni di trading",
        tagline: "Tutto per soddisfare i tuoi interessi",
      },
      depWithdrawal: {
        title: "Depositi e prelievi",
        tagline: "Gestione del denaro senza problemi",
      },
    },
  },
  platforms: {
    name: "Piattaforme",
    submenu: {
      mt4: {
        title: "MT4",
        tagline: "La piattaforma di trading più popolare",
      },
      mt5: {
        title: "MT5",
        tagline: "Avanzato e multifunzionale",
      },
      act: {
        name: "ATTO",
        title: "ActTrader",
        tagline: "Facile ed intuitivo",
      },
      comparison: {
        title: "Confronto tra le piattaforme",
        tagline: "Prendi una decisione informata",
      },
    },
  },
  promotions: {
    name: "Promozioni",
    submenu: {
      bonus: {
        title: "Bonus di deposito",
        tagline: "Raddoppia i tuoi margini senza alcun costo",
      },
      refer: {
        title: "Fare riferimento e guadagnare",
        tagline: "Invita i tuoi amici e condividi i vantaggi",
      },
      vps: {
        title: "VPS gratuito",
        tagline: "Usufruisci del trading gratuito ad alta velocità",
      },
    },
  },
  tools: {
    name: "Strumenti e risorse",
    submenu: {
      zulu: {
        title: "ZuluTrade",
        tagline: "Unisciti alla più grande rete di social trading",
      },
      calculators: {
        title: "Calcolatori di trading",
        tagline: "Calcoli facilitati per te",
      },
      ecoCalendar: {
        title: "Calendario economico",
        tagline: "Rimani aggiornato e fai trading con saggezza",
      },
      partnerships: {
        title: "Collaborazioni",
        tagline: "Collabora con noi e cresci",
      },
      faq: {
        title: "Domande frequenti",
        tagline: "Ricevi risposte alle domande più frequenti",
      },
    },
  },
  legalinfo: {
    name: "Informazioni legali",
    para1:
      "Triple A Experts Investment Services Single Member SA è autorizzata e regolamentata dalla Commissione ellenica per il mercato dei capitali (id:2/540/17.2.2010) con sede legale presso Triple A Experts Investment Services S.A., via Akti Kondyli 14, 18545 Pireo, Grecia.",
    para2:
      "Sikhula Venture Capital (Pty) Ltd. è autorizzata e regolata dalla Financial Sector Conduct Authority sudafricana (FSCA n. 2017/315029/07).",
    para3:
      "www.aaafx.com è gestito da Triple A Experts Investment Services, Membro Unico SA.",
    para4:
      "www.aaafx.com è gestito da Triple A Experts Investment Services Single Member SA.",
  },
  riskdisc: {
    name: "Divulgazione del rischio",
    para_one:
      "I CFD sono strumenti complessi e comportano un rischio elevato di perdere rapidamente denaro a causa della leva finanziaria.",
    para_two:
      "dei conti degli investitori al dettaglio perdono denaro quando fanno trading di CFD con AAAFx. Dovrebbe valutare se comprende il funzionamento dei CFD e se può permettersi di correre il rischio elevato di perdere il suo denaro. Maggiori dettagli possono essere trovati",
    link: "qui.",
  },
  accounttypepage: {
    title: "Tipi di conto",
    tagline1:
      "Scegli il conto più adatto alle tue esigenze di trading! Abbiamo offerte interessanti per",
    tagline2: "ogni trader, in modo altamente semplificato.",
    zeroPointZero: "0.0",
    hundredDollar: "$100k",
    fiveDollar: "$5",
    threeDollar: "$3",
    thirtyX: "30X",
    hundered: "$100",
    tenThousand: "$10,000",
    chf: "CHF",
    seventyFifty: "70/50%",
    para1: {
      desc1:
        "Esistono tre diversi tipi di conto, che soddisfano le esigenze di un trader principiante, di un trader professionista o di un trader istituzionale. Alcune delle interessanti caratteristiche comuni assicurate in tutti i conti sono:",
      point1: "Broker regolamentato dall'UE",
      point2: "Spread ultra-sottili",
      point3: "Tutte le strategie di trading consentite",
      point4: "Nessun ordine minimo. Distanza dell'ordine - ordini a piacere",
      point5: "Protezione del saldo negativo",
      point6: "Trading di micro lotti",
      desc2:
        "Senza alcuna discriminazione, offriamo spread e liquidità di livello istituzionale a tutti i trader.",
    },
    accdesc: {
      title: "Descrizione del conto",
      point1: {
        title: "Conto ECN",
        desc: "Godi di spread ECN grezzi e di un'esecuzione delle operazioni fulminea a costi molto bassi. Le basse commissioni e i bassi costi di swap, combinati con la nostra tecnologia avanzata, rendono la tua esperienza di trading esemplare in tutti i sensi. Inoltre, non imponiamo alcuna restrizione alle strategie di trading.",
      },
      point2: {
        title: "Conto ECN plus",
        desc: "Per i trader con un deposito minimo di 10.000 euro o per quelli della categoria AAA nel Programma Fedeltà, ECN zero è una ricompensa a cui ambire. Si tratta della migliore combinazione possibile per qualsiasi trader: zero commissioni sul Forex con spread sottili, senza mark up. Potrai godere di tutto questo insieme alla nostra esecuzione fulminea delle operazioni.",
      },
      point3: {
        title: "Conto Trading ECN Zulu",
        desc: "Diventa parte della più grande comunità di social trading aprendo un Conto Zulu con noi. Questo conto è automaticamente collegato alla Piattaforma Zulu, non sono necessarie altre azioni. Di conseguenza, potrai godere automaticamente di spread sottilissimi, commissioni ridotte, della nostra tecnologia avanzata, di una liquidità profonda e di un'esecuzione fulminea, oltre all'accesso diretto alla comunità di trading di livello mondiale.",
      },
      para1:
        "Le condizioni di trading più flessibili (aumento della leva finanziaria) possono essere applicate in caso di clienti classificati come Professionisti. Ti preghiamo di accedere al tuo conto e di verificare l'idoneità alla classificazione di Cliente Professionale, oppure di scriverci a",
    },
    supportEmail: "support{'@'}aaafx.com",
  },
  acttraderpage: {
    title: "ActTrader",
    para1:
      "Vuoi fare trading ma non sei sicuro di conoscere le piattaforme di trading avanzate?",
    para2:
      " Noi ti copriamo con la nostra piattaforma di trading ActTrader. ActTrader è il leader del settore del trading online dal 2000, dotato della migliore tecnologia della categoria. La piattaforma si concentra sulla semplicità, sulla leggibilità e sulla sfida alle tradizioni di trading esistenti con una tecnologia robusta, in modo che l'utente possa concentrarsi sulle opzioni di trading. ActTrader ha assistito ad un aumento molteplice del volume di trading, fino a 5.000 miliardi di dollari al mese, a conferma della profonda liquidità della piattaforma.",
    para3: "Inizia il tuo viaggio di trading senza problemi con ActTrader:",
    questionnaire: {
      question1: {
        heading: "Passi per scaricare ActTrader Desktop",
        point: "1. Scarica la piattaforma dal link qui sopra.",
        point1: "2. Esegui il file .exe",
        point2:
          "3. Inserisci il numero di conto trading e la password sulla tua piattaforma ACT.",
        point3:
          " 4. Seleziona il tipo di conto : Demo per il conto demo / Reale per il conto live.",
        point4: "5. Seleziona la lingua preferita.",
        point5: '6. Clicca su "login".',
        point6:
          "7. Verrà visualizzata una piccola casella. Seleziona il fuso orario.",
        point7:
          "8. Anche in questo caso, si aprirà una piccola finestra. Seleziona il layout della piattaforma principiante/avanzato.",
      },
      question2: {
        heading: "Passi per disinstallare ActTrader Desktop",
        point: "1. Clicca sul pulsante Start in Windows",
        point1: "2. La voce ACT sarà inclusa nell'elenco del menu.",
        point2:
          "3. Clicca con il tasto destro del mouse sul nome dell'applicazione e seleziona 'Disinstalla'.",
      },
      question3: {
        heading: "Passi per accedere ad Act Trader basato sul web",
        point: "1. Clicchi sul link Web Trader",
        aTag: "qui",
        point1:
          '2. Seleziona "Demo web trader" per il conto demo o "Live web trader" per il conto live.',
        point2: "3. Si aprirà una nuova scheda.",
        point3:
          "4. Compila le credenziali di accesso. Ora hai effettuato l'accesso.",
      },
      question4: {
        heading: "Passi per scaricare ActTrader Android",
        point: "1. Installa l'applicazione dal link sopra indicato.",
        point1:
          "2. Clicca sull'applicazione installata sul tuo dispositivo Android.",
        point2: '3. Premi "Accetta" sul contratto di licenza.',
        point3:
          "4. Devi inserire il numero del tuo conto trading sul nome utente e la relativa password.",
        point4:
          "5. Selezionare il tipo di conto: AAAFX Demo per la demo e AAAFX Live per il live.",
        point5: '6. Clicca su "login".',
      },
      question5: {
        heading: "Passaggi per disinstallare ActTrader Android",
        point:
          "1. Nel Menu, vai su Impostazioni > Applicazioni > Gestisci applicazioni",
        point1: "2. Trovi l'ACT elencato e tocchi il suo nome.",
        point2: "3. Cliccare Disinstalla.",
      },
      question6: {
        heading: "Passi per scaricare ActTrader iOS",
        point: "1. Apri l'applicazione ActPhone.",
        point1: "2. Inserisci le credenziali di accesso condivise via e-mail.",
        point2:
          "3. In Tipo di conto, scorri verso il basso e seleziona AAAFX Demo per un conto Demo o AAAFX Live per un conto Live.",
        point3: "4. Seleziona Accedi e attendi il caricamento del tuo account.",
      },
      question7: {
        heading: "Passaggi per disinstallare ActTrader iOS",
        point:
          "1. Premi a lungo sull'icona dell'applicazione ActTrader finché tutte le icone sullo schermo non iniziano a tremare.",
        point1:
          "2. Clicca sulla \"x\" nell'angolo superiore sinistro dell'icona dell'applicazione ActTrader.",
      },
    },
    journey: "Inizia il tuo viaggio di trading senza problemi con ActTrader:",
    heading:
      "AAAFx ActTrader promette di consentire all'utente di esplorare una moltitudine di opportunità di trading con condizioni su misura e la possibilità di avere una panoramica di tutto da zero.",
    features: "Caratteristiche principali della piattaforma AAAFx ActTrader:",
    platform:
      "Sei ancora titubante? Dai un'occhiata alla breve panoramica delle funzionalità offerte dalla piattaforma ActTrader:",
    depth: "Strumenti analitici approfonditi:",
    ul: {
      li: "Un'ampia varietà di indicatori già installati",
      li1: "Strumenti di disegno multipli da utilizzare sui grafici",
      li2: "Lavoro semplificato con l'opzione di trading con un solo clic",
      li3: "Imposta gli avvisi sui prezzi chiave, in modo da non perdere mai nessuna opportunità.",
      li4: "Esporta i dati del conto direttamente in Excel con la massima comodità.",
      liTag: "Aggiungere/rimuovere uno spazio di lavoro aggiuntivo",
      liTag1: "Formatta le impostazioni di data e ora",
      liTag2:
        "Aggiungi dei colori al tuo terminale di trading e crea un'area di lavoro divertente per te.",
      liTag3: "I prezzi della piattaforma Streaming nel modo che preferisci",
      liTag4: "70+ valute forex",
      liTag5: "Materie prime popolari, tra cui l'oro e il petrolio",
      liTag6: "11 indici globali - Nasdaq, Dow Jones, ecc.",
      liTag7: "6 criptovalute più importanti e più transate",
    },
    tradingWindow: "Personalizza la tua finestra di trading:",
    instument: "Strumenti offerti:",
    liveAccount: "APRI UN CONTO LIVE",
    demoAccount: "APRI UN CONTO DEMO",
  },
  loginPage: {
    h3Tag: "Attendi prego. Reindirizzamento al tuo account",
  },
  compensation: {
    title: "Fondo di compensazione",
    questions: {
      ques: "Che cos'è la Commissione finanziaria?",
      ans: "La Commissione Finanziaria è un organismo indipendente, costituito in modo unico, per far sentire i trader sicuri e protetti. L'obiettivo principale della Commissione Finanziaria è quello di risolvere in modo rapido ed efficace le controversie tra trader e broker. Opera in un modo in cui la trasparenza, la rapidità e l'educazione sono i valori principali. Tutti i servizi della Commissione finanziaria sono assolutamente gratuiti per i nostri clienti.",
      ques1: "Come funziona?",
      ans1: "Un Fondo di compensazione è finanziato dalla Commissione finanziaria attraverso l'assegnazione del 10% delle quote associative al fondo, che viene tenuto in un conto bancario separato e non può essere utilizzato per finanziare le operazioni o altre attività dell'organizzazione. Il Fondo fornisce una rete di sicurezza ai clienti AAAFx fino a 20.000 euro per un singolo reclamo.",
      ques2: "Perché abbiamo aderito alla Commissione finanziaria?",
      ans2: "La Commissione Finanziaria è stata riunita da alcuni dei principali specialisti del settore, il cui obiettivo principale è quello di aumentare la trasparenza del mercato e gli standard di servizio nell'industria del Forex. Questo è in linea con ciò in cui crediamo veramente in AAAFx: onestà e trasparenza. Mantenere i clienti al centro della nostra attività è una delle nostre ideologie principali. Per questo motivo, ci siamo uniti alla Commissione Finanziaria per fornire una risoluzione efficace e tempestiva delle controversie ai nostri clienti.",
    },
    steps: "Quali sono le fasi della controversia",
    process: "Processo di risoluzione?",
    complaint: "Reclamo",
    pTag: "Un cliente può contattare la Commissione se",
    brTag: "hanno ricevuto una risposta ufficiale dal",
    brTag1: "broker in merito a una controversia non risolta che",
    brTag2: "il cliente ritiene che non sia soddisfacente.",
    investigation: "Indagine",
    pTag1: "Una volta che il Comitato per la risoluzione delle controversie",
    brTag3: "ha ricevuto il reclamo, l'indagine e",
    brTag4: "inizia la ricerca e viene presa una decisione",
    brTag5: "entro 1-2 settimane.",
    text: "Compensazione",
    pTag2: "Quando i broker si rifiutano di rispettare le decisioni",
    brTag6: "dalla Commissione finanziaria, un",
    brTag7: "Il compenso viene corrisposto al commerciante dallo speciale",
    brTag8: "fondo di compensazione.",
    decision: "Decisione",
    resolutionRegarding: "La Risoluzione dei Comitati riguardante",
    brTag9: "la valutazione della controversia è vincolante per",
    brTag10: "Il broker deve attenersi a quanto indicato da",
    brTag11: "il broker.",
  },
  zuluTools: {
    title: "ZULUTRADE",
    pTag: "Non fare trading da solo: unisciti alla più grande rete di social trading.",
    para: "Su AAAFx, hai la possibilità di negoziare liquidità di livello istituzionale e di sfruttare le nostre condizioni di trading superiori. Tuttavia, a volte il trading può essere molto impegnativo per il tuo tempo e per le tue emozioni, il che può ostacolare un'esperienza di trading di successo per alcune persone.",
    bTag: "Su AAAFx, hai la possibilità di negoziare liquidità di livello istituzionale e di sfruttare le nostre condizioni di trading superiori. Tuttavia, a volte il trading può essere molto impegnativo per il tuo tempo e per le tue emozioni, il che può ostacolare un'esperienza di trading di successo per alcune persone.",
    bTag1:
      "Fondata nel 2007, ZuluTrade aiuta gli investitori a scoprire trader eccezionali da tutto il mondo e a copiare le loro strategie per fare trading per conto proprio. Si tratta di una piattaforma online di social e copy trading che consente agli utenti di adottare il piano d'azione di un esperto per le loro operazioni. Classifica le performance degli esperti e consente al trader di notare e copiare prontamente le operazioni dal loro portafoglio. Oggi è una delle reti di social trading più popolari.",
    copier: "Diventa un Copier",
    pTag1:
      "Con il nostro Conto ZuluTrade, ottiene un accesso diretto e automatico a una pletora di opportunità di guadagno attraverso la connessione Zulu.",
    brTag:
      "Può semplicemente esaminare le performance di trading dei trader esperti, copiarle e rilassarsi!",
    register: "REGISTRAZIONE",
    master: "Diventa un Master",
    pTag2:
      "Se vuoi diventare un trader Master e vuoi che gli altri vedano e copino la tua esperienza di trading, con noi ha anche questa possibilità. Il tuo conto AAAFx è automaticamente collegato a Zulu.",
    brTag1:
      "In questo modo, potrai ottenere profitti sul tuo conto di trading e guadagnare anche commissioni di performance dai tuoi copier!",
    offers:
      "ZuluTrade offre una varietà di funzioni e prodotti, alcuni dei quali sono elencati di seguito:",
    platform: "Piattaforma sociale ZuluTrade:",
    pTag3:
      "Sia che tu sia un trader e abbia una strategia di trading di successo da condividere, sia che tu sia un investitore e vuoi discutere dei Trader Zulu e delle loro performance, ecco un forum unico. ZuluTrade dispone di un'ampia piattaforma di comunità di social trading, che include blog, discussioni sul trading e social feed. Questo significa la possibilità di interagire con la comunità di trading e di imparare da loro.",
    zuluGuard: "ZuluTrade Guard:",
    pTag4:
      "ZuluTrade Guard offre una protezione del conto monitorando il comportamento dell'esperto e non segue il fornitore di segnali nel caso in cui vengano apportate modifiche alla strategia di trading.",
    brTag2:
      "Lo fa calcolando un valore di uscita per le operazioni aperte sul conto e quando la soglia viene raggiunta, le operazioni vengono chiuse.",
    automator: "Automatore:",
    pTag5:
      "Automator è l'acronimo di Automated Trading. Si riferisce al trading attraverso regole predefinite per l'entrata e l'uscita dei trade eseguiti da un programma automatizzato. Aiuta a eliminare le emozioni del trading e a risparmiare tempo.",
    combos: "Combinazioni:",
    pTag6:
      "I trader vengono raggruppati da ZuluTrade in combinazioni basate sulla loro propensione al rischio e sulle loro strategie. L'investitore può scegliere una combinazione da seguire. Ogni combinazione è gestita dal Team di gestione del portafoglio certificato di Zulu Trade.",
    quickSteps: "Tre rapidi passaggi per iniziare a copiare:",
    openFund: "Apri e finanzia il tuo conto AAAFx",
    zuluAccount: "Conto ECN zulu",
    cred: "Ricevi le credenziali di accesso a Zulu via e-mail",
    zuluTrade: "Inizia a copiare le operazioni da Zulu Trade",
    openLive: "APRI UN CONTO LIVE",
    openDemo: "APRI UN CONTO DEMO",
  },
  zuluTrade: {
    mt4: "MT4",
    zulutrade: "ZuluTrade",
    h5Tag:
      "Le persone tendono a ottenere risultati migliori nelle comunità e seguendo coloro su cui si può fare affidamento.",
    pTag: "AAAfx comprende questa esigenza e offre la piattaforma Zulu Trade, la più ricercata. Zulu Trade è stata fondata nel 2007, in un momento in cui il mondo non disponeva di un meccanismo adeguato per la verifica delle pratiche di trading e per la condivisione delle conoscenze di trading. Questa piattaforma ha anche eliminato la necessità di stare ore e ore davanti a uno schermo e ha introdotto il trading finanziario automatizzato e professionale nel mainstream.",
    brTag:
      "Zulu Trade è una piattaforma di trading automatizzata, che fornisce un'analisi statistica approfondita dei migliori trader, per consentire all'utente di acquisire una visione e di stabilire un sistema per copiare le operazioni e guadagnare il vantaggio.",
    startTrading: "Inizia a fare trading in soli 3 semplici passi:",
    openFund: "Apri e finanzia il tuo conto AAAFx",
    ecnPlus: "Conto commerciale ECN Zulu",
    variousTraders: "Analizzare le performance dei vari trader.",
    copy: "Seleziona il trader che desideri copiare.",
    pTag1:
      "Puoi anche scegliere tra strategie diversificate costruite con le migliori combinazioni dei migliori trader di Zulu Trade. Scelga il servizio Combo+ e avrai il tuo conto gestito dal Team di gestione del portafoglio certificato di ZuluTrade in modalità completamente automatica o semi automatica, come desideri!",
    ztp: "ZTP è una piattaforma puramente web che può essere facilmente raggiunta qui.",
    webBase: "Piattaforma basata sul web",
    h3Tag:
      "AAAFx offre un'esperienza di trading eccezionale combinata con le seguenti caratteristiche dello ZTP di classe mondiale:",
  },
  why: {
    title: "Perché AAAFx?",
    honesty: "L'eccellenza con onestà e trasparenza",
    believe: "Perché credere in AAAFx?",
    pTag: "Abbiamo una storia più che decennale nel fornire ai nostri clienti globali un servizio affidabile ed eccellente. Instauriamo una totale trasparenza con i nostri clienti, in modo che possano prendere decisioni ben informate.",
    brTag:
      "Sappiamo cosa serve per essere un trader di successo e per questo puntiamo a fornire ai nostri clienti condizioni di trading e assistenza clienti di prim'ordine. Siamo orgogliosi di essere uno dei broker più affidabili del mercato forex, che serve sia clienti al dettaglio che istituzionali.",
    listing: {
      point: "Broker sicuro e affidabile",
      point1: "Esecuzione fulminea",
      point2: "Zero Commissione, Spread grezzi sul Forex",
      point3: "Pool di liquidità profonda",
      point4: "3 Piattaforme di trading premium",
      point5: "I centri dati Equinix sono dotati di tecnologia di alto livello",
      point6: "14 anni di esperienza",
      point7: "Diversi metodi per effettuare depositi e prelievi gratuiti",
    },
    more: "Cosa c'è di più?",
    socialTrading: "Auto si collega alla più grande comunità di social trading",
    entireTeam: "E l'intero team AAAFx è immediatamente al tuo servizio!!!",
    pledge: "Ci impegniamo...",
    liTag:
      "Per fornirle un servizio di intermediazione che operi nel migliore interesse dei trader.",
    liTag1:
      "Rispettare le Regole pubblicate nell'ambito dei quadri normativi pertinenti.",
    liTag2: "Cercare continuamente opportunità per innovare e migliorare.",
    liTag3: "Aderire sempre ai nostri Valori fondamentali.",
    founded: "FONDATO NEL",
    serve: "PAESI CHE SERVIAMO",
    trade: "Strumenti per il commercio",
    platform: "Piattaforme che offriamo",
    support: "Assistenza LIVE",
    number: {
      twoThousand: "2008",
      thirty: "30",
      twoHundred: "200+",
      three: "3",
      twentyFour: "24/5",
    },
    team: "Il nostro team...",
    pTag1:
      "Individualmente, i nostri ideali possono sembrare evidenti. Ma quando li mettiamo insieme, abbiamo la nostra cultura aziendale. Chi siamo, cosa sosteniamo e come agiamo è tutto plasmato dai nostri valori.",
    pTag2:
      "La nostra Direzione è formata da un team di abili professionisti dalla mentalità forte, che hanno una vasta esperienza di lavoro a Wall Street. Pertanto, sanno esattamente cosa serve per essere un trader di successo. Grazie al loro formidabile background e alla loro esperienza nel trading, possiedono una conoscenza approfondita di tutte le migliori e peggiori pratiche del settore.",
    wallStreet: "Persone volitive con un'ampia esperienza nelle borse",
    innovation: "Sempre alla ricerca di innovazione e miglioramento",
    approach: 'L\'approccio "Client First",',
    weDo: "in tutto ciò che facciamo",
    values: "I nostri valori, il tuo successo!",
    pTag3:
      "Siamo fondati su basi solide, in quanto promuoviamo un ambiente basato sui valori in tutti i nostri uffici, che è guidato dalla forte convinzione del nostro management nell'etica aziendale.",
    pTag4:
      "Ci atteniamo ai nostri 5 valori fondamentali, in modo che tu possa brillare nella comunità del trading globale.",
    h3Tag: "Clienti felici in più di",
    countries: "30 Paesi dell'UE*",
    taking: "Prendendo",
    worldClass: "approfittate dei nostri servizi di livello mondiale.",
    france: "*Tranne Belgio e Francia",
    info: "ΕΝΗΜΕΡΩΤΙΚΑ ΣΤΟΙΧΕΙΑ ΕΤΑΙΡΕΙΑΣ/INFORMAZIONI AZIENDALI",
    data: "ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ/DATI FINANZIARI",
    date: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2024",
    thirtyFirst: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2023",
    thirty: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2023",
    financeStatement: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2022",
    financialData: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2022",
    statement: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2021",
    aTag: {
      li: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2021",
      liTag: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2020",
      liTag1: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2020",
      liTag2: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2019",
      liTag3: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2019",
      liTag4: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2018",
      liTag5: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2018",
      liTag6: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2017",
      liTag7: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2017",
      liTag8: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2016",
      liTag9: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2016",
      li1: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2015",
      li2: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2015",
      li3: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2014",
      li4: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2014",
      li5: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2013",
      li6: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2013",
      li7: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2012",
      li8: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2012",
      li9: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2011",
      liTag10: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2011",
      liTag11: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2010",
      liTag12:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2015",
      liTag13:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2014",
      liTag14:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2013",
      liTag15:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2012",
      liTag16:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2011",
      liTag17:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2010",
    },
    dropdown: {
      title: "ΕΠΟΠΤΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ/INFORMAZIONI DI VIGILANZA",
      aTag2: {
        pillar2023:
          "2023 Rapporto sulla Disciplina di mercato e l'informativa del III Pilastro",
        pillar2022:
          "2022 Relazione sulla disciplina di mercato e l'informativa del III Pilastro",
        pillar2021:
          "2021 Relazione sulla disciplina di mercato e sull'informativa del III Pilastro",
        regulatory2023:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΝ 2023_α80&87_Ν4261_2014",
        corporateGovernance2022:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2022_α80&87_Ν4261_2014",
        financial2021:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2021_α80ΩΣ 87_Ν4261_2014",
        financial2020:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2020",
        financial2019:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2019",
        financial2018:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2018",
        financial2017:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2017",
        financial2016:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2016",
        financial2015:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2015",
        financial2014:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2014",
        financial2013:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2013",
        financial2012:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2012",
        financial2011:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2011",
        financial2010:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2010",
        details2020:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2020_α80 ΕΩΣ 87_Ν4261_2014",
        details2019:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2019_α80 ΕΩΣ 87_Ν4261_2014",
        details2018:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2018_α80 ΕΩΣ 87_Ν4261_2014",
        details2017:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2017_α80 ΕΩΣ 87_Ν4261_2014",
        details2016:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2016_α80 ΕΩΣ 87_Ν4261_2014",
        details2015:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΝ 2015_α81&82_Ν4261_2014",
        details2014b:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2015_α80 ΕΩΣ 87_Ν4261_2014",
        details2014:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΝ 2014_α81&82_Ν4261_2014",
      },
    },
    announcment: "ΑΝΑΚΟΙΝΩΣΕΙΣ/ANNOUNCE",
    aTag3: {
      executionVenues2023:
        "2023 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2023 SEDI DI ESECUZIONE",
      executionVenues2022:
        "2022 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2022 SEDI DI ESECUZIONE",
      executionVenues2021:
        "2021 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2021 SEDI DI ESECUZIONE",
      executionVenues2020:
        "2020 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2020 LUOGO DI ESECUZIONE",
      executionVenues2019:
        "2019 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ LUOGO DI ESECUZIONE/2019",
      executionVenues2018:
        "2018 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2018 LUOGO DI ESECUZIONE",
      executionVenues2017:
        "2017 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2017 LUOGO DI ESECUZIONE",
      generalMeetingInvite2013:
        "ΠΡΟΣΚΛΗΣΗ ΤΑΚΤΙΚΗΣ ΓΕΝΙΚΗΣ ΣΥΝΕΛΕΥΣΗΣ 28-06-2013",
    },
    accordion: "ΟΡΓΑΝΟΓΡΑΜΜΑ/ORGANIGRAMMA AZIENDALE",
    officialOrganization: "AAAFx - Organigramma ufficiale (HCMC)",
    certifiedEmploy: {
      title: "ΠΙΣΤΟΠΟΙΗΜΕΝΟΙ ΥΠΑΛΛΗΛΟΙ/DIPENDENTI CERTIFICATI",
    },
    name: "NOME",
    cretificate: "CERTIFICAZIONE",
    issuer: "ISSUER",
    year: "ANNO",
    table: {
      td: "DIMOPOULOS CHRISTOS",
      td1: "Ricezione, trasmissione ed esecuzione di ordini per conto di terzi in titoli (A1)",
      td2: "HCMC",
      td0: "DORDA AIKATERINI",
      td3: "Ricezione, trasmissione ed esecuzione di ordini per conto di terzi in titoli (A1)",
      td4: "KARLIS KONSTANTINOS",
      td5: "Gestione del portafoglio del cliente (C)",
      td6: "KONTOGEORGI ELENI",
      td7: "Ricezione, trasmissione ed esecuzione di ordini per conto di terzi in titoli (A1)",
      td8: "TZIANOUMI GIANNOULA",
      td9: " Fornitura di consulenza sugli investimenti in titoli, quote di organismi di investimento collettivo, strumenti del mercato monetario, derivati e prodotti finanziari strutturati (B)",
      td10: "Commerciante di titoli",
      td11: "ASE",
      td12: "EFSTRATIOS DEI POLICRONEI",
      td13: "Esecuzione di analisi su strumenti finanziari o emittenti (D)",
    },
    maria: "CHRYSAITOU MARIA ARGYRO",
    investment:
      "Fornitura di consulenza sugli investimenti in titoli, quote di organismi di investimento collettivo, strumenti del mercato monetario, derivati e prodotti finanziari strutturati (B)",
    text: "PANAGIOTIS KOURBELIS",
    text1: "praticante di trading desk",
    text2: "GEORGIOS KARMIS",
    responsive: {
      trTag: {
        line: "ΟΝΟΜΑΤΕΠΩΝΥΜΟ",
        line1: "ΠΙΣΤΟΠΙΗΣΗ",
        line2: "ΦΟΡΕΑΣ",
        line3: "ΕΤΟΣ ΚΤΗΣΗΣ/ΑΝΑΝΕΩΣΗΣ",
        line4: "Δημόπουλος Χρήστος",
        line5: "Λήψη, Διαβίβαση και Εκτέλεση Εντολών σε Κινητές Αξίες (Α1)",
        line6: "Επιτροπή Επιτροπή",
        line7: "Καρλής Κωνσταντίνος",
        line8: "Διαχείριση Χαρτοφυλακίων (Γ)",
        line9: "Επιτροπή Κεφαλαιαγοράς",
        line10: "Κοντογεώργη Ελένη",
        line11: "Ντόρντα Αικατερίνη",
        line12: "Ντόρντα Αικατερίνη Εκτέλεση Εντολών σε Κινητές Αξίες (Α1)",
        line13: "Τζιανούμη Γιαννούλα",
        line14: "Παροχή Επενδυτικών Συμβουλών (Β)",
        line15: "Παναγιώτης Κουρμπέλης",
        line16: "Ασκούμενος Trading Desk",
        line17:
          "ΓΝΩΣΤΟΠΟΙΗΣΕΙΣ ΚΑΝΟΝΙΣΜΟΥ ΑΕΙΦΟΡΙΑΣ (SFDR)/INFORMAZIONI SUL REGOLAMENTO DI SOSTENIBILITÀ (SFDR)",
        line18: "Divulgazione del regolamento sulla sostenibilità (SFDR)",
        line19:
          "Γνωστοποιήσεις Κανονισμού Αειφορίας (SFDR) και Πολιτική Βιωσιμότητας (ESG)",
        line20: "Πιστοποιημένος Διαπραγματευτής Αξιών",
        line21: "Χρηματιστήριο Αξιών Αθηνών",
        line22: "Χρυσαΐτου Μαρία Αργυρώ",
        line23: "Γεώργιος Καρμής",
      },
    },
  },
  faq: {
    heading: "Domande frequenti",
  },
  comparePlatform: {
    heading: "Confronta le piattaforme di trading",
    content: '"Più siamo, meglio è".',
    h6Tag:
      "AAAFx assicura di fornire una soluzione unica e migliore per le esigenze di ogni trader con la molteplicità delle piattaforme di trading offerte.",
    pTag: "Ogni piattaforma è dotata dei fondamenti fondamentali della massima velocità di esecuzione, di varie opzioni personalizzabili, che consentono l'uso di indicatori e consulenti esperti, e dei migliori prezzi di mercato disponibili.",
    condition: "Condizioni di trading",
    timeFrames: "Tempistiche",
    leverage: "Leva",
    microLot: "Trading di micro-lotti",
    yes: "Sì",
    scalping: "Copertura e scalping",
    attachment: "Allegato EAs/Indicatori",
    other: "Altri strumenti",
    economic: "Calendario economico incorporato",
    no: "No",
    report: "Formato dei report sul conto",
    html: "HTML",
    language: "Convertitore di lingua di piattaforme",
    priceAlert: "Avvisi di prezzo",
    device: "Compatibilità operativa del dispositivo",
    window: "Windows Desktop",
    mac: "Mac/iPad",
    android: "Android",
    ios: "iOS",
    webBased: "Basato sul web",
    sutability: "Idoneità",
    beginner: "Principianti",
    intermediate: "Intermedi",
    advance: "Avanzato",
    openAccount: "Aprire un conto",
    platform: "Quale piattaforma vorrebbe per sé?",
    choice:
      "Può essere una scelta difficile. Ecco cosa puoi prendere in considerazione:",
    point: "Quanto è facile l'interfaccia utente per te?",
    point1: "Quale piattaforma si adatta alle tue strategie di trading?",
    point2: "Avete un supporto disponibile per quella piattaforma?",
    point3:
      "Come ultima domanda, abbiamo pensato a te. Forniamo un'assistenza tecnica completa in caso di problemi con qualsiasi piattaforma.",
    point4: "Per le altre due domande, può aprire un",
    point5: "Conto demo",
    point6:
      "e abituati a tutte le sfumature delle nostre fantastiche piattaforme di trading!",
  },
  depWith: {
    heading: "Depositi e prelievi",
    title:
      "Una varietà di opzioni facili e convenienti, senza alcun costo da parte nostra.",
    pTag: "Depositare e prelevare dal suo conto di trading non è mai stato così facile! Investa nel suo conto di trading senza pagare alcuna commissione aggiuntiva. Abbiamo diverse opzioni di deposito e prelievo affidabili per rendere il suo viaggio di trading semplice e senza intoppi.",
    serviceFee: "0% Commissione di servizio",
    charge:
      "Non applichiamo alcuna commissione né sui depositi né sui prelievi.",
    safety: "Sicurezza delle transazioni",
    funds:
      "I fondi sono conservati in banche Tier-1 e sono segregati dai fondi propri dell'azienda.",
    quickProcessing: "Elaborazione rapida",
    deposit:
      "I depositi e i prelievi con AAAFX vengono elaborati istantaneamente.",
    minDeposit: "Importo minimo del deposito",
    globalCurrency: "Si accettano valute globali",
    control: "Aumento del controllo",
    security: "Il KYC obbligatorio garantisce una maggiore sicurezza",
    with: "RITIRI",
    seamless:
      "I prelievi senza problemi rendono felice ogni trader, giusto? Questo è ciò che aspiriamo a fare per i nostri clienti. Ecco alcune opzioni facili e veloci da utilizzare per prelevare il suo denaro duramente guadagnato entro 12 ore.",
    step: "Procedura per prelevare dal suo Conto Live AAAFx:",
    step1: "1. Acceda al Portale clienti.",
    step2: "2. Configuri un conto bancario in 'Configurazione della banca'.",
    step3: '3. Clicchi su "Fondi" e poi su "Prelievo".',
    depositText: "Deposito",
    withdrawal: "Prelievi",
    bankCard: "Carte bancarie",
    region: "Regione",
    currencies: "Valute",
    min: "Min. Deposito",
    service: "Tassa di servizio",
    processing: "Tempo di elaborazione",
    worldWide: "In tutto il mondo",
    instant: "INSTANTE",
    fund: "FONDO",
    bankWire: "Trasferimento bancario",
    days: "2-3 GIORNI LAVORATIVI",
    europe: "Europa",
    southEast: "Sud Est Asiatico",
    latinAmerica: "America Latina",
    mexico: "Messico",
    eastAfrica: "Africa orientale",
    global: "Globale",
    info: "Informazioni aggiuntive:",
    step4:
      "1. Tutti i prelievi saranno elaborati tramite il metodo utilizzato per effettuare un deposito sul conto trading.",
    step5:
      "2. AAAFx non addebita alcuna commissione di transazione né sui depositi né sui prelievi.",
    step6:
      "3. Rimborsiamo il costo della transazione da lei sostenuto fino al 5% dell'importo del suo deposito, sul suo conto trading.",
    live: "APRI UN CONTO LIVE",
    demo: "APRI UN CONTO DEMO",
  },
  whiteLabels: {
    banner: {
      menu: {
        white_label: "Etichetta bianca",
      },
      title: "Etichetta bianca",
      subtitle: "La nostra esperienza, il Suo successo!",
    },
    pamm_content: {
      intro: {
        heading:
          "Un'opportunità unica per creare il suo brokeraggio, con l'aiuto della nostra conoscenza, esperienza e supporto.",
      },
      description: {
        paragraph_1:
          "Un programma white-label è un ottimo modo per creare rapidamente una posizione forte sul mercato. Questo è possibile grazie all'utilizzo della nostra tecnologia, di condizioni di trading impeccabili, di servizi di assistenza e di utili strumenti CRM di back-office.",
        paragraph_2:
          "Il team AAAFx si dedica al suo successo attraverso il nostro supporto tecnico e professionale.",
        paragraph_3:
          "Il nostro programma white label le offre la piena flessibilità di progettare i suoi prodotti e servizi, in modo da ottenere la migliore opportunità di far prosperare la sua impresa.",
        paragraph_4:
          "La scelta del broker giusto è il primo passo per raggiungere gli obiettivi desiderati.",
        paragraph_5: "Si prepari e faccia subito la scelta giusta!",
      },
    },
    expectations_section: {
      heading: "Cosa aspettarsi?",
      list: {
        item_1: "Collaborazione con la più grande rete di social trading",
        item_2: "Gestori di conti dedicati per investitori e manager",
        item_3:
          "Flessibilità nell'impostare i parametri e le condizioni del conto.",
        item_4: "Commissioni bassissime con spread grezzi",
        item_5: "Sicurezza dei fondi con un broker di fiducia",
        item_6: "È necessario un piccolo investimento per iniziare",
        item_7: "Trasparenza per gli investitori: nessuna clausola nascosta.",
        item_8:
          "Pieno controllo degli investitori sui depositi e sui prelievi.",
      },
    },
    contact_section: {
      title:
        "È pronto a ricevere soluzioni personalizzate per la sua nuova impresa?",
      contact_us: "Ci contatti ora",
      email_link: "qui",
      email_info: "oppure ci scriva all'indirizzo",
      email_address: "support{'@'}aaafx.com",
    },
  },
  vpsHosting: {
    banner: {
      title: "Hosting VPS gratuito",
    },
    bonus_trade_section: {
      description:
        "Può fare trading da qualsiasi parte del mondo in modo comodo e senza interruzioni di connessione, evitando di preoccuparsi di interruzioni di internet o di corrente. È fondamentale ridurre al minimo qualsiasi latenza durante il trading, perché un piccolo ritardo può costarle una fortuna.",
      customer_approach:
        "In linea con il nostro approccio incentrato sul cliente, vogliamo sempre che i nostri clienti abbiano la migliore esperienza di trading possibile. AAAFx offre servizi di server privato virtuale, senza alcun costo aggiuntivo per lei.",
      advantages_title: "Vantaggi del trading con i VPS",
      advantages_list: {
        item_1:
          "Il VPS consente di eseguire i suoi trade in qualsiasi momento con un ritardo minimo, il che si traduce in un minore slippage.",
        item_2:
          "Il VPS garantisce un funzionamento infinito della piattaforma di trading e la rende più adatta se utilizza robot di trading o segnali di trading.",
        item_3:
          "Le permette di fare trading da qualsiasi luogo del mondo, anche se non è disponibile una connessione internet adeguata, se manca la corrente o se il sistema si blocca.",
        item_4:
          "Il VPS offre anonimato, privacy e sicurezza che le consentono di operare in tutta tranquillità, sapendo che i suoi dati essenziali sono al sicuro.",
      },
    },
    accordion_section: {
      how_to_get_vps: {
        title: "Come posso ottenere un VPS gratuito?",
        content:
          "Se è un cliente con volumi di trading leggermente elevati (con un deposito di almeno 5.000 dollari), ha diritto a un VPS gratuito.",
      },
      why_free_vps: {
        title: "Perché AAAFX offre un VPS gratuito?",
        content:
          "Il nostro partner tecnologico ci fornisce un hosting VPN a basso costo, in cambio di volumi elevati. Ci piace trasferire i vantaggi che otteniamo ai nostri clienti.",
      },
      lesser_deposit: {
        title: "Cosa succede se il mio deposito è inferiore?",
        content:
          "Non è un problema. Può ancora usufruire del nostro VPS ad un canone molto basso di soli 25 dollari al mese. Questo canone sarà dedotto automaticamente dal suo conto trading.",
      },
      other_vps: {
        title:
          "E se volessi utilizzare un altro VPS invece del VPS facilitato AAAFx?",
        content:
          "Se desidera utilizzare il suo VPS o quello di una terza parte, possiamo offrirle un rimborso per il suo VPS fino a 25 dollari al mese, a condizione che il suo deposito netto complessivo per il mese sia di almeno 5.000 dollari. Basta condividere con noi i dettagli dell'acquisto del VPS e la ricevuta del pagamento mensile per poter usufruire di questo rimborso.",
      },
    },
    vps_configuration_section: {
      title: "Configurazione VPS gratuita",
      configuration: {
        intel: {
          title: "INTEL",
          description: "Processore",
        },
        ram: {
          title: "1300 MB",
          description: "RAM",
        },
        bandwidth: {
          title: "25 GB",
          description: "Larghezza di banda",
        },
        disk_space: {
          title: "2 TB",
          description: "Spazio su disco",
        },
      },
    },
    apply_vps_section: {
      title: "Come richiedere un VPS gratuito?",
      steps: {
        step_1: {
          description: "Apra un conto LIVE",
        },
        step_2: {
          description: "Deposito minimo di $5.000 o valuta equivalente",
        },
        step_3: {
          description: "Ci invii un'e-mail all'indirizzo",
          email: "support{'@'}aaafx.com",
        },
      },
    },
  },
  tradingCondition: {
    heading: "Condizioni di trading",
    pTag: "Caratteristiche che le permetteranno di fare trading in modo più redditizio! Un ambiente di trading adatto a tutti i tipi di strategie di trading.",
    h5Tag:
      "Dia un'occhiata a tutti i termini e le condizioni per pianificare un'esperienza di trading impeccabile:",
    content:
      "AAAFx presenta termini e condizioni di trading adatti ad ogni trader - sia esso un principiante o un esperto. Vogliamo che i nostri clienti operino con la massima fiducia e comodità.",
    deepLiquidity: "Trading ECN con liquidità profonda",
    buySell:
      "I conti ECN (Electronic Communication Network) sono progettati per abbinare gli ordini di acquisto e di vendita attraverso l'accesso diretto ai fornitori di liquidità.",
    brTag:
      "Attraverso i nostri Prime LP, siamo in grado di trasferire a tutti i nostri clienti i migliori prezzi di mercato e la liquidità prevalente in modo continuo. In questo modo può accedere ai pool di liquidità più profondi in modo istantaneo ad un costo di spread trascurabile.",
    powerfulArsenal: "Arsenale di trading potente AAAFx",
    arsenalContent:
      "Sappiamo quanto sia cruciale scegliere una piattaforma di trading quintessenziale per implementare la sua strategia di trading! Ci siamo presi cura di tutti i suoi requisiti per operare rapidamente con una navigazione e un'interfaccia utente semplici e per guadagnare da oltre 200 strumenti di trading disponibili su tre piattaforme di trading complete - MT4, MT5, ActTrader. La ciliegina sulla torta è la connessione a Zulu Trade tramite il Conto Zulu, che la aiuterà a connettersi immediatamente alla più grande comunità di social trading.",
    zeroCommission: "Zero commissioni e spread sottili come il rasoio",
    commissionContent:
      "Le offriamo le migliori condizioni per fare trading al costo più basso. Con il nostro conto ECN plus, può operare senza costi di commissione su tutte le coppie Forex. Con spread ultra sottili a partire da 0,0 pip su tutti i nostri strumenti negoziabili, può iniziare a utilizzare la nostra liquidità di livello istituzionale a un costo bassissimo. Otteniamo queste condizioni di trading dai nostri fornitori di liquidità di alto livello per servirla al meglio nel suo percorso di trading.",
    fastExecution: "Esecuzione fulminea",
    executionContent:
      "La qualità e l'ubicazione di prim'ordine dei nostri severs consentono l'esecuzione più rapida possibile delle transazioni, con una velocità di 10 ms. Può piazzare ordini di compravendita fino a 100 lotti. È libero da ritardi, requote e desk di negoziazione. Questo rende la nostra piattaforma ideale per tutti i tipi di trader - scalper, principianti, utenti EA, trader manuali, ecc.",
    fundingOptions: "Varietà di opzioni di finanziamento",
    fundingContent:
      "I depositi e i prelievi senza problemi contribuiscono a rendere il suo viaggio di trading semplice e senza intoppi. Può scegliere il suo metodo di deposito tra le diverse opzioni disponibili. Sappiamo che ogni centesimo conta per finanziare i suoi conti di trading. Pertanto, non addebitiamo alcuna commissione per l'accettazione dei depositi e l'elaborazione dei prelievi. I prelievi vengono elaborati in modo ultraveloce e senza soluzione di continuità, e può scegliere tra diverse modalità di prelievo.",
    lowRollover: "Basse spese di rollover",
    rolloverContent:
      "Molti trader amano mantenere i loro scambi durante la notte e a tal fine i tassi di swap sono importanti. Ogni coppia di trading ha le proprie tariffe di swap (ad eccezione dei conti islamici). Questi tassi vengono applicati alle 00:00 ora del Server. I mercati sono chiusi durante il fine settimana; tuttavia, le banche continuano a calcolare gli interessi anche sulle posizioni detenute durante il weekend. Quindi, se detiene una posizione durante la notte di venerdì, dovrà sostenere tassi di swap 3X. Offriamo tariffe di swap competitive e basse, in modo che lei abbia una maggiore flessibilità nel decidere quando chiudere i suoi trade.",
    doubt: "Ha dei dubbi su una condizione di trading? Provi il nostro",
    demoAccount: "Conto demo",
    stayAware: "Rimanga consapevole e faccia uso di ampie",
    calculate: "Calcolatori di trading",
    getAll: "per azzeccare tutti i numeri!",
    liveAccount: "APRI UN CONTO LIVE",
    openAccount: "APRI UN CONTO DEMO",
  },
  tradingCalculator: {
    title: "Calcolatori di trading",
    madeEasy: "Calcoli facilitati per lei",
    neverMiss:
      "Non perda mai i profitti del trading a causa di calcoli sbagliati!",
    pTag: "Faccia valutazioni accurate in un batter d'occhio utilizzando i nostri Calcolatori di Trading. Ci impegniamo ad aiutarla a trarre il massimo dai suoi scambi, risparmiando tempo e concentrando tutta l'attenzione sulle decisioni di trading cruciali, invece di impegnarsi in calcoli noiosi. Abbiamo selezionato i calcolatori più utili per il trading e li abbiamo inclusi qui per lei. Non deve scaricare nulla. Basta scegliere la calcolatrice che desidera, inserire i suoi dati e la calcolatrice le darà la risposta!",
    swap: "Calcolatore di scambio",
    margin: "Calcolatore del margine",
    pip: "Calcolatrice Pip",
    calculate: "Calcolatrice",
  },
  forexStock: {
    innerPages: {
      forex: "Forex",
      indices: "Indici",
      commodities: "Materie prime",
      stocks: "Azioni",
      crypto: "Criptovalute",
    },
    title: "FOREX",
    trade: "Negoziare con le principali coppie forex con AAAFx",
    min: "min.",
    commission: "Commissione",
    execution: "esecuzione",
    spread: "Spread",
    upTo: "Fino a",
    leverage: "Leva",
    paris: "Coppie",
    contract: "Contratto Forex",
    specification: "Specifiche",
    flexible:
      "Le condizioni di trading più flessibili (aumento della leva finanziaria) possono essere applicate in caso di clienti classificati come Professionisti. La preghiamo di accedere al suo conto e di verificare l'idoneità alla classificazione di Cliente Professionale, oppure di scriverci a",
    email: "support{'@'}aaafx.com",
    instument: "Strumento",
    bid: "Offerta",
    ask: "Chiedere",
    size: "Dimensioni del contratto",
    minTrade: "Min. Dimensione commerciale",
    tickValue: "Valore di spunta",
    hours: "Orari di negoziazione",
    h3Tag: "Che cos'è il Forex Trading?",
    pTag: "Il mercato Forex è un ampio mercato globale per lo scambio di valute internazionali. Il trading sul Forex è la compravendita generale di valute con l'intento principale di guadagnare profitti. Il mercato globale del forex è il più grande e il più liquido al mondo, con un volume di transazioni giornaliere di oltre 6.000 miliardi di dollari.",
    how: "Come funziona?",
    trading:
      "Il trading sul Forex prevede l'acquisto di una valuta e la vendita di un'altra valuta al tasso di cambio prevalente sul mercato. Non c'è consegna di alcuna valuta e la differenza tra il prezzo di vendita e il prezzo di acquisto viene semplicemente regolata sul conto di trading. Pertanto, queste coppie forex sono come i CFD Forex a prezzi spot. Se desidera operare nel mercato forex principale, dovrà sborsare centinaia di migliaia di dollari. Tuttavia, con i vantaggi del trading con leva e dei CFD, le bastano anche solo 100 unità di una valuta e può iniziare a trarre vantaggio dai movimenti dei prezzi. Inoltre, può anche beneficiare del differenziale dei tassi di interesse tra due valute, tramite i tassi swap. Se mantiene una posizione aperta in una coppia di valute durante la notte e l'interesse sulla valuta che ha acquistato è più alto di quello sulla valuta che ha venduto, guadagnerà un reddito da interessi. In caso contrario, pagherà un costo. Questo interesse è noto come tasso di swap o di rollover.",
    account: "Conto",
    type: "Tipi",
    compare: "Confronta i tipi di conto",
    online: "Il nostro sito online",
    tradingText: "Trading",
    platform: "Piattaforme",
    desktop: "Desktop",
    webTrade: "Web Trader",
    android: "Android",
    ios: "iOS",
    learn: "SCOPRI DI PIÙ",
    negative: "Saldo negativo",
    protection: "Protezione",
    pSubTag: "Protezione affidabile dei suoi fondi sui Conti AAAFx",
    pTag1:
      "Ci sforziamo sempre di lavorare per il miglior interesse dei nostri clienti. Durante il trading in periodi di volatilità, se il suo conto va in negativo, noi la copriamo le spalle! Azzereremo il suo saldo negativo, in modo che non subisca ulteriori perdite. Può depositare e ricominciare a fare trading. Pertanto, non può perdere più di quanto depositato.",
    live: "APRI UN CONTO LIVE",
    demo: "APRI UN CONTO DEMO",
  },
  pageNotFound: {
    title:
      "La pagina che sta cercando potrebbe essere stata rimossa, aver cambiato nome o essere temporaneamente non disponibile.",
    redirect: "Vai alla Homepage di AAAFx",
  },
  annualLoser: {
    winner: "Vincitore",
    loser: "I perdenti",
    total: "Totale",
    percentage: "Percentuale di perdenti",
    annually: "Annualmente",
    semi_annually: "Semestralmente",
    quaterly: "Trimestrale",
    monthly: "Mensile",
  },
  safetyFund: {
    title: "Sicurezza dei fondi",
    heading: "Integrità, fiducia e completa affidabilità",
    safety: "Fondi Sicurezza e protezione",
    investor:
      "Gli investitori dovrebbero concentrare tutto il loro tempo e le loro energie sulla costruzione di strategie efficaci, piuttosto che preoccuparsi della sicurezza dei loro fondi.",
    protect:
      "Siamo qui per proteggere i suoi fondi perché le nostre fondamenta poggiano sulla sua fiducia. Vogliamo che investa in modo intelligente e che faccia trading in modo sicuro.",
    heading1: "REGOLATI E SORVEGLIATI",
    strict:
      "In conformità con il rigoroso quadro normativo della Commissione ellenica per il mercato dei capitali (HCMC) dell'UE.",
    responsible: "FIDUCIARIO RESPONSABILE",
    client:
      "Il denaro del cliente è custodito in modo sicuro in conti bancari sicuri, presso banche affidabili.",
    audit: "AUDIT DEDICATI E",
    review: "REVISIONI DI CONFORMITÀ",
    internal:
      "Audit interni ed esterni, funzione di Compliance dedicata per verificare costantemente l'applicazione delle procedure relative agli asset dei clienti.",
    negativeBalance: "PROTEZIONE DEL SALDO NEGATIVO",
    pTag: "I clienti non possono perdere più del saldo del loro conto. Se il loro saldo diventa negativo, lo azzeriamo.",
    funds: "FONDI E REGISTRI SEGREGATI",
    companyFunds:
      "Rigorosa segregazione tra i fondi dei clienti e quelli dell'azienda. Viene mantenuta una chiara distinzione tra i fondi/registri dei clienti e quelli dell'azienda.",
    policies: "POLITICHE IT SOLIDE",
    secure:
      "I socket web sicuri sul sito web, la tecnologia Cloudflare e l'autenticazione a due fattori (2FA) disponibile per tutti gli account dei clienti.",
    investorFund: "FONDO DI COMPENSAZIONE DEGLI INVESTITORI",
    perPerson:
      "Gli investitori di AAAFx sono protetti fino a 30.000 euro a persona dal Fondo di Garanzia greco, ai sensi della Direttiva europea 93/22/CEE.",
    security: "Siamo sempre impegnati a garantire la sua sicurezza.",
    awareness:
      "Ma la consapevolezza e la vigilanza sono altrettanto importanti:",
    never: "Mai",
    click: "cliccare su link non identificati nelle e-mail.",
    unSecured:
      "utilizzare reti wireless non protette o qualsiasi dispositivo pubblico per accedere ai conti finanziari.",
    otps: "rispondere a e-mail o SMS che richiedono informazioni personali o OTP.",
    websites:
      "inserire le sue informazioni personali o finanziarie su siti web non protetti.",
    leave:
      "lasciare il suo dispositivo incustodito mentre è connesso a conti personali o finanziari protetti.",
    twoFa: "gestire un conto finanziario senza 2FA.",
  },
  regulations: {
    title: "Regolamenti e licenze",
    multiple:
      "Abbiamo registrazioni e normative multiple in tutto il mondo. La piena trasparenza sui dettagli della nostra registrazione significa maggiore affidabilità, sicurezza e tranquillità per i nostri clienti!",
    capitalMarket:
      "Commissione ellenica del mercato dei capitali (HCMC), Grecia",
    member:
      "Triple A Experts Investment Services Single Member SA è autorizzata e regolamentata nell'Unione Europea dalla Hellenic Capital Market Commission. (id:2/540/17.2.2010) Numero di identificazione fiscale: 998281207.",
    h5Tag: "Informazioni su HCMC",
    pTag: "La Commissione ellenica del mercato dei capitali (HCMC) è stata istituita come entità legale in Grecia per la sorveglianza della corretta applicazione della legislazione sul mercato dei capitali. L'HCMC è membro dell'Autorità europea degli strumenti finanziari e dei mercati (ESMA) e dell'Organizzazione internazionale delle commissioni sui valori mobiliari (IOSCO).",
    about: "Informazioni su MiFID II",
    adopted:
      "Il Parlamento europeo e il Consiglio hanno adottato la Direttiva 2014/65/UE, del 15 maggio 2014, relativa ai mercati degli strumenti finanziari e che modifica la Direttiva 2002/92/CE e la Direttiva 2011/61/UE (rifusione) (MiFID II), entrata in vigore il 03/01/2018. Il suo obiettivo è quello di regolamentare i mercati degli strumenti finanziari per la fornitura di servizi e attività di investimento all'interno dello Spazio Economico Europeo (SEE). La MiFID è stata incorporata nella legislazione greca con la Legge n. 4514/2018. Gli obiettivi principali della Direttiva sono l'aumento dell'efficienza, il miglioramento della trasparenza finanziaria, l'incremento della concorrenza e una maggiore protezione dei consumatori nei servizi di investimento.",
    gdpr: "Informazioni sul GDPR",
    aboutGDPR:
      "Il Regolamento Generale sulla Protezione dei Dati (GDPR) è considerato una delle leggi sulla privacy più severe al mondo. È stato redatto e approvato dall'Unione Europea (UE) ed è entrato in vigore il 25 maggio 2018. Stabilisce un quadro giuridico che definisce le linee guida per la raccolta e la libera circolazione dei dati personali delle persone all'interno dell'Unione Europea (UE). \"AAAFx\" e le sue società affiliate, comprese le filiali, le succursali, gli uffici di rappresentanza, i direttori, i funzionari e i dipendenti adottano ogni misura ragionevole per conformarsi al GDPR.",
    investor: "Informazioni sul Fondo di compensazione per gli investitori",
    hcmc: "Gli investitori di AAAFx sono protetti dal Fondo di Garanzia greco in base alla Direttiva europea 93/22/CEE. In caso di mancato adempimento degli obblighi da parte di una Società di Investimento, il Fondo di Garanzia greco interverrà per risarcire gli investitori fino a 30.000 euro per persona. Il Fondo di Garanzia greco è finanziato dai membri di ATHEX (Athens Exchange) ed è supervisionato dall'HCMC.",
    group: "Regolamenti di gruppo",
    sector: "Autorità di condotta del settore finanziario (FSCA), Sudafrica",
    southAfrica:
      "Sikhula Venture Capital (Pty) Ltd. è autorizzata e regolata dalla Financial Sector Conduct Authority (FSCA) del Sudafrica. (Numero di licenza: 2017/315029/07).",
    view: "Visualizza la licenza",
    register: "Registrazioni in altre giurisdizioni",
  },
  homePage: {
    h3tag: "Il futuro del Trading a...",
    h1tag: "Commissioni ULTRA-BASSE!",
    ul: {
      lTag: "Spread a partire",
      strong: "da",
      strong1: "0.0",
      lTag1: "pip",
      lTag2: "Leva finanziaria fino a",
      strong2: "500x*",
      supTag: "per clienti professionali",
      // bTag: "Greco",
      lTag3: "Regolamentazione e registrazione europea",
      // strong3: "Gratuito",
      lTag4: "Depositi e prelievi gratuiti*",
    },
    liveAccount: "APRI UN CONTO LIVE",
    demoAccount: "APRI UN CONTO DEMO",
    tabs: {
      forex: "Forex",
      indices: "Indici",
      commodities: "Materie prime",
      stocks: "Azioni",
      cryptoCurrencies: "Criptovalute",
    },
    forex: {
      title: "70+ coppie FX",
      point1: "1",
      content: "Il mercato più liquido del mondo",
      pTag: "Può accedere ai mercati più liquidi del mondo",
      point2: "2",
      h5Tag: "Negoziare micro lotti",
      pTag1:
        "Negozia in base a qualsiasi strategia, micro lotti fino a 100 lotti.",
      point3: "3",
      h5Tag1: "Indicatori grafici avanzati",
      pTag2:
        "Utilizzi le nostre splendide piattaforme di trading con strumenti avanzati",
      startTrading: "INIZIARE A FARE TRADING",
      tryDemo: "PROVA UNA DEMO",
      tableRow: {
        tableHeading: {
          pair: "Coppia",
          bid: "Offerta",
          ask: "Chiedere",
          spread: "Spread",
          trade: "Commercio",
        },
      },
      buySell: "ACQUISTO/VENDITA",
      SeeAll: "Vedi tutti",
    },
    indices: {
      title: "Indici 11+",
      point1: "1",
      content: "Indici di commercio in continua crescita",
      pTag: "come DOW JONES, S&P 500, Nasdaq e molti altri...",
      point2: "2",
      h5Tag: "Spread bassi e requisiti di margine bassi",
      pTag1:
        "Abbiamo gli spread più sottili e un'elevata leva finanziaria su tutti gli indici globali.",
      point3: "3",
      h5Tag1: "Diversificare: negoziare un portafoglio, non un singolo titolo.",
      pTag2: "Offriamo tutti i principali indici globali per il trading",
      startTrading: "INIZIARE A FARE TRADING",
      tryDemo: "PROVA UNA DEMO",
      tableRow: {
        tableHeading: {
          pair: "Coppia",
          bid: "Offerta",
          ask: "Chiedere",
          spread: "Spread",
          trade: "Commercio",
        },
      },
      buySell: "ACQUISTO/VENDITA",
      SeeAll: "Vedi tutti",
    },
    commodities: {
      title: "9 Materie prime",
      point1: "1",
      content: "Ampia gamma di materie prime",
      pTag: "Sono disponibili tutti i principali metalli preziosi, il petrolio e il gas naturale.",
      point2: "2",
      h5Tag: "Investire meno, ottenere di più",
      pTag1: "Ottenere facilmente beni preziosi con la leva finanziaria",
      point3: "3",
      h5Tag1: "Latenza zero, slittamento minimo",
      pTag2:
        "Impatto minimo della volatilità con velocità di esecuzione di 10 ms.",
      startTrading: "INIZIARE A FARE TRADING",
      tryDemo: "PROVA UNA DEMO",
      tableRow: {
        tableHeading: {
          pair: "Coppia",
          bid: "Offerta",
          ask: "Chiedere",
          spread: "Spread",
          trade: "Commercio",
        },
      },
      buySell: "ACQUISTO/VENDITA",
      SeeAll: "Vedi tutti",
    },
    stocks: {
      title: "100+ Azioni",
      point1: "1",
      content: "Negoziare le azioni delle blue chip",
      pTag: "Trascinare nel suo portafoglio le azioni fidate delle blue-chip",
      point2: "2",
      h5Tag: "Guadagnare dividendi senza possedere azioni",
      pTag1:
        "Guadagna un reddito da dividendi entusiasmante da aziende di prim'ordine",
      point3: "3",
      h5Tag1: "Andare long o andare short, a seconda del mercato",
      pTag2:
        "Negoziare non solo azioni in rialzo ma anche in ribasso, utilizzando qualsiasi strategia di trading.",
      startTrading: "INIZIARE A FARE TRADING",
      tryDemo: "PROVA UNA DEMO",
      tableRow: {
        tableHeading: {
          pair: "Coppia",
          bid: "Offerta",
          ask: "Chiedere",
          spread: "Spread",
          trade: "Commercio",
        },
      },
      buySell: "ACQUISTO/VENDITA",
      SeeAll: "Vedi tutti",
    },
    crypto: {
      title: "6 Criptovalute",
      point1: "1",
      content: "Scambiare denaro facendo girare le criptovalute",
      pTag: "Negoziare le attività più redditizie dell'epoca attuale",
      point2: "2",
      h5Tag:
        "Le criptovalute più potenti direttamente sulla sua piattaforma di trading",
      pTag1: "Tutte le principali criptovalute sono disponibili per il trading",
      point3: "3",
      h5Tag1: "Trading affidabile per attività volatili",
      pTag2:
        "Accedere a una liquidità profonda con un broker regolamentato e affidabile",
      startTrading: "INIZIARE A FARE TRADING",
      tryDemo: "PROVA UNA DEMO",
      tableRow: {
        tableHeading: {
          pair: "Coppia",
          bid: "Offerta",
          ask: "Chiedere",
          spread: "Spread",
          trade: "Commercio",
        },
      },
      buySell: "ACQUISTO/VENDITA",
      SeeAll: "Vedi tutti",
    },
    youShouldJoin: "Perché dovrebbe unirsi a noi",
    aaafx: "AAAFx",
    content: {
      h3Tag: "Condizioni di trading di prim'ordine",
      ul: {
        liTag: "Esecuzione delle operazioni più rapida con spread quasi nulli",
        liTag1: "Basse commissioni e spese di rollover",
        liTag2: "Tutte le strategie di trading consentite",
        liTag3: "Nessun markup nascosto",
      },
    },
    content1: {
      h3Tag: "Sicurezza e affidabilità",
      ul: {
        liTag: "Broker regolamentato dall'UE.",
        liTag1: "Numero di registrazione: 2/540/17.2.2010.",
        liTag2: "Protezione del saldo negativo.",
        liTag3: "Rispetto rigoroso della conformità fiduciaria.",
      },
    },
    content2: {
      h3Tag: "14 anni di esperienza nel settore",
      ul: {
        liTag: "In attività da oltre un decennio",
        liTag1: "Un team di professionisti capaci ed esperti",
        liTag2: "Broker affidabile e trasparente",
        liTag3: "Profonda comprensione delle esigenze dei commercianti",
      },
    },
    content3: {
      h3Tag: "Pool di liquidità profonda",
      ul: {
        liTag: "Liquidità di livello istituzionale",
        liTag1: "Nessuna richiesta e slittamento minimo",
        liTag2: "Liquidità garantita a tutti i livelli di prezzo",
        liTag3: "Adatto per il trading ad alto volume",
      },
    },
    content4: {
      h3Tag: "Tecnologia",
      ul: {
        liTag: "Tecnologia interna di alto livello",
        liTag1: "Server in centri dati Equinix di alto livello",
        liTag2: "Velocità di esecuzione più rapida sotto i 10 ms",
        liTag3:
          "VPS collocato gratuito che fornisce una latenza ancora più bassa",
      },
    },
    content5: {
      h3Tag: "Prelievi ultraveloci",
      ul: {
        liTag: "Prelievi gratuiti entro 24 ore",
        liTag1: "Nessuna restrizione sull'importo o sulla frequenza",
        liTag2: "Diverse opzioni di prelievo flessibili",
        liTag3: "Elaborazione dei prelievi ad alta sicurezza",
      },
    },
    rawSpread: "Spread ridotti, commissioni zero",
    plusAccount: "su coppie Forex con un account ECN Plus",
    pTag: " AAAFx è qui per ridurre la tensione e le commissioni del suo viaggio di trading. Sì, ha letto bene! Abbiamo spazio per farle vivere un'esperienza di trading a basso costo con noi. Per maggiori informazioni",
    clickHere: "Clicchi qui",
    brTag:
      "Le offriamo il massimo potenziale per ottenere il miglior risultato possibile. Grazie all'utilizzo di una tecnologia all'avanguardia, siamo in grado di fornirle un accesso immediato a un bacino di liquidità profondo a basso costo.",
    ecn: "ECN",
    commission: "Commissione",
    minSpread: "min. Diffondere",
    minDeposit: "min. Deposito",
    plus: "più",
    forexCommission: "Commissione sul Forex",
    pips: "pip",
    compare: "Confronta i tipi di conto",
    experience:
      "Esperto, affidabile e regolamentato, con la promessa di protezione dei suoi fondi",
    security: "La sicurezza non è un prodotto, ma un processo.",
    strongValue:
      "Con un forte sistema di valori, offriamo un'esperienza di trading di livello mondiale. Rispettiamo un rigoroso quadro normativo al fine di proteggere i suoi fondi. Siamo regolati dalla Commissione ellenica per il mercato dei capitali (HCMC), che è disciplinata dalle legislazioni greca ed europea.",
    ourClient:
      "I nostri clienti si affidano a noi perché adottiamo tutte le misure per garantire la sicurezza dei loro fondi e l'esecuzione perfetta delle loro operazioni.",
    success: "Scambi con la pace, scambi per il successo!",
    live: "APRI UN CONTO LIVE",
    demo: "APRI UN CONTO DEMO",
    zuluTrade: "ZuluTrade",
    treasure: "Un tesoro per i trader Forex!",
    copyTrade: "Copia un maestro del trading o lo diventi anche tu!",
    socialTrading:
      "Acceda alla più grande rete di social trading attraverso un broker affidabile, con il nostro Conto Zulu. Le permette di copiare automaticamente le operazioni di alcuni trader di successo che mostrano le loro performance di trading dal vivo.",
    investor:
      "dei loro investitori ottengono profitti quando copiano correttamente i top trader.",
    topTrader: "Diventi un top trader o segua un top trader.",
    yourTrading: "Il suo trading",
    arsenal: "Arsenale",
    actTrader: "Atto di compravendita",
    mostAdvance: "La tecnologia più avanzata",
    comming: "Prossimamente",
    desktop: "Desktop",
    ios: "iOS",
    android: "Android",
    web: "Web",
    mt4: "MetaTrader 4",
    popularPlatform: "La piattaforma più popolare",
    mt5: "MetaTrader 5",
    nextGen: "La piattaforma di nuova generazione",
    firstTrade: "Effettua il tuo primo trade con AAAFx in meno di 15 minuti.",
    register: "Registrazione",
    min: "In meno di un minuto",
    kyc: "KYC",
    photo: "Un documento di identità valido fronte e retro",
    addressProof: "Prova di residenza",
    // withIn: "Entro 10 minuti",
    deposit: "Deposito",
    instantly: "IMMEDIATO",
    method: "Oltre 5 metodi di deposito disponibili",
    trade: "TRADING",
    range: "Ampia gamma di mercati",
    spread: "Spread ridotti",
    ultraLow: "Commissione ultra-basse",
    create: "CREA IL TUO ACCOUNT",
  },
  contactUsPage: {
    heading: "Contatti",
    headingDescription: "Il nostro team di assistenza è qui per lei 24/5",
    requestCallBack: "Chieda di essere richiamato:",
    FullName: "Nome completo",
    EmailAddress: "Indirizzo e-mail",
    PhoneNumber: "Numero di telefono",
    Are_you_an_existing_client: "È un cliente esistente?",
    Yes: "Sì",
    No: "No",
    Select_your_Timezone: "Selezioni il suo fuso orario",
    How_can_we_help_you: "Come possiamo aiutarla?",
    Select_a_topic: "Selezionare un argomento",
    NewAccounts: "Nuovi conti",
    CustomerSupport: "Assistenza clienti",
    TechnicalQueries: "Quesiti tecnici",
    IBProgram: "Programma IB",
    PAMM_Accounts: "Conti PAMM",
    WhiteLabel: "WhiteLabel",
    BestTimeToCallMessage:
      "Quando è il momento migliore per chiamarla? La preghiamo di selezionare",
    SelectPreferredTime: "Selezionare l'orario preferito",
    eightAM: "8:00",
    tenAM: "10:00",
    twelveNoon: "12:00",
    twoPM: "14:00",
    fourPM: "16:00",
    sixPM: "18:00",
    eightPM: "20:00",
    Message: "Messaggio",
    I_agree_to_AAAFx: "Sono d'accordo con AAAFx",
    PrivacyPolicy: "Informativa sulla privacy",
    LiveChat: "Chat in diretta",
    AvailableMondayToFriday: "(24/5, lunedì-venerdì)",
    CallUs: "Ci chiami",
    GreeceAndAthens: "Grecia, Atene",
    GreeceAndAthensContactNumber: "+30 213 0176300",
    AvailableMondayToFridayTime: "Da lunedì a venerdì, dalle 9:00 alle 18:00",
    EmailAddresses: "Indirizzi e-mail",
    Support: "SUPPORTO",
    Complaints: "RECLAMI",
    FollowUs: "Ci segua",
    RegisteredAddress: "Indirizzo registrato",
    ProvidedRegisteredAddressOne:
      "Triple A Experts Investment Services Membro Unico S.A.",
    ProvidedRegisteredAddressTwo: "580 Viale Vouliagmenis 16452",
    ProvidedRegisteredAddressThree: "Argyroupoli Attica",
  },
  depositBonusPage: {
    heading: "Bonus di deposito",
    headingDescription: "Perché accontentarsi di meno?",
    subHeadingOne:
      "Con il Bonus AAAFX, negozia il doppio con lo stesso deposito",
    subHeadingOneDescription:
      "Attualmente stiamo offrendo un'offerta promozionale per usufruire di credito gratuito (alias denaro bonus) per lo stesso importo del suo deposito. Questo denaro bonus raddoppia istantaneamente il suo margine, in modo da consentirle di operare di più e di guadagnare potenzialmente di più.",
    What_is_Bonus: "Che cos'è il Bonus?",
    What_is_Bonus_desc:
      "Il bonus viene aggiunto come margine supplementare al suo conto di trading. Viene subito aggiunto come credito al suo conto di trading e aumenta il capitale totale del suo conto di trading.",
    Why_should_I_avail_Bonus: "Perché dovrei usufruire del Bonus?",
    Why_should_I_avail_Bonus_pointOne:
      "Il doppio margine di trading è disponibile senza costi aggiuntivi",
    Why_should_I_avail_Bonus_pointOne_desc:
      "Supponiamo che depositi 1.000 dollari, noi aggiungeremo altri 1.000 dollari al suo conto. Quindi, otterrà un capitale totale di 2.000 dollari con cui fare trading. Può usufruire del denaro bonus gratuito istantaneamente, una volta effettuato il deposito.",
    Why_should_I_avail_Bonus_pointTwo:
      "Aggiunta istantanea di fondi extra sul conto",
    Why_should_I_avail_Bonus_pointTwo_desc:
      "Per usufruire del bonus, deve semplicemente accedere alla sua Area Clienti sicura dopo aver effettuato il deposito e cliccare su 'Utilizza il bonus'. Il bonus viene aggiunto istantaneamente al suo conto trading.",
    Why_should_I_avail_Bonus_pointThree:
      "I PROFITTI OTTENUTI CON IL BONUS SONO TUTTI SUOI",
    Why_should_I_avail_Bonus_pointThree_desc:
      "Non ci deve nulla dai profitti ottenuti con il trading sul suo deposito o con il bonus aggiunto da noi. Può prelevare tutti i profitti da lei ottenuti sul suo conto come e quando vuole.",
    Why_should_I_avail_Bonus_pointFour: "Livello di arresto ritardato",
    Why_should_I_avail_Bonus_pointFour_desc:
      "Un altro vantaggio dell'utilizzo del denaro bonus è che, in caso di sfortunata estrazione, lo Stop-out del suo conto viene ritardato. Le sue operazioni non saranno chiuse forzatamente dal sistema fino a quando 'TUTTI' i suoi fondi non saranno persi nel trading. Il livello di stop-out regolare è al 50%, ossia quando il rapporto tra il suo 'Capitale' e il 'Margine usato' raggiunge il 50%, il suo conto viene interrotto. Questo cuscinetto extra offre un'ulteriore opportunità per i trade di andare nella direzione opposta e potenzialmente diventare redditizi.",
    Why_should_I_avail_Bonus_pointFive:
      "La sosteniamo per qualsiasi Bilanciamento negativo",
    Why_should_I_avail_Bonus_pointFive_desc:
      "Se per caso dovesse perdere i suoi fondi e il suo saldo diventasse negativo, le offriamo una protezione completa. Non può perdere più di quanto depositato. Azzeriamo il saldo negativo del suo conto.",
    Why_should_I_avail_Bonus_pointSix:
      "Le mie perdite possono essere compensate con il bonus?",
    Why_should_I_avail_Bonus_pointSix_desc:
      "Le perdite non possono essere utilizzate come bonus. Una volta che tutti i suoi fondi sono stati persi nel trading, tutte le operazioni aperte saranno chiuse ai prezzi di mercato prevalenti.",
    ReadAllTermsCondition: "Legga tutti i termini e le condizioni del bonus",
    here: "qui",
    ClaimBonus: "RICHIEDERE IL BONUS",
    ClaimBonusQuestion: "Domande? Ci contatti alla Live Chat o scriva a",
  },
  depositWithdrawalPage: {
    heading: "Depositi e prelievi",
    headingDescription:
      "Una varietà di opzioni facili e convenienti, senza alcun costo da parte nostra.",
    subHeading:
      "Depositare e prelevare dal suo conto di trading non è mai stato così facile! Investa nel suo conto di trading senza pagare alcuna commissione aggiuntiva. Abbiamo diverse opzioni di deposito e prelievo affidabili per rendere il suo viaggio di trading semplice e senza intoppi.",
    subHeadingPointOne: "0% Commissione di servizio",
    subHeadingPointOneDesc:
      "Non applichiamo alcuna commissione né sui depositi né sui prelievi.",
    subHeadingPointTwo: "Sicurezza delle transazioni",
    subHeadingPointTwoDesc:
      "I fondi sono conservati in banche Tier-1 e sono segregati dai fondi propri dell'azienda.",
    subHeadingPointThree: "Elaborazione rapida",
    subHeadingPointThreeDesc:
      "I depositi e i prelievi con AAAFx vengono elaborati istantaneamente.",
    subHeadingPointFour: "Euro 100",
    subHeadingPointFourDesc: "Importo minimo del deposito",
    subHeadingPointFive: "10+",
    subHeadingPointFiveDesc: "Valute globali accettate",
    subHeadingPointSix: "Aumento del controllo",
    subHeadingPointSixDesc:
      "Il KYC obbligatorio garantisce una maggiore sicurezza",
    WithdrawalUpperCase: "RITIRI",
    WithdrawalDesc:
      "I prelievi senza problemi rendono felice ogni trader, giusto? Questo è ciò che aspiriamo a fare per i nostri clienti. Ecco alcune opzioni facili e veloci da utilizzare per il prelievo del suo denaro duramente guadagnato entro 24 ore.",
    StepsToWithdrawal: "Procedura per prelevare dal suo Conto Live AAAFx",
    StepsToWithdrawalPointOne: "Acceda al Portale clienti.",
    StepsToWithdrawalPointTwo:
      "Configuri un conto bancario in 'Configurazione della banca'.",
    StepsToWithdrawalPointThree: 'Clicchi su "Fondi" e poi su "Prelievo".',
    Deposit: "Deposito",
    Withdrawals: "Prelievi",
    BankCards: "Carte bancarie",
    Region: "Regione",
    Currencies: "Valute",
    MinDeposit: "Min. Deposito",
    ServiceFee: "Tassa di servizio",
    ProcessingTime: "Tempo di elaborazione",
    WorldWide: "In tutto il mondo",
    EUR: "EUR",
    USD: "USD",
    CHF: "CHF",
    GBP: "GBP",
    JPY: "JPY",
    AUD: "AUD",
    BGN: "BGN",
    zero: "0",
    ten: "10",
    instantCaps: "INSTANTE",
    fundCaps: "FONDO",
    BankWire: "BankWire",
    twoToThreeBusinessDays: "2-3 GIORNI LAVORATIVI",
    MY: "MIO",
    TH: "TH",
    ID: "ID",
    PH: "PH",
    VN: "VN",
    BTC: "BTC",
    AdditionalInformation: "Informazioni aggiuntive",
    AdditionalInformationPointOne:
      "Tutti i prelievi saranno elaborati tramite il metodo utilizzato per effettuare un deposito sul conto trading.",
    AdditionalInformationPointTwo:
      "AAAFx non applica alcuna commissione di transazione né sui depositi né sui prelievi.",
    AdditionalInformationPointThree:
      "Rimborsiamo il costo della transazione da lei sostenuto fino al 5% dell'importo del suo deposito, sul suo conto trading.",
    CapsOpenLiveAccount: "APRI UN CONTO LIVE",
    CapsOpenDemoAccount: "APRI UN CONTO DEMO",
    DepositUpperCase: "DEPOSITI",
    DepositDesc:
      "Cosa c'è di più importante che aggiungere fondi in modo sicuro al suo conto di trading? Inizi il suo viaggio nel trading con un deposito minimo di soli 10 dollari. Offriamo un'ampia varietà di opzioni che può utilizzare per finanziare il suo conto di trading.",
    Steps_to_make_a_deposit:
      "Procedura per effettuare un deposito sul suo Conto Live AAAFx",
    Steps_to_make_a_deposit_pointOne: "Acceda al Portale clienti.",
    Steps_to_make_a_deposit_pointTwo: 'Clicchi su "Fondi" e poi su "Deposito".',
    Bitcoin: "Bitcoin",
    uptoThirtyMin: "Fino a 30 minuti",
    e_wallets: "Portafogli elettronici",
    depositNoteOne:
      "In caso di congestione della rete, le transazioni Bitcoin possono richiedere diverse ore.",
    depositNoteTwo:
      "Per i depositi bancari, AAAFx coprirà solo le commissioni applicate dalla nostra banca destinataria, cioè Eurobank, fino a un importo di 25 dollari. AAAFx non è a conoscenza di altre commissioni che potrebbero essere applicate dal mittente o da qualsiasi altra banca intermediaria e pertanto tali commissioni non saranno coperte.",
    EuroBankCaps: "EUROBANK",
    Currency: "Valuta",
    SwiftCode: "Codice Swift",
    BeneficiaryName: "Nome del beneficiario",
    ProvidedBeneficiaryName: "ESPERTI DI TRIPLA A",
    ProvidedBeneficiaryNameTwo: "TRIPLE A EXPERTS INVESTMENT SERVICES SA",
    IBAN: "IBAN",
    Address: "Indirizzo",
    ProvidedAddress: "Akti Kondili 26-28, 18545 Pireo, GRECIA",
    ProvidedAddressTwo: "Chemin de la Crétaux 33, 1196 Gland, Svizzera",
    ProvidedSwiftCode: "ERBKGRAA",
    ProvidedSwiftCodeTwo: "SWQBCHZZXXX",
    IBANCodeOne: "GR9502600590000421200023114",
    IBANCodeTwo: "GR3202600590000410200460346",
    IBANCodeThree: "GR9702600590000441200009691",
    IBANCodeFour: "GR4202600590000451200009759",
    IBANCodeFive: "GR0602600590000411200012446",
    IBANCodeSix: "GR6202600590000441200012358",
    IBANCodeSeven: "GR5402600590000471200017496",
    IBANCodeEight: "CH0708781000097621100",
    Warning: "Avvertimento",
    WarningMessage:
      "Includa sempre la sua e-mail, il suo nome completo e il numero di conto trading (solo e-mail e nome completo per i nuovi conti) nei dettagli del bonifico in uscita per un'elaborazione immediata. In caso contrario, AAAFx potrebbe ritardare il suo deposito.",
    swissubebankSA: "SWISSYUBE BANK SA",
  },
  stocksPage: {
    Forex: "Forex",
    Indices: "Indici",
    Commodities: "Materie prime",
    Stocks: "Azioni",
    Cryptocurrencies: "Criptovalute",
    heading: "TITOLI",
    headingDescription:
      "Investa le sue partecipazioni nelle principali azioni blue-chip del mondo",
    min: "min",
    zeroPointZeroSeventyFive: "0.075",
    zeroPointZero: "0.0",
    five: "5",
    one: "1",
    Commission: "Commissione",
    minExecution: "esecuzione minima",
    tenMiliSeconds: "10ms",
    Spread: "Diffusione",
    hundredPlus: "100+",
    UpTo: "Fino a",
    Leverage: "Leva",
    StocksContract: "Contratto azionario",
    Specifications: "Specifiche",
    StocksContractSpecifications:
      "Le condizioni di trading più flessibili (aumento della leva finanziaria) possono essere applicate in caso di clienti classificati come Professionisti. La preghiamo di accedere al suo conto e di verificare l'idoneità alla classificazione di Cliente Professionale, oppure di scriverci a",
    supportMail: "support{'@'}aaafx.com",
    Instrument: "Strumento",
    Bid: "Offerta",
    Ask: "Richiesta",
    ContractSize: "Dimensioni del contratto",
    MinTradeSize: "Min. Dimensione commerciale",
    TickValue: "Valore di spunta",
    TradingHours: "Orari di negoziazione",
    sevenAM: "7:00",
    twelvePM: "12:00",
    Treasury_products_description:
      "I buoni del Tesoro hanno una scadenza trimestrale (vedere la tabella sottostante). I clienti che detengono una posizione aperta alla Data di scadenza saranno chiusi al nostro bid/offer alle 21:00 GMT per BUND; l'unica conseguenza è che il cliente realizzerà qualsiasi P/L fluttuante al momento della chiusura. Non sono previsti rollover per tutti i contratti del Tesoro offerti.",
    ExpirationDateCaps: "DATE DI SCADENZA",
    Bund: "Bund",
    CORNF: "CORNF",
    WHEATF: "GRANO",
    SOYF: "SOYF",
    seventhDecemberTwentyTwentyOne: "7-dic-21",
    twentysixNovemberTwentyOne: "26-nov-21",
    twentysevenNovemberTwentyOne: "27-nov-21",
    twentyFourFebruaryTwentyTwo: "24-feb-22",
    twentyNineDecemberTwentyOne: "29-dic-21",
    WhatisStocksCFD: "Che cos'è il trading di CFD sulle azioni?",
    WhatisStocksCFDDesc:
      "I CFD azionari sono un modo a basso costo per trarre vantaggio dai movimenti dei prezzi delle azioni delle principali società. Il principale vantaggio del trading di CFD azionari rispetto alle azioni è il basso requisito di capitale. Quando si tratta di azioni, si deve pagare l'intero prezzo di un'azione. Tuttavia, nel caso dei CFD su azioni, non si ottiene la proprietà effettiva delle azioni e quindi si paga solo una piccola percentuale del prezzo, solo per beneficiare dei movimenti di prezzo. Inoltre, può usufruire della leva finanziaria per massimizzare il suo potenziale di guadagno.",
    How_does_it_work: "Come funziona?",
    How_does_it_work_desc:
      "Quando fa trading con i CFD azionari, non possiede l'azione su cui sta investendo. Si limita a depositare un piccolo margine, molto inferiore al costo effettivo dell'azione e a sfruttare i vantaggi del trading con leva finanziaria. I profitti o le perdite vengono calcolati sottraendo il prezzo di acquisto dal prezzo di vendita e moltiplicando il risultato per la dimensione della posizione. Per esempio, per acquistare l'equivalente di 100 CFD azionari di Facebook con noi, le basterà un deposito del 20% del valore totale della posizione rispetto a quanto pagherebbe per acquistare azioni fisiche. Ora dovrà depositare solo 6400 dollari di margine con noi (20% di 32.000 dollari = 6400 dollari), esclusa la commissione. D'altra parte, se dovesse acquistare lo stesso numero di azioni direttamente da un broker azionario, dovrebbe pagare l'intero importo di 32.000 dollari, più le commissioni e le tasse.",
    Account: "Conto",
    Types: "Tipi",
    ECN: "ECN",
    plus: "più",
    zulu: "zulu",
    DepositMin: "Deposito (min.)",
    hundred: "100",
    tenThousand: "10,000",
    CommissionPerLakh: "Commissione (per 100k dollari)",
    zeroPointZeroNinetyThree: "0.093",
    SpreadMin: "Larghezza (min.)",
    CompareAccountTypes: "Confronta i tipi di conto",
    OurOnline: "Il nostro sito online",
    Trading: "Trading",
    Platforms: "Piattaforme",
    Desktop: "Desktop",
    WebTrader: "Web Trader",
    Android: "Android",
    iOS: "iOS",
    LeranMore: "SCOPRI DI PIÙ",
    NegativeBalance: "Saldo negativo",
    Protection: "Protezione",
    ReliableProtectionHeading:
      "Protezione affidabile dei suoi fondi sui Conti AAAFx",
    ReliableProtectionDescription:
      "Ci sforziamo sempre di lavorare per il miglior interesse dei nostri clienti. Durante il trading in periodi di volatilità, se il suo conto va in negativo, noi la copriamo le spalle! Azzereremo il suo saldo negativo, in modo che non subisca ulteriori perdite. Può depositare e ricominciare a fare trading. Pertanto, non può perdere più di quanto depositato.",
    OpenLiveAccount: "APRI UN CONTO LIVE",
    OpenDemoAccount: "APRI UN CONTO DEMO",
  },
  referAndEarnPage: {
    heading: "Fare riferimento e guadagnare",
    headingDescription: "Più condivide, più riceve",
    startsWithSharing: "Tutto inizia con la condivisione!",
    startsWithSharingDescOne:
      "Conosce qualcuno che vuole introdurre al trading? Beh, potrebbero farle guadagnare soldi. Inviti i suoi amici a iscriversi a AAAFx attraverso il suo link di riferimento unico. Lei e il suo referente guadagnerete 25 dollari ciascuno.",
    startsWithSharingDescTwo:
      "Senza sostenere alcun costo aggiuntivo, aumenta semplicemente il suo reddito. Più amici le vengono segnalati, più lei guadagna!",
    startsWithSharingDescThree:
      "Tenga sempre sotto controllo il suo conto con i nostri strumenti di reporting in tempo reale e monitori lo stato dei suoi referral per massimizzare i suoi guadagni:",
    startsWithSharingDescThreePointOne:
      "Riceva il suo link di riferimento unico quando necessario.",
    startsWithSharingDescThreePointTwo:
      "Vedere quanto ha guadagnato fino ad oggi",
    startsWithSharingDescThreePointThree:
      "Controllare la storia delle referenze",
    startsWithSharingDescThreePointFour:
      "Accesso alle statistiche in ogni momento",
    How_to_get_started: "Come iniziare?",
    How_to_get_started_pointOne: "Acceda alla sua Area clienti protetta",
    How_to_get_started_pointTwo:
      "Riceva il suo link di riferimento e lo condivida con i suoi amici.",
    How_to_get_started_pointThree:
      "Guadagna 25 dollari quando i suoi referenti si iscrivono e si qualificano.",
    WhatDoYourReferrals: "Cosa devono fare i suoi referenti?",
    WhatDoYourReferralsDescOne:
      "I suoi referenti devono semplicemente utilizzare il suo link di riferimento per registrarsi con noi. Se non lo fanno, non è un problema! Possono",
    WhatDoYourReferralsDescTwo:
      "è sufficiente venire in Live Chat e comunicarci che sono arrivati tramite lei. Li aggiungeremo al suo elenco di referenti.",
    Benefits_to_you: "Vantaggi per lei",
    Benefits_to_you_pointOne: "Guadagni illimitati da referral.",
    Benefits_to_you_pointTwo:
      "Dopo aver fatto riferimento a 10 clienti, può continuare con il piano Refer and Earn o passare al programma IB.",
    Benefits_to_you_pointThree:
      "Tracciamento in tempo reale dei suoi guadagni.",
    Benefits_to_you_pointFour:
      "Attiri i clienti con le nostre vantaggiose offerte promozionali, compresi i bonus.",
    Benefits_to_you_pointFive:
      "Assistenza clienti 24/5 e account manager personale.",
    Benefits_to_your_referrals: "Vantaggi per i suoi referenti",
    Benefits_to_your_referrals_pointOne:
      "Si goda le nostre impeccabili condizioni di trading.",
    Benefits_to_your_referrals_pointTwo:
      "Guadagna il Premio Referral, proprio come il referente.",
    Benefits_to_your_referrals_pointThree:
      "Approfitti dei nostri conti completamente integrati con ZuluTrading.",
    Benefits_to_your_referrals_pointFour:
      "Approfitti delle nostre promozioni, compresi i bonus e il Programma Fedeltà.",
    Benefits_to_your_referrals_pointFive:
      "Utilizza il proprio link di riferimento e porta i propri clienti.",
    How_is_AAAFx_Refer_and: "Come si riferisce AAAFx e",
    Earn_Plan_different_from_the_IB_Program:
      "Il Piano di guadagno è diverso dal Programma IB?",
    How_is_AAAFx_Refer_desc:
      "Sebbene entrambi i programmi abbiano un suono simile, sono molto diversi l'uno dall'altro. Ecco le differenze principali",
    Feature: "CARATTERIZZAZIONE",
    referandEarnPlan: "PIANO DI REFERENZE E GUADAGNO",
    IBProgram: "PROGRAMMA IB",
    Earnings: "Guadagni",
    EarningsPointOneP1: "25 dollari a lei e al suo referente; fino a",
    EarningsPointOneP2: "10 clienti (passa a 20 dollari dopo",
    EarningsPointOneP3: "10 clienti qualificati)",
    EarningsPointTwop1: "20% di commissioni in Standard IB",
    EarningsPointTwop2: "Programma e quota personalizzata in",
    EarningsPointTwop3: "Programma IB avanzato",
    Earning_frequency: "Frequenza di guadagno",
    Earning_frequency_p1: "Premio una tantum",
    Earning_frequency_p2: "Commissioni a vita",
    Live_tracking: "Tracciamento in tempo reale",
    Yes: "Sì",
    No: "No",
    View_of_referrals_activities: "Vista delle attività dei referenti",
    Limited: "Limitato",
    Full_fledged: "A pieno titolo",
    SeparatePortal: "Portale separato",
    Pay_out_frequency: "Frequenza dei pagamenti",
    Weekly: "Settimanale",
    you_can_read_terms_and_condition:
      "Può leggere tutti i termini e le condizioni del Piano Inviare e guadagnare",
    here: "qui",
    capsStartRefering: "INIZIARE A FARE RIFERIMENTO",
    readyToGoBeyondEarnPlan:
      "È pronto ad andare oltre il Piano Referenze e Guadagni?",
    signUpAsIBWithUs: "Si registri come IB con noi. Leggi tutti i vantaggi",
  },
  commoditiesPage: {
    Forex: "Forex",
    Indices: "Indici",
    Commodities: "Materie prime",
    Stocks: "Azioni",
    Cryptocurrencies: "Criptovalute",
    heading: "COMMERCIALI",
    headingDescription:
      "Costruisca il suo portafoglio diversificato con il trading delle principali materie prime",
    min: "min",
    hundred: "100",
    tenThousand: "10,000",
    CommissionPerLakh: "Commissione (per 100k dollari)",
    zeroPointNinentyNine: "0.99",
    zeroPointSeventyFour: "0.74",
    Commission: "Commissione",
    zeroPintZero: "0.0",
    Spread: "Diffusione",
    execution: "esecuzione",
    tenMiliSec: "10ms",
    ten: "10",
    Up_to: "Fino a",
    twenty: "20",
    Leverage: "Leva",
    CommoditiesContract: "Contratto sulle materie prime",
    Specifications: "Specifiche",
    CommoditiesContractDesc:
      "Le condizioni di trading più flessibili (aumento della leva finanziaria) possono essere applicate in caso di clienti classificati come Professionisti. La preghiamo di accedere al suo conto e di verificare l'idoneità alla classificazione di Cliente Professionale, oppure di scriverci a",
    supportmail: "support{'@'}aaafx.com",
    Instrument: "Strumento",
    Bid: "Offerta",
    Ask: "Chiedere",
    ContractSize: "Dimensioni del contratto",
    MinTradeSize: "Min. Dimensione commerciale",
    TickValue: "Valore di spunta",
    TradingHours: "Orari di negoziazione",
    sevenAM: "7:00",
    twelvePM: "12:00",
    TreasuryProductsDescription:
      "I prodotti del Tesoro hanno una scadenza trimestrale (vedere la tabella sottostante). I clienti che detengono una posizione aperta alla Data di scadenza saranno chiusi al nostro bid/offer alle 21:00 GMT per BUND; l'unica conseguenza è che il cliente realizzerà qualsiasi P/L fluttuante al momento della chiusura. Non sono previsti rollover per tutti i contratti del Tesoro offerti.",
    capsExpirationDates: "DATE DI SCADENZA",
    Bund: "Bund",
    cornf: "CORNF",
    wheatf: "GRANOF",
    soyf: "SOYF",
    seventhDecemberTwentyTwentyOne: "7-dic-21",
    twentysixNovemberTwentyOne: "26-nov-21",
    twentysevenNovemberTwentyOne: "27-nov-21",
    twentyFourFebruaryTwentyTwo: "24-feb-22",
    twentyNineDecemberTwentyOne: "29-dic-21",
    What_is_Commodities_Trading: "Che cos'è il trading di materie prime?",
    What_is_Commodities_Trading_desc:
      "Le materie prime comprendono i metalli preziosi, il petrolio e il gas naturale. Questi strumenti presentano una minore correlazione con tutti gli altri asset presenti sul mercato e possono rappresentare un'ottima opzione di diversificazione. Data la volatilità fondamentale dei Paesi di tutto il mondo, le materie prime sono considerate un 'bene rifugio' che le protegge dalle forti fluttuazioni dei prezzi.",
    How_does_it_work: "Come funziona?",
    How_does_it_work_desc:
      "Il trading di materie prime non comporta la consegna fisica di alcun bene. Il potenziale di guadagno risiede nei movimenti di prezzo. Poiché questi beni hanno un'offerta limitata sulla terra e sono utilizzati nella produzione industriale, a volte sono esposti alla volatilità, a seconda di vari fattori economici e politici. Senza possedere effettivamente l'asset, il trader può semplicemente speculare sul suo prezzo. Con una pletora di informazioni disponibili relative all'economia di vari Paesi, alle situazioni politiche, alle informazioni sugli investimenti, ai fattori di domanda e offerta, ecc. è diventato più conveniente che mai per i trader prevedere i principali movimenti di prezzo e speculare su di essi.I prezzi delle materie prime sono quotati principalmente in USD. Ad esempio, XAUUSD si riferisce al prezzo dell'oro in USD. Per investire effettivamente nell'oro, è necessario un capitale significativo. Ma con il trading a leva, può investire in tutti i metalli preziosi, petrolio, ecc. con un esborso minimo di capitale.",
    Account: "Conto",
    Types: "Tipi",
    ECN: "ECN",
    plus: "più",
    zulu: "zulu",
    DepositMin: "Deposito (min.)",
    spreadMin: "Larghezza (min.)",
    CompareAccountTypes: "Confronta i tipi di conto",
    OurOnline: "Il nostro sito online",
    Trading: "Trading",
    Platforms: "Piattaforme",
    Desktop: "Desktop",
    WebTrader: "Web Trader",
    Android: "Android",
    iOS: "iOS",
    capsLearnMore: "SCOPRI DI PIÙ",
    NegativeBalance: "Saldo negativo",
    Protection: "Protezione",
    NegativeBalanceDesc1:
      "Protezione affidabile dei suoi fondi sui Conti AAAFx",
    NegativeBalanceDesc2:
      "Ci sforziamo sempre di lavorare per il miglior interesse dei nostri clienti. Durante il trading in periodi di volatilità, se il suo conto va in negativo, noi la copriamo le spalle! Azzereremo il suo saldo negativo, in modo che non subisca ulteriori perdite. Può depositare e ricominciare a fare trading. Pertanto, non può perdere più di quanto depositato.",
    CapsOpenLiveAccount: "APRI UN CONTO LIVE",
    CapsOpenDemoAccount: "APRI UN CONTO DEMO",
  },
  careerPage: {
    heading: "Carriera",
    headingDescription:
      "Amiamo ciò che facciamo e pensiamo che lo amerà anche lei! Inclusività, diversità, innovazione e lealtà sono i motivi per cui le nostre persone amano il loro lavoro e amano venire al lavoro ogni giorno.",
    Join_us_and_help_us:
      "Unisciti a noi e aiutaci ad aggiungere valore in tutto ciò che facciamo!",
    OurCulture: "La nostra cultura",
    OurCulturePointOne:
      "Nessuno può suonare una sinfonia. Ci vuole un'intera orchestra per crearla. - (H.E. Luccock)",
    OurCulturePointTwo:
      "In AAAFx la incoraggiamo a essere la versione migliore di se stesso. Esprima chi è veramente; impari e abbia successo! Attualmente, impieghiamo più di 80 persone e puntiamo ad ampliare ulteriormente il nostro team. Il nostro obiettivo è creare un ambiente di lavoro favorevole che le dia un senso di realizzazione.",
    When_our_clients: "Quando i nostri clienti",
    succeed_we_succeed: "riusciamo, noi riusciamo!",
    succeed_we_succeed_pointOneP1: "Siamo meno quando non includiamo tutti.",
    succeed_we_succeed_pointOneP2: "- (Stuart Milk)",
    succeed_we_succeed_pointOnedesc1:
      "Abbiamo costruito una cultura coesa e inclusiva che abbraccia un'ampia gamma di dipendenti, che vantano una lunga lista di successi personali e un immenso spirito di squadra. L'inclusività favorisce un senso di appartenenza e di rispetto verso l'organizzazione. Questo è il motivo per cui le nostre persone si sentono importanti e apprezzate.  Ci sforziamo di creare un ambiente in cui tutti sentano di lavorare insieme per la stessa causa -.",
    succeed_we_succeed_pointOnedesc2:
      '"Prestazioni eccellenti per un domani migliore".',
    succeed_we_succeed_pointTwoP1:
      "La forza sta nelle differenze, non nelle somiglianze.",
    succeed_we_succeed_pointTwoP2: "- (Stephen R. Covey)",
    succeed_we_succeed_pointTwodesc1:
      "Avere un team diversificato in termini di genere e cultura è essenziale per avere un impatto più ampio e globale. Il nostro principio è quello di avere proporzioni uguali di membri del team di sesso maschile e femminile, con rispetto e accettazione. Inoltre, tenendo da parte le abilità fisiche, il razzismo e le credenze religiose, coesistiamo e prosperiamo in un ambiente accogliente. Ogni persona che lavora qui riceve pari opportunità, pari retribuzione e contribuisce a superare gli stereotipi della società.",
    succeed_we_succeed_pointThreeP1:
      "La fedeltà dei dipendenti inizia con la fedeltà del datore di lavoro.",
    succeed_we_succeed_pointThreeP2: " - (Harvey Mackay)",
    succeed_we_succeed_pointThreedesc:
      "Senza il sostegno del nostro team, non saremmo potuti arrivare dove siamo. Il nostro marchio prospera grazie alla fedeltà delle persone, siano esse clienti o dipendenti. La lealtà non è solo una qualità ordinaria; è una forza che spinge i membri del team a dare il meglio di sé e a raggiungere gli standard più elevati possibili. Inoltre, si dice spesso che la lealtà dei dipendenti è contagiosa. Parte dall'alto e attraversa tutte le sezioni del nostro team. Il nostro team è orgoglioso di ciò che fa e si impegna a dare il 100%.",
    succeed_we_succeed_pointFourP1: "L'innovazione è l'unico modo per vincere.",
    succeed_we_succeed_pointFourP2: "- (Steve Jobs)",
    succeed_we_succeed_pointFourdesc:
      "Promuoviamo il pensiero fuori dagli schemi a tutti i livelli per far emergere il meglio delle nostre persone. La creatività è essenziale per produrre cose nuove utilizzando l'immaginazione e poi le competenze uniche dei nostri collaboratori le perfezionano ulteriormente. Questo ci ha portato a migliorare le relazioni con i clienti, ad aumentare la produttività, a fornire un servizio migliore, a ridurre i costi e a costruire insieme un marchio forte.",
    Rewards_and_Benefits: "Premi e benefici",
    Rewards_and_Benefits_PointOne:
      "La ricompensa per un lavoro ben fatto è l'opportunità di fare di più. - (Jonas Salk)",
    Rewards_and_Benefits_PointTwo:
      "Vuole dedicare più tempo alla sua famiglia o alla sua salute e forma fisica? È pronto a sviluppare la sua professione, pur mantenendo un equilibrio? Non solo offriamo ai nostri dipendenti una retribuzione adeguata agli standard del settore, ma anche un ambiente di lavoro flessibile, premi vantaggiosi e varie modalità di apprezzamento e riconoscimento.",
    CurrentVacancies: "Offerte di lavoro attuali",
    CurrentVacanciesDescOne:
      "Si unisca a noi per entrare a far parte di una forza lavoro eccezionale, che è guidata solo ed esclusivamente dall'eccellenza e dall'impegno in un ambiente di squadra coeso. Tutti coloro che lavorano con AAAFx si sentono apprezzati sul lavoro!",
    CurrentVacanciesDescTwo:
      "Scopra cosa serve per diventare uno di noi e come può prepararsi al meglio. Non vediamo l'ora di ricevere la sua candidatura e di sentirla.",
    ReadyForChallengesReadyForYou:
      "Se è pronto per le sfide, noi siamo pronti per lei.",
    CustomerSupportGreece: "Executive dell'assistenza clienti, Grecia",
    What_the_job_looks_like: "Che aspetto ha il lavoro?",
    What_the_job_looks_like_pointOne:
      "Fornisce assistenza ai clienti via chat, e-mail e telefono.",
    What_the_job_looks_like_pointTwo:
      "Esaminare le richieste dei clienti in modo responsabile e proattivo.",
    What_the_job_looks_like_pointThree:
      "Indagare sui traffici dei clienti, quando necessario.",
    What_the_job_looks_like_pointFour:
      "Comunicare con i clienti in modo morbido, amichevole e altamente professionale.",
    What_the_job_looks_like_pointFive:
      "Lavorare a stretto contatto con la direzione su obiettivi e traguardi a breve e lungo termine.",
    What_the_job_looks_like_pointSix:
      "Prendere nota delle richieste dei clienti e trasmetterle in modo proattivo ai team competenti.",
    What_the_job_looks_like_pointSeven:
      "Seguire i team alleati, come e quando necessario, per offrire un servizio eccellente ai clienti.",
    What_the_job_looks_like_pointEight:
      "Garantire i più alti livelli di servizio ai clienti.",
    What_do_we_look_for: "Che cosa cerchiamo?",
    What_do_we_look_for_pointOne:
      "Forte presenza mentale e capacità di risolvere i problemi.",
    What_do_we_look_for_pointTwo: "Eccellenti capacità di comunicazione.",
    What_do_we_look_for_pointThree: "Forte spirito di squadra",
    What_do_we_look_for_pointFour:
      "Atteggiamento positivo e comportamento calmo e composto.",
    What_do_we_look_for_pointFive:
      "Esperienza precedente nella finanza d'investimento o nel ruolo di assistenza clienti.",
    What_do_we_look_for_pointSix: "Professionalità, onestà e integrità.",
    applyForTheJob: "SI CANDIDI PER IL LAVORO",
    FinanceExecutiveGreece: "Dirigente finanziario, Grecia",
    What_the_job_looks_like_point1:
      "Assistere nella realizzazione dei bilanci trimestrali e annuali.",
    What_the_job_looks_like_point2: "Gestione dei registri e delle ricevute",
    What_the_job_looks_like_point3:
      "Preparare l'analisi e i rapporti sul bilancio e sui dati reali",
    What_the_job_looks_like_point4:
      "Riconciliazione delle transazioni giornaliere, mensili, trimestrali e annuali.",
    What_the_job_looks_like_point5: "Preparazione dei bilanci trimestrali",
    What_the_job_looks_like_point6: "Elaborazione delle fatture",
    What_the_job_looks_like_point7:
      "Preparare i rendiconti del flusso di cassa per il Dipartimento",
    What_the_job_looks_like_point8:
      "Essere un punto di contatto per gli altri dipartimenti per le questioni relative alle finanze.",
    What_the_job_looks_like_point9: "Liquidazione dei rimborsi dei dipendenti.",
    What_the_job_looks_like_point10:
      "Calcolo e pubblicazione dei rapporti finanziari",
    What_the_job_looks_like_point11:
      "Fornire assistenza negli audit, come e quando richiesto.",
    What_the_job_looks_like_point12:
      "Portare a bordo idee e suggerimenti per una gestione efficiente dei fondi.",
    What_do_we_look_for_point1: "Passione per la finanza e la contabilità",
    What_do_we_look_for_point2: "Conoscenza completa di ERP e MS Office",
    What_do_we_look_for_point3:
      "Master in contabilità ed esperienza minima di 3 anni di lavoro con un broker globale.",
    What_do_we_look_for_point4: "Forti capacità analitiche",
    What_do_we_look_for_point5: "Eccellente giocatore di squadra",
    What_do_we_look_for_point6: "Atteggiamento positivo",
    DataAdministratorGreece: "Amministratore dati, Grecia",
    What_the_job_looks_like_p1:
      "Controlla le autorizzazioni e i privilegi di accesso.",
    What_the_job_looks_like_p2:
      "Fornire supporto e assistenza agli sviluppatori.",
    What_the_job_looks_like_p3:
      "Monitorare e mantenere la salute e la sicurezza del sistema.",
    What_the_job_looks_like_p4:
      "Progetta processi di backup per il server e i dati associati.",
    What_the_job_looks_like_p5:
      "Crea account per tutti gli utenti e assegna livelli di sicurezza.",
    What_the_job_looks_like_p6:
      "Fornire assistenza tecnica end-to-end e risoluzione dei problemi.",
    What_the_job_looks_like_p7:
      "Pianifica ed esegue la manutenzione regolare del server.",
    What_the_job_looks_like_p8: "Creare procedure di gestione del database.",
    What_the_job_looks_like_p9:
      "Preparare informazioni statistiche per uso interno ed esterno.",
    What_the_job_looks_like_p10:
      "Valutare i modelli e le procedure di analisi dei dati.",
    What_the_job_looks_like_p11:
      "Partecipa al programma di formazione trasversale dell'azienda.",
    What_do_we_look_for_p1: "Laurea in Informatica.",
    What_do_we_look_for_p2: "1+ anni di esperienza in un ruolo simile.",
    What_do_we_look_for_p3:
      "Conoscenza avanzata della struttura e della teoria dei database.",
    What_do_we_look_for_p4: "Esperienza con MySQL e MSSQL.",
    What_do_we_look_for_p5:
      "Comprensione delle procedure di backup del database.",
    What_do_we_look_for_p6: "La certificazione PostgreSQL è preferibile.",
    What_do_we_look_for_p7:
      "Capacità di lavorare in modo indipendente con una supervisione e un'assistenza minime.",
    How_to_Apply: "Come fare domanda?",
    How_to_Apply_p1:
      "Se ritiene di potersi inserire bene in uno dei ruoli sopra descritti, non deve fare altro che cliccare sul pulsante 'Candidati' e inviarci il suo",
    How_to_Apply_p2:
      "Curriculum. Il nostro responsabile delle risorse umane la contatterà.",
  },
  autoChartistPage: {
    heading: "Autochartist",
    headingDesc:
      "Le permette di investire il suo tempo di trading in modo giudizioso",
    subHeadingOne:
      "Acceda gratuitamente a uno degli strumenti di trading più popolari di AAAFx.",
    subHeadingOneP1:
      "Prenda decisioni informate, perché c analizza tutti i grafici e i modelli per lei.",
    subHeadingOneP2:
      "Se è un trader, conosce il valore del tempo come nessun altro. Le presentiamo uno strumento eccellente che può farle risparmiare tutto il suo tempo e la sua energia, eseguendo per lei diversi tipi di analisi.",
    subHeadingTwo: "Come funziona Autochartist?",
    subHeadingTwoP1: "Non perda mai un'opportunità!",
    subHeadingTwoP2: "Autochartist lavora su tre tipi di modelli:",
    ChartPatterns: "Modelli di grafici",
    FibonacciPatterns: "Modelli di Fibonacci",
    KeyLevels: "Livelli chiave",
    subHeadingTwoDesc:
      "Monitora il mercato e continua a inviare avvisi per le opportunità di trading. Lasci che la tecnologia lavori per lei 24 ore al giorno, senza sosta.",
    One_stop_solution: "Soluzione unica per l'analisi di mercato",
    One_stop_solution_p1:
      "Identificazione dei livelli di supporto e resistenza",
    One_stop_solution_p2: "Avvisi in tempo reale di movimenti eccessivi",
    One_stop_solution_p3: "Analisi approfondita delle notizie macroeconomiche",
    One_stop_solution_p4:
      "Identificazione dei livelli di supporto e resistenza",
    Single_Screen_access_to_everythinig:
      "Accesso a tutto in un'unica schermata",
    Get_simply_plugged_in_to_MT4_and_MT5:
      "Si colleghi semplicemente alla MT4 e alla MT5",
    API_Driven_Technology_free_from_manual_errors:
      "Tecnologia API Driven, senza errori manuali",
    All_rounder_for_all_types_of_Traders:
      "Tuttofare per tutti i tipi di trader",
    Autochartist_Volatility_Analysis:
      "Analisi della volatilità di Autochartist",
    Autochartist_Volatility_Analysis_p1:
      "Costruisca la sua strategia di trading basandosi sui fatti.",
    Autochartist_Volatility_Analysis_p2:
      "Conoscere il momento migliore per fare trading su uno strumento, acquisendo la conoscenza dei suoi momenti di maggiore e minore volatilità durante la giornata.",
    Autochartist_Volatility_Analysis_p3:
      "Con questo strumento, potrà vedere quanto segue",
    Autochartist_Volatility_Analysis_sub_p1:
      "Intervalli di prezzo previsti in vari periodi di tempo",
    Autochartist_Volatility_Analysis_sub_p2:
      "Movimento del prezzo previsto per ora del giorno",
    Autochartist_Volatility_Analysis_sub_p3:
      "Movimento del prezzo previsto per giorno della settimana",
    Know_more_here: "Per saperne di più",
    AutochartistRiskCalculator: "Calcolatore del rischio di autocostruzione",
    AutochartistRiskCalculatorP1:
      "Misuri il suo rischio prima di fare trading.",
    AutochartistRiskCalculatorP2:
      "Ora può essere sempre consapevole del volume di trading ideale per lei, tenendo conto della sua propensione al rischio.",
    AutochartistRiskCalculatorP3:
      "Questo strumento le mostra gli intervalli di movimento dei prezzi previsti",
    AutochartistRiskCalculatorSubP1:
      "Imposta il livello di ingresso e il livello di stop loss desiderato",
    AutochartistRiskCalculatorSubP2:
      "Inserisca la somma di denaro che è disposto a rischiare sull'operazione.",
    AutochartistRiskCalculatorSubP3:
      "La Calcolatrice le indicherà la dimensione della posizione da assumere",
    CapsOpenLiveAccount: "APRI UN CONTO LIVE",
    CapsOpenDemoAccount: "APRI UN CONTO DEMO",
  },
  cryptoCurrency: {
    innerPages: {
      forex: "Forex",
      indices: "Indici",
      commodities: "Materie prime",
      stocks: "Azioni",
      crypto: "Criptovalute",
    },
    title: "CRIPTOVALUTE",
    heading:
      "Negoziare le criptovalute più potenti sulle nostre piattaforme di trading",
    min: "min .",
    tradeSize: "Dimensione del commercio",
    execution: "esecuzione",
    paris: "Coppie",
    leverage: "Leva",
    upTo: "Fino a",
    contract: "Contratto sulle criptovalute",
    specification: "Specifiche",
    pTag: "Le condizioni di trading più flessibili (aumento della leva finanziaria) possono essere applicate in caso di clienti classificati come Professionisti. La preghiamo di accedere al suo conto e di verificare l'idoneità alla classificazione di Cliente Professionale, oppure di scriverci a",
    email: "support{'@'}aaafx.com",
    table: {
      heading: {
        instrument: "Strumento",
        bid: "Offerta",
        ask: "Richiesta",
        spread: "Spread",
        contractSize: "Dimensioni del contratto",
        tradeSize: "Min. Dimensione commerciale",
        tickValue: "Valore di spunta",
        tradingHours: "Orari di negoziazione",
      },
    },
    trading: "Che cos'è il trading di criptovalute?",
    pTag1:
      "Le criptovalute sono essenzialmente asset digitali garantiti dalla crittografia che si muovono attraverso una rete elettronica decentralizzata. Questi asset non sono sostenuti da alcuna merce o non sono convertibili in essa. Funzionano tramite un libro mastro pubblico, basato sulla tecnologia Blockchain. Il Bitcoin, rilasciato nel 2009, è la prima criptovaluta decentralizzata. Da allora, sono state create molte altre criptovalute. Queste monete hanno davvero cambiato le carte in tavola nei mercati finanziari.",
    howItWork: "Come funziona?",
    pTag2:
      "Le valute fiat tradizionali sono generalmente influenzate dagli eventi economici e politici che si verificano in tutto il mondo. Tuttavia, poiché le criptovalute non sono legate a nessun Paese in particolare e sono generalmente decentralizzate, i loro prezzi tendono a muoversi in modo indipendente. Si tratta di una classe di asset ad alto grado di volatilità, il che rende indispensabile per i trader soppesare il rischio prima di fare trading su di esse. Offriamo CFD sulle criptovalute, il che significa che può semplicemente fare trading sulle criptovalute senza doverle possedere. I profitti e le perdite dovuti al differenziale di prezzo vengono semplicemente aggiustati sul suo conto. Questi strumenti appaiono come una coppia, quotata rispetto all'USD. Ad esempio, BTCUSD. Il prezzo di questa coppia indica quanti USD sono necessari per 1 BTC.",
    account: "Conto",
    types: "Tipi",
    ecn: "ECN",
    plus: "più",
    zulu: "zulu",
    deposit: "Deposito",
    commission: "Commissione",
    compare: "Confronta i tipi di conto",
    ourOnline: "Il nostro sito online",
    tradingText: "Trading",
    platform: "Piattaforme",
    desktop: "Desktop",
    webTrade: "Web Trader",
    android: "Android",
    ios: "iOS",
    learn: "SCOPRI DI PIÙ",
    negative: "Saldo negativo",
    protection: "Protezione",
    pSubTag: "Protezione affidabile dei suoi fondi sui Conti AAAFx",
    pTag3:
      "Ci sforziamo sempre di lavorare per il miglior interesse dei nostri clienti. Durante il trading in tempi volatili, se il suo conto va in negativo, noi la copriamo le spalle! Azzereremo il suo saldo negativo, in modo che non subisca ulteriori perdite. Può depositare e ricominciare a fare trading. Pertanto, non può perdere più di quanto depositato.",
    live: "APRI UN CONTO LIVE",
    demo: "APRI UN CONTO DEMO",
  },
  ib: {
    whiteLabel: "Etichetta bianca",
    pamm: "PAMM/MAM",
    title: "Introducing Broker",
    heading: "Sviluppare una fonte di reddito permanente per lei",
    h3Tag:
      "Il successo oggi si basa sulla costruzione di una rete forte, lasci che la sua rete lavori per guadagnare denaro per lei, anche quando dorme!",
    pTag: "Vuole tirare fuori il meglio delle sue capacità di marketing? AAAFx ha creato un programma di arricchimento per i nostri clienti, per diffondere la nostra fantastica piattaforma e guadagnare un reddito illimitato. Basta invitare i trader a noi e guadagnare una commissione quando fanno trading!",
    h3Tag1:
      "Diffondere la parola - Creare un impatto nella comunità di trading",
    pTag1:
      "Offriamo condizioni di trading e strumenti eccezionali per i trader e aiutiamo i nostri partner IB a crescere insieme ai loro clienti:",
    ul: {
      li: "Alta liquidità per il trading",
      liTag: "Zero commissioni sul Forex",
      liTag1: "Spread crudi",
      liTag2: "Leva elevata fino a 500X",
      liTag3: "Broker fidato e affidabile",
      liTag4: "Accesso a strumenti di trading come Auto chartist",
      liTag5: "Accesso diretto a Zulu Trade",
      liTag6: "Tecnologia all'avanguardia",
    },
    question: {
      ques: "Perché dovrebbe partecipare come AAAFx IB?",
      point:
        "La ricompensa per il suo lavoro intelligente e la sua costanza può essere enorme se continua a far crescere la nostra comunità di trading, aiutandola a sfruttare le diverse caratteristiche delle nostre piattaforme di trading. In AAAFx, offriamo il programma IB più personalizzato e più semplice. Non tutti gli IB sono uguali. Ma abbiamo qualcosa da offrire a tutti.",
      point1:
        "Per questo motivo, abbiamo creato due categorie di programmi IB:",
      point2:
        "Programma IB standard - Ottiene subito il 20% dei nostri guadagni di commissione dai suoi clienti. Questo è utile per un IB principiante che vuole iniziare da zero o che può iniziare a portare un volume limitato di clienti da noi.",
      point3:
        "Programma IB avanzato - Si tratta di un programma IB altamente gratificante e personalizzato per i nostri IB più grandi. È utile per chi ha una base clienti consistente e può portare un volume maggiore.",
      point4:
        "Può passare dal Programma IB Standard al Programma IB Avanzato, una volta che avrà creato una rete sufficientemente ampia.",
    },
    signUp: "Si iscriva come IB",
    referralLink: "Condividere il link di riferimento",
    clients: "Ottenere i suoi clienti",
    earn: "Guadagnare con il commercio",
    benefits: "Altri vantaggi del nostro Programma IB",
    zero: "Guadagna con zero investimenti o esperienza",
    ibCommission: "Pagamenti settimanali delle commissioni IB",
    earning: "Potenziale di guadagno illimitato",
    customized: "Programma IB avanzato altamente personalizzato e gratificante",
    ultraFast: "Prelievi ultraveloci delle commissioni IB",
    easyUser: "Interfaccia utente facile del portale IB",
    accountManager: "Account Manager dedicato",
    helpful: "Materiale di marketing utile e gratuito",
    h6Tag:
      "Il nostro Programma IB Avanzato è altamente personalizzabile con un elevato potenziale di reddito. Se pensa di avere questo potenziale, si metta in contatto con noi per saperne di più. Ci contatti ora",
    click: "qui",
    orWrite: "oppure ci scriva all'indirizzo",
    email: "support{'@'}aaafx.com.",
  },
  mt4PageData: {
    MT4: "MT4",
    MetaTrade4: "MetaTrader 4",
    MT5: "MT5",
    ActTrader: "ActTrader",
    ZuluTrade: "ZuluTrade",
    MetaTrade4DescOne:
      "La piattaforma MetaTrader 4 è stata creata nel 2002 da MetaQuotes. La piattaforma MT4 è considerata il software gold-standard tra la vasta gamma di piattaforme di trading; viene utilizzata per il trading di forex, CFD, azioni e varie criptovalute popolari.",
    MetaTrade4DescTwo:
      "La piattaforma MT4 di AAAFx offre un'interfaccia facile da usare, che consente agli utenti di comprendere le caratteristiche della piattaforma con assoluta facilità. Assimila le esigenze dell'utente e offre la migliore soluzione possibile, fornendo la possibilità di applicare vari strumenti di analisi, di eseguire robot di trading (consulenti esperti) e di copiare le operazioni di altri.",
    MetaTrade4DescThree:
      "Migliori la sua esperienza di trading su uno schermo più grande, con maggiori funzionalità e maggiore attenzione, scaricando la piattaforma MT4 sul suo desktop o semplicemente accedendo alla nostra piattaforma web.",
    Steps_to_download_MT4_Desktop: "Passi per scaricare MT4 Desktop",
    Steps_to_download_MT4_Desktop_point1:
      "1. Scarichi la piattaforma dal link qui sopra.",
    Steps_to_download_MT4_Desktop_point2: "2. Esegua il file .exe",
    Steps_to_download_MT4_Desktop_point3: '3. Vada su File> Aprire un conto".',
    Steps_to_download_MT4_Desktop_point4:
      "4. Clicchi su Aggiungi nuovo broker e digiti AAAFX. Selezioni AAAFX-FX-DEMO per il conto demo e AAAFX-FX-REAL per il conto live.",
    Steps_to_download_MT4_Desktop_point5:
      "5. Clicchi sul pulsante Scansione. Il server è ora aggiunto.",
    Steps_to_download_MT4_Desktop_point6:
      '6. Clicchi su Avanti> selezioni la casella di controllo "Conto commerciale esistente" e inserisca il suo ID di accesso e la sua Password. Clicchi su Fine.',
    Steps_to_uninstall_MT4_Desktop: "Passi per disinstallare MT4 Desktop",
    Steps_to_uninstall_MT4_Desktop_point1:
      "1. Clicchi sul pulsante Start in Windows",
    Steps_to_uninstall_MT4_Desktop_point2:
      "2. L'applicazione MT4 sarà inclusa nell'elenco dei programmi.",
    Steps_to_uninstall_MT4_Desktop_point3:
      "3. Clicchi con il tasto destro del mouse sul nome dell'applicazione e selezioni 'Disinstalla'.",
    Steps_to_log_into_Webbased_MT4:
      "Passi per accedere alla MT4 basata sul web",
    Steps_to_log_into_Webbased_MT4_point1:
      '1. Sul Web-Trader, vada all\'opzione "File" sulla barra dei menu e selezioni "Accedi al conto Trade".',
    Steps_to_log_into_Webbased_MT4_point2:
      "2. Inserisca l'ID e la password del conto trading.",
    Steps_to_log_into_Webbased_MT4_point3:
      '3. Selezioni il server come "AAAFX-FX-REAL" per il conto live e "AAAFX-FX-DEMO" per il conto demo.',
    Steps_to_log_into_Webbased_MT4_point4:
      '4. Poi selezioni la piattaforma MT4 e clicchi su "OK".',
    AAAFx_mobile_trading_application_description:
      "Porti la sua euforia di trading ovunque vada con tutti gli strumenti di trading e gli indicatori disponibili per l'applicazione di trading mobile AAAFx.",
    Steps_to_download_MT4_Android: "Passi per scaricare MT4 Android",
    Steps_to_download_MT4_Android_point1:
      "1. Scarichi l'applicazione dal link qui sopra.",
    Steps_to_download_MT4_Android_point2:
      '2. Clicchi sulle tre linee orizzontali nel Menu e vada su "Gestione conti"/"Inizia".',
    Steps_to_download_MT4_Android_point3:
      '3. Clicchi sul segno "+" nell\'angolo in alto a destra.',
    Steps_to_download_MT4_Android_point4:
      '4. Selezioni "Accedi a un conto esistente".',
    Steps_to_download_MT4_Android_point5:
      "5. Alla voce 'Trova il tuo broker' seleziona AAAFX-FX-DEMO per il conto demo e AAAFX-FX-REAL per il conto live. Ora inserisca il suo ID di accesso e la sua password e clicchi su login.",
    Steps_to_uninstall_MT4_Android: "Passi per disinstallare MT4 Android",
    Steps_to_uninstall_MT4_Android_point1:
      "1. Nel Menu, vada su Impostazioni > Applicazioni > Gestisci applicazioni",
    Steps_to_uninstall_MT4_Android_point2:
      "2. Trovi la MT4 elencata e tocchi il suo nome.",
    Steps_to_uninstall_MT4_Android_point3: "3. Toccare 'Disinstalla'.",
    Steps_to_download_MT4_iOS: "Passi per scaricare MT4 iOS",
    Steps_to_download_MT4_iOS_point1:
      "1. Scarichi l'applicazione dal link qui sopra.",
    Steps_to_download_MT4_iOS_point2:
      '2. Clicchi su "Impostazioni" e vada all\'opzione "Nuovo account",',
    Steps_to_download_MT4_iOS_point3:
      '3. Poi clicchi su "Accedi a un account esistente".',
    Steps_to_download_MT4_iOS_point4:
      '4. Inserisca "AAAFX" alla voce Trova broker.',
    Steps_to_download_MT4_iOS_point5:
      "5. Selezioni AAAFX-FX-DEMO per il conto demo e AAAFX-FX-REAL per il conto live.",
    Steps_to_download_MT4_iOS_point6:
      '6. Inserisca il suo ID di accesso e la sua password e clicchi su "Accedi".',
    Steps_to_uninstall_MT4_iOS: "Passi per disinstallare MT4 iOS",
    Steps_to_uninstall_MT4_iOS_point1:
      "1. Prema a lungo sull'icona dell'applicazione MT4 finché tutte le icone sullo schermo non iniziano a tremare.",
    Steps_to_uninstall_MT4_iOS_point2:
      "2. Clicchi sulla 'x' nell'angolo superiore sinistro dell'icona dell'applicazione MT4.",
    take_trading_experience_to_next_level:
      "Porti la sua esperienza di trading sulla piattaforma MT4 con AAAFx al livello successivo con le seguenti caratteristiche",
    Why_AAAFxs_MT4: "Perché la MT4 di AAAFx?",
    Why_AAAFxs_MT4_point1:
      "Associato alla più grande comunità di trading di MQL",
    Why_AAAFxs_MT4_point2: "20+ oggetti grafici per un'analisi approfondita",
    Why_AAAFxs_MT4_point3: "Prezzo di mercato in tempo reale",
    Why_AAAFxs_MT4_point4: "Interfaccia semplice da capire",
    Why_AAAFxs_MT4_point5:
      "Compatibile con Windows, iPhone, Android o semplicemente con il commercio direttamente dal web.",
    Why_AAAFxs_MT4_point6:
      "Script pronti all'uso; consentono un'esperienza di trading automatizzata.",
    Why_AAAFxs_MT4_point7:
      "1000+ indicatori/EA gratuiti e scaricabili offerti da MQL4",
    Why_AAAFxs_MT4_point8:
      "La velocità di esecuzione più elevata con server multipli in tutto il mondo",
    Why_AAAFxs_MT4_point9:
      "70+ coppie di valute forex, indici globali e materie prime disponibili per il trading",
    Why_AAAFxs_MT4_point10:
      "Esecuzione a mercato e 4 tipi di ordini pendenti disponibili",
    capsOpenLiveAccount: "APRI UN CONTO LIVE",
    capsOpenDemoAccount: "APRI UN CONTO DEMO",
  },
  mt5PageData: {
    MT4: "MT4",
    MetaTrade5: "MetaTrader 5",
    MT5: "MT5",
    ActTrader: "ActTrader",
    ZuluTrade: "ZuluTrade",
    MetaTrade5DescOne:
      "La piattaforma MT5 è stata rilasciata nel 2010 e supporta una tecnologia di elaborazione più veloce che mai e layout aggiuntivi.",
    MetaTrade5DescTwo:
      "La piattaforma MT5 di AAAFx offre un ampio elenco di strumenti di trading, tra cui i CFD sulle criptovalute, aiutando la piattaforma avanzata a realizzare le sue prospettive futuristiche.",
    download_mt5_preferred_device:
      "È sempre meglio su uno schermo più grande. Scarichi la nostra piattaforma MT5 sul suo dispositivo preferito.",
    Steps_to_download_MT5_Desktop: "Passi per scaricare MT5 Desktop",
    Steps_to_download_MT5_Desktop_point1:
      "1. Scarichi la piattaforma dal link qui sopra.",
    Steps_to_download_MT5_Desktop_point2: "2. Esegua il file .exe",
    Steps_to_download_MT5_Desktop_point3: '3. Vada su File> Aprire un conto".',
    Steps_to_download_MT5_Desktop_point4:
      "4. Digiti AAAFX e in 'Aggiungi nuovo broker' e clicchi su 'Trova il tuo broker'.",
    Steps_to_download_MT5_Desktop_point5:
      '5. Selezioni "AAAFX" e clicchi su "Avanti".',
    Steps_to_download_MT5_Desktop_point6:
      '6. Clicchi su "Connetti con un conto trading esistente" e inserisca i suoi dati di accesso.',
    Steps_to_download_MT5_Desktop_point7:
      "7. Per accedere a un conto Live, selezioni il nome del server 'AAAFX-FX-REAL' e 'AAAFX-FX-DEMO' per il conto Demo dal menu a discesa e clicchi su \"Fine\".",
    Steps_to_uninstall_MT5_Desktop: "Passi per disinstallare MT5 Desktop",
    Steps_to_uninstall_MT5_Desktop_point1:
      "1. Clicchi sul pulsante Start in Windows",
    Steps_to_uninstall_MT5_Desktop_point2:
      "2. L'applicazione MT5 sarà inclusa nell'elenco dei programmi.",
    Steps_to_uninstall_MT5_Desktop_point3:
      "3. Clicchi con il tasto destro del mouse sul nome dell'applicazione e selezioni 'Disinstalla'.",
    Steps_to_log_into_Webbased_MT5:
      "Passi per accedere alla MT5 basata sul web",
    Steps_to_log_into_Webbased_MT5_point1:
      '1. Sul Web-Trader, vada all\'opzione "File" sulla barra dei menu e selezioni "Accedi al conto Trade".',
    Steps_to_log_into_Webbased_MT5_point2:
      "2. Inserisca l'ID e la password del conto trading.",
    Steps_to_log_into_Webbased_MT5_point3:
      '3. Selezioni il server "AAAFX-FX-REAL" per il conto live e "AAAFX-FX-DEMO" per il conto demo.',
    Steps_to_log_into_Webbased_MT5_point4:
      '4. Poi selezioni la piattaforma MT5 E clicchi su "OK".',
    AAAFx_mobile_trading_application:
      "Porti la sua euforia di trading ovunque vada con tutti gli strumenti di trading e gli indicatori disponibili per l'applicazione di trading mobile AAAFx.",
    Steps_to_download_MT5_Android: "Passi per scaricare MT5 Android",
    Steps_to_download_MT5_Android_point1:
      "1. Scarichi l'applicazione dal link qui sopra.",
    Steps_to_download_MT5_Android_point2:
      '2. Clicchi sulle tre linee orizzontali nel Menu e vada a gestire gli account"/"Iniziare".',
    Steps_to_download_MT5_Android_point3:
      '3. Clicchi sul segno "+" nell\'angolo in alto a destra.',
    Steps_to_download_MT5_Android_point4:
      "4. Alla voce 'Trova il tuo broker' seleziona AAAFX-FX-DEMO per il conto demo e AAAFX-FX-REAL per il conto live. Ora inserisca il suo ID di accesso e la sua password e clicchi su login.",
    Steps_to_uninstall_MT5_Android: "Passi per disinstallare MT5 Android",
    Steps_to_uninstall_MT5_Android_point1:
      "1. Nel Menu, vada su Impostazioni > Applicazioni > Gestisci applicazioni",
    Steps_to_uninstall_MT5_Android_point2:
      "2. Trovi la MT4 elencata e tocchi il suo nome.",
    Steps_to_uninstall_MT5_Android_point3: "3. Toccare 'Disinstalla'.",
    Steps_to_download_MT5_iOS: "Passi per scaricare MT5 iOS",
    Steps_to_download_MT5_iOS_point1:
      "1. Scarichi l'applicazione dal link qui sopra.",
    Steps_to_download_MT5_iOS_point2:
      '2. Clicchi su "Impostazioni" e vada all\'opzione "Nuovo account",',
    Steps_to_download_MT5_iOS_point3:
      '3. Poi clicchi su "Accedi a un account esistente".',
    Steps_to_download_MT5_iOS_point4:
      '4. Inserisca "AAAFX" alla voce trova broker. br 5. Selezioni AAAFX-FX-DEMO per il conto demo e AAAFX-FX-REAL per il conto live.',
    Steps_to_download_MT5_iOS_point5:
      '5. Inserisca il suo ID di accesso e la sua password e clicchi su "Accedi".',
    Steps_to_uninstall_MT5_iOS: "Passi per disinstallare MT5 iOS",
    Steps_to_uninstall_MT5_iOS_point1:
      "1. Prema a lungo sull'icona dell'applicazione MT5 finché tutte le icone sullo schermo non iniziano a tremare.",
    Steps_to_uninstall_MT5_iOS_point2:
      "2. Clicchi sulla 'x' nell'angolo superiore sinistro dell'icona dell'applicazione MT5.",
    trading_experience_the_next_level:
      "Porti la sua esperienza di trading sulla piattaforma MT5 con AAAFx al livello successivo con le seguenti caratteristiche:",
    Why_AAAFxs_MT5: "Perché la MT5 di AAAFx?",
    TechnicalAnalysisTools: "Strumenti di analisi tecnica",
    TechnicalAnalysisToolsPoint1:
      "21 diverse cornici temporali, in modo da non perdere nemmeno un secondo di opportunità.",
    TechnicalAnalysisToolsPoint2:
      "30+ indicatori integrati e 40+ oggetti tecnici",
    TechnicalAnalysisToolsPoint3:
      "Disponibilità di dati grafici storici fino a 30 anni.",
    TechnicalAnalysisToolsPoint4:
      "6 tipi di ordini pendenti disponibili insieme all'esecuzione sul mercato",
    TechnicalAnalysisToolsPoint5:
      "Opzione integrata e modificabile per gli script",
    FundamentalAnalysisTools: "Strumenti di analisi fondamentale",
    FundamentalAnalysisToolsPoint1:
      "Calendario economico incorporato; la tiene aggiornato su ogni evento volatile.",
    FundamentalAnalysisToolsPoint2:
      "Possibilità di scaricare l'estratto conto dettagliato per un periodo di tempo personalizzabile.",
    FundamentalAnalysisToolsPoint3:
      "Lo strumento della profondità del mercato le offre un vantaggio sulle sue strategie di trading.",
    capsOpenLiveAccount: "APRI UN CONTO LIVE",
    capsOpenDemoAccount: "APRI UN CONTO DEMO",
  },
  ourServicesPage: {
    heading: "I nostri server",
    headingDescription: "Un'impostazione robusta per un'esperienza impeccabile",
    EquinixDataCenters: "Centri dati Equinix",
    EquinixDataCentersDescription:
      "Nel nostro sforzo di offrire ai nostri clienti un'esperienza di trading di livello mondiale, ci affidiamo a niente di meno che alla migliore tecnologia e infrastruttura del mondo. Il nostro obiettivo è quello di fornire la connettività più affidabile e veloce quando si tratta di eseguire le transazioni e, pertanto, utilizziamo la rete globale e i cluster di server più avanzati al mondo.",
    What_are_the_advantages_of_Equinix: "Quali sono i vantaggi di Equinix?",
    FastestExecution: "Esecuzione più veloce",
    FastestExecutionDescription:
      "Il Data Center è collocato presso i nostri principali LP, garantendo così la connettività più veloce possibile.",
    Low_latency_connection: "Connessione a bassa latenza",
    Low_latency_connection_description:
      "La connessione veloce tra i nostri server commerciali e gli LP attraverso un canale dedicato garantisce la minima latenza possibile.",
    Uninterrupted_trading: "Trading ininterrotto",
    Uninterrupted_trading_description:
      "Connettività istantanea e senza interruzioni, tempo di attività massimo, per rendere la sua esperienza di trading impeccabile.",
    Low_slippage: "Basso slittamento",
    Low_slippage_description:
      "La connessione ultraveloce le consente di ottenere il prezzo desiderato, con minime possibilità di slittamento.",
    Easy_connectivity: "Connettività facile",
    Easy_connectivity_description:
      "Non dovrà lottare con la connettività quando si collegherà con una rete densa che funziona con una tecnologia all'avanguardia.",
    What_is_Equinix: "Che cos'è Equinix?",
    What_is_Equinix_desone:
      "Equinix, Inc. (NASDAQ: EQIX) è una multinazionale americana con sede a Redwood City, California, specializzata nella connettività Internet e nella gestione dei centri dati.",
    What_is_Equinix_destwo:
      "L'azienda è leader nella co-locazione globale con 229 centri dati in 27 Paesi di cinque continenti. Inoltre, ha vinto diversi premi per l'affidabilità e la sostenibilità. I loro centri dati sono considerati il gold standard e l'infrastruttura digitale più affidabile e sicura.",
    What_is_the_LD4_Data_Center: "Che cos'è il Centro dati LD4?",
    What_is_the_LD4_Data_Center_descOne:
      "Il centro dati Equinix LD4, dove è ospitata la maggior parte dei server di AAAFx, è la posizione più importante del settore Forex per il posizionamento dei server, in quanto consente una connettività diretta e diretta con tutti i principali fornitori di liquidità globali. Ha una superficie di oltre 14.000 metri quadrati e possiede diverse certificazioni globali, come SOC 1 Tipo 1, SOC 2 Tipo 2, ISO 27001, PCI DSS, OHSAS 18001, ISO 9001:2015, ISO 22301, ISO 14001:1015 e ISO 50001.",
    What_is_the_LD4_Data_Center_descTwo:
      "La connessione accelerata ai nostri fornitori di liquidità attraverso la fitta rete consente un'esecuzione ultraveloce delle operazioni con una latenza minima.",
  },
  LoyaltyBonusPageData: {
    heading: "Programma fedeltà",
    headingDescription: "Più scambierà, più premi guadagnerà automaticamente!",
    LoyaltyBonusDescription:
      "Si dice che la fedeltà vada in entrambe le direzioni. Noi ci guadagniamo la fedeltà dei nostri clienti attraverso il nostro duro lavoro <br />e la nostra trasparenza, e crediamo di poterla restituire attraverso il nostro programma gratuito Rewards.0",
    What_is_AAAFx_loyalty_program: "Che cos'è il programma fedeltà AAAFx?",
    What_is_AAAFx_loyalty_program_descOne:
      "Ogni operazione conta nel viaggio di un trader. Ogni operazione effettuata con noi contribuisce a garantirle rendimenti entusiasmanti grazie al nostro meraviglioso Programma Fedeltà.",
    What_is_AAAFx_loyalty_program_descTwo:
      "Non è necessario iscriversi. Tutte le sue transazioni continueranno a farle guadagnare Punti Premio (RP) automaticamente. Una volta raccolto un certo numero di Punti Premio (RP), potrà riscattarli in diversi modi, anche in contanti!",
    Deposit: "Deposito",
    Trade: "Commercio",
    Earn_RPs: "Guadagna RP",
    Redeem_RPs: "Riscattare RP",
    loyalty_program_descOne:
      "Il nostro programma fedeltà è adatto a tutti i tipi di",
    loyalty_program_descTwo: "commercianti. Ci sono due modi per progredire",
    loyalty_program_descThree: "attraverso i suoi Livelli:",
    ExpressRoute: "Percorso espresso",
    ExpressRouteDesc:
      "Adatto a quei trader che amano fare più operazioni in un'unica soluzione.",
    SteadyRoute: "Percorso costante",
    SteadyRouteDesc:
      "Per coloro che amano fare trading in modo coerente per un periodo di tempo più lungo",
    OurFabulousFourLoyaltyTiers: "I nostri favolosi quattro livelli di fedeltà",
    OurFabulousFourLoyaltyTiersDescOne: "Negoziare di più, guadagnare di più",
    OurFabulousFourLoyaltyTiersDescTwo:
      "Diventa sempre più grande e migliore ad ogni livello che sale.",
    OurFabulousFourLoyaltyTiersDescThree:
      "Abbiamo due percorsi molto semplici e flessibili per qualificarsi ai livelli di fedeltà. Qualunque sia il percorso da seguire, ci assicureremo che",
    OurFabulousFourLoyaltyTiersDescFour:
      "guadagna gli stessi livelli di fedeltà applicabili e gli stessi punti premio. Se si sovrappone in questi due percorsi, sarà",
    OurFabulousFourLoyaltyTiersDescFive:
      "assegnare il livello applicabile più alto tra i due percorsi.",
    Redeem_your_Reward_Points: "Riscuota i suoi Punti Premio",
    EarnMoreRedeemMore: "Guadagna di più, riscatta di più",
    EarnMoreRedeemMoreDesc:
      "Opzioni flessibili e attraenti per convertire i suoi punti Premio in numerosi vantaggi.",
    Redeem_your_Reward_Points_point1:
      "Contanti (Min. 500 RP possono essere riscattati in contanti)",
    Redeem_your_Reward_Points_point2: "VPS di base gratuito per 3 mesi",
    Redeem_your_Reward_Points_point3: "VPS Premium gratuito per 3 mesi",
    Redeem_your_Reward_Points_point4: "Abbonamento SeatsonAX per 3 mesi",
    Redeem_your_Reward_Points_point5:
      "Prelievi ancora più rapidi per i prossimi 20 giorni",
    Redeem_your_Reward_Points_point6:
      "Passa al livello successivo del Livello Premio",
    Redeem_your_Reward_Points_point7:
      "Iphone/Ipad o un dispositivo intelligente simile con un valore massimo di $1200.",
    Redeem_your_Reward_Points_point8:
      "1 Pip spread piatto per 10 giorni di trading",
    Redeem_your_Reward_Points_point9:
      "10% di commissione IB extra per l'ultimo mese",
    Redeem_your_Reward_Points_point10:
      "100 dollari in più per ogni referenza nell'ultimo mese",
    Redeem_your_Reward_Points_point11: "1 mese di rimborso delle commissioni",
    Redeem_your_Reward_Points_point12:
      "Deposito anticipato per deposito in transito",
    Redeem_your_Reward_Points_point13: "Bonus aggiuntivo sul conto",
    Redeem_your_Reward_Points_point14:
      "Rimborso della commissione swap di 1 mese",
    Redeem_your_Reward_Points_point15:
      "10% di deposito extra (prelevabile) sugli ultimi 5 depositi",
    Redeem_your_Reward_Points_point16:
      "Rimborso delle perdite su una qualsiasi operazione negli ultimi 20 giorni di contrattazione",
    Redeem_your_Reward_Points_point17:
      "Rimborso dello spread su 10 operazioni in perdita (negli ultimi 20 giorni)",
    AdditionalCommAmount: "Comm. aggiuntivo Importo",
    AdvanceDepositAmount: "Importo del deposito anticipato",
    Reimbursement_amount: "Importo del rimborso",
    readTermsAndConditionHere:
      "Legga i termini e le condizioni del nostro Programma Fedeltà",
    BonusAmount: "Importo del bonus",
    here: "qui",
    two: "2",
    six: "6",
    seven: "7",
    ten: "10",
    sixHundred: "600",
    sevenHundred: "700",
    oneThousand: "1000",
    twoThousand: "2000",
    twoThousandFivehundred: "2500",
    threeThousand: "3000",
    tenThousand: "10,000",
    twentyFiveThousand: "25,000",
    rps: "RP",
    rpsPerReferral: "RP per referral",
    oneusd: "1USD",
  },
  legalDocument: {
    title: "Documenti legali",
    heading:
      "Siamo orgogliosi di abbracciare l'onestà e la trasparenza in AAAFx.",
    recommend:
      "Le consigliamo di leggere e comprendere tutte le informazioni necessarie.",
    tradingJourney:
      "documenti legali prima di iniziare il suo percorso di trading con noi.",
    policy: "Informativa sulla privacy",
    view: "vista",
    risk: "Esclusione del rischio",
    clientAgreement: "Contratto cliente retail",
    refundPolicy: "Politica di rimborso",
    executionPolicy: "Politica di esecuzione",
    conflicts: "Politica sui conflitti di interesse",
    clientAsset: "Attività del cliente",
    investor: "Schema di compensazione degli investitori",
    h3Tag: "DOCUMENTO INFORMATIVO CHIAVE - CFDS SULLE MATERIE PRIME",
    h3Tag1: "DOCUMENTO INFORMATIVO CHIAVE-CFDS SUGLI INDICI",
    h3Tag2: "DOCUMENTO INFORMATIVO CHIAVE-CFD SULLE AZIONI",
    h3Tag3: "DOCUMENTO INFORMATIVO CHIAVE-CFDS SULLE COPPIE DI VALUTE",
    h3Tag4: "Nozioni di base della direttiva MiFID",
  },
  pamm: {
    whiteLabel: "whiteLabel",
    title: "PAMM/MAM",
    programm: "Programma PAMM/MAM",
    heading:
      "Un'opportunità perfetta per guadagnare grazie agli anni di esperienza come trader di successo.",
    pTag: "Su AAAFx, offriamo ai trader un'opportunità d'oro per mostrare la loro esperienza di trading al mondo e guadagnare grazie ai loro follower. Questo dà anche la possibilità agli investitori di scegliere i migliori trader che operano per loro.",
    account: "Che cos'è un conto PAMM o MAM?",
    pTag1:
      "Si tratta di una forma di fondo comune di investimento in cui un Gestore Delegato opera sui fondi raccolti dai suoi clienti investitori. Con un unico accesso al conto, il Gestore Delegato può effettuare operazioni su tutti i conti dei suoi investitori. Nel conto PAMM, i trade vengono assegnati automaticamente e istantaneamente in base al rapporto del patrimonio netto relativo dei conti degli investitori.  Nel conto MAM, l'investitore può scegliere il livello di rischio che desidera assumere e decidere l'allocazione dei trade di conseguenza.",
    combined: "Perché abbiamo combinato il nostro PAMM e MAM con ZuluTrade?",
    pTag2:
      "ZuluTrade è sinonimo di trasparenza. Quando mette in comune dei fondi come investitore e lascia che un trader operi con il suo denaro, deve avere una chiara visibilità della performance storica del trader, la piena flessibilità di scegliere l'allocazione degli scambi, l'opportunità di interagire con vari trader esperti, porre loro domande, imparare da loro, ecc. Noi le offriamo tutto questo in collaborazione con ZuluTrade.",
    advantage: "Vantaggio per il Manager PAMM/MAM",
    pTag3:
      "Il Gestore PAMM/MAM può sfruttare la sua esperienza per ottenere un reddito maggiore attraverso la commissione di performance addebitata ai suoi clienti. I nostri Gestori PAMM/MAM hanno anche la piena flessibilità di stabilire i propri termini e condizioni relativi all'attività di trading e all'allocazione dei profitti.  Inoltre, i Gestori PAMM/MAM ricevono anche pagamenti IB molto interessanti, oltre alle commissioni di performance dai loro clienti.",
    investor: "Vantaggi per gli investitori",
    pTag4:
      "Gli investitori possono scegliere tra una vasta gamma di trader elencati su ZuluTrade con AAAFx. Possono esaminare la loro performance di trading, interagire con loro prima di partecipare al programma PAMM o MAM. In seguito, possono semplicemente rilassarsi e lasciare che il Manager faccia il suo lavoro. Dopo aver finanziato il loro conto e aver accettato un documento di Termini e Condizioni completamente trasparente, possono monitorare costantemente la performance del loro conto. Inoltre, hanno il pieno controllo del loro conto in termini di depositi, prelievi e disconnessione dal conto del Manager.",
    aaafxProgramm: "Perché il programma AAAFx PAMM/MAM?",
    ul: {
      liTag: "Collaborazione con la più grande rete di social trading",
      liTag1: "Gestori di conti dedicati per investitori e manager",
      liTag2:
        "Flessibilità nell'impostare i parametri e le condizioni del conto.",
      liTag3: "Commissioni bassissime con spread grezzi",
      liTag4: "Sicurezza dei fondi con un broker di fiducia",
      liTag5: "È necessario un piccolo investimento per iniziare",
      liTag6: "Trasparenza per gli investitori: nessuna clausola nascosta.",
      liTag7: "Pieno controllo degli investitori sui depositi e sui prelievi.",
    },
    minimum: "Requisiti minimi",
    liTag: "Il deposito totale deve essere di almeno 5.000 dollari.",
    subAccount: "Sono richiesti almeno 4 conti secondari.",
    eachInvestor:
      "Ogni investitore deve firmare il POA e accettare tutte le condizioni.",
    howTo: "Come iscriversi?",
    portal: "Si iscriva al portale AAAFx.",
    build: "Costruisca un team di investitori.",
    sign: "Faccia firmare il POA agli investitori.",
    earning: "Inizia a guadagnare profitti.",
    titleHeading:
      "Il nostro programma PAMM/MAM è altamente personalizzabile, quindi si metta in contatto con noi per saperne di più e per avviare un progetto.",
    contact: "Conto PAMM/MAM. Ci contatti subito qui o ci scriva all'indirizzo",
    email: "support{'@'}aaafx.com.",
  },
  indicesPages: {
    innerPages: {
      forex: "Forex",
      indices: "Indici",
      commodities: "Materie prime",
      stocks: "Azioni",
      crypto: "Criptovalute",
    },
    title: "INDICI",
    heading: "Aumenta il suo conto di trading con il trading di indici globali",
    min: "min .",
    commission: "Commissione",
    execution: "esecuzione",
    spread: "Spread",
    upTo: "Fino a",
    leverage: "Leva",
    contract: "Indici Contratto",
    specification: "Specifiche",
    pTag: "Le condizioni di trading più flessibili (aumento della leva finanziaria) possono essere applicate in caso di clienti classificati come Professionisti. La preghiamo di accedere al suo conto e di verificare l'idoneità alla classificazione di Cliente Professionale, oppure di scriverci a",
    email: "support{'@'}aaafx.com",
    instument: "Strumento",
    bid: "Offerta",
    ask: "Richiesta",
    size: "Dimensioni del contratto",
    minTrade: "Min. Dimensione commerciale",
    tickValue: "Valore di spunta",
    hours: "Orari di negoziazione",
    trading: "Che cos'è il trading di CFD sugli indici?",
    pTag1:
      "Un indice rappresenta un portafoglio separato con un'amalgama di profili di varie società che aiuta a mitigare il profilo di rischio di un cliente. Il trading di indici solleva il trader dalla grande quantità di tempo da dedicare all'analisi di un singolo titolo. Gli indici possono essere di vari tipi, basati sulla geografia (come l'indice spagnolo, l'indice tedesco, l'indice di Hong Kong) e sul settore (come il NASDAQ).",
    how: "Come funziona?",
    content:
      "La volatilità degli indici azionari è determinata da fattori politici ed economici e non solo dalla performance dei singoli titoli. I trader possono ottenere profitti speculando sul movimento degli indici azionari al rialzo o al ribasso. Il trader non ha bisogno di acquistare tutte le singole azioni quotate su Dow Jones, ad esempio. Può semplicemente fare trading sul Dow Jones CFD. Il trading di indici è un ottimo modo per diversificare il suo portafoglio con un piccolo esborso di capitale. I trader generalmente si basano sulla performance economica delle regioni (per gli indici basati sui Paesi) o anche sulla performance complessiva del settore (per gli indici basati sui settori) per valutare se è opportuno andare long o short sull'indice. L'indice in sé è solo un numero, ma la bellezza di questo prodotto sta nella possibilità di investire complessivamente in tutte le singole azioni dell'indice, con un capitale molto più ridotto.",
    account: "Conto",
    type: "Tipi",
    ecn: "ECN",
    plus: "più",
    zulu: "zulu",
    deposit: "Deposito",
    compare: "Confronta i tipi di conto",
    online: "Il nostro sito online",
    tradingText: "Trading",
    platform: "Piattaforme",
    desktop: "Desktop",
    webTrade: "Web Trader",
    android: "Android",
    ios: "iOS",
    learn: "SCOPRI DI PIÙ",
    negative: "Saldo negativo",
    protection: "Protezione",
    pSubTag: "Protezione affidabile dei suoi fondi sui Conti AAAFx",
    workTowards:
      "Ci sforziamo sempre di lavorare per il miglior interesse dei nostri clienti. Durante il trading in periodi di volatilità, se il suo conto va in negativo, noi la copriamo le spalle! Azzereremo il suo saldo negativo, in modo che non subisca ulteriori perdite. Può depositare e ricominciare a fare trading. Pertanto, non può perdere più di quanto depositato.",
    live: "APRI UN CONTO LIVE",
    demo: "APRI UN CONTO DEMO",
  },
  economicCalander: {
    title: "Calendario economico",
    heading: "Eventi globali a portata di mano",
    h5Tag:
      "Lasci che il nostro Calendario Economico la informi su tutti gli eventi finanziari importanti che hanno un impatto sui mercati globali.",
    news: "Calendario economico, notizie sul Forex",
    pTag: "Si tratta di uno strumento indispensabile per l'analisi fondamentale del markert finanziario basato sulle notizie economiche. Oltre 500 indici delle maggiori economie mondiali si basano su dati in tempo reale raccolti da fonti pubbliche.",
    upNext: "Prossimamente",
    country: "Paese",
    selectAll: "Seleziona tutti",
    clear: "Libero",
    myFilter: "Il mio filtro",
    default: "Predefinito",
    text: "Impatto",
    type: {
      all: "Tutti",
      medium: "Medio",
      high: "Alto",
    },
    saveSetting: "Salva l'impostazione nel mio filtro",
    apply: "Applica il filtro",
    calander: {
      time: "Tempo",
      country: "Paese",
      event: "Evento",
      actual: "Effettivo",
      forecast: "Previsioni",
      previous: "Precedente",
    },
    head: "Titolo:",
    category: "Categoria:",
    desc: "Descrizione:",
    defi: "Definizione:",
  },
  faqs: {
    Iniziare: [
      {
        label: "Come registrarsi con AAAFx e verificare il mio account?",
        content: `<div class="content">
      <p>Per una verifica di successo, è necessario seguire il seguente processo:</p>
      <ul>
      <li>Passo 1: Fornisci i tuoi dati personali, inclusi Nome, Numero di telefono, Indirizzo email, ecc.</li>
      <li>Passo 2: Dopo aver inserito tutti i dati personali, carica un Documento d'identità con foto (patente, passaporto, ecc.).</li>
      <li>Passo 3: Per motivi di sicurezza, scatta un selfie sul momento e caricalo nella pagina richiesta.</li>
      <li>Passo 4: Nel passaggio successivo, carica una 'Prova di residenza' recente (rilasciata entro 3 mesi dalla data attuale), contenente il tuo nome completo e indirizzo (ad es. una bolletta, estratto conto bancario, ecc.).</li>
      <li>Passo 5: Compila i dettagli e fai clic su Invia.</li>
      </ul>
      </div>`,
      },
      {
        label: "Da quali paesi accettate clienti?",
        content: `<div class="content"><p>Accettiamo clienti da 27 paesi dell'Area Economica Europea (UE e Islanda, Svizzera e Liechtenstein). I clienti provenienti da Belgio e Regno Unito non sono accettati.</p></div>`,
      },
      {
        label: "AAAFx è regolamentata?",
        content: `<div class="content"><p>AAAFx è un fornitore di servizi finanziari regolamentato con licenze da HCMC (Grecia) e FSCA (Sud Africa).</p></div>`,
      },
      {
        label:
          "Posso registrarmi senza un indirizzo email/numero di telefono? Non ne ho uno.",
        content: `<div class="content"><p>Ti informiamo che è necessario fornire un indirizzo email/numero di telefono per registrarti con noi, poiché inviamo conferme tramite email registrata per verificare le richieste effettuate dall'account web. Ti invitiamo inoltre a fornire un numero di telefono, in modo da poterti contattare in caso di emergenza.</p></div>`,
      },
      {
        label: "Quali documenti sono necessari per la verifica dell'account?",
        content: `<div class="content">
      <p>Sono richiesti i seguenti documenti per aprire un account con noi:</p>
      <ul>
      <li>1. Un documento d'identità con foto valido (come passaporto, patente di guida o qualsiasi documento con foto rilasciato da un ufficio governativo).</li>
      <li>2. Una prova di residenza recente (rilasciata entro 3 mesi), contenente nome completo e indirizzo (ad es. una bolletta, estratto conto bancario, ecc.). Puoi caricare i documenti necessari sull'account web o inviarli tramite email a support{'@'}aaafx.com.</li>
      </ul>
      </div>`,
      },
      {
        label:
          "Cosa succede se devo cambiare i miei dati in seguito? Come posso aggiornare le informazioni nel mio profilo?",
        content: `<div class="content">
      <p>Puoi modificare i seguenti dettagli personali accedendo al tuo account web:</p>
      <ul>
      <li>Telefono principale</li>
      <li>Telefono cellulare</li>
      <li>Genere</li>
      <li>Stato civile</li>
      </ul>
      <p>Puoi anche modificare i dettagli bancari accedendo al tuo account web. Per aggiornare altri dettagli, contattaci tramite Live Chat o email a support{'@'}aaafx.com.</p>
      </div>`,
      },
      {
        label: "Quanti account Live posso aprire?",
        content: `<div class="content"><p>Puoi aprire tutti gli account che desideri. Non ci sono restrizioni.</p></div>`,
      },
      {
        label: "In quante valute di base posso aprire un account?",
        content: `<div class="content"><p>Puoi aprire un account con una valuta di base come USD, EUR e CHF.</p></div>`,
      },
      {
        label: "Qual è il deposito minimo per aprire un account?",
        content: `<div class="content"><p>Il deposito minimo è di soli $100.</p></div>`,
      },
      {
        label: "Offrite account islamici (senza swap)?",
        content: `<div class="content"><p>Sì, puoi selezionare il tipo di account islamico durante la creazione della richiesta.</p></div>`,
      },
      {
        label: "Consentite l'Hedging?",
        content: `<div class="content"><p>Sì, consentiamo l'Hedging.</p></div>`,
      },
      {
        label: "Consentite lo Scalping?",
        content: `<div class="content"><p>Sì, consentiamo lo Scalping.</p></div>`,
      },
    ],
    "Depositi e prelievi": [
      {
        label: "Come posso effettuare un deposito sul mio conto?",
        content: `<div class="content">
      <ul>
      <li>1. Accedi al tuo account web</li>
      <li>2. Vai alla scheda Fondi nel menu a sinistra</li>
      <li>3. Seleziona il conto di trading che desideri finanziare.</li>
      <li>4. Scegli il metodo di pagamento che preferisci utilizzare.</li>
      </ul>
      </div>`,
      },
      {
        label: "Come posso trasferire fondi tra i miei conti?",
        content: `<div class="content">
      <ul>
      <li>1. Accedi al tuo account web</li>
      <li>2. Vai alla sezione Gestione Conti nel menu a sinistra</li>
      <li>3. Clicca sul pulsante 'Trasferimento Interno'.</li>
      <li>4. Seleziona i conti e inserisci l'importo che desideri trasferire.</li>
      <li>5. Clicca su Procedi. I fondi saranno trasferiti istantaneamente.</li>
      </ul>
      <p>In caso di trasferimento interno tra conti con valute di base diverse, l'importo trasferito sarà convertito secondo i tassi di mercato prevalenti.</p>
      </div>`,
      },
      {
        label: "Come posso prelevare fondi dal mio conto?",
        content: `<div class="content">
      <ul>
      <li>1. Accedi al tuo account web.</li>
      <li>2. Vai alla scheda Fondi nel menu a sinistra.</li>
      <li>3. Clicca sulla scheda Prelievi.</li>
      <li>4. Seleziona l'ID di trading da cui desideri effettuare il prelievo.</li>
      <li>5. Inserisci l'importo da prelevare e seleziona il metodo di prelievo.</li>
      <li>6. Clicca su Crea Transazione.</li>
      </ul>
      </div>`,
      },
      {
        label: "Quanto tempo richiede l'elaborazione di un prelievo?",
        content: `<div class="content"><p>Tutti i prelievi sono soggetti a un tempo di elaborazione di 24 ore, inclusi i fine settimana.</p></div>`,
      },
      {
        label: "Ci sono commissioni per depositi/prelievi?",
        content: `<div class="content"><p>No, tutti i depositi e prelievi sono gratuiti dalla nostra parte.</p></div>`,
      },
      {
        label:
          "Quanto tempo richiede affinché il deposito si rifletta nel mio conto di trading?",
        content: `<div class="content">
      <ul>
      <li><b>Carte Bancarie: </b>I pagamenti vengono elaborati istantaneamente.</li>
      <li><b>Bonifico Bancario: </b>I bonifici bancari di solito richiedono 2-3 giorni lavorativi per raggiungerci.</li>
      <li><b>Portafogli Elettronici: </b>I pagamenti vengono elaborati istantaneamente. Di solito richiede fino a 1 giorno lavorativo per riflettersi nel conto se il deposito viene effettuato tramite Skrill.</li>
      <li><b>Criptovalute: </b>I depositi in criptovalute vengono accreditati entro 30-40 minuti.</li>
      </ul>
      </div>`,
      },
      {
        label: "Rimborsate le commissioni di transazione?",
        content: `<div class="content"><p>AAAFx rimborsa le spese di transazione che si sostengono durante un deposito, fino a un massimo del 5% del valore della transazione.</p></div>`,
      },
      {
        label:
          "Cosa succede se non effettuo transazioni nella valuta base del mio conto?",
        content: `<div class="content"><p>Quando effettui un deposito in qualsiasi valuta, i fondi appariranno nella valuta base del tuo conto. La conversione sarà effettuata secondo il miglior tasso di mercato disponibile in quel momento.</p></div>`,
      },
      {
        label: "Quando posso annullare una richiesta di deposito?",
        content: `<div class="content"><p>Puoi annullare qualsiasi richiesta di deposito inviando un'e-mail a support{'@'}aaafx.com oppure contattando il nostro supporto chat dal vivo e noi annulleremo la richiesta per te.</p></div>`,
      },
      {
        label:
          "Posso trasferire fondi al mio conto di trading da un altro broker?",
        content: `<div class="content"><p>AAAFx non supporta trasferimenti di fondi tra broker. Devono essere seguiti i metodi stabiliti per iniziare un trasferimento di fondi.</p></div>`,
      },
      {
        label: "Ci sono limiti minimi o massimi di prelievo?",
        content: `<div class="content"><p>L'importo minimo di prelievo è di 10 $. Non esiste un limite massimo per i prelievi.</p></div>`,
      },
      {
        label:
          "Qual è la procedura per prelevare tramite metodi di finanziamento disponibili?",
        content: `<div class="content">
      <ul>
      <li>Accedi al tuo account web</li>
      <li>Vai alla scheda Fondi nel menu a sinistra</li>
      <li>Clicca sulla scheda Prelievi.</li>
      <li>Seleziona l'ID di trading da cui desideri effettuare il prelievo.</li>
      <li>Inserisci l'importo da prelevare e seleziona il metodo di prelievo.</li>
      <li>Clicca su Crea Transazione.</li>
      </ul>
      </div>`,
      },
      {
        label: "Qual è il tempo standard di elaborazione dei prelievi?",
        content: `<div class="content"><p>I prelievi vengono generalmente confermati in 1 giorno lavorativo. Il tempo necessario per rendere disponibili i fondi sul tuo conto dipende da fattori come il paese di destinazione o l'uso di banche intermediarie.</p></div>`,
      },
      {
        label:
          "Posso richiedere un prelievo con un metodo diverso da quello usato per il deposito?",
        content: `<div class="content"><p>Se il metodo di deposito non è valido, puoi contattarci tramite la chat dal vivo o inviarci un'e-mail a <a href="mailto:contactus{'@'}aaafx.com">contactus{'@'}aaafx.com</a>.</p></div>`,
      },
      {
        label: "Elaborate i prelievi durante il fine settimana?",
        content: `<div class="content"><p>Sì, elaboriamo i prelievi anche durante il fine settimana.</p></div>`,
      },
      {
        label:
          "Ci sono circostanze in cui un prelievo potrebbe essere rifiutato?",
        content: `<div class="content">
      <p>Sì, un prelievo potrebbe essere rifiutato per:</p>
      <ul>
      <li>Margine libero insufficiente</li>
      <li>Dettagli di prelievo errati</li>
      </ul>
      <p>Altre ragioni saranno specificate in caso di rifiuto.</p>
      </div>`,
      },
      {
        label: "Posso prelevare fondi con operazioni aperte?",
        content: `<div class="content"><p>Puoi prelevare solo fino al margine libero disponibile nel tuo conto di trading. Si consiglia di richiedere un prelievo quando non ci sono operazioni aperte.</p></div>`,
      },
      {
        label: "Accettate pagamenti da terze parti?",
        content: `<div class="content"><p>No, non accettiamo pagamenti da terze parti.</p></div>`,
      },
    ],
    "Commercio di valute": [
      {
        label: "Come calcolare il profitto/la perdita?",
        content: `<div class="content">
      <p>Quando il valore di una valuta acquistata aumenta, viene generato un profitto; puoi assicurarti il profitto vendendo la valuta e chiudendo così la posizione. Vediamo un esempio in cui acquistiamo dollari statunitensi e vendiamo franchi svizzeri.</p>
      <p>Il tasso che ti viene quotato è 1.2755 / 1.2760. Poiché stai acquistando dollari statunitensi, lavorerai con il prezzo di "ask" di 1.2760, ovvero il tasso al quale i trader sono disposti a vendere.</p>
      <p>Supponiamo di acquistare 1 lotto standard (100.000 unità) a 1.2760.</p>
      <p>Poche ore dopo, il prezzo si muove a 1.2780 e decidi di chiudere la tua operazione.</p>
      <p>La nuova quotazione per USD/CHF è 1.2780 / 1.2785. Poiché stai chiudendo l'operazione e inizialmente hai acquistato per entrare nella transazione, ora vendi per chiuderla, quindi devi prendere il prezzo "bid" di 1.2780, ovvero il tasso al quale i trader sono disposti ad acquistare.</p>
      <p>La differenza tra 1.2760 e 1.2780 è di .0020 o 20 pips.</p>
      <p>Utilizzando la formula per il calcolo dei pips, otteniamo: (.0001/1.2780) x 100.000 = $7,82 per pip x 20 pips = $156,49. Tieni presente che quando entri o esci da un'operazione sei soggetto allo spread della quotazione bid/ask.</p>
      <p>Quando acquisti una valuta, utilizzi il prezzo di "ask", mentre quando vendi utilizzi il prezzo di "bid".</p>
      </div>`,
      },
      {
        label: "Quali sono gli orari di trading di AAAFx?",
        content: `<div class="content">
      <p>Il mercato apre la domenica alle 22:00 UTC e chiude il venerdì alle 22:00 UTC. Quando l'orario estivo è in vigore nell'ora orientale, il mercato apre la domenica alle 21:00 UTC e chiude il venerdì alle 21:00 UTC.</p>
      </div>`,
      },
      {
        label:
          "Perché vedo i prezzi aggiornarsi prima che il mercato apra ma non riesco a fare trading?",
        content: `<div class="content">
      <p>Anche se il mercato apre ufficialmente domenica alle 00:00, potresti notare lievi fluttuazioni dei tassi prima dell'apertura. La Trading Desk avvia i servizi pertinenti e inizia ad aggiornare i tassi per averli pronti quando il trading riprende. Tuttavia, i tassi visualizzati prima dell'apertura ufficiale del mercato non possono essere applicati a nuovi ordini di mercato e il trading non può essere eseguito.</p>
      </div>`,
      },
      {
        label:
          "Come verrà influenzata la mia posizione aperta se i prezzi cambiano dalla chiusura di venerdì all'apertura di domenica?",
        content: `<div class="content">
      <p>In condizioni normali di mercato, all'apertura vedrai che il prezzo iniziale di una coppia di valute è molto vicino al prezzo registrato il venerdì prima della chiusura del mercato. Tuttavia, ci sono momenti in cui i tassi possono muoversi significativamente verso l'alto o verso il basso, e questo può avere un effetto positivo o negativo sulla tua attività di trading.</p>
      <p>Se vengono annunciati eventi economici importanti durante il fine settimana, c'è una maggiore probabilità di "gap" nel mercato.</p>
      <p>Tieni presente che poiché il prezzo può muoversi contro la tua posizione attuale, potresti ritrovarti con una perdita anche se eri in profitto alla chiusura del mercato. Per questo motivo, i trader che mantengono posizioni aperte durante il fine settimana devono essere consapevoli di tali rischi.</p>
      </div>`,
      },
      {
        label:
          "Perché ho ricevuto una notifica di Margin Call quando il mercato era chiuso?",
        content: `<div class="content">
      <p>Il processo di apertura del mercato non è istantaneo - è un processo graduale che inizia prima delle 22:00 UTC.</p>
      <p>Di conseguenza, iniziamo a ricevere tassi prima delle 22:00, quindi generiamo notifiche di Margin Call o Stop Out in anticipo - tuttavia, le operazioni non vengono chiuse prima dell'apertura ufficiale del mercato alle 22:00.</p>
      <p>Se dopo l'apertura del mercato i tassi disponibili mettono ancora il tuo conto al di sotto del livello di Stop Out, il meccanismo di Stop Out viene attivato e la chiusura delle operazioni viene avviata.</p>
      </div>`,
      },
      {
        label:
          "Come posso proteggere le mie operazioni durante il fine settimana?",
        content: `<div class="content">
      <p>Se ritieni che durante il fine settimana il mercato possa essere volatile e i tassi si muoveranno contro le tue posizioni aperte, dovresti anticipare il rischio. Se questa strategia di trading non è adatta a te, si consiglia di uscire da un'operazione realizzando l'attuale profitto o perdita prima della chiusura del mercato.</p>
      </div>`,
      },
    ],
    "Metalli e CFD": [
      {
        label:
          "Qual è la dimensione minima del lotto per il trading di Metalli e CFD?",
        content: `<div class="content"><p>La dimensione minima del lotto per Metalli e CFD è 1 lotto standard.</p></div>`,
      },
      {
        label:
          "A cosa equivale un lotto standard di oro (XAU/USD) e argento (XAG/USD)?",
        content: `<div class="content"><p>1 lotto standard di oro (XAU/USD) equivale a 1 oncia (oz) d'oro, mentre 1 lotto standard di argento (XAG/USD) equivale a 50 once (oz) d'argento.</p></div>`,
      },
      {
        label:
          "Qual è il margine richiesto per fare trading sui CFD e quali sono i loro orari di trading?",
        content: `<div class="content"><p>Trova tutte le informazioni sui CFD nella sezione Glossario, alla voce CFD.</p></div>`,
      },
      {
        label:
          "Qual è il margine richiesto per fare trading su oro (XAU/USD) e argento (XAG/USD) sul mio conto AAAFx?",
        content: `<div class="content"><p>Il margine richiesto per l'oro (XAU/USD) è il 100% del prezzo corrente e il valore pip è $0,01. Il margine richiesto per l'argento (XAG/USD) è il 200% del prezzo corrente moltiplicato per la dimensione del contratto (moltiplicatore) e il valore pip è $0,5.</p></div>`,
      },
      {
        label:
          "Perché la mia operazione USOilSpot/UKOil/NGAS/BUND/Rame è stata chiusa? Lo stop o il limite non è stato raggiunto e non ho ricevuto nemmeno una Margin Call.",
        content: `<div class="content"><p>I titoli di Stato tedeschi hanno date di scadenza, come indicato nella tabella della sezione Merci e Azioni, Date di scadenza. Di conseguenza, i clienti realizzeranno eventuali profitti/perdite flottanti al momento della chiusura della posizione a causa della scadenza.</p></div>`,
      },
      {
        label:
          "Qual è il margine richiesto per fare trading su BTC/USD sul mio conto AAAFx?",
        content: `<div class="content"><p>Il margine richiesto per Bitcoin (BTC/USD) è il 1000% del prezzo corrente e il valore pip è $0,01.</p></div>`,
      },
      {
        label: "Qual è la commissione applicata per un contratto BTC/USD?",
        content: `<div class="content"><p>La commissione è 0,0008*Prezzo per un conto ECN Plus.</p></div>`,
      },
      {
        label:
          "Qual è la dimensione minima del contratto per fare trading su BTC/USD?",
        content: `<div class="content"><p>La dimensione minima è di 1 contratto = 1 BTC. Ciò significa che con un tasso BTC/USD di circa $35.000, hai bisogno di un margine di $7.000 per aprire (Acquistare o Vendere) l'ordine minimo.</p></div>`,
      },
      {
        label:
          "Qual è il margine richiesto per fare trading su ETH/USD sul mio conto AAAFx?",
        content: `<div class="content"><p>Il margine richiesto per Ethereum (ETH/USD) è il 1000% del prezzo corrente.</p></div>`,
      },
      {
        label: "Qual è il numero massimo di contratti ETH/USD per cliente?",
        content: `<div class="content"><p>C'è un limite di 1000 contratti di esposizione totale (ad esempio, 500 acquisti e 500 vendite = 1K) per cliente.</p></div>`,
      },
      {
        label:
          "Qual è il margine richiesto per fare trading su LTC/USD sul mio conto AAAFx?",
        content: `<div class="content"><p>Il margine richiesto per Litecoin (LTC/USD) è il 1000% del prezzo corrente.</p></div>`,
      },
      {
        label: "Qual è il numero massimo di contratti LTC/USD per cliente?",
        content: `<div class="content"><p>C'è un limite di 1000 contratti di esposizione totale (ad esempio, 500 acquisti e 500 vendite = 1K) per cliente.</p></div>`,
      },
    ],
    "Informazioni di Base sul Forex": [
      {
        label: "Cos'è il Forex?",
        content: `<div class="content"><p>Il mercato dei cambi (Forex, FX o mercato valutario) è una forma di scambio per il trading decentralizzato globale delle valute. I centri finanziari di tutto il mondo fungono da ancore di trading tra una vasta gamma di tipi di acquirenti e venditori 24 ore su 24, ad eccezione dei fine settimana. Il mercato dei cambi determina i valori relativi delle diverse valute.</p></div>`,
      },
      {
        label: "Quanto è grande il mercato dei cambi?",
        content: `<div class="content"><p>Il mercato dei cambi è il mercato finanziario più liquido al mondo. Il volume medio giornaliero nei mercati globali dei cambi e correlati è in costante crescita. Secondo il sondaggio triennale del 2010 coordinato dalla Banca dei Regolamenti Internazionali, il volume medio giornaliero è stato di 3,98 trilioni di dollari ad aprile 2010 (rispetto ai 1,7 trilioni del 1998). Di questi 3,98 trilioni, 1,5 trilioni erano transazioni a pronti e 2,5 trilioni erano scambi a termine, swap e altri derivati.</p></div>`,
      },
      {
        label: "Dove si trova il mercato dei cambi?",
        content: `<div class="content"><p>Non esiste un mercato unificato o regolato centralmente per la maggior parte delle transazioni e c'è poca regolamentazione transfrontaliera. A causa della natura over-the-counter (OTC) dei mercati valutari, esistono numerosi mercati interconnessi dove vengono scambiati diversi strumenti valutari. Ciò implica che non esiste un singolo tasso di cambio ma piuttosto diversi tassi (prezzi), a seconda della banca o del market maker che effettua lo scambio e della sua posizione.</p></div>`,
      },
      {
        label: "Chi partecipa al mercato?",
        content: `<div class="content"><p>A differenza di un mercato azionario, il mercato dei cambi è suddiviso in livelli di accesso. Al vertice c'è il mercato interbancario, costituito dalle più grandi banche commerciali e dai dealer di titoli. Il mercato interbancario di primo livello rappresenta il 53% di tutte le transazioni. Da lì, le transazioni avvengono tra banche più piccole, grandi multinazionali, grandi hedge fund e persino alcuni market maker al dettaglio. Tuttavia, la quota di altri partecipanti al mercato come broker, multinazionali, dealer registrati e investitori privati sta aumentando rapidamente.</p></div>`,
      },
      {
        label: "Cos'è un Lotto?",
        content: `<div class="content"><p>Un Lotto è l'unità standard di una transazione. Tipicamente, per le valute Forex, un lotto standard equivale a 100.000 unità della valuta base, 10.000 unità se si tratta di un mini lotto o 1.000 unità se si tratta di un micro lotto.</p></div>`,
      },
      {
        label: "Cos'è lo Spread?",
        content: `<div class="content"><p>La differenza tra i prezzi Bid e Ask è chiamata Spread. AAAFx offre tra gli spread più bassi sul mercato Forex.</p></div>`,
      },
      {
        label: "Cos'è un Pip/Tick?",
        content: `<div class="content"><p>Un Pip (o Tick) è il più piccolo incremento di prezzo che una valuta può effettuare. È la quarta cifra in tutte le coppie di valute, con l'eccezione dello Yen giapponese (JPY), dove è la seconda cifra (Ad esempio, 1 pip = 0.0001 per EUR/USD, o 0.01 per USD/JPY).</p></div>`,
      },
      {
        label: "Come si calcola il valore di 1 Pip?",
        content: `<div class="content">
      <p>Per esprimere il valore nella valuta del termine, moltiplica 1 pip per la dimensione del lotto.</p>
      <ul>
      <li>EUR/USD pip = 0.0001 X 100.000 = $10.00 (per lotti standard)</li>
      <li>EUR/USD pip = 0.0001 X 10.000 = $1.00 (per mini lotti)</li>
      <li>EUR/USD pip = 0.0001 X 1.000 = $0.1 (per micro lotti)</li>
      <li>GBP/JPY pip = 0.01 X 100.000 = 1000 ¥ (per lotti standard)</li>
      </ul>
      <p>Per convertirlo nella valuta base, dividere per il tasso di cambio:</p>
      <p>Supponiamo che il tasso di cambio EUR/USD = 1.4750, quindi $10.00 / 1.4750 = 6.78€</p>
      <p>Per convertirlo nella valuta del conto (ad es. USD), dividere per il tasso di cambio $/¥:</p>
      <p>Se $/¥ = 82.12 allora 1000 ¥ / 82.12 = 12.17$</p>
      </div>`,
      },
      {
        label: "Quali sono le sette coppie di valute principali?",
        content: `<div class="content">
      <p>Le sette coppie più liquide sono scambiate contro il dollaro USA, con le prime quattro che sono le coppie principali, seguite da tre coppie di valute delle materie prime. Altre coppie di valute sono conosciute come cross valutari, i più popolari includono l'Euro: EUR/JPY, EUR/GBP ed EUR/CHF.</p>
      <p>Le principali coppie di valute sono:</p>
      <ul>
      <li>EUR/USD</li>
      <li>USD/JPY</li>
      <li>USD/CHF</li>
      <li>GBP/USD</li>
      </ul>
      <p>Le coppie di valute delle materie prime sono:</p>
      <ul>
      <li>AUD/USD</li>
      <li>NZD/USD</li>
      <li>USD/CAD</li>
      </ul>
      </div>`,
      },
      {
        label: "Cos'è la Leva?",
        content: `<div class="content"><p>La leva finanziaria nel Forex indica la % di denaro che puoi prendere in prestito dal broker per aprire una posizione. La leva determina la percentuale del valore nominale di una transazione richiesta come margine. In termini semplici, puoi pensare alla leva come un prestito che ti consente di acquistare valute.</p></div>`,
      },
      {
        label: "Cos'è il Margine?",
        content: `<div class="content"><p>Tipicamente, affinché la leva finanziaria sia rilasciata, i broker richiedono che una certa quantità dei fondi reali del conto sia allocata per ogni posizione (lotto) scambiata. Quindi, il margine è l'importo minimo richiesto per aprire una posizione. Il margine dipende dalla leva del conto; ad esempio, se la leva è 1:30 puoi negoziare una posizione trenta volte superiore all'importo depositato. In termini semplici, puoi pensare al Margine come a un deposito di buona fede richiesto per ottenere il prestito (leva).</p><p>Ad esempio, con una leva di 100:1 (o 1% richiesto per la posizione), puoi negoziare una posizione del valore di $100.000 con un capitale di appena $5.000. Questo è possibile poiché il broker metterebbe da parte $1.000 come deposito o "margine" e ti consentirebbe di "prendere in prestito" il resto. Naturalmente, eventuali perdite o guadagni saranno detratti o aggiunti al saldo del conto.</p></div>`,
      },
    ],
    "CFD azionari": [
      {
        label: "Come funzionano i CFD su azioni?",
        content: `<div class="content"><p>Azioni (o azioni) di società quotate possono essere oggetto di speculazione. I broker online possono offrire contratti per differenza (CFD) con azioni di diverse società come attività sottostanti per questi CFD. Quando viene acquistato un CFD azionario, l'acquirente non possiede effettivamente il titolo stesso ma semplicemente specula sul movimento del prezzo di quel titolo tramite il CFD.</p><p>Con i CFD azionari (contratti per differenza), gli investitori possono andare long (acquistare) o andare short (vendere). Con una posizione lunga, un aumento del prezzo produrrà un profitto. D'altro canto, una posizione corta genera profitti quando il prezzo di un titolo scende.</p></div>`,
      },
      {
        label: "Cosa muove il mercato azionario?",
        content: `<div class="content"><p>Il prezzo di un titolo è influenzato dalla domanda e dall'offerta. Le forze che influenzano la domanda e l'offerta sono molteplici: fattori fondamentali (ad esempio utili per azione), sentiment degli investitori, fattori tecnici, notizie su una particolare azienda o sul suo settore industriale, ecc.</p></div>`,
      },
      {
        label:
          "Qual è la differenza tra il trading di azioni e quello dei CFD azionari?",
        content: `<div class="content"><p>Il trading con CFD imita il trading azionario, con l'eccezione che in un contratto per differenza, in realtà non possiedi l'asset sottostante, a differenza delle azioni della società, dove lo possiedi. Ciò che stai essenzialmente facendo con il trading di CFD è acquistare un contratto tra te e il fornitore di CFD che, a seconda della tua posizione ("Vendita allo scoperto" o "Acquisto"), avrà un prezzo di entrata quando entri in un'operazione e un prezzo di uscita prezzo quando chiudi la tua operazione con una posizione uguale opposta.</p><p>Una delle differenze principali tra il trading di un CFD long e l'acquisto di un titolo è che puoi usufruire di funzionalità di leva finanziaria più ampie. I contratti per differenza sono negoziati a margine, il che significa che non è necessario vincolare l'intero valore di mercato dell'acquisto della posizione azionaria equivalente.</p><p>Esistono inoltre una serie di differenze chiave tra la negoziazione di un asset sottostante e un CFD :</p>
        <ul>
        <li>Le azioni CFD possono essere negoziate a lungo o a breve e non è necessario consegnare l'asset sottostante in caso di vendita allo scoperto.</li>
        <li>L'investitore non possiede l'asset sottostante su cui si basa il CFD, ma stipula invece un accordo contrattuale con il broker CFD, per scambiare la differenza in contanti nel prezzo tra il prezzo di apertura e quello di chiusura del contratto.</ li>
        <li>A differenza di detenere l'asset sottostante, un CFD è negoziato a margine, il che significa che un deposito iniziale viene depositato presso il broker CFD, che consente all'investitore di acquistare o vendere un numero di CFD in base al calcolo del margine, che generalmente consente leva aggiuntiva sull'acquisto di azioni stesso.</li>
        </ul>
        </div>`,
      },
      {
        label: "Qual è la leva finanziaria per i CFD su azioni?",
        content: `<div class="content"><p>Come tutti gli altri CFD su azioni, il livello di leva è pari a 1:5. Esempio: supponiamo che tu acquisti 5 CFD su Facebook a $ 400. Il requisito di margine è del 20%, ti verrà richiesto di spendere solo $ 100, lasciandoti così più denaro da utilizzare su altre operazioni.</p></div>`,
      },
      {
        label: "Quali CFD azionari offrite?",
        content: `<div class="content"><p>Al momento offriamo le azioni più popolari di DOW30 e Nasdaq, consulta l'elenco completo <a href="/stocks">qui</a></p </div>`,
      },
      {
        label:
          "Quali sono gli orari di trading per il trading di CFD su azioni?",
        content: `<div class="content"><p>A differenza del mercato Forex che può essere scambiato 24 ore al giorno, 5 giorni alla settimana, i mercati azionari globali sono aperti solo per circa 6,5-8,5 ore al giorno, 5 giorni alla settimana, a seconda dello scambio. Anche gli orari in cui le azioni dei diversi paesi possono essere scambiate differiscono a seconda del fuso orario di ciascun paese.</p><p>Supportiamo i CFD del mercato azionario statunitense, quindi seguiamo gli orari regolari della sessione di trading del mercato azionario statunitense ( NASDAQ, Dow 30): giorni lavorativi dalle 09:30 alle 16:00 (Eastern Standard Time - EST).</p><p>Per i CFD su azioni greche gli orari di trading sono: Giorni lavorativi 08:35-15"00 (UTC)</p></div>`,
      },
      {
        label: "Cosa sono le azioni aziendali?",
        content: `<div class="content"><p>Le azioni societarie sono eventi avviati da una società quotata in borsa che di solito hanno un impatto diretto o indiretto sugli azionisti e determinano modifiche sostanziali alle sue azioni influenzando il prezzo, il numero di azioni negoziate o altre caratteristiche (ad esempio il nome del titolo). Le azioni societarie comprendono frazionamenti azionari, dividendi, fusioni, acquisizioni, emissioni di diritti e scissioni. Tutte queste sono decisioni importanti che in genere devono essere approvate dal consiglio di amministrazione della società e autorizzate dai suoi azionisti. Le azioni societarie sono informazioni pubbliche e le date delle azioni pertinenti sono disponibili in varie fonti online.</p></div>`,
      },
      {
        label: "Cos'è un dividendo azionario?",
        content: `<div class="content"><p>Un dividendo è una ricompensa che le aziende trasferiscono ai propri azionisti e proviene dall'utile netto della società. Tali premi possono essere sotto forma di contanti, equivalenti di denaro, azioni, ecc. e sono per lo più pagati dalla quota rimanente dell'utile una volta soddisfatte le spese essenziali. In termini più semplici, un dividendo è la quota spettante a un investitore dei profitti di una società.</p></div>`,
      },
      {
        label: "Chi ha diritto a ricevere i dividendi?",
        content: `<div class="content"><p>Quando acquisti alcuni CFD su azioni di una società, di solito beneficerai di eventuali dividendi pagati dalla società su tali azioni. Gli investitori in CFD azionari hanno diritto ai dividendi se detengono le azioni fino alla data ex dividendo. A seconda del tipo di posizione (lunga/corta), riceveranno o verranno addebitati i dividendi alla data di pagamento.</p><p>Se acquisti un CFD azionario alla data ex dividendo o successivamente, lo farai non ricevere il prossimo pagamento dei dividendi. Invece, il venditore in questa situazione riceverà il dividendo.</p><p>Tieni presente che i dividendi azionari verranno pagati sul tuo conto detratte eventuali ritenute fiscali applicabili. Il pagamento verrà effettivamente visualizzato sul tuo conto come denaro aggiuntivo.</p></div>`,
      },
      {
        label: "Cos'è un frazionamento azionario?",
        content: `<div class="content"><p>Quando una società dichiara un frazionamento azionario, il numero di azioni di quella società aumenta, ma la capitalizzazione di mercato rimane la stessa. Questa azione, che richiede l'approvazione preventiva da parte del consiglio di amministrazione della società degli amministratori, di solito comporta l'emissione di azioni aggiuntive per gli azionisti esistenti.</p><p>Ad esempio, la società ABC ha 4 milioni di azioni in circolazione e ciascuna azione ha un valore di $ 10. In questo caso, il valore di mercato totale della società è pari a 40 milioni di dollari (4 milioni x 10 dollari per azione). Dopo un frazionamento azionario due per uno, il numero di azioni dell’azienda raddoppierà fino a 8 milioni, mentre il valore di tali azioni sarà dimezzato a 5 dollari. Tuttavia, la capitalizzazione di mercato totale della società rimarrà la stessa, attestandosi a soli 40 milioni di dollari (8 milioni* 5 dollari per azione).</p></div>`,
      },
      {
        label: "Ricevo dividendi dai CFD azionari?",
        content: `<div class="content"><p>Gli investitori in CFD azionari, a seconda del tipo di posizione (lunga/corta), hanno diritto a essere accreditati o addebitati il pagamento del dividendo se detengono una posizione azionaria dopo la fine dell'ex -data del dividendo. I dividendi sono informazioni pubbliche e le date possono essere facilmente trovate online.</p></div>`,
      },
      {
        label: "Qual è la dimensione minima della transazione?",
        content: `<div class="content"><p>La dimensione minima della transazione è 1 lotto per ordine che rappresenta un titolo.</p></div>`,
      },
      {
        label:
          "Qual è il programma festivo ufficiale per le azioni statunitensi?",
        content: `<div class="content"><p>Di seguito sono riportati i giorni in cui il mercato statunitense è ufficialmente chiuso:</p>
        <ul>
        <li>Venerdì Santo</li>
        <li>Giornata della Memoria</li>
        <li>Giorno dell'Indipendenza</li>
        <li>Festa dei lavoratori</li>
        <li>Giorno del Ringraziamento</li>
        <li>Venerdì di Natale</li>
        </ul>
        </div>`,
      },
    ],
    "Piattaforme di Trading - Generale": [
      {
        label: "Quali prezzi mostra il grafico sulla piattaforma di trading?",
        content: `<div class="content"><p>I grafici sulla piattaforma di trading mostrano i prezzi bid per impostazione predefinita. Tuttavia, puoi visualizzare la linea ask seguendo i passaggi riportati di seguito:</p>
      <ul>
      <li>Fai clic destro in un punto qualsiasi del grafico e seleziona Proprietà.</li>
      <li>Clicca sulla scheda Comune e seleziona la casella Mostra linea Ask.</li>
      <li>Clicca sul pulsante OK e vedrai apparire la linea Ask nel grafico MT4.</li>
      </ul>
      </div>`,
      },
      {
        label:
          "Come posso generare l'estratto conto del mio account di trading sulla piattaforma?",
        content: `<div class="content"><p>Segui i passaggi riportati di seguito per scaricare l'estratto conto dell'account di trading dalla piattaforma:</p>
      <ul>
      <li>Accedi al tuo account di trading.</li>
      <li>Clicca sulla scheda "Cronologia account".</li>
      <li>Fai clic destro sull'area vuota e seleziona "tutta la cronologia".</li>
      <li>Una volta visualizzata la cronologia completa, fai clic destro e seleziona "Salva come report dettagliato".</li>
      </ul>
      <p>L'estratto conto verrà scaricato sul tuo dispositivo.</p>
      </div>`,
      },
      {
        label:
          "Come posso nascondere o mostrare più coppie/strumenti nella finestra Market Watch?",
        content: `<div class="content">
      <ul>
      <li>Fai clic destro su uno strumento qualsiasi.</li>
      <li>Seleziona l'opzione “Simboli”.</li>
      </ul>
      <p>Dall'elenco di forex, indici e materie prime, scegli quali coppie desideri nascondere o mostrare. (Scorciatoia: Ctrl U)</p>
      </div>`,
      },
      {
        label: "Come posso vedere i dettagli su uno strumento di trading?",
        content: `<div class="content"><p>Per vedere i dettagli su uno strumento di trading:</p>
      <ul>
      <li>Fai clic destro sullo strumento di trading.</li>
      <li>Seleziona “Specifica”.</li>
      </ul>
      <p>Puoi controllare la finestra delle specifiche del contratto per informazioni rilevanti come spread, dimensione del contratto, punti swap, ecc.</p>
      </div>`,
      },
      {
        label:
          "Come posso attivare il trading con un solo clic sulla piattaforma?",
        content: `<div class="content"><p>Scorciatoia: Alt T o fai clic destro sul grafico e abilita Trading con un clic.</p></div>`,
      },
      {
        label: "Cos'è una Password Investitore? Perché è necessaria?",
        content: `<div class="content"><p>La password investitore è un altro nome per 'password di sola lettura'. La password investitore entra in gioco quando desideri che una terza parte visualizzi le operazioni sul tuo account senza poter svolgere altre attività. Per generare la password investitore, accedi al tuo account di trading. Clicca su Strumenti>Opzioni>Server e seleziona Cambia.</p><p>Puoi cambiare/generare la password investitore direttamente dal tuo account web seguendo i passaggi riportati di seguito:</p><ol><li>Accedi al tuo account web.</li><li>Seleziona 'Gestisci Account' dal pannello sinistro.</li><li>Clicca su 'Reimposta Password di Trading' > 'Password Investitore'.</li></ol></div>`,
      },
      {
        label: "Esiste un modo per cambiare l'orario sui miei grafici?",
        content: `<div class="content"><p>Gli orari sui grafici delle piattaforme di trading seguono l'ora del server. Su AAAFx, l'ora del server è impostata su UTC O UTC+1 a seconda dell'ora legale.</p></div>`,
      },
      {
        label:
          "Posso personalizzare i miei grafici sulla piattaforma di trading? Se sì, come?",
        content: `<div class="content"><p>Sì, i grafici possono essere personalizzati seguendo i passaggi riportati di seguito:</p>
      <ul>
      <li>Fai clic destro sulla finestra del grafico.</li>
      <li>Seleziona l'opzione 'Proprietà'.</li>
      <li>Puoi modificare i colori del grafico come preferisci.</li>
      </ul>
      <p>Per altre modifiche, seleziona la scheda 'Comune'.</p>
      </div>`,
      },
      {
        label: "Posso accedere a più account di trading contemporaneamente?",
        content: `<div class="content"><p>Sì, puoi accedere a più account di trading su una singola piattaforma di trading. Tuttavia, è possibile fare trading solo su un account alla volta.</p></div>`,
      },
    ],
    "Piattaforme di trading - MT4": [
      {
        label: "Come accedere al mio account di trading MT4?",
        content: `<div class="content"><p>Per accedere alla piattaforma MT4, segui questi passaggi:</p>
      <ul>
      <li>Fai clic sull'opzione "File" nella barra dei menu</li>
      <li>Seleziona "Accedi all'account di trading"</li>
      <li>Inserisci il tuo ID di trading e la password</li>
      <li>Seleziona il nome del server: AAAFx-FX-Demo (per account Demo) / AAAFx-FX-Real (per account Reale)</li>
      <li>Fai clic su "Accedi"</li>
      </ul>
      <p>Sarai connesso al tuo account di trading.</p>
      </div>`,
      },
      {
        label: "Come accedo a un account reale su MT4?",
        content: `<div class="content"><p>Per accedere a un account reale sulla piattaforma MT4, segui questi passaggi:</p>
      <p>Prima devi creare un account reale sul portale. Per creare un account live, segui questi passaggi:</p>
      <ul>
      <li>Registrati: Fai clic sull'opzione “Join now” nel portale</li>
      <li>Inserisci i tuoi dati: Nome, email, numero di telefono</li>
      <li>Inserisci il codice di verifica che riceverai via email</li>
      <li>Scegli la piattaforma, il tipo di account, la valuta di base dell'account, la leva e infine imposta la password dell'account.</li>
      <li>Carica i documenti richiesti</li>
      <li>Deposita i fondi</li>
      </ul>
      <p>Successivamente, segui questi passaggi per accedere alla piattaforma:</p>
      <ul>
      <li>Fai clic sull'opzione “File” nella barra dei menu</li>
      <li>Seleziona “Login to a trade account”</li>
      <li>Inserisci il tuo ID di trading e la password</li>
      <li>Seleziona Server: AAA Fx-FX-Real</li>
      <li>Fai clic su "Login"</li>
      </ul>
      </div>`,
      },
      {
        label: "Come creo/accendo un account demo?",
        content: `<div class="content"><p>Per creare un account demo, segui questi passaggi:</p>
      <ol>
      <li>Accedi al tuo account web.</li>
      <li>Vai su "Gestisci account"</li>
      <li>Fai clic su "Crea un account demo"</li>
      <li>Scegli la piattaforma di trading, la leva dell'account, la valuta di base dell'account e imposta la password.</li>
      </ol>
      <p>Ora è stato creato un account demo per te.</p>
      </div>`,
      },
      {
        label: "Dove posso visualizzare le mie commissioni?",
        content: `<div class="content"><p>Per vedere le commissioni, segui questi passaggi:</p>
      <ul>
      <li>Fai clic sull'opzione “View” nella barra dei menu</li>
      <li>Seleziona “Terminal”</li>
      <li>Seleziona “Trade Section”</li>
      <li>Fai clic con il tasto destro sul trade effettuato</li>
      <li>Abilita le commissioni</li>
      </ul>
      <p>Ora puoi vedere le commissioni come una colonna separata.</p>            
      </div>`,
      },
      {
        label:
          "Come posso cambiare la password del mio account di trading dalla piattaforma?",
        content: `<div class="content"><p>Segui questi passaggi per cambiare la password dell'account su MT4:</p>
      <ul>
      <li>Fai clic su “Tools” nella barra dei menu</li>
      <li>Seleziona “Options” > Apparirà una finestra di dialogo</li>
      <li>Inserisci la nuova password > Ok</li>
      </ul>
      <p>OPPURE</p>
      <ul>
      <li>Nella finestra di navigazione, sotto accounts > Fai clic con il tasto destro sull'account per cui vuoi cambiare la password</li>
      <li>Inserisci la password attuale e la nuova password > Fai clic su Ok</li>
      </ul>
      </div>`,
      },
      {
        label:
          "Come posso eliminare/rimuovere il mio account dalla piattaforma MT4?",
        content: `<div class="content"><p>Per rimuovere o eliminare il tuo account dalla piattaforma MT4, fai clic con il tasto destro sull'account che desideri rimuovere e seleziona l'opzione “Delete”.</p></div>`,
      },
      {
        label: "Da dove posso scaricare la piattaforma MT4?",
        content: `<div class="content"><p>Per scaricare la piattaforma, segui questi passaggi:</p>
      <ul>
      <li>Fai clic sulla scheda “Platform” nel portale</li>
      <li>Fai clic su “Download here”</li>
      </ul>
      </div>`,
      },
      {
        label: "Posso usare un EA/BOT/Trading Robot?",
        content: `<div class="content"><p>Puoi sicuramente utilizzare un Expert Advisor (EA) su MT4. Verifica quelli disponibili gratuitamente nella finestra di navigazione. Se vuoi, puoi anche acquistare EA dal sito MQL 5.</p></div>`,
      },
      {
        label: "Come posso allegare un EA su MT4?",
        content: `<div class="content"><p>Per allegare un EA, segui questi passaggi:</p>
      <ul>
      <li>Apri la finestra Navigator (Ctrl N)</li>
      <li>Expert Advisor</li>
      <li>Fai clic con il tasto destro sull'EA che vuoi usare</li>
      <li>Allega al grafico</li>
      </ul>
      <p>Se desideri applicare un EA acquistato dal sito MQL, segui questi passaggi:</p>
      <ul>
      <li>Vai sulla scheda “File” nella barra dei menu</li>
      <li>Fai clic su "Open Data Folder"</li>
      <li>Apri la cartella “Experts” nel file “MQL”.</li>
      <li>Riapri la piattaforma MT4.</li>
      </ul>
      </div>`,
      },
      {
        label: "Come imposto i grafici predefiniti con indicatori su MT4?",
        content: `<div class="content"><p>Segui questi passaggi:</p>
      <p><b>Per salvare un profilo:</b></p>
      <p>Una volta che hai posizionato tutto come desideri, vai su File - Profili e fai clic su “Salva come” Inserisci un nome per il profilo e premi “Ok”</p>
      <p>Per caricare un profilo salvato, vai su File - Profili e seleziona il profilo dalla lista che appare. Il profilo verrà caricato.</p>
      <p><b>Per salvare un template:</b></p>
      <p>Una volta che hai impostato tutto sul grafico come desideri, vai su Charts - Template - Save Template per salvare il template. Inserisci un nome per il template e premi salva.</p>
      </div>`,
      },
      {
        label: "Posso usare più account di trading su MT4?",
        content: `<div class="content"><p>Sì, puoi usare più account di trading su MT4. Per aggiungere un account, segui questi passaggi:</p>
      <ul>
      <li>Fai clic con il tasto destro su accounts nella finestra di navigazione</li>
      <li>Scegli “Login to trading account”</li>
      <li>Inserisci il tuo ID di trading e la password</li>
      </ul>
      <p>Il tuo account di trading verrà inserito su MT4.</p>
      </div>`,
      },
      {
        label:
          "Dove posso verificare lo stato di connessione del mio account di trading?",
        content: `<div class="content"><p>Lo stato di connessione può essere verificato nell'angolo in basso a destra sotto la finestra del terminale.</p><p>Per rendere visibile lo stato di connessione, fai clic su “view” > abilita barra di stato</p></div>`,
      },
    ],
    "Piattaforme di trading - MT5": [
      {
        label: "Come accedere al mio account di trading MT5?",
        content: `<div class="content"><p>Per accedere alla piattaforma MT5, segui questi passaggi:</p>
      <ul>
      <li>Clicca sull'opzione “File” nella barra del menu</li>
      <li>Seleziona “Accedi al conto di trading”</li>
      <li>Inserisci il tuo ID di trading e la password</li>
      <li>Seleziona il nome del server: AAAFx-FX-Demo (per conto Demo) / AAAFx-FX-Real (per conto Reale)</li>
      <li>Clicca su accedi</li>
      <p>Sarai connesso al tuo account di trading</p>
      </ul>
      </div>`,
      },
      {
        label: "Come posso accedere a un conto reale su MT5?",
        content: `<div class="content"><p>Per accedere a un conto reale sulla piattaforma MT5, segui questi passaggi:</p>
      <p>Per prima cosa, devi creare un account reale sul portale. Per creare un conto live segui questi passaggi:</p>
      <ul>
      <li>Registrati: Clicca su “Iscriviti ora” sul portale</li>
      <li>Inserisci i tuoi dati: Nome, email, numero di telefono</li>
      <li>Inserisci il codice di verifica che hai ricevuto nella tua email</li>
      <li>Scegli la valuta di base del conto e la piattaforma di trading</li>
      <li>Carica i documenti richiesti</li>
      <li>Deposita fondi.</li>
      </ul>
      <p>Successivamente, segui questi passaggi per accedere alla piattaforma:</p>
      <ul>
      <li>Clicca su “File” nella barra del menu</li>
      <li>Scegli “Accedi al conto di trading”</li>
      <li>Inserisci il tuo ID di trading e la password</li>
      <li>Seleziona Server: AAA Fx-FX-Real</li>
      <li>Clicca su 'Accedi'</li>
      </ul>
      </div>`,
      },
      {
        label: "Come posso creare/accedere a un conto demo?",
        content: `<div class="content"><p>Per creare un conto demo, segui questi passaggi:</p>
      <ol>
      <li>Accedi al tuo account web.</li>
      <li>Vai su "Gestisci conti"</li>
      <li>Clicca su "Crea un conto Demo"</li>
      <li>Scegli la piattaforma di trading, la leva del conto, la valuta di base del conto e imposta la password.</li>
      </ol>
      <p>Ora è stato creato un conto demo per te.</p>
      </div>`,
      },
      {
        label: "Dove posso vedere le mie commissioni?",
        content: `<div class="content"><p>Per visualizzare le commissioni, segui questi passaggi:</p>
      <ul>
      <li>Clicca su “Visualizza” nella barra del menu</li>
      <li>Scegli “Terminale”</li>
      <li>Seleziona “Sezione Trading”</li>
      <li>Clicca con il tasto destro sul trade piazzato</li>
      <li>Abilita le commissioni</li>
      </ul>
      <p>Ora puoi vedere le commissioni come una colonna separata</p>
      </div>`,
      },
      {
        label:
          "Come posso cambiare la mia password del conto di trading dalla piattaforma?",
        content: `<div class="content"><p>Segui questi passaggi per cambiare la password dell'account su MT5:</p>
      <ul>
      <li>Clicca su “Strumenti” nella barra del menu</li>
      <li>Seleziona “Opzioni” > Apparirà una finestra di dialogo</li>
      <li>Inserisci la nuova password > OK</li>
      </ul>
      <p>OPPURE</p>
      <ul>
      <li>Nel pannello di navigazione, sotto conti > Clicca con il tasto destro sul conto per cui desideri cambiare la password</li>
      <li>Inserisci la password attuale e la nuova password > Clicca su OK</li>
      </ul>
      </div>`,
      },
      {
        label:
          "Come posso eliminare/rimuovere il mio conto dalla piattaforma MT5?",
        content: `<div class="content"><p>Per rimuovere o eliminare il tuo conto dalla piattaforma MT5, fai clic con il tasto destro sul conto che desideri rimuovere e seleziona l'opzione “Elimina”.</p></div>`,
      },
      {
        label: "Dove posso scaricare la piattaforma MT5?",
        content: `<div class="content"><p>Per scaricare la piattaforma segui questi passaggi:</p>
      <ul>
      <li>Clicca sulla scheda “Piattaforma” sul portale</li>
      <li>Clicca su “Scarica qui”</li>
      </ul>
      </div>`,
      },
      {
        label: "Posso utilizzare un EA/BOT/Trading Robot?",
        content: `<div class="content"><p>Puoi sicuramente utilizzare un Expert Advisor su MT5. Puoi cercare quelli disponibili gratuitamente nella finestra di navigazione. Se vuoi, puoi anche acquistare EA dal sito MQL 5.</p></div>`,
      },
      {
        label: "Come posso aggiungere un EA in MT5?",
        content: `<div class="content"><p>Per aggiungere un EA, segui questi passaggi:</p>
      <ul>
      <li>Apri la finestra Navigator (Ctrl N)</li>
      <li>Seleziona Expert Advisor</li>
      <li>Clicca con il tasto destro sull'EA che desideri utilizzare</li>
      <li>Seleziona “Aggiungi al grafico”</li>
      </ul>
      <p>Se desideri applicare un EA acquistato dal sito MQL, segui questi passaggi:</p>
      <ul>
      <li>Vai sulla scheda “File” nella barra del menu</li>
      <li>Clicca su “Apri cartella dati”</li>
      <li>Apri la cartella “Experts” nel file “MQL”.</li>
      <li>Riavvia la piattaforma MT4.</li>
      </ul>
      </div>`,
      },
      {
        label:
          "Come posso configurare i grafici predefiniti con indicatori su MT5?",
        content: `<div class="content"><p>Segui i passaggi qui sotto:</p>
      <p><b>Per salvare un profilo:</b></p>
      <p>Una volta che hai posizionato tutto come desideri, vai su File - Profili e clicca su “Salva come”. Inserisci un nome per il profilo e poi premi “OK”</p>
      <p>Per caricare un profilo salvato, vai su File - Profili e seleziona il profilo dalla lista che appare. Il profilo dovrebbe caricarsi.</p>
      <p><b>Per salvare un template:</b></p>
      <p>Una volta che hai tutto impostato sul grafico come desideri, vai su Grafici - Template - Salva Template per salvare il Template. Inserisci un nome per il template e premi salva.</p>
      </div>`,
      },
      {
        label: "Posso usare più conti di trading su MT5?",
        content: `<div class="content"><p>Sì, puoi utilizzare più conti di trading su MT5. Per aggiungere un conto, segui questi passaggi:</p>
      <ul>
      <li>Fai clic con il tasto destro su conti nella finestra di navigazione</li>
      <li>Scegli “Accedi al conto di trading”</li>
      <li>Inserisci il tuo ID di trading e la password</li>
      </ul>
      <p>Il tuo conto di trading verrà inserito in MT5.</p>
      </div>`,
      },
      {
        label:
          "Dove posso verificare lo stato di connessione del mio conto di trading?",
        content: `<div class="content"><p>Lo stato di connessione può essere verificato nell'angolo in basso a destra sotto la finestra del terminale.</p><p>Per visualizzare lo stato di connessione, clicca su “Visualizza” > abilita barra di stato</p></div>`,
      },
    ],
    "Piattaforme di trading - ActTrader": [
      {
        label:
          "Come posso accedere al mio conto di trading sull'ACT sul desktop?",
        content: `<div class="content"><p>Segui i passaggi seguenti per accedere al tuo conto di trading:</p>
        <ul>
        <li>Fai clic sul <a href="https://aaafx.com/act-trader" target="_blank">link</a> per scaricare la piattaforma di trading.</li>
        <li>Una configurazione verrà scaricata sul sistema. Fai clic su Configurazione e la piattaforma verrà installata.</li>
        <li>Dopo aver installato la piattaforma, inserisci il nome utente e la password per il conto di trading.</li>
        <li>Seleziona il server: "Demo" per un account demo e "Reale" per un account live.</li>
        <li>Fai clic su "Accedi". Hai effettuato l'accesso al tuo account.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "Come posso accedere al mio conto di trading sull'ACT sul web trader?",
        content: `<div class="content"><p>Segui i passaggi seguenti per accedere al tuo conto di trading sul web trader:</p>
        <ul>
        <li>Fai clic sul <a href="https://aaafx.com/act-trader" target="_blank">link</a> per aprire web trader</li>
        <li>Apparirà una nuova scheda.</li>
        <li>Inserisci il nome utente e la password per il conto di trading.</li>
        <li>Fai clic su "Accedi". Hai effettuato l'accesso al tuo account.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "Sono confuso su come installare l'applicazione ACT sul mio dispositivo mobile. Per favore aiutatemi.",
        content: `<div class="content"><p>Segui i passaggi seguenti per installare l'applicazione sul tuo dispositivo mobile:</p>
        <p><b>ANDROID</b></p>
        <ul>
        <li>Accedi al tuo portale clienti sul tuo dispositivo mobile.</li>
        <li>Fai clic sul <a href="https://aaafx.com/act-trader" target="_blank">link</a></li>
        <li>Scorri fino alla parte inferiore della finestra e fai clic su "ACT ANDROID".</li>
        <li>Google Store apre un'applicazione denominata "ActDroid".</li>
        <li>Fai clic su "Installa" e l'applicazione verrà installata sul tuo dispositivo.</li>
        </ul>
        <p><b>IOS</b></p>
        <ul>
        <li>Accedi al tuo portale clienti sul tuo dispositivo mobile.</li>
        <li>Fai clic sul <a href="https://aaafx.com/act-trader" target="_blank">link</a></li>
        <li>Scorri fino alla parte inferiore della finestra e fai clic su ACT IOS.</li>
        <li>Google Store apre un'applicazione denominata "ActPhone".</li>
        <li>Fai clic su "Installa" e l'applicazione verrà installata sul tuo dispositivo.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "Come posso accedere al mio conto di trading sul mio dispositivo mobile?",
        content: `<div class="content"><p>Segui i passaggi seguenti per accedere al tuo conto di trading sul tuo dispositivo mobile:</p>
        <p><b>ANDROID</b></p>
        <ul>
          <li>Fai clic sull'applicazione ActDroid sul tuo dispositivo.</li>
          <li>Premi "Accetto" sul contratto di licenza.</li>
          <li>Inserisci il nome utente e la password per il conto di trading.</li>
          <li>Nel tipo di account, seleziona il server in base al tipo di account: "AAAFx-FX-Demo" per un account demo e "AAAFx-FX-Real" per un account live.</li>
          <li>Fai clic su "Accedi". Ora hai effettuato l'accesso al tuo account.</li>
        </ul>
        <p><b>IOS</b></p>
        <ul>
          <li>Fai clic sull'applicazione ActPhone sul tuo dispositivo.</li>
          <li>Inserisci il nome utente e la password per il conto di trading.</li>
          <li>Nel tipo di account, seleziona il server in base al tipo di account: "AAAFx-FX-Demo" per un account demo e "AAAFx-FX-Real" per un account live.</li>
          <li>Fai clic su "Accedi". Ora hai effettuato l'accesso al tuo account.</li>
        </ul>
        </div>`,
      },
      {
        label: "Come posso effettuare un ordine?",
        content: `<div class="content"><p>Puoi facilmente effettuare un'operazione facendo clic sui pulsanti Vendi o Acquista nella scheda Home della barra degli strumenti FXVoyager.</p><p>Puoi anche effettuare un ordine di mercato direttamente dalla finestra Tassi di negoziazione, dalla finestra Strumenti o dalla Tabella dei tassi di negoziazione. È sufficiente fare clic con il pulsante sinistro del mouse sull'offerta o sulla domanda oppure fare clic con il pulsante destro del mouse sul tasso e scegliere Ordine di mercato.</p></div>`,
      },
      {
        label:
          "Come posso controllare il saldo del mio conto di trading sulla piattaforma di trading?",
        content: `<div class="content"><p>Puoi controllare il saldo del conto nel modo seguente:</p>
        <ul>
        <li>Accedi al tuo conto di trading.</li>
        </li>Fai clic sulla scheda "Riepilogo".
        </ul>
        <p>Verranno visualizzate tutte le informazioni sull'account, incluso il saldo.</p>
        <p>O</p>
        <p>Il saldo del conto può essere controllato nell'angolo in basso a sinistra della piattaforma.</p>
        </div>`,
      },
      {
        label: "Sull'ACT, posso accedere a più account contemporaneamente?",
        content: `<div class="content"><p>No, ACT non consente all'utente di accedere a più conti di trading contemporaneamente.</p></div>`,
      },
      {
        label: "Come posso abilitare il trading con un clic?",
        contenuto: `<div class="contenuto">
        <ul>
        <li>Fai clic sull'icona delle impostazioni nell'angolo in alto a sinistra</li>
        <li>Seleziona "impostazioni principali"</li>
        <li>Seleziona la casella "Trading con un clic"</li>
        <li>Fai clic su Applica</li>
        </ul>
        </div>`,
      },
      {
        label: "Posso applicare gli indicatori al mio grafico? Come?",
        content: `<div class="content"><p>Segui i passaggi seguenti per applicare gli indicatori sul tuo grafico:</p>
        <ul>
        <li>Apri il grafico su cui desideri applicare l'indicatore.</li>
        <li>Sposta il cursore sul grafico. Fai clic con il pulsante destro del mouse sull'area del grafico.</li>
        <li>Seleziona "Imposta indicatori".</li>
        <li>Si aprirà una finestra con vari indicatori.</li>
        <li>Selezionare l'indicatore e premere il pulsante avanti " > "</li>
        <li>Puoi modificare le impostazioni dell'indicatore selezionato nell'area della busta prima dell'applicazione.</li>
        <li>Fai clic su "Applica" e poi su "Ok".</li>
        </ul>
        <p>Vedrai che l'indicatore è stato applicato sul tuo grafico.</p>
        </div>`,
      },
      {
        label: "Come posso rimuovere un indicatore dal mio grafico?",
        content: `<div class="content"><p>Segui i passaggi seguenti per rimuovere gli indicatori sul grafico:</p>
        <ul>
        <li>Apri il grafico da cui desideri rimuovere l'indicatore.</li>
        <li>Sposta il cursore sul grafico. Fai clic con il pulsante destro del mouse sull'area del grafico.</li>
        <li>Seleziona "Imposta indicatori".</li>
        <li>Si aprirà una finestra con vari indicatori.</li>
        <li>Puoi vedere l'elenco di tutti gli indicatori applicati sotto "Indicatori attivi".</li>
        <li>Selezionare l'indicatore e premere il pulsante di inversione " < "</li>
        <li>Fai clic su "Applica" e poi su "Ok".</li>
        </ul>
        <p>Vedrai che l'indicatore è stato rimosso dal grafico.</p>
        </div>`,
      },
      {
        label: "Posso rimuovere più indicatori dal grafico?",
        content: `<div class="content"><p>Sì, puoi sicuramente farlo.</p>
        <ul>
        <li>Apri il grafico da cui desideri rimuovere l'indicatore.</li>
        <li>Sposta il cursore sul grafico. Fai clic con il pulsante destro del mouse sull'area del grafico.</li>
        <li>Seleziona "Imposta indicatori".</li>
        <li>Si aprirà una finestra con vari indicatori.</li>
        <li>Puoi vedere l'elenco di tutti gli indicatori applicati sotto "Indicatori attivi".</li>
        <li>Fare clic sul pulsante di riavvolgimento rapido " << " per rimuovere tutti gli indicatori</li>
        <li>Fai clic su "Applica" e poi su "Ok".</li>
        </ul>
        </div>`,
      },
      {
        label: "Da dove posso aprire la finestra del tasso di negoziazione?",
        content: `<div class="content"><p>Segui i passaggi seguenti per aprire la finestra del tasso di negoziazione:</p>
        <ul>
        <li>Accedi al tuo conto di trading sulla piattaforma.</li>
        <li>Fai clic sulla scheda "Strumento".</li>
        <li>Sposta il cursore verso lo strumento particolare.</li>
        <li>Fai clic con il pulsante destro del mouse e seleziona la "Finestra del tasso di negoziazione individuale".</li>
        <li>Si aprirà un pop-up con il tasso di negoziazione di quella coppia.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "Come posso effettuare un ordine dalla finestra del tasso di negoziazione?",
        content: `<div class="content"><p>Segui i passaggi seguenti per aprire la finestra del tasso di negoziazione:</p>
        <ul>
        <li>Accedi al tuo conto di trading sulla piattaforma.</li>
        <li>Fai clic sulla scheda "Strumento".</li>
        <li>Sposta il cursore verso lo strumento particolare.</li>
        <li>Fai clic con il pulsante destro del mouse e seleziona la "Finestra del tasso di negoziazione individuale".</li>
        <li>Si aprirà un pop-up con il tasso di negoziazione di quella coppia.</li>
        <li>Fai doppio clic sul pop-up, modifica le impostazioni richieste e fai clic su "OK" per effettuare l'ordine.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "Posso scaricare il mio estratto conto di trading dalla piattaforma di trading?",
        content: `<div class="content"><p>Sì, l'estratto conto di trading può essere scaricato dalla piattaforma seguendo i passaggi seguenti:</p>
        <ul>
        <li>Accedi al tuo conto di trading sulla piattaforma.</li>
        <li>Fai clic su "Rapporti".</li>
        <li>Seleziona "Estratto conto".</li>
        <li>Seleziona il formato dell'ora e fai clic su "Esegui rapporto".</li>
        <li>Il rapporto si aprirà sullo schermo. Seleziona "Esporta in Excel". Il report si aprirà sul foglio Excel e potrà essere salvato da lì.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "Voglio modificare il mio ordine di vendita. Come posso applicare i livelli di Stop Loss e Take Profit al mio ordine?",
        content: `<div class="content"><p>Per modificare un ordine, apri la scheda 'Trading' sulla barra degli strumenti e fai clic sul pulsante 'Stop o Limit'. Puoi impostare un ordine Stop o Limite dalla finestra Posizioni aperte. Puoi anche impostare un arresto o un limite predefinito nella finestra di dialogo Nuova posizione.</p></div>`,
      },
      {
        label: "Come posso chiudere un'operazione sul grafico?",
        content: `<div class="content"><p>Per chiudere o coprire una posizione, fare clic con il pulsante destro del mouse sul triangolo della posizione e selezionare il comando appropriato.</p></div>`,
      },
      {
        label: "Come posso aggiungere Trailing Stop su un ordine?",
        content: `<div class="content"><p>Segui i passaggi seguenti per aggiungere Trailing Stop al tuo ordine:</p>
        <ul>
        <li>Nella finestra Posizioni aperte, fai clic con il pulsante destro del mouse sulla posizione desiderata.</li>
        <li>Sullo schermo verrà visualizzato un menu a comparsa.</li>
        <li>Scegli il livello Trailing Stop e fai clic su "OK".</li>
        </ul>
        </div>`,
      },
      {
        label: "Dove posso vedere la mia cronologia di trading?",
        content: `<div class="content"><p>La cronologia delle posizioni può essere visualizzata nella finestra Posizioni chiuse.</p></div>`,
      },
      {
        label: "Come posso visualizzare la cronologia degli scambi su IOS?",
        content: `<div class="content"><p>Per visualizzare la cronologia su Ios, fare clic su Menu > Cronologia. Per impostazione predefinita, è possibile visualizzare fino a 15 posizioni. Il numero di posizioni può essere aumentato aprendo Menu > Impostazioni.</p></div>`,
      },
      {
        label: "Come posso aprire un grafico di uno strumento di trading?",
        content: `<div class="content"><p>Per aprire una finestra dei grafici nella tua area di lavoro di trading, scegli "Grafici" nel menu del pannello superiore e quindi seleziona "Nuovo grafico".</p>
        <p>O</p>
        <p>Fai clic con il pulsante destro del mouse su uno strumento nella finestra Tassi di negoziazione e seleziona Apri grafico. Seleziona lo strumento (se hai fatto clic con il tasto destro su uno strumento per raggiungere la finestra di dialogo Nuovo grafico, quello strumento sarà preselezionato) e la scala temporale dal menu Nuovo grafico e premi OK.
        </p>
        </div>`,
      },
      {
        label: "Come posso ingrandire e rimpicciolire il grafico?",
        content: `<div class="content"><p>Nella parte superiore del grafico, le icone più '+' e meno '-' consentono lo zoom avanti, lo zoom indietro e la visualizzazione predefinita del grafico.</p>< /div>`,
      },
      {
        label: "Posso cambiare i colori del grafico?",
        content: `<div class="content"><p>Sì, puoi cambiare i colori del grafico. Fare clic con il tasto destro sul grafico e selezionare "Impostazioni grafico". Vedrai il menu a discesa "Colori". I colori possono essere modificati di conseguenza.</p></div>`,
      },
      {
        label: "Dove vedo la linea del prezzo sul grafico?",
        content: `<div class="content"><p>La linea del prezzo è la linea di colore blu sullo sfondo del grafico. Si muove secondo il movimento del prezzo dello strumento.</p></div>`,
      },
    ],
    "Condizioni di negoziazione": [
      {
        label:
          "Ci sono restrizioni nell'adottare stili di trading? Sono uno scalper.",
        content: `<div class="content"><p>Essendo un broker amico dei trader, non ci sono restrizioni su alcun stile di trading. Puoi fare hedging, scalping e usare Expert Advisors (EAs) sulla tua piattaforma di trading.</p></div>`,
      },
      {
        label: "Seguite la regola FIFO per l'esecuzione degli ordini?",
        content: `<div class="content"><p>No, non seguiamo la regola FIFO. Il trader può chiudere le operazioni secondo la propria strategia.</p></div>`,
      },
      {
        label: "Che tipo di esecuzione degli ordini offrite?",
        content: `<div class="content"><p>Su AAAFx, l'esecuzione degli ordini è istantanea.</p></div>`,
      },
      {
        label: "C'è un limite sul numero di operazioni da piazzare ogni mese?",
        content: `<div class="content"><p>No, non ci sono limiti di questo tipo. Puoi piazzare operazioni secondo la tua scelta.</p></div>`,
      },
      {
        label: "Posso fare trading nel fine settimana?",
        content: `<div class="content"><p>No, il trading non è disponibile nel fine settimana.</p></div>`,
      },
      {
        label: "Per quanto tempo posso mantenere aperte le mie posizioni?",
        content: `<div class="content"><p>Non c'è alcuna limitazione sulla durata per cui una posizione può rimanere aperta. Puoi mantenerla aperta finché hai margine libero sufficiente nel tuo account.</p></div>`,
      },
      {
        label:
          "Posso perdere più di quanto deposito quando faccio trading con voi?",
        content: `<div class="content"><p>Quando fai trading con AAAFx, non puoi perdere più di quanto depositi perché offriamo la Protezione del Saldo Negativo. AAAFx determina, a sua discrezione e in buona fede, che l'occorrenza di un saldo negativo non è dovuta a comportamenti dannosi o abusivi del cliente e non è stata causata intenzionalmente dal cliente.</p></div>`,
      },
      {
        label:
          "Cosa succede se non c'è più margine libero nel mio account di trading?",
        content: `<div class="content"><p>Se non c'è margine libero, non puoi piazzare ulteriori ordini. Inoltre, non puoi fare una richiesta di prelievo perché puoi sempre prelevare l'importo disponibile come margine libero nel tuo account.</p></div>`,
      },
      {
        label: "Che tipo di spread offrite su AAAFx?",
        content: `<div class="content"><p>Offriamo spread sottili a tutti i nostri clienti, indipendentemente dai diversi tipi di account.</p></div>`,
      },
      {
        label: "Che leva è offerta da AAAFx?",
        content: `<div class="content"><p>La leva massima offerta da AAAFx Europe è 30X.</p></div>`,
      },
      {
        label: "A quale fuso orario sono impostati i vostri server?",
        content: `<div class="content"><p>Il fuso orario è UTC [UTC +1 durante l'ora legale].</p></div>`,
      },
      {
        label: "Dove si trovano i vostri server?",
        content: `<div class="content"><p>I nostri server si trovano a Londra e New York.</p></div>`,
      },
      {
        label: "Offrite consulenze di trading ai vostri clienti?",
        content: `<div class="content"><p>Essendo un broker, non forniamo alcun tipo di consulenza di trading ai nostri clienti. Per strategie di trading ideali, puoi fare riferimento al sito web di Zulutrade e imparare il più possibile. Inoltre, puoi anche leggere sul rapporto tra leva e margine per investire i tuoi fondi in modo saggio. Il trading Forex riguarda tutto l'investire il tuo tempo e denaro in modo giusto. Inizia a investire ora!</p></div>`,
      },
    ],
  },
};
