<template>
  <div id="main-wrapper" class="inner-pages acct-page comp-plat-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{ $t("comparePlatform.heading") }}</h2>
        <p>{{ $t("comparePlatform.content") }}</p>
      </div>
    </div>
    <div class="acct-content compare-accts section">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h6>
            {{ $t("comparePlatform.h6Tag") }}
          </h6>
          <br />
          <p>
            {{ $t("comparePlatform.pTag") }}
          </p>
        </div>
        <div class="table-holder home-table compare-acct wow fadeIn">
          <table>
            <tr>
              <th>{{ $t("comparePlatform.condition") }}</th>
              <th>
                <img
                  src="assets/images/comp-ico1.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="174"
                  height="45"
                  class="fluid-img"
                />
              </th>
            </tr>
            <tr>
              <td>{{ $t("comparePlatform.timeFrames") }}</td>
              <td>9</td>
            </tr>
            <tr>
              <td>{{ $t("comparePlatform.leverage") }}</td>
              <td>up to 30X</td>
            </tr>
            <tr>
              <td>{{ $t("comparePlatform.microLot") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td>{{ $t("comparePlatform.scalping") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td>{{ $t("comparePlatform.attachment") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t("comparePlatform.other") }}</strong>
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>{{ $t("comparePlatform.economic") }}</td>
              <td>{{ $t("comparePlatform.no") }}</td>
            </tr>
            <tr>
              <td>{{ $t("comparePlatform.report") }}</td>
              <td>{{ $t("comparePlatform.html") }}</td>
            </tr>
            <tr>
              <td>{{ $t("comparePlatform.language") }}</td>
              <td>{{ $t("comparePlatform.no") }}</td>
            </tr>

            <tr>
              <td>{{ $t("comparePlatform.priceAlert") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t("comparePlatform.device") }}</strong>
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td class="">{{ $t("comparePlatform.window") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td class="">{{ $t("comparePlatform.mac") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td class="">{{ $t("comparePlatform.android") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td class="">{{ $t("comparePlatform.ios") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td class="">{{ $t("comparePlatform.webBased") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t("comparePlatform.sutability") }}</strong>
              </td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td class="">{{ $t("comparePlatform.beginner") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td class="">{{ $t("comparePlatform.intermediate") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td class="">{{ $t("comparePlatform.advance") }}</td>
              <td>{{ $t("comparePlatform.yes") }}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>
                <a :href="siteurl" target="_blank" class="btn btn-llblue">{{
                  $t("comparePlatform.openAccount")
                }}</a>
              </td>
            </tr>
          </table>
        </div>
        <div class="title-holder wow fadeIn t-left">
          <h5>{{ $t("comparePlatform.platform") }}</h5>
          <br />
          <p>
            {{ $t("comparePlatform.choice") }}
          </p>
          <ul class="listing-check">
            <li>{{ $t("comparePlatform.point") }}</li>
            <li>{{ $t("comparePlatform.point1") }}</li>
            <li>{{ $t("comparePlatform.point2") }}</li>
          </ul>
          <br />
          <p>
            {{ $t("comparePlatform.point3") }}

            <br />{{ $t("comparePlatform.point4") }}
            <a :href="siteurl" target="_blank">{{
              $t("comparePlatform.point5")
            }}</a>
            {{ $t("comparePlatform.point6") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      siteurl: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
};
</script>
