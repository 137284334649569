<template>
  <div id="main-wrapper" class="inner-pages forex-page pamm-page ib-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li>
              <router-link to="/white-label">{{
                $t("ib.whiteLabel")
              }}</router-link>
            </li>
            <li>
              <router-link to="/pamm">{{ $t("ib.pamm") }}</router-link>
            </li>
            <li class="active">
              <router-link to="/ib">{{ $t("ib.title") }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{ $t("ib.title") }}</h2>
        <p>{{ $t("ib.heading") }}</p>
      </div>
    </div>
    <div class="pamm-content section">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h3>{{ $t("ib.h3Tag") }}</h3>
          <br />
          <p>{{ $t("ib.pTag") }}</p>
        </div>
      </div>
      <div class="full-img wow fadeIn"></div>
    </div>
    <div class="expect-sec section">
      <div class="wrapper">
        <div class="img-holder wow fadeInLeft">
          <img
            src="assets/images/ib-img2.webp"
            alt="AAAFx"
            title="AAAFx"
            width="564"
            height="551"
            class="fluid-img"
          />
        </div>
        <div class="content-holder wow fadeInRight">
          <div class="title-holder">
            <h3>{{ $t("ib.h3Tag1") }}</h3>
            <p>{{ $t("ib.pTag1") }}</p>
            <ul class="listing-check">
              <li>{{ $t("ib.ul.li") }}</li>
              <li>{{ $t("ib.ul.liTag") }}</li>
              <li>{{ $t("ib.ul.liTag1") }}</li>
              <li>{{ $t("ib.ul.liTag2") }}</li>
              <li>{{ $t("ib.ul.liTag3") }}</li>
              <li>{{ $t("ib.ul.liTag4") }}</li>
              <li>{{ $t("ib.ul.liTag5") }}</li>
              <li>{{ $t("ib.ul.liTag6") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="section join-ib">
      <div class="wrapper">
        <div class="title-holder t-left">
          <h3>{{ $t("ib.question.ques") }}</h3>
          <br /><br />
          <p>
            {{ $t("ib.question.point") }}
            <br /><br />
            {{ $t("ib.question.point1") }}
            <br /><br />
            <span>1.</span>{{ $t("ib.question.point2") }}<br /><br />
            <span>2.</span>{{ $t("ib.question.point3") }} <br /><br />
            {{ $t("ib.question.point4") }}
          </p>
        </div>
        <div class="listing">
          <div class="join-holder col wow fadeIn">
            <p>{{ $t("ib.signUp") }}</p>
            <img
              src="assets/images/ib-ico1.webp"
              alt="AAAFx"
              title="AAAFx"
              width="185"
              height="193"
              class="fluid-img"
            />
          </div>
          <div class="join-holder col wow fadeIn" data-wow-delay="0.3s">
            <p>{{ $t("ib.referralLink") }}</p>
            <img
              src="assets/images/ib-ico2.webp"
              alt="AAAFx"
              title="AAAFx"
              width="185"
              height="193"
              class="fluid-img"
            />
          </div>
          <div class="join-holder col wow fadeIn" data-wow-delay="0.6s">
            <p>{{ $t("ib.clients") }}</p>
            <img
              src="assets/images/ib-ico3.webp"
              alt="AAAFx"
              title="AAAFx"
              width="185"
              height="193"
              class="fluid-img"
            />
          </div>
          <div class="join-holder col wow fadeIn" data-wow-delay="0.9s">
            <p>{{ $t("ib.earn") }}</p>
            <img
              src="assets/images/ib-ico4.webp"
              alt="AAAFx"
              title="AAAFx"
              width="185"
              height="193"
              class="fluid-img"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section benefits-ib wow fadeIn">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h3>{{ $t("ib.benefits") }}</h3>
        </div>
        <div class="benefits-holder col">
          <img
            src="assets/images/ib1.webp"
            alt="AAAFx"
            title="AAAFx"
            width="94"
            height="94"
            class="fluid-img"
          />
          <p>{{ $t("ib.zero") }}</p>
        </div>
        <div class="benefits-holder col">
          <img
            src="assets/images/ib2.webp"
            alt="AAAFx"
            title="AAAFx"
            width="94"
            height="94"
            class="fluid-img"
          />
          <p>{{ $t("ib.ibCommission") }}</p>
        </div>
        <div class="benefits-holder col">
          <img
            src="assets/images/ib3.webp"
            alt="AAAFx"
            title="AAAFx"
            width="94"
            height="94"
            class="fluid-img"
          />
          <p>{{ $t("ib.earning") }}</p>
        </div>
        <div class="benefits-holder col">
          <img
            src="assets/images/ib4.webp"
            alt="AAAFx"
            title="AAAFx"
            width="94"
            height="94"
            class="fluid-img"
          />
          <p>{{ $t("ib.customized") }}</p>
        </div>
        <div class="benefits-holder col">
          <img
            src="assets/images/ib5.webp"
            alt="AAAFx"
            title="AAAFx"
            width="94"
            height="94"
            class="fluid-img"
          />
          <p>{{ $t("ib.ultraFast") }}</p>
        </div>
        <div class="benefits-holder col">
          <img
            src="assets/images/ib6.webp"
            alt="AAAFx"
            title="AAAFx"
            width="94"
            height="94"
            class="fluid-img"
          />
          <p>{{ $t("ib.easyUser") }}</p>
        </div>
        <div class="benefits-holder col">
          <img
            src="assets/images/ib7.webp"
            alt="AAAFx"
            title="AAAFx"
            width="94"
            height="94"
            class="fluid-img"
          />
          <p>{{ $t("ib.accountManager") }}</p>
        </div>
        <div class="benefits-holder col">
          <img
            src="assets/images/ib8.webp"
            alt="AAAFx"
            title="AAAFx"
            width="94"
            height="94"
            class="fluid-img"
          />
          <p>{{ $t("ib.helpful") }}</p>
        </div>
      </div>
    </div>
    <div class="section-small">
      <div class="wrapper">
        <div class="title-holder">
          <h6>
            {{ $t("ib.h6Tag") }}
            <a href="mailto:support@aaafx.com">{{ $t("ib.click") }} </a>
            {{ $t("ib.orWrite") }}
            <a href="mailto:support@aaafx.com">{{ $t("ib.email") }}</a>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
