<template>
	<div id="main-wrapper" class="inner-pages why-page career-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('careerPage.heading')}}</h2>
				<p>{{$t('careerPage.headingDescription')}}</p>
				<br>
				<h6><strong>{{$t('careerPage.Join_us_and_help_us')}}</strong></h6>
			</div>
		</div>
		<div class="section our-cult">
			<div class="wrapper2">
				<div class="img-holder wow fadeInLeft">
					<img src="assets/images/cult-img.webp" alt="AAAFx" title="AAAFx" width="517" height="694" class="fluid-img" />
				</div>
				<div class="content-holder wow fadeInRight">
					<h3>{{$t('careerPage.OurCulture')}}</h3>
					<br>
					<p class="italic">{{$t('careerPage.OurCulturePointOne')}}</p>
					<br>
					<p>{{$t('careerPage.OurCulturePointTwo')}}</p>
					<br>
					<h5>{{$t('careerPage.When_our_clients')}} <br>{{$t('careerPage.succeed_we_succeed')}}</h5>
				</div>

				<div class="section testi-list">
					<div class="testi-holder wow fadeIn">
						<div class="testi">
								<h3>{{$t('careerPage.succeed_we_succeed_pointOneP1')}}
									<br>
                  {{$t('careerPage.succeed_we_succeed_pointOneP2')}}                  
                </h3>
								<p>{{$t('careerPage.succeed_we_succeed_pointOnedesc1')}} <span style="font-style:italic;">{{$t('careerPage.succeed_we_succeed_pointOnedesc2')}}</span> </p>
						</div>
					</div>
					<div class="testi-holder wow fadeIn" data-wow-delay="0.3s">

						<div class="testi">
								<h3>{{$t('careerPage.succeed_we_succeed_pointTwoP1')}}
									<br>
                  {{$t('careerPage.succeed_we_succeed_pointTwoP2')}}
                </h3>
								<p>{{$t('careerPage.succeed_we_succeed_pointTwodesc1')}}</p>
						</div>
					</div>
					<div class="testi-holder wow fadeIn" data-wow-delay="0.6s">
						<div class="testi">
								<h3>
                  {{$t('careerPage.succeed_we_succeed_pointThreeP1')}}
									<br>
                  {{$t('careerPage.succeed_we_succeed_pointThreeP2')}}
                </h3>
								<p>{{$t('careerPage.succeed_we_succeed_pointThreedesc')}}</p>
						</div>
					</div>
					<div class="testi-holder wow fadeIn" data-wow-delay="0.9s">
						<div class="testi">
								<h3>
                  {{$t('careerPage.succeed_we_succeed_pointFourP1')}}
									<br>
                  {{$t('careerPage.succeed_we_succeed_pointFourP2')}}
                </h3>
								<p>{{$t('careerPage.succeed_we_succeed_pointFourdesc')}}</p>
						</div>
					</div>
					<img src="assets/images/hr.webp" alt="AAAFx" title="AAAFx" width="250" height="250" class="center-div wow fadeIn fluid-img" data-wow-delay="1.2s" />
				</div>
				<div class="section benefits-sec">
					<div class="content-holder wow fadeInLeft">
						<h3>{{$t('careerPage.Rewards_and_Benefits')}}</h3>
						<br>
						<p class="italic">{{$t('careerPage.Rewards_and_Benefits_PointOne')}}</p>
						<br>
						<p>{{$t('careerPage.Rewards_and_Benefits_PointTwo')}}</p>
						<br>

					</div>
					<div class="img-holder wow fadeInRight">
						<img src="assets/images/bene.webp" alt="AAAFx" title="AAAFx" width="560" height="343" class="fluid-img" />
					</div>
				</div>
				<div class="section-small current-vac t-center">
					<div class="content-holder wow fadeIn t-center">
						<h3>{{$t('careerPage.CurrentVacancies')}}</h3>
						<br>
						<p>{{$t('careerPage.CurrentVacanciesDescOne')}}
						<br><br>
						{{$t('careerPage.CurrentVacanciesDescTwo')}}</p>
						<br>
						<div class="title-holder">
							<h6><strong>{{$t('careerPage.ReadyForChallengesReadyForYou')}}</strong></h6>
						</div>
					</div>
				</div>

			<div class="section-small job-list">
				<div class="wrapper">
					<div class="accordion-holder">
						<h3 v-on:click="Support ^= true" :class="[{active : Support}]">{{$t('careerPage.CustomerSupportGreece')}}</h3>
						<div class="content" v-show="Support" style="padding: 20px">
							<div class="job-holder">
                  <h5>{{$t('careerPage.What_the_job_looks_like')}}</h5>
                  <ul class="listing-check">
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_pointOne')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_pointTwo')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_pointThree')}}</li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_pointFour')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_pointFive')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_pointSix')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_pointSeven')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_pointEight')}}
                    </li>
                  </ul>
                  <h5>{{$t('careerPage.What_do_we_look_for')}}</h5>
                  <ul class="listing-check">
                    <li>
                      {{$t('careerPage.What_do_we_look_for_pointOne')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_pointTwo')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_pointThree')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_pointFour')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_pointFive')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_pointSix')}}
                    </li>
                  </ul>
                </div>
							<div class="apply-btn">
								<a href="mailto:support@aaafx.com" class="btn btn-blue">{{$t('careerPage.applyForTheJob')}}</a>
							</div>
						</div>
						<h3 v-on:click="Finance ^= true"  :class="[{active : Finance}]">{{$t('careerPage.FinanceExecutiveGreece')}}</h3>
						<div class="content" v-show="Finance">
							<div class="job-holder">
                  <h5>{{$t('careerPage.What_the_job_looks_like')}}</h5>
                  <ul class="listing-check">
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point1')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point2')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point3')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point4')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point5')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point6')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point7')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point8')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point9')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point10')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point11')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_point12')}}
                    </li>
                  </ul>
                  <h5>{{$t('careerPage.What_do_we_look_for')}}</h5>
                  <ul class="listing-check">
                    <li>
                      {{$t('careerPage.What_do_we_look_for_point1')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_point2')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_point3')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_point4')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_point5')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_point6')}}
                    </li>
                  </ul>
                </div>
							<div class="apply-btn">
								<a href="mailto:support@aaafx.com" class="btn btn-blue">{{$t('careerPage.applyForTheJob')}}</a>
							</div>
						</div>
						<h3 v-on:click="Administrator ^= true"  :class="[{active : Administrator}]">{{$t('careerPage.DataAdministratorGreece')}}</h3>
						<div class="content" v-show="Administrator">
							<div class="job-holder">
                  <h5>{{$t('careerPage.What_the_job_looks_like')}}</h5>
                  <ul class="listing-check">
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p1')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p2')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p3')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p4')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p5')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p6')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p7')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p8')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p9')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p10')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_the_job_looks_like_p11')}}
                    </li>
                  </ul>
                  <h5>{{$t('careerPage.What_do_we_look_for')}}</h5>
                  <ul class="listing-check">
                    <li>
                      {{$t('careerPage.What_do_we_look_for_p1')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_p2')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_p3')}}
                    </li>
                    <li >
                      {{$t('careerPage.What_do_we_look_for_p4')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_p5')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_p6')}}
                    </li>
                    <li>
                      {{$t('careerPage.What_do_we_look_for_p7')}}
                    </li>
                  </ul>
                </div>
							<div class="apply-btn">
								<a href="mailto:support@aaafx.com" class="btn btn-blue">{{$t('careerPage.applyForTheJob')}}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section-small current-vac t-center">
				<div class="content-holder wow fadeIn t-center">
					<h3>{{$t('careerPage.How_to_Apply')}}</h3>
					<br>
					<p>
            {{$t('careerPage.How_to_Apply_p1')}}
            <br>
            {{$t('careerPage.How_to_Apply_p1')}}
          </p>

				</div>
			</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
        Support:false,
        Finance:false,
        Administrator:false,
      }
    }
  }

</script>