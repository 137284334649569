import { createI18n } from "vue-i18n";
import en from "./en.js";
import it from "./it.js";
import de from "./de.js";
import pl from "./pl.js";
import sv from "./sv.js";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  warnHtmlMessage: false,
  warnHtmlInMessage: "off",
  messages: {
    en,
    it,
    de,
    pl,
    sv,
  },
});
export default i18n;
