<template>
  <Header v-if="$route.name != 'WebTrader'" />
  <div id="main-container">
      <router-view />
  </div>
  <Footer v-if="$route.name != 'WebTrader'" />
</template>

<script>
import Header from "@/components/shared/Header";
import Footer from "@/components/shared/Footer";
import { ProviderCacheMixins } from './plugins/vue-provider-cache/mixin.ts'
import seoData from "../public/assets/js/seo.js";
export default {
  components: { Header, Footer },
  mixins: [ ProviderCacheMixins ],
  watch: {
    $route() {
      if (this.$route.path) {
        let seoJson = {};
        seoJson = seoData[0];
        let key = this.$route.path.split("/")[1];
        let getSeoPath = seoJson[key];
        if (key == undefined || seoJson[key] == undefined) {
          getSeoPath = seoJson[""];
        }
        if (getSeoPath) {
          document.title = getSeoPath.title;
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", getSeoPath.description);
          document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content", getSeoPath.keywords);
          /*this.pageTitle = getSeoPath.META_TITLE
          this.pageDescription = getSeoPath.META_DESCRIPTION
          this.pageKeywords = getSeoPath.META_KEYWORDS*/
        }
      }
    },
  },
};
</script>
