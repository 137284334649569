export default {
    loginLink: 'https://aaafx-international.com/login',
    registerLink: 'https://aaafx-international.com/sign-up',
    platformLinks: {
        mt4: {
            desktop: 'https://download.mql5.com/cdn/web/triple.a.experts/mt4/tripleaexperts4setup.exe',
            mac: 'javascript:void(0);',
            web: 'https://metatraderweb.app/trade?servers=TurnkeyGlobal-Demo%2cTurnkeyGlobal-Live&amp;trade_server=TurnkeyGlobal-Demo&amp;startup_mode=create_demo&amp;utm_source=www.turnkeyforex.com&amp;lang=en',
            android: 'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en',
            ios: 'https://apps.apple.com/us/app/metatrader-4/id496212596',
        },
        mt5: {
            desktop: 'https://download.mql5.com/cdn/web/triple.a.experts/mt5/tripleaexperts5setup.exe',
            mac: 'javascript:void(0);',
            web: 'https://metatraderweb.app/trade?servers=TurnkeyGlobal-Demo%2cTurnkeyGlobal-Live&amp;trade_server=TurnkeyGlobal-Demo&amp;startup_mode=create_demo&amp;utm_source=www.turnkeyforex.com&amp;lang=en',
            android: 'javascript:void(0);',
            ios: 'javascript:void(0);',
        },
        actTrader: {
            desktop: 'javascript:void(0);',
            mac: 'javascript:void(0);',
            web: 'javascript:void(0);',
            android: 'javascript:void(0);',
            ios: 'javascript:void(0);',
        },
    },
    pair_list: {
        commodities: {
            "CORNF" : {
                "contract_size": "1",
                "leverage": "12.5",
                "margin_per": "8",
                "min_vol": "1",
                "max_vol": "100"
            },
            "Copper" : {
                "contract_size": "1000",
                "leverage": "20",
                "margin_per": "5",
                "min_vol": "1",
                "max_vol": "100"
            },
            "NGAS" : {
                "contract_size": "1000",
                "leverage": "16.67",
                "margin_per": "6",
                "min_vol": "1",
                "max_vol": "100"
            },
            "SOYF" : {
                "contract_size": "1",
                "leverage": "20",
                "margin_per": "5",
                "min_vol": "1",
                "max_vol": "100"
            },
            "USOILSPOT" : {
                "contract_size": "10",
                "leverage": "20",
                "margin_per": "5",
                "min_vol": "1",
                "max_vol": "100"
            },
            "WheatF" : {
                "contract_size": "1",
                "leverage": "14.28",
                "margin_per": "7",
                "min_vol": "1",
                "max_vol": "100"
            },
            "XAGUSD" : {
                "contract_size": "50",
                "leverage": "100",
                "margin_per": "1",
                "min_vol": "1",
                "max_vol": "100"
            },
            "XAUUSD" : {
                "contract_size": "1",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            }
        },
        cryptos: {
            "BCHUSD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "100"
            },
            "BTCUSD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "100"
            },
            "EOSUSD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "100"
            },
            "ETHUSD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "100"
            },
            "LTCUSD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "100"
            },
            "XLMUSD" : {
                "contract_size": "100",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "100"
            }
        },
        indices: {
            "AUS200" : {
                "contract_size": "1",
                "leverage": "66.67",
                "margin_per": "1.50",
                "min_vol": "1",
                "max_vol": "100"
            },
            "Bund" : {
                "contract_size": "100",
                "leverage": "100",
                "margin_per": "1",
                "min_vol": "1",
                "max_vol": "100"
            },
            "CHN50" : {
                "contract_size": "1",
                "leverage": "20",
                "margin_per": "5",
                "min_vol": "1",
                "max_vol": "100"
            },
            "ESP35" : {
                "contract_size": "1",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            },
            "EUSTX50" : {
                "contract_size": "1",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            },
            "FRA40" : {
                "contract_size": "1",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            },
            "GER30" : {
                "contract_size": "1",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            },
            "JPN225" : {
                "contract_size": "100",
                "leverage": "25",
                "margin_per": "4",
                "min_vol": "1",
                "max_vol": "100"
            },
            "NAS100" : {
                "contract_size": "1",
                "leverage": "100",
                "margin_per": "1",
                "min_vol": "1",
                "max_vol": "100"
            },
            "SPX500" : {
                "contract_size": "10",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            },
            "US2000" : {
                "contract_size": "1",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            },
            "US30" : {
                "contract_size": "1",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            },
            "UK100" : {
                "contract_size": "1",
                "leverage": "50",
                "margin_per": "2",
                "min_vol": "1",
                "max_vol": "100"
            }
        },
        stocks: {
            "AAL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "AAPL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ABNB" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ACN" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ADBE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "AMD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "AMGN" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "AMZN" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "AON" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ASML" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ATVI" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "AVGO" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "AXP" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BABA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BAC" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BK" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BKNG" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BLK" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BMY" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "C" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CAT" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CCL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CME" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "COIN" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "COST" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CRM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CRWD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CSCO" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CVX" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "DAL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "DIS" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "DOCU" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "EA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "EBAY" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "UBER" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "EXPE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "FB" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "FDX" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "GILD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "XPEV" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "GM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "GOOGL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "GS" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "HAL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "HD" : {
                "contract_size": "1",
                "leverage": "3.33",
                "margin_per": "30",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "HOOD" : {
                "contract_size": "1",
                "leverage": "3.33",
                "margin_per": "30",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "HPQ" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "IBM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "INTC" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "JD" : {
                "contract_size": "1",
                "leverage": "3.33",
                "margin_per": "30",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "JNJ" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "JPM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "KHC" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "KO" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "LMT" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "LRCX" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "LUV" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "M" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MCD" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MDLZ" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MMM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MRK" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MS" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MSFT" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NFLX" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NKE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NOW" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NVDA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "OKTA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ORCL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PEP" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PFE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PG" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PYPL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "QCOM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "RCL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "SBUX" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "SE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "SQ" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "T" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TMUS" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TSLA" : {
                "contract_size": "1",
                "leverage": "3",
                "margin_per": "30",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TWLO" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TWTR" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TXN" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "UAL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "10",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "UNH" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "V" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "VZ" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "WDC" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "WFC" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "WMT" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "XOM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ZM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            //New-stocks
            "ALB" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "APA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "AUY" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BB" : {
                "contract_size": "1",
                "leverage": "2",
                "margin_per": "50",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BBBY" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "CX" : {
                "contract_size": "1",
                "leverage": "4",
                "margin_per": "25",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "DBX" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "DE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "EDU" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "FCEL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "FSLR" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "GPRO" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "HLT" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "HOG" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "JBL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "LYFT" : {
                "contract_size": "1",
                "leverage": "3",
                "margin_per": "30",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MARA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MRVL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MTCH" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NEE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NIO" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NOK" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NUAN" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "NVAX" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PINS" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PLTR" : {
                "contract_size": "1",
                "leverage": "2",
                "margin_per": "50",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PLUG" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "RIOT" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "SHOP" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "SNAP" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "SONY" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "SPLK" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TEVA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TM" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TRIP" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "U" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            // "UBER" : {
            //     "contract_size": "1",
            //     "leverage": "5",
            //     "margin_per": "20",
            //     "min_vol": "1",
            //     "max_vol": "1000"
            // },
            "VALE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "VIPS" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "WB" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "X" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            // "XPEV" : {
            //     "contract_size": "1",
            //     "leverage": "5",
            //     "margin_per": "20",
            //     "min_vol": "1",
            //     "max_vol": "1000"
            // },
            "YY" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ALPHA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "BELA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "EEEK" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ELLAKTOR" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ELPE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "ETE" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "EUROB" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "EYDAP" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "GEKTERNA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "HTO" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "LAMDA" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MOH" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "MYTIL" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "OPAP" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PMEZZ" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "PPC" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            },
            "TPEIR" : {
                "contract_size": "1",
                "leverage": "5",
                "margin_per": "20",
                "min_vol": "1",
                "max_vol": "1000"
            }
        },
        forex: {
            "AUDCAD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "AUDNZD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "AUDUSD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "AUDCHF" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "AUDJPY" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "CADCHF" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "CADJPY" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "CHFJPY" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "170",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURAUD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURCAD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURCHF" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURGBP" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURJPY" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURNOK" : {
                "contract_size": "1,00,000",
                "leverage": "150",
                "margin_per": "330",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURNZD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURSEK" : {
                "contract_size": "1,00,000",
                "leverage": "294",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "EURUSD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "GBPAUD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "GBPCAD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "GBPCHF" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "GBPJPY" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "GBPNZD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "GBPUSD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "NZDCAD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "NZDCHF" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "NZDJPY" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "NZDUSD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "USDCAD" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "USDCHF" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "USDCNH" : {
                "contract_size": "1,00,000",
                "leverage": "125",
                "margin_per": "400",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "USDJPY" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "USDMXN" : {
                "contract_size": "1,00,000",
                "leverage": "50",
                "margin_per": "1000",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "USDNOK" : {
                "contract_size": "1,00,000",
                "leverage": "150",
                "margin_per": "330",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "USDSEK" : {
                "contract_size": "1,00,000",
                "leverage": "294",
                "margin_per": "170",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "USDZAR" : {
                "contract_size": "1,00,000",
                "leverage": "125",
                "margin_per": "400",
                "min_vol": "0.01",
                "max_vol": "1,000"
            },
            "ZARJPY" : {
                "contract_size": "1,00,000",
                "leverage": "500",
                "margin_per": "100",
                "min_vol": "0.01",
                "max_vol": "1,000"
            } 
        }
    }
}