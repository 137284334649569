<template>
	<div id="main-wrapper" class="inner-pages depbonus-page refer-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('referAndEarnPage.heading')}}</h2>
				<p>{{$t('referAndEarnPage.headingDescription')}}</p>
			</div>
		</div>
		<div class="section bonus-trade">
			<div class="wrapper">
				<div class="content-holder wow fadeInLeft col">
					<div class="title-holder">
						<h3>{{$t('referAndEarnPage.startsWithSharing')}}</h3> <br>
						<p>{{$t('referAndEarnPage.startsWithSharingDescOne')}}
						<br><br>
						{{$t('referAndEarnPage.startsWithSharingDescTwo')}}
						<br><br>
						{{$t('referAndEarnPage.startsWithSharingDescThree')}}
						</p>
						<ul class="listing-check">
							<li>{{$t('referAndEarnPage.startsWithSharingDescThreePointOne')}}</li>
							<li>{{$t('referAndEarnPage.startsWithSharingDescThreePointTwo')}}</li>
							<li>{{$t('referAndEarnPage.startsWithSharingDescThreePointThree')}}</li>
							<li>{{$t('referAndEarnPage.startsWithSharingDescThreePointFour')}}</li>
						</ul>
					</div>
				</div>
				<div class="img-holder wow fadeInRight col t-center">
					<img src="assets/images/refer-img1.webp" alt="AAAFx" title="AAAFx" width="500" height="428" class="fluid-img" />
				</div>
			</div>
		</div>
		<div class="section avail-bonus">
			<div class="wrapper">
				<div class="title-holder">
					<h3>{{$t('referAndEarnPage.How_to_get_started')}}</h3>
				</div>
				<div class="listing">
					<div class="refer-holder col wow fadeIn">
						<div class="icon-holder">
							<img src="assets/images/refer-ico1.webp" alt="AAAFx" title="AAAFx" width="53" height="53" class="fluid-img" />
						</div>
						<div class="content-holder">
							<p>{{$t('referAndEarnPage.How_to_get_started_pointOne')}}</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer1.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.3s">
						<div class="icon-holder">
							<img src="assets/images/refer-ico2.webp" alt="AAAFx" title="AAAFx" width="53" height="53" class="fluid-img" />
						</div>
						<div class="content-holder">
							<p>{{$t('referAndEarnPage.How_to_get_started_pointTwo')}}</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer2.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.6s">
						<div class="icon-holder">
							<img src="assets/images/refer-ico3.webp" alt="AAAFx" title="AAAFx" width="53" height="53" class="fluid-img" />
						</div>
						<div class="content-holder">
							<p>{{$t('referAndEarnPage.How_to_get_started_pointThree')}}</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer3.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
				</div>
				<div class="section benefits-sec">
					<div class="title-holder">
						<h3>{{$t('referAndEarnPage.WhatDoYourReferrals')}}</h3>
						<p>{{$t('referAndEarnPage.WhatDoYourReferralsDescOne')}} <br>{{$t('referAndEarnPage.WhatDoYourReferralsDescTwo')}}</p>
					</div>
					<div class="benefits-holder col wow fadeInLeft">
						<h3>{{$t('referAndEarnPage.Benefits_to_you')}}:</h3>
						<ul class="listing-check listing-check2">
							<li>{{$t('referAndEarnPage.Benefits_to_you_pointOne')}}</li>
							<li>{{$t('referAndEarnPage.Benefits_to_you_pointTwo')}}</li>
							<li>{{$t('referAndEarnPage.Benefits_to_you_pointThree')}}</li>
							<li>{{$t('referAndEarnPage.Benefits_to_you_pointFour')}}</li>
							<li>{{$t('referAndEarnPage.Benefits_to_you_pointFive')}}</li>
						</ul>
					</div>
					<div class="benefits-holder bene-refer col wow fadeInRight">
							<h3>{{$t('referAndEarnPage.Benefits_to_your_referrals')}}:</h3>
							<ul class="listing-check">
								<li>{{$t('referAndEarnPage.Benefits_to_your_referrals_pointOne')}}</li>
								<li>{{$t('referAndEarnPage.Benefits_to_your_referrals_pointTwo')}}</li>
								<li>{{$t('referAndEarnPage.Benefits_to_your_referrals_pointThree')}}</li>
								<li>{{$t('referAndEarnPage.Benefits_to_your_referrals_pointFour')}}</li>
								<li>{{$t('referAndEarnPage.Benefits_to_your_referrals_pointFive')}}</li>
							</ul>
					</div>
				</div>
				<div class="section ib-program">
					<div class="title-holder wow fadeIn">
						<h3>
							{{$t('referAndEarnPage.How_is_AAAFx_Refer_and')}}
							<br>
							{{$t('referAndEarnPage.Earn_Plan_different_from_the_IB_Program')}}
						</h3> 
						<br>
						<p>{{$t('referAndEarnPage.How_is_AAAFx_Refer_desc')}}: -</p>
					</div>
					<div class="table-holder wow fadeIn">
						<table>
							<tr>
								<th>{{$t('referAndEarnPage.Feature')}}</th>
								<th>{{$t('referAndEarnPage.referandEarnPlan')}}</th>
								<th>{{$t('referAndEarnPage.IBProgram')}}</th>
							</tr>
							<tr>
								<td>{{$t('referAndEarnPage.Earnings')}}</td>
								<td>
									{{$t('referAndEarnPage.EarningsPointOneP1')}} <br>
									{{$t('referAndEarnPage.EarningsPointOneP2')}} <br>
									{{$t('referAndEarnPage.EarningsPointOneP3')}}</td>
								<td>{{$t('referAndEarnPage.EarningsPointTwop1')}} <br>{{$t('referAndEarnPage.EarningsPointTwop2')}} <br>{{$t('referAndEarnPage.EarningsPointTwop3')}}</td>
							</tr>
							<tr>
								<td>{{$t('referAndEarnPage.Earning_frequency')}}</td>
								<td>{{$t('referAndEarnPage.Earning_frequency_p1')}}</td>
								<td>{{$t('referAndEarnPage.Earning_frequency_p2')}}</td>
							</tr>
							<tr>
								<td>{{$t('referAndEarnPage.Live_tracking')}}</td>
								<td>{{$t('referAndEarnPage.Yes')}}</td>
								<td>{{$t('referAndEarnPage.Yes')}}</td>
							</tr>
							<tr>
								<td>{{$t('referAndEarnPage.View_of_referrals_activities')}}</td>
								<td>{{$t('referAndEarnPage.Limited')}}</td>
								<td>{{$t('referAndEarnPage.Full_fledged')}}</td>
							</tr>
							<tr>
								<td>{{$t('referAndEarnPage.SeparatePortal')}}</td>
								<td>{{$t('referAndEarnPage.No')}}</td>
								<td>{{$t('referAndEarnPage.Yes')}}</td>
							</tr>
							<tr>
								<td>{{$t('referAndEarnPage.Pay_out_frequency')}}</td>
								<td>{{$t('referAndEarnPage.Weekly')}}</td>
								<td>{{$t('referAndEarnPage.Weekly')}}</td>
							</tr>
						</table>
					</div>
					<div class="title-holder t-left wow fadeIn">
						<p>{{$t('referAndEarnPage.you_can_read_terms_and_condition')}} <a href="#"> {{$t('referAndEarnPage.here')}}</a></p>
					</div>
					<div class="btn-holder wow fadeIn">
						<a href="#" class="btn btn-yellow">{{$t('referAndEarnPage.capsStartRefering')}}</a>
					</div>
					<div class="title-holder wow fadeIn">
						<h5>{{$t('referAndEarnPage.readyToGoBeyondEarnPlan')}}</h5> <br>
						<p>{{$t('referAndEarnPage.signUpAsIBWithUs')}} <a href="#"> {{$t('referAndEarnPage.here')}}</a>.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>