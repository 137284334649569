<template>
  <div id="main-wrapper" class="inner-pages forex-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li class="active"><router-link to="/forex">{{$t('forexStock.innerPages.forex')}}</router-link></li>
            <li><router-link to="/indices">{{$t('forexStock.innerPages.indices')}}</router-link></li>
            <li><router-link to="/commodities">{{$t('forexStock.innerPages.commodities')}}</router-link></li>
            <li><router-link to="/stocks">{{$t('forexStock.innerPages.stocks')}}</router-link></li>
            <li><router-link to="/crypto">{{$t('forexStock.innerPages.crypto')}}</router-link></li>
          </ul>
        </div>
      </div>
      <div class="forex-map center-div">
        <img
          src="assets/images/forex-map.webp"
          alt="AAAFx"
          title="AAAFx"
          width="1351"
          height="448"
          class="fluid-img"
        />
      </div>
      <div
        class="title-holder t-white t-center center-div wow fadeIn"
        data-wow-delay="1.6s"
      >
        <h2>{{$t('forexStock.title')}}</h2>
        <p>{{$t('forexStock.trade')}}</p>
      </div>
    </div>
    <div class="section strip-details">
      <div class="wrapper2">
        <div class="strip-info wow fadeIn">
          <h3>{{$t('forexStock.min')}} <span>$0</span> {{$t('forexStock.commission')}}</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.3s">
          <h3>{{$t('forexStock.min')}} {{$t('forexStock.execution')}} <span>10ms</span></h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.6s">
          <h3>{{$t('forexStock.min')}} <span>0.0</span> {{$t('forexStock.spread')}}</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="0.9s">
          <h3><span>70+</span> {{$t('forexStock.paris')}}</h3>
        </div>
        <div class="strip-info wow fadeIn" data-wow-delay="1.2s">
          <h3>{{$t('forexStock.upTo')}} <span>30x</span> {{$t('forexStock.leverage')}}</h3>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper">
        <div class="title-holder t-left wow fadeIn">
          <h3>{{$t('forexStock.contract')}} <strong>{{$t('forexStock.specification')}}</strong></h3>
          <p>
            {{$t('forexStock.flexible')}}
            <a href="mailto:support@aaafx.com">support@aaafx.com</a>
          </p>
        </div>
        <div class="table-holder home-table wow fadeIn">
          <div class="search-holder">
            <input
              type="text"
              name=""
              value=""
              placeholder="Search Instrument"
            />
          </div>
          <table>
            <tr>
              <th>{{$t('forexStock.instument')}}</th>
              <th>{{$t('forexStock.bid')}}</th>
              <th>{{$t('forexStock.ask')}}</th>
              <th>{{$t('forexStock.spread')}}</th>
              <th class="">{{$t('forexStock.size')}}</th>
              <th class="">{{$t('forexStock.minTrade')}}</th>
              <th class="">{{$t('forexStock.tickValue')}}</th>
              <th class="">{{$t('forexStock.hours')}}</th>
            </tr>
            <tr
              v-for="(spreads, key, index) in get_currencies"
              :key="key"
              :index="index"
              v-show="index < page * limit && index >= (page - 1) * limit"
            >
              <td>
                {{ key }}
              </td>
              <td>
                <span
                  :class="[
                    {
                      't-green': spreads.buy_increase_staus == 1,
                      't-red': spreads.buy_increase_staus == 0,
                    },
                  ]"
                  >{{ spreads.B }}</span
                >
              </td>
              <td>
                <span
                  :class="[
                    {
                      't-green': spreads.sell_increase_staus == 1,
                      't-red': spreads.sell_increase_staus == 0,
                    },
                  ]"
                  >{{ spreads.A }}</span
                >
              </td>

              <td class="">{{ spreads.SPREAD }}</td>
              <td class="">{{ spreads.other.Contract_Size }}</td>
              <td class="">{{ spreads.other.Min_Trade_Size }}</td>
              <td class="">{{ spreads.other.Tick_Value }}</td>
              <td class="">7:00 am - 12:00pm</td>
            </tr>
          </table>
          <div class="clearfix links">
            <pagination
              :totalRecords="Object.keys(this.get_currencies).length"
              :limit="limit"
              :pageNo="page"
            />
          </div>
        </div>

        <div class="section-small">
          <div class="accordion-holder t-left">
            <h3 v-on:click="trading ^= true" :class="trading ? 'active' : ''">
              {{$t('forexStock.h3Tag')}}
            </h3>
            <div class="content" v-show="trading">
              <p>
                {{$t('forexStock.pTag')}}
              </p>
            </div>
            <h3 v-on:click="work ^= true" :class="work ? 'active' : ''">
              {{$t('forexStock.how')}}
            </h3>
            <div class="content" v-show="work">
              <p>
                {{$t('forexStock.trading')}}
              </p>
            </div>
          </div>
        </div>

        <div class="section account-types">
          <div class="title-holder t-left wow fadeIn">
            <h3>{{$t('forexStock.account')}} <strong>{{$t('forexStock.type')}}</strong></h3>
          </div>
          <div class="table-holder home-table acct-type wow fadeIn">
            <table>
              <tr>
                <th>&nbsp;</th>
                <th>ECN</th>
                <th>ECN <span>plus</span></th>
                <th>ECN <span>Zulu Trade</span></th>
              </tr>
              <tr>
                <td>Deposit ({{$t('forexStock.min')}})</td>
                <td>$100</td>
                <td>$10,000</td>
                <td>$100</td>
              </tr>
              <tr>
                <td>Commission (per $100k)</td>
                <td>$5</td>
                <td>$3</td>
                <td>$5</td>
              </tr>
              <tr>
                <td>{{$t('forexStock.spread')}} ({{$t('forexStock.min')}})</td>
                <td>0.0</td>
                <td>0.0</td>
                <td>0.0</td>
              </tr>
            </table>
            <div class="clearfix links">
              <router-link to="/account-types" target="_blank" class="t-right see-link f-right" >
                {{$t('forexStock.compare')}}
              </router-link>
            </div>
          </div>
        </div>

        <div class="section trading-plat">
          <div class="info-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>
                {{$t('forexStock.online')}} <strong>{{$t('forexStock.tradingText')}} <br />{{$t('forexStock.platform')}}</strong>
              </h3>
            </div>
            <div class="link-holder col">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/plat-ico1.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="69"
                  height="55"
                  class="fluid-img"
                />
                {{$t('forexStock.desktop')}}
              </a>
            </div>
            <div class="link-holder col">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/plat-ico2.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="69"
                  height="55"
                  class="fluid-img"
                />
                {{$t('forexStock.webTrade')}}
              </a>
            </div>
            <div class="link-holder col">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/plat-ico3.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="69"
                  height="55"
                  class="fluid-img"
                />
                {{$t('forexStock.android')}}
              </a>
            </div>
            <div class="link-holder col">
              <a href="javascript:void(0)">
                <img
                  src="assets/images/plat-ico4.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="69"
                  height="55"
                  class="fluid-img"
                />
                {{$t('forexStock.ios')}}
              </a>
            </div>
            <div class="btn-holder">
              <router-link to="/compare-platform" class="btn btn-bluey"
                > {{$t('forexStock.learn')}}</router-link
              >
            </div>
          </div>
          <div class="links-holder wow fadeInRight">
            <div class="link-holder col">
              <img
                src="assets/images/plat3.webp"
                alt="AAAFx"
                title="AAAFx"
                width="237"
                height="100"
                class="fluid-img"
              />
            </div>
          </div>
        </div>

        <div class="section nega-sec">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>{{$t('forexStock.negative')}} <strong>{{$t('forexStock.protection')}}</strong></h3>
              <p class="sub">
                {{$t('forexStock.pSubTag')}}
              </p>
              <br />
              <p>
                {{$t('forexStock.pTag1')}}
              </p>
              <div class="btn-holder">
                <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                  >{{$t('forexStock.live')}}</a
                >
                <a :href="siteurl2" target="_blank" class="btn btn-blue"
                  >{{$t('forexStock.demo')}}</a
                >
              </div>
            </div>
          </div>
          <div class="img-holder t-center wow fadeInRight">
            <img
              src="assets/images/nega-img.webp"
              alt="AAAFx"
              title="AAAFx"
              width="579"
              height="433"
              class="fluid-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";
import pagination from "@/components/shared/pagination.vue";
export default {
  name: "forex",
  data() {
    return {
      page: 1,
      limit: 8,
      trading: true,
      work: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
  components: {
    pagination: pagination,
  },
  computed: {
    get_currencies() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCurrencies();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return {};
      }
    },
  },
};
</script>
