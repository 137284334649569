<template>
  <div id="main-wrapper">
    <div class="section no-padding">
      <div class="banner-holder home-banner">
        <div class="video-bg">
          <video
            id="bannervid"
            autoplay
            muted
            loop
            poster="assets/images/video-poster.webp"
          >
            <source src="assets/video/map.mp4" type="video/mp4" />
            <source src="assets/video/map.ogg" type="video/ogg" />
            <source src="assets/video/map.webm" type="video/webm" />
          </video>
        </div>
        <div class="coverup"></div>
        <div class="wrapper2">
          <div class="content-holder middle-div">
            <h3>{{ $t("homePage.h3tag") }}</h3>
            <h1>{{ $t("homePage.h1tag") }}</h1>

            <div class="banner-winner">
              <div>
                <ul>
                  <li>
                    <strong>{{ $t("homePage.ul.lTag") }}</strong>
                    {{ $t("homePage.ul.strong") }}
                    <strong>{{ $t("homePage.ul.strong1") }}</strong>
                    {{ $t("homePage.ul.lTag1") }}
                  </li>
                  <li>
                    <b-button v-b-tooltip.hover title="professional customers">
                      {{ $t("homePage.ul.lTag2") }}
                      <strong
                        >{{ $t("homePage.ul.strong2") }}
                        <sup>{{ $t("homePage.ul.supTag") }}</sup></strong
                      >
                    </b-button>
                  </li>
                  <li>
                    <!-- <b>{{ $t("homePage.ul.bTag") }}</b> -->
                    {{ $t("homePage.ul.lTag3") }}
                  </li>
                  <li>
                    <b-button v-b-tooltip.hover title="professional clients">
                      <!-- <strong>{{ $t("homePage.ul.strong3") }}</strong> -->
                      {{ $t("homePage.ul.lTag4") }}
                    </b-button>
                  </li>
                </ul>
              </div>
              <div class="win-image">
                <img
                  class="lozad fluid-img"
                  width="120"
                  height="120"
                  src="assets/images/winner-new.webp"
                  alt="AAAFx"
                  title="UFAWARDS"
                />
              </div>
            </div>
            <div class="banner-btn btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow">{{
                $t("homePage.liveAccount")
              }}</a>
              <!-- <a :href="siteurl2" target="_blank" class="btn btn-blue">{{
                $t("homePage.demoAccount")
              }}</a> -->
            </div>
            <br />
            <!-- <img src="assets/images/helenic.webp" alt=""> -->
          </div>
          <div class="home-banner content-holder">
            <img
              class="sasd fluid-img"
              src="assets/images/helenic.png"
              alt="AAAFx"
              title="AAAFx"
              width="351"
              height="50"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section fx-pairs">
      <div class="links-holder tabs-forex wow fadeIn">
        <ul class="clearfix">
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'forex',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'forex',
              },
            ]"
          >
            <a href="#forex" @click.stop.prevent="showTab = 'forex'">
              {{ $t("homePage.tabs.forex") }}</a
            >
          </li>
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'indices',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'indices',
              },
            ]"
          >
            <a href="#indices" @click.stop.prevent="showTab = 'indices'">
              {{ $t("homePage.tabs.indices") }}</a
            >
          </li>
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'comm',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'comm',
              },
            ]"
          >
            <a href="#comm" @click.stop.prevent="showTab = 'comm'">
              {{ $t("homePage.tabs.commodities") }}</a
            >
          </li>
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'stocks',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'stocks',
              },
            ]"
          >
            <a href="#stocks" @click.stop.prevent="showTab = 'stocks'">
              {{ $t("homePage.tabs.stocks") }}</a
            >
          </li>
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'crypto',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'crypto',
              },
            ]"
          >
            <a href="#crypto" @click.stop.prevent="showTab = 'crypto'">
              {{ $t("homePage.tabs.cryptoCurrencies") }}</a
            >
          </li>
        </ul>

        <div id="forex" class="tab-holder" v-show="showTab == 'forex'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>{{ $t("homePage.forex.title") }}</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>{{ $t("homePage.forex.point1") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.forex.content") }}</h5>
                <p>{{ $t("homePage.forex.pTag") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.forex.point2") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.forex.h5Tag") }}</h5>
                <p>{{ $t("homePage.forex.pTag1") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.forex.point3") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.forex.h5Tag1") }}</h5>
                <p>{{ $t("homePage.forex.pTag2") }}</p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.forex.startTrading")
              }}</a>
              <!-- <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.forex.tryDemo")
              }}</a> -->
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>{{ $t("homePage.forex.tableRow.tableHeading.pair") }}</th>
                <!-- <th>Leverage</th> -->
                <th>{{ $t("homePage.forex.tableRow.tableHeading.bid") }}</th>
                <th>{{ $t("homePage.forex.tableRow.tableHeading.ask") }}</th>
                <th class="">
                  {{ $t("homePage.forex.tableRow.tableHeading.spread") }}
                </th>
                <th>{{ $t("homePage.forex.tableRow.tableHeading.trade") }}</th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_currencies"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" />  -->{{
                    spreads.other.mn
                  }}
                </td>
                <!--  <td><span class="lev">1-30X</span></td> -->
                <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td> -->

                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td>
                  <router-link to="/web-trader" class="btn btn-blue">{{
                    $t("homePage.forex.buySell")
                  }}</router-link>
                </td>
              </tr>
            </table>
            <router-link to="/forex" class="t-right see-link">
              {{ $t("homePage.forex.SeeAll") }}</router-link
            >
          </div>
        </div>
        <div id="indices" class="tab-holder" v-show="showTab == 'indices'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>{{ $t("homePage.indices.title") }}</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>{{ $t("homePage.indices.point1") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.indices.content") }}</h5>
                <p>{{ $t("homePage.indices.pTag") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.indices.point2") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.indices.h5Tag") }}</h5>
                <p>
                  {{ $t("homePage.indices.pTag1") }}
                </p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.indices.point3") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.indices.h5Tag1") }}</h5>
                <p>{{ $t("homePage.indices.pTag2") }}</p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.indices.startTrading")
              }}</a>
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.indices.tryDemo")
              }}</a>
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>{{ $t("homePage.indices.tableRow.tableHeading.pair") }}</th>
                <!-- <th>Leverage</th> -->
                <th>{{ $t("homePage.indices.tableRow.tableHeading.bid") }}</th>
                <th>{{ $t("homePage.indices.tableRow.tableHeading.ask") }}</th>
                <th class="">
                  {{ $t("homePage.indices.tableRow.tableHeading.spread") }}
                </th>
                <th>
                  {{ $t("homePage.indices.tableRow.tableHeading.trade") }}
                </th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_indices"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" />  -->{{
                    spreads.other.mn
                  }}
                </td>
                <!-- <td><span class="lev">1-30X</span></td> -->
                <!-- <td>
                  <span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus < 0 }]">{{spreads.B}}</span>
                </td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus < 0 }]">{{spreads.A}}</span></td>-->
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td>
                  <router-link to="/web-trader" class="btn btn-blue">{{
                    $t("homePage.indices.buySell")
                  }}</router-link>
                </td>
              </tr>
              <!-- <tr>
                <td><img src="assets/images/bitcoin-ico.webp" alt="" /> BTC/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>65913.14</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/ada-ico.webp" alt="" /> ADA/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>2.07</td>
                <td><span class="t-green">+243%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/eth-ico.webp" alt="" /> ETH/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>4780</td>
                <td><span class="t-green">+3.97%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/solana-ico.webp" alt="" /> SOL/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>248.193</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr> -->
            </table>
            <router-link to="/indices" class="t-right see-link">
              {{ $t("homePage.indices.SeeAll") }}</router-link
            >
          </div>
        </div>
        <div id="comm" class="tab-holder" v-show="showTab == 'comm'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>{{ $t("homePage.commodities.title") }}</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>{{ $t("homePage.commodities.point1") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.commodities.content") }}</h5>
                <p>{{ $t("homePage.commodities.pTag") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.commodities.point2") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.commodities.h5Tag") }}</h5>
                <p>{{ $t("homePage.commodities.pTag1") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.commodities.point3") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.commodities.h5Tag1") }}</h5>
                <p>{{ $t("homePage.commodities.pTag2") }}</p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.commodities.startTrading")
              }}</a>
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.commodities.tryDemo")
              }}</a>
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>
                  {{ $t("homePage.commodities.tableRow.tableHeading.pair") }}
                </th>
                <!-- <th>Leverage</th> -->
                <th>
                  {{ $t("homePage.commodities.tableRow.tableHeading.bid") }}
                </th>
                <th>
                  {{ $t("homePage.commodities.tableRow.tableHeading.ask") }}
                </th>
                <th>
                  {{ $t("homePage.commodities.tableRow.tableHeading.spread") }}
                </th>
                <th>
                  {{ $t("homePage.commodities.tableRow.tableHeading.trade") }}
                </th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_Commodity"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" />  -->{{
                    spreads.other.mn
                  }}
                </td>
                <!-- <td><span class="lev">1-30X</span></td> -->

                <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td> -->

                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td>
                  <router-link to="/web-trader" class="btn btn-blue">{{
                    $t("homePage.commodities.buySell")
                  }}</router-link>
                </td>
              </tr>
              <!-- <tr>
                <td><img src="assets/images/bitcoin-ico.webp" alt="" /> BTC/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>65913.14</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/ada-ico.webp" alt="" /> ADA/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>2.07</td>
                <td><span class="t-green">+243%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/eth-ico.webp" alt="" /> ETH/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>4780</td>
                <td><span class="t-green">+3.97%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/solana-ico.webp" alt="" /> SOL/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>248.193</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr> -->
            </table>
            <router-link to="/commodities" class="t-right see-link">
              {{ $t("homePage.commodities.SeeAll") }}</router-link
            >
          </div>
        </div>
        <div id="stocks" class="tab-holder" v-show="showTab == 'stocks'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>{{ $t("homePage.stocks.title") }}</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>{{ $t("homePage.stocks.point1") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.stocks.content") }}</h5>
                <p>{{ $t("homePage.stocks.pTag") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.stocks.point2") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.stocks.h5Tag") }}</h5>
                <p>{{ $t("homePage.stocks.pTag1") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.stocks.point3") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.stocks.h5Tag1") }}</h5>
                <p>
                  {{ $t("homePage.stocks.pTag2") }}
                </p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.stocks.startTrading")
              }}</a>
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.stocks.tryDemo")
              }}</a>
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>{{ $t("homePage.stocks.tableRow.tableHeading.pair") }}</th>
                <!-- <th>Leverage</th> -->
                <th>{{ $t("homePage.stocks.tableRow.tableHeading.bid") }}</th>
                <th>{{ $t("homePage.stocks.tableRow.tableHeading.ask") }}</th>
                <th class="">
                  {{ $t("homePage.stocks.tableRow.tableHeading.spread") }}
                </th>
                <th>{{ $t("homePage.stocks.tableRow.tableHeading.trade") }}</th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_Stocks"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" /> -->
                  {{ spreads.other.mn }}
                </td>
                <!-- <td><span class="lev">1-30X</span></td> -->

                <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td>   -->

                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td>
                  <router-link to="/web-trader" class="btn btn-blue">{{
                    $t("homePage.stocks.buySell")
                  }}</router-link>
                </td>
              </tr>
              <!-- <tr>
                <td><img src="assets/images/bitcoin-ico.webp" alt="" /> BTC/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>65913.14</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/ada-ico.webp" alt="" /> ADA/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>2.07</td>
                <td><span class="t-green">+243%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/eth-ico.webp" alt="" /> ETH/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>4780</td>
                <td><span class="t-green">+3.97%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/solana-ico.webp" alt="" /> SOL/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>248.193</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr> -->
            </table>
            <router-link to="/stocks" class="t-right see-link">
              {{ $t("homePage.stocks.SeeAll") }}</router-link
            >
          </div>
        </div>
        <div id="crypto" class="tab-holder" v-show="showTab == 'crypto'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>{{ $t("homePage.crypto.title") }}</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>{{ $t("homePage.crypto.point1") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.crypto.content") }}</h5>
                <p>{{ $t("homePage.crypto.pTag") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.crypto.point2") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.crypto.h5Tag") }}</h5>
                <p>{{ $t("homePage.crypto.pTag1") }}</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>{{ $t("homePage.crypto.point3") }}</h3>
              </div>
              <div class="content colu">
                <h5>{{ $t("homePage.crypto.h5Tag1") }}</h5>
                <p>
                  {{ $t("homePage.crypto.pTag2") }}
                </p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.crypto.startTrading")
              }}</a>
              <a :href="siteurl2" target="_blank" class="btn btn-lblue">{{
                $t("homePage.crypto.tryDemo")
              }}</a>
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>{{ $t("homePage.crypto.tableRow.tableHeading.pair") }}</th>
                <!-- <th>Leverage</th> -->
                <th>{{ $t("homePage.crypto.tableRow.tableHeading.bid") }}</th>
                <th>{{ $t("homePage.crypto.tableRow.tableHeading.ask") }}</th>
                <th>
                  {{ $t("homePage.crypto.tableRow.tableHeading.spread") }}
                </th>
                <th>{{ $t("homePage.crypto.tableRow.tableHeading.trade") }}</th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_Crypto"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" /> -->
                  {{ spreads.other.mn }}
                </td>
                <!-- <td><span class="lev">1-30X</span></td> -->
                <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td> -->

                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td>
                  <router-link to="/web-trader" class="btn btn-blue">{{
                    $t("homePage.crypto.buySell")
                  }}</router-link>
                </td>
              </tr>
              <!-- <tr>
                <td><img src="assets/images/bitcoin-ico.webp" alt="" /> BTC/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>65913.14</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/ada-ico.webp" alt="" /> ADA/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>2.07</td>
                <td><span class="t-green">+243%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/eth-ico.webp" alt="" /> ETH/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>4780</td>
                <td><span class="t-green">+3.97%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/solana-ico.webp" alt="" /> SOL/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>248.193</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr> -->
            </table>
            <router-link to="/crypto" class="t-right see-link">
              {{ $t("homePage.crypto.SeeAll") }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="winnerLTC">
      <div class="wrapper2 t-center">
        <img src="assets/images/winner.webp" alt="Winner" title="UFAWARDS" />
      </div>
    </div> -->

    <div class="section why-join">
      <div class="wrapper2">
        <div class="title-holder wow fadeIn">
          <h3>
            {{ $t("homePage.youShouldJoin") }}
            <strong>{{ $t("homePage.aaafx") }}</strong>
          </h3>
        </div>
        <div
          class="join-container wow fadeIn"
          ontouchstart="this.classList.toggle('hover');"
        >
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img
                  src="assets/images/join-ico1.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="35"
                  height="35"
                  class="fluid-img"
                />
              </div>
              <div class="content">
                <h3>{{ $t("homePage.content.h3Tag") }}</h3>
                <hr />
                <ul>
                  <li>{{ $t("homePage.content.ul.liTag") }}</li>
                  <li>{{ $t("homePage.content.ul.liTag1") }}</li>
                  <li>{{ $t("homePage.content.ul.liTag2") }}</li>
                  <li>{{ $t("homePage.content.ul.liTag3") }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img
                  src="assets/images/join-ico2.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  width="35"
                  height="35"
                  class="fluid-img"
                />
              </div>
              <div class="content">
                <h3>{{ $t("homePage.content1.h3Tag") }}</h3>
                <hr />
                <ul>
                  <li>{{ $t("homePage.content1.ul.liTag") }}</li>
                  <li>{{ $t("homePage.content1.ul.liTag1") }}</li>
                  <li>{{ $t("homePage.content1.ul.liTag2") }}</li>
                  <li>{{ $t("homePage.content1.ul.liTag3") }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img
                  src="assets/images/join-ico3.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  class="fluid-img"
                  width="35"
                  height="35"
                />
              </div>
              <div class="content">
                <h3>{{ $t("homePage.content2.h3Tag") }}</h3>
                <hr />
                <ul>
                  <li>{{ $t("homePage.content2.ul.liTag") }}</li>
                  <li>{{ $t("homePage.content2.ul.liTag1") }}</li>
                  <li>{{ $t("homePage.content2.ul.liTag2") }}</li>
                  <li>{{ $t("homePage.content2.ul.liTag3") }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img
                  src="assets/images/join-ico4.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  class="fluid-img"
                  width="35"
                  height="35"
                />
              </div>
              <div class="content">
                <h3>{{ $t("homePage.content3.h3Tag") }}</h3>
                <hr />
                <ul>
                  <li>{{ $t("homePage.content3.ul.liTag") }}</li>
                  <li>{{ $t("homePage.content3.ul.liTag1") }}</li>
                  <li>{{ $t("homePage.content3.ul.liTag2") }}</li>
                  <li>{{ $t("homePage.content3.ul.liTag3") }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img
                  src="assets/images/join-ico5.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  class="fluid-img"
                  width="35"
                  height="35"
                />
              </div>
              <div class="content">
                <h3>{{ $t("homePage.content4.h3Tag") }}</h3>
                <hr />
                <ul>
                  <li>{{ $t("homePage.content4.ul.liTag") }}</li>
                  <li>{{ $t("homePage.content4.ul.liTag1") }}</li>
                  <li>{{ $t("homePage.content4.ul.liTag2") }}</li>
                  <li>{{ $t("homePage.content4.ul.liTag3") }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img
                  src="assets/images/join-ico6.webp"
                  alt="AAAFx"
                  title="AAAFx"
                  class="fluid-img"
                  width="35"
                  height="35"
                />
              </div>
              <div class="content">
                <h3>{{ $t("homePage.content5.h3Tag") }}</h3>
                <hr />
                <ul>
                  <li>{{ $t("homePage.content5.ul.liTag") }}</li>
                  <li>{{ $t("homePage.content5.ul.liTag1") }}</li>
                  <li>{{ $t("homePage.content5.ul.liTag2") }}</li>
                  <li>{{ $t("homePage.content5.ul.liTag3") }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section zero-comm">
      <div class="wrapper2">
        <div class="content-holder img-cont wow fadeInLeft">
          <div class="title-holder">
            <h3>
              <strong>{{ $t("homePage.rawSpread") }} </strong><br />
              {{ $t("homePage.plusAccount") }}
            </h3>
          </div>
          <div class="content">
            <p>
              {{ $t("homePage.pTag") }}
              <a href="javascript:void(0)">{{ $t("homePage.clickHere") }}</a>
              <br /><br />
              {{ $t("homePage.brTag") }}
            </p>
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow">{{
                $t("homePage.liveAccount")
              }}</a>
              <!-- <a :href="siteurl2" target="_blank" class="btn btn-blue">{{
                $t("homePage.demoAccount")
              }}</a> -->
            </div>
          </div>
        </div>
        <div class="chart-container wow fadeInRight">
          <div class="chart-holder wow fadeIn" data-wow-delay="0.3s">
            <div class="title">
              <h3>{{ $t("homePage.ecn") }}</h3>
            </div>
            <div class="chart-row">
              <h3>$5</h3>
              <p>{{ $t("homePage.commission") }} / $100K</p>
            </div>
            <div class="chart-row">
              <h3>0.0<span>pips</span></h3>
              <p>{{ $t("homePage.minSpread") }}</p>
            </div>
            <div class="chart-row">
              <h3>$100</h3>
              <p>{{ $t("homePage.minDeposit") }}</p>
            </div>
          </div>
          <div class="chart-holder wow fadeIn" data-wow-delay="0.6s">
            <div class="title">
              <h3>
                {{ $t("homePage.ecn") }} <span>{{ $t("homePage.plus") }}</span>
              </h3>
            </div>
            <div class="chart-row">
              <h3>$3</h3>
              <p>{{ $t("homePage.forexCommission") }}</p>
            </div>
            <div class="chart-row">
              <h3>
                0.0<span>{{ $t("homePage.pips") }}</span>
              </h3>
              <p>{{ $t("homePage.minSpread") }}</p>
            </div>
            <div class="chart-row">
              <h3>$10,000</h3>
              <p>{{ $t("homePage.minDeposit") }}</p>
            </div>
          </div>
          <router-link
            to="/account-types"
            class="wow fadeIn"
            data-wow-delay="0.9s"
            target="_blank"
            >{{ $t("homePage.compare") }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="section zero-comm dep-bonus">
      <div class="wrapper2">
        <div class="img-holder img-cont wow fadeInLeft">
          <img
            src="assets/images/trust-img.webp"
            alt="AAAFx"
            title="AAAFx"
            class="fluid-img"
            width="607"
            height="566"
          />
        </div>
        <div class="content-holder wow fadeInRight">
          <div class="title-holder">
            <h3>
              <strong>{{ $t("homePage.experience") }} </strong>
            </h3>
          </div>
          <div class="content">
            <p class="italic">{{ $t("homePage.security") }}</p>
            <br />
            <p>
              {{ $t("homePage.strongValue") }} <br /><br />
              {{ $t("homePage.ourClient") }}
              <br /><br />
              {{ $t("homePage.success") }}
            </p>
            <br />
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow">{{
                $t("homePage.live")
              }}</a>
              <!-- <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >{{$t('homePage.demo')}}</a
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section zero-comm zulutrade">
      <div class="wrapper2">
        <div class="content-holder wow fadeInLeft">
          <div class="title-holder">
            <h3>
              <strong>{{ $t("homePage.zuluTrade") }}</strong> -
              {{ $t("homePage.treasure") }}
            </h3>
          </div>
          <div class="content">
            <p class="italic">{{ $t("homePage.copyTrade") }}</p>
            <br />
            <p>
              <span>{{ $t("homePage.socialTrading") }}</span>
              <span title="Risk Disclosure"> 67%* </span>
              <span
                >{{ $t("homePage.investor") }} <br /><br />
                {{ $t("homePage.topTrader") }}</span
              >
            </p>

            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow">{{
                $t("homePage.live")
              }}</a>
              <!-- <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >{{$t('homePage.demo')}}</a
              > -->
            </div>
          </div>
        </div>
        <div class="img-holder img-cont wow fadeInRight">
          <img
            src="assets/images/zulu.webp"
            class="fluid-img"
            alt="AAAFx"
            title="AAAFx"
            width="620"
            height="521"
          />
          <img
            src="assets/images/zulu1.webp"
            alt="AAAFx"
            class="pop1 wow fadeInLeft fluid-img"
            data-wow-delay="0.6s"
            title="AAAFx"
            width="221"
            height="277"
          />
          <img
            src="assets/images/zulu2.webp"
            alt="AAAFx"
            class="pop2 wow fadeInRight fluid-img"
            data-wow-delay="0.6s"
            title="AAAFx"
            width="218"
            height="277"
          />
        </div>
      </div>
    </div>
    <div class="section trading-arsenal">
      <div class="wrapper2">
        <div class="title-holder t-white wow fadeIn">
          <h3>
            {{ $t("homePage.yourTrading") }}
            <strong>{{ $t("homePage.arsenal") }}</strong>
          </h3>
        </div>
        <div class="arsenal-cont wow fadeIn" data-wow-delay=".3s">
          <div class="arsenal-holder">
            <h3>{{ $t("homePage.actTrader") }}</h3>
            <p>{{ $t("homePage.mostAdvance") }}</p>
            <div class="img-holder">
              <img
                src="assets/images/act-img.webp"
                alt="AAAFx"
                title="ActTrader"
                width="407"
                height="221"
                class="fluid-img"
              />
            </div>
            <a v-if="noDataActTrader" href="javascript:void(0);">{{
              $t("homePage.comming")
            }}</a>
            <a
              v-if="
                static_vars.platformLinks.actTrader.desktop !=
                'javascript:void(0);'
              "
              :href="static_vars.platformLinks.actTrader.desktop"
              >{{ $t("homePage.desktop") }}</a
            >
            <a
              v-if="
                static_vars.platformLinks.actTrader.ios != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.actTrader.ios"
              >{{ $t("homePage.ios") }}</a
            >
            <a
              v-if="
                static_vars.platformLinks.actTrader.android !=
                'javascript:void(0);'
              "
              :href="static_vars.platformLinks.actTrader.android"
              >{{ $t("homePage.android") }}</a
            >
            <a
              v-if="
                static_vars.platformLinks.actTrader.web != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.actTrader.web"
              >{{ $t("homePage.web") }}</a
            >
          </div>
          <div class="arsenal-holder">
            <h3>{{ $t("homePage.mt4") }}</h3>
            <p>{{ $t("homePage.popularPlatform") }}</p>
            <div class="img-holder">
              <img
                src="assets/images/meta-img.webp"
                alt="AAAFx"
                title="MetaTrader 4"
                width="428"
                height="232"
                class="fluid-img"
              />
            </div>
            <a v-if="noDataMT4" href="javascript:void(0);">{{
              $t("homePage.comming")
            }}</a>
            <a
              v-if="
                static_vars.platformLinks.mt4.desktop != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.mt4.desktop"
              >{{ $t("homePage.desktop") }}</a
            >
            <a
              v-if="static_vars.platformLinks.mt4.ios != 'javascript:void(0);'"
              :href="static_vars.platformLinks.mt4.ios"
              >{{ $t("homePage.ios") }}</a
            >
            <a
              v-if="
                static_vars.platformLinks.mt4.android != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.mt4.android"
              >{{ $t("homePage.android") }}</a
            >
            <a
              v-if="static_vars.platformLinks.mt4.web != 'javascript:void(0);'"
              :href="static_vars.platformLinks.mt4.web"
              >{{ $t("homePage.web") }}</a
            >
          </div>
          <div class="arsenal-holder">
            <h3>{{ $t("homePage.mt5") }}</h3>
            <p>{{ $t("homePage.nextGen") }}</p>
            <div class="img-holder">
              <img
                src="assets/images/mt5-new.webp"
                class="fluid-img"
                alt="AAAFx"
                title="MetaTrader 5"
                width="440"
                height="238"
              />
            </div>
            <a v-if="noDataMT5" href="javascript:void(0);">{{
              $t("homePage.comming")
            }}</a>
            <a
              v-if="
                static_vars.platformLinks.mt5.desktop != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.mt5.desktop"
              >{{ $t("homePage.desktop") }}</a
            >
            <a
              v-if="static_vars.platformLinks.mt5.ios != 'javascript:void(0);'"
              :href="static_vars.platformLinks.mt5.ios"
              >{{ $t("homePage.ios") }}</a
            >
            <a
              v-if="
                static_vars.platformLinks.mt5.android != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.mt5.android"
              >{{ $t("homePage.android") }}</a
            >
            <a
              v-if="static_vars.platformLinks.mt5.web != 'javascript:void(0);'"
              :href="static_vars.platformLinks.mt5.web"
              >{{ $t("homePage.web") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="section reg-steps">
      <div class="wrapper2">
        <div class="title-holder t-white wow fadeIn">
          <h3>{{ $t("homePage.firstTrade") }}</h3>
        </div>
        <div class="listing-holder t-center">
          <div class="steps-holder wow fadeInLeft" data-wow-delay=".2s">
            <div class="img-holder">
              <img
                src="assets/images/reg-ico1.webp"
                alt="AAAFx"
                title="AAAFx"
                width="48"
                height="44"
                class="fluid-img"
              />
            </div>
            <div class="content">
              <h3>{{ $t("homePage.register") }}</h3>
              <ul>
                <li>{{ $t("homePage.min") }}</li>
              </ul>
            </div>
          </div>
          <div class="steps-holder wow fadeInLeft" data-wow-delay=".4s">
            <div class="img-holder">
              <img
                src="assets/images/reg-ico2.webp"
                alt="AAAFx"
                title="AAAFx"
                width="48"
                height="44"
                class="fluid-img"
              />
            </div>
            <div class="content">
              <h3>{{ $t("homePage.kyc") }}</h3>
              <ul>
                <li>{{ $t("homePage.photo") }}</li>
                <li>{{ $t("homePage.addressProof") }}</li>
                <!-- <li>{{ $t("homePage.withIn") }}</li> -->
              </ul>
            </div>
          </div>
          <div class="steps-holder wow fadeInLeft" data-wow-delay=".6s">
            <div class="img-holder">
              <img
                src="assets/images/reg-ico3.webp"
                alt="AAAFx"
                title="AAAFx"
                width="48"
                height="44"
                class="fluid-img"
              />
            </div>
            <div class="content">
              <h3>{{ $t("homePage.deposit") }}</h3>
              <ul>
                <li>{{ $t("homePage.instantly") }}</li>
                <li>{{ $t("homePage.method") }}</li>
              </ul>
            </div>
          </div>
          <div class="steps-holder wow fadeInLeft" data-wow-delay=".8s">
            <div class="img-holder">
              <img
                src="assets/images/reg-ico4.webp"
                class="fluid-img"
                alt="AAAFx"
                title="AAAFx"
                width="48"
                height="44"
              />
            </div>
            <div class="content">
              <h3>{{ $t("homePage.trade") }}</h3>
              <ul>
                <li>{{ $t("homePage.range") }}</li>
                <li>{{ $t("homePage.spread") }}</li>
                <li>{{ $t("homePage.ultraLow") }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="btn-holder t-center wow fadeIn">
          <a :href="siteurl2" target="_blank" class="btn btn-green">{{
            $t("homePage.create")
          }}</a>
        </div>
      </div>
    </div>
    <div class="section payments-sec">
      <div class="wrapper2">
        <img
          src="assets/images/payment1.webp"
          alt="AAAFx"
          title="AAAFx"
          width="68"
          height="22"
          class="fluid-img"
        />
        <img
          src="assets/images/payment2.webp"
          alt="AAAFx"
          title="AAAFx"
          width="55"
          height="33"
          class="fluid-img"
        />
        <img
          src="assets/images/payment3.webp"
          alt="AAAFx"
          title="AAAFx"
          width="75"
          height="28"
          class="fluid-img"
        />
        <img
          src="assets/images/payment4.webp"
          alt="AAAFx"
          title="AAAFx"
          width="74"
          height="31"
          class="fluid-img"
        />
        <img
          src="assets/images/payment5.webp"
          alt="AAAFx"
          title="AAAFx"
          width="85"
          height="36"
          class="fluid-img"
        />
        <img
          src="assets/images/payment6.webp"
          alt="AAAFx"
          title="AAAFx"
          width="64"
          height="45"
          class="fluid-img"
        />
        <img
          src="assets/images/payment7.webp"
          alt="AAAFx"
          title="AAAFx"
          width="108"
          height="30"
          class="fluid-img"
        />
        <img
          src="assets/images/payment8.webp"
          alt="AAAFx"
          title="AAAFx"
          width="144"
          height="35"
          class="fluid-img"
        />
        <img
          src="assets/images/payment9.webp"
          alt="AAAFx"
          title="AAAFx"
          width="73"
          height="25"
          class="fluid-img"
        />
        <img
          src="assets/images/payment10.webp"
          alt="AAAFx"
          title="AAAFx"
          width="105"
          height="18"
          class="fluid-img"
        />
        <img
          src="assets/images/payment11.webp"
          alt="AAAFx"
          title="AAAFx"
          width="99"
          height="34"
          class="fluid-img"
        />
        <img
          src="assets/images/payment12.webp"
          alt="AAAFx"
          title="AAAFx"
          width="66"
          height="29"
          class="fluid-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";
export default {
  name: "Home",
  data() {
    return {
      showTab: "forex",
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
  methods: {},
  computed: {
    noDataMT4() {
      let flag = true,
        type = "mt4";
      console.log(type);
      for (let key in this.static_vars.platformLinks[type]) {
        if (
          this.static_vars.platformLinks[type][key] != "javascript:void(0);"
        ) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    noDataActTrader() {
      let flag = true,
        type = "actTrader";
      console.log(type);
      for (let key in this.static_vars.platformLinks[type]) {
        if (
          this.static_vars.platformLinks[type][key] != "javascript:void(0);"
        ) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    noDataMT5() {
      let flag = true,
        type = "mt5";
      console.log(type);
      for (let key in this.static_vars.platformLinks[type]) {
        if (
          this.static_vars.platformLinks[type][key] != "javascript:void(0);"
        ) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    get_currencies() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCurrencies();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
    get_indices() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getIndicesAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
    get_Commodity() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCommodityAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
    get_Stocks() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getStocks();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
    get_Crypto() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCryptosAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
  },
};
</script>
