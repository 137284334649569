<template>
  <div id="main-wrapper" class="inner-pages why-page legal-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{ $t("legalDocument.title") }}</h2>
        <p>{{ $t("legalDocument.heading") }}</p>
      </div>
    </div>
    <div class="section why-bel">
      <div class="wrapper">
        <div class="title-holder t-center wow fadeIn">
          <h5>
            {{ $t("legalDocument.recommend") }}<br />{{
              $t("legalDocument.tradingJourney")
            }}
          </h5>
        </div>
      </div>
    </div>
    <div class="section docu-list t-center wow fadeIn">
      <div class="wrapper">
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.policy") }}</h3>
            <a target="_blank" href="assets/docs/Privacy Policy.pdf">{{
              $t("legalDocument.view")
            }}</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.risk") }}</h3>
            <a target="_blank" href="assets/docs/Risk Disclaimer.pdf">{{
              $t("legalDocument.view")
            }}</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.clientAgreement") }}</h3>
            <a target="_blank" href="assets/docs/Retail Client Agreement.pdf">{{
              $t("legalDocument.view")
            }}</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.refundPolicy") }}</h3>
            <a target="_blank" href="assets/docs/Refund Policy.pdf">{{
              $t("legalDocument.view")
            }}</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.exexutionPolicy") }}</h3>
            <a target="_blank" href="assets/docs/Execution Policy.pdf">{{
              $t("legalDocument.view")
            }}</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.conflicts") }}</h3>
            <a
              target="_blank"
              href="assets/docs/Conflicts Of Interest Policy.pdf"
              >{{ $t("legalDocument.view") }}</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.clientAsset") }}</h3>
            <a target="_blank" href="assets/docs/Client Assets.pdf">{{
              $t("legalDocument.view")
            }}</a>
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.investor") }}</h3>
            <a
              target="_blank"
              href="assets/docs/Investor Compensation Scheme.pdf"
              >{{ $t("legalDocument.view") }}</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.h3Tag") }}</h3>
            <a
              target="_blank"
              href="assets/docs/Key_Information_Document_CFD_COMMODITIES.pdf"
              >{{ $t("legalDocument.view") }}</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.h3Tag1") }}</h3>
            <a
              target="_blank"
              href="assets/docs/Key_Information_Document_CFD_INDICES.pdf"
              >{{ $t("legalDocument.view") }}</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.h3Tag2") }}</h3>
            <a
              target="_blank"
              href="assets/docs/Key_Information_Document_CFD_SHARES.pdf"
              >{{ $t("legalDocument.view") }}</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.h3Tag3") }}</h3>
            <a
              target="_blank"
              href="assets/docs/Key_Information_Document_CFD_FOREX.pdf"
              >{{ $t("legalDocument.view") }}</a
            >
          </div>
        </div>
        <div class="docu-holder">
          <div class="icon-holder">
            <img
              src="assets/images/pdf-ico.webp"
              alt="AAAFx"
              title="AAAFx"
              width="45"
              height="49"
              class="fluid-img"
            />
          </div>
          <div class="details">
            <h3>{{ $t("legalDocument.h3Tag4") }}</h3>
            <a
              target="_blank"
              href="assets/docs/MiFID ΙΙ Directive Basics.pdf"
              >{{ $t("legalDocument.view") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
