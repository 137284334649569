<template>
  <div id="main-wrapper" class="inner-pages faq-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{ $t("faq.heading") }}</h2>
      </div>
    </div>
    <div class="section faq-sec">
      <div class="wrapper">
        <div id="faq-container" class="faq-container">
          <div class="highlight-note t-left">
            <div v-for="(value, key) in allFaqs" :key="key">
              <div class="title-holder">
                <h3 class="t-center">{{ key }}</h3>
              </div>
              <div id="section1" class="accordion-holder">
                <div v-for="(faq, index) in value" :key="faq.label">
                  <h3
                    @click="() => toggleClass(key, index)"
                    :class="{
                      active:
                        currentItem.key == key && currentItem.index == index,
                    }"
                  >
                    {{ faq.label }}
                  </h3>
                  <transition name="slide">
                    <div
                      v-show="
                        currentItem.key == key && currentItem.index == index
                      "
                      v-html="faq.content"
                    ></div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import faqLists from "../../public/assets/locales/en";
export default {
  data() {
    return {
      currentItem: {
        key: "Opening an Account",
        index: 0,
      },
      faqs: {},
      allFaqs:[]
    };
  },
  methods: {
    toggleClass(key, index) {
      if (this.currentItem.key === key && this.currentItem.index === index) {
        this.currentItem = {
          key: null,
          index: null,
        };
      } else {
        this.currentItem = {
          key,
          index,
        };
      }
    },
    loadFaqs() {
      try {
        this.faqs = require(`../../public/assets/locales/${this.$i18n.locale}.js`);
        this.allFaqs= this.faqs.default.faqs
      } catch (error) {
        console.log("Error loading FAQ file:", error);
      }
    },
  },
  watch: {
    "$i18n.locale": {
      immediate: true,
      handler() {
        this.loadFaqs();
      },
    },
  },
  created() {
    this.loadFaqs();
  },
};
</script>
