<template>
  <div id="main-wrapper" class="inner-pages forex-page pamm-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li>
              <router-link to="/white-label">{{
                $t("pamm.whiteLabel")
              }}</router-link>
            </li>
            <li class="active">
              <router-link to="/pamm">{{ $t("pamm.title") }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{ $t("pamm.programm") }}</h2>
        <p>{{ $t("pamm.heading") }}</p>
      </div>
    </div>
    <div class="pamm-content section">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <p>{{ $t("pamm.pTag") }}</p>
        </div>
        <div class="main-img-holder t-center wow fadeIn">
          <img
            src="assets/images/pamm-img1.webp"
            alt="AAAFx"
            title="AAAFx"
            width="1117"
            height="144"
            class="fluid-img"
          />
        </div>
        <div class="content-holder wow fadeInLeft">
          <div class="title-holder">
            <h3>{{ $t("pamm.account") }}</h3>
            <br />
            <p>{{ $t("pamm.pTag1") }}</p>
          </div>
        </div>
        <div class="img-holder wow fadeInRight">
          <img
            src="assets/images/pamm-img2.webp"
            alt="AAAFx"
            title="AAAFx"
            width="620"
            height="513"
            class="fluid-img"
          />
        </div>
      </div>
    </div>
    <div class="pamm-zulu section">
      <div class="wrapper">
        <div class="title-holder">
          <h3>{{ $t("pamm.combined") }}</h3>
          <br />
          <p>{{ $t("pamm.pTag2") }}</p>
        </div>
        <div class="img-holder t-center">
          <img
            src="assets/images/pamm-img3.webp"
            alt="AAAFx"
            title="AAAFx"
            width="843"
            height="175"
            class="fluid-img"
          />
        </div>

        <div class="content-holder pamm-man wow fadeInLeft">
          <div class="title-holder">
            <h3>{{ $t("pamm.advantage") }}</h3>
          </div>
          <div class="icon-holder">
            <img
              src="assets/images/pamm-ico1.webp"
              alt="AAAFx"
              title="AAAFx"
              width="42"
              height="136"
              class="fluid-img"
            />
          </div>
          <div class="text-holder">
            <p>{{ $t("pamm.pTag3") }}</p>
          </div>
        </div>
        <div class="content-holder pamm-investor wow fadeInRight">
          <div class="title-holder">
            <h3>{{ $t("pamm.investor") }}</h3>
          </div>
          <div class="icon-holder">
            <img
              src="assets/images/pamm-ico2.webp"
              alt="AAAFx"
              title="AAAFx"
              width="91"
              height="125"
              class="fluid-img"
            />
          </div>
          <div class="text-holder">
            <p>{{ $t("pamm.pTag4") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section t-left">
      <div class="wrapper accordion-holder">
        <h3 v-on:click="pamm ^= true" :class="[{ active: pamm }]">
          {{ $t("pamm.aaafxProgramm") }}
        </h3>
        <div class="content" v-show="pamm">
          <ul class="listing-check">
            <li>{{ $t("pamm.liTag") }}</li>
            <li>{{ $t("pamm.liTag1") }}</li>
            <li>{{ $t("pamm.liTag2") }}</li>
            <li>{{ $t("pamm.liTag3") }}</li>
            <li>{{ $t("pamm.liTag4") }}</li>
            <li>{{ $t("pamm.liTag5") }}</li>
            <li>{{ $t("pamm.liTag6") }}</li>
            <li>{{ $t("pamm.liTag7") }}</li>
          </ul>
        </div>
        <h3
          v-on:click="requirements1 ^= true"
          :class="[{ active: requirements1 }]"
        >
          {{ $t("pamm.minimum") }}
        </h3>
        <div class="content" v-show="requirements1">
          <p></p>
          <ul class="listing-check">
            <li>{{ $t("pamm.liTag") }}</li>
            <li>{{ $t("pamm.subAccount") }}</li>
            <li>
              {{ $t("pamm.eachInvestor") }}
            </li>
          </ul>
        </div>
        <h3
          v-on:click="requirements2 ^= true"
          :class="[{ active: requirements2 }]"
        >
          {{ $t("pamm.howTo") }}
        </h3>
        <div class="content" v-show="requirements2">
          <ul class="listing-check">
            <li>{{ $t("pamm.portal") }}</li>
            <li>{{ $t("pamm.build") }}</li>
            <li>{{ $t("pamm.sign") }}</li>
            <li>{{ $t("pamm.earning") }}</li>
          </ul>
        </div>
        <div class="section-small">
          <div class="title-holder">
            <h6>
              {{ $t("pamm.titleHeading") }} <br />{{ $t("pamm.contact") }}
              <a href="mailto:support@aaafx.com">{{ $t("pamm.email") }}</a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "forex",
  data() {
    return {
      pamm: false,
      requirements1: false,
      requirements2: false,
    };
  },
};
</script>
