<template>
  <div id="main-wrapper" class="inner-pages tradcalc-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{$t('tradingCalculator.title')}}</h2>
        <p>{{$t('tradingCalculator.madeEasy')}}</p>
      </div>
    </div>
    <div class="section bonus-trade trading-calc">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h5>{{$t('tradingCalculator.neverMiss')}}</h5>
          <br />
          <p>
            {{$t('tradingCalculator.pTag')}}
          </p>
          <div class="btn-holder">
            <a
              @click="calculatorType = 'Swap'"
              class="btn btn-llblue"
              :class="{ activeButton: calculatorType == 'Swap' }"
              ><img src="assets/images/calc-ico.webp" alt="AAAFx" title="AAAFx" width="30" height="30" class="fluid-img" />{{$t('tradingCalculator.swap')}}
            </a>
            <a
              @click="calculatorType = 'Margin'"
              class="btn btn-llblue"
              :class="{ activeButton: calculatorType == 'Margin' }"
              ><img src="assets/images/calc-ico.webp" alt="AAAFx" title="AAAFx" width="30" height="30" class="fluid-img" />{{$t('tradingCalculator.margin')}}
            </a>
            <a
              @click="calculatorType = 'Pip'"
              class="btn btn-llblue"
              :class="{ activeButton: calculatorType == 'Pip' }"
              ><img src="assets/images/calc-ico.webp" alt="AAAFx" title="AAAFx" width="30" height="30" class="fluid-img" />{{$t('tradingCalculator.pip')}}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section forex-calc">
      <div class="wrapper">
        <div class="title-holder">
          <h3>{{ calculatorType }} {{$t('tradingCalculator.calculate')}}</h3>
        </div>
        <div class="form-holder calcu-holder">
          <swap v-if="calculatorType == 'Swap'" />
          <margin v-if="calculatorType == 'Margin'" />
          <pip v-if="calculatorType == 'Pip'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swap from "@/components/calculators/Swap";
import Margin from "@/components/calculators/Margin";
import Pip from "@/components/calculators/Pip";

export default {
  components: {
    Swap,
    Margin,
    Pip,
  },
  data() {
    return {
      calculatorType: "Swap",
    };
  },
};
</script>
