<template>
  <div class="lang-dropdown" :class="lang_hideShow ? 'lang-dropdown-show' : ''">
    <a
      class="nav-link app-language"
      href="javascript:void(0)"
      @click="lang_hideShow ^= true"
      :title="flagList[lang].value"
    >
      <div class="flag-dropdown">
        <div class="toggle-button">
          <img
            class="selected-flag"
            :src="`assets/images/languages/${lang}.svg`"
            alt="en"
            width="25"
          />
        </div>
        <img class="drop" src="assets/images/dropdown-1.png" alt="" />
      </div>
    </a>

    <ul class="flag-list">
      <li
        v-for="(item, key) in flagList"
        :key="item.id"
        :data-value="item.lang"
        @click="changeLocale(key, item)"
        :title="item.value"
      >
        <img
          :src="`assets/images/languages/${key}.svg`"
          :alt="item.lang"
          width="25"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "Login",
  data() {
    return {
      lang_hideShow: false,
      lang: "en",
      flagList: {
        en: { lang: "English", value: "English" },
        it: { lang: "Italian", value: "Italiano" },
        de: { lang: "German", value: "German" },
        sv: { lang: "Swedish", value: "Swedish" },
        pl: { lang: "Polish", value: "Polish" },
      },
      availableLang: ["en", "it", "de", "sv", "pl"],
    };
  },
  methods: {
    changeLocale(key) {
      this.$i18n.locale = key;
      this.lang = key;
      localStorage.setItem("lang", key);
    },
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      let lang = localStorage.getItem("lang");
      if (this.flagList[lang]) {
        this.$i18n.locale = lang;
        this.lang = lang;
      }
    } else if (navigator?.language || navigator?.userLanguage) {
      let lang = (navigator.language || navigator.userLanguage)
        ?.split("-")[0]
        ?.toLowerCase();
      let isAvailableLang = this.availableLang.includes(lang);
      lang = isAvailableLang ? lang : "en";
      this.lang = lang;
      this.$i18n.locale = lang;
    }

    const _that = this;
    $(document).on("click", function (event) {
      if (!$(event.target).closest(".app-language").length) {
        _that.lang_hideShow = false;
      }
    });
  },
};
</script>
