<template>
	<div id="main-wrapper" class="inner-pages forex-page mt4-page">
		<div class="section banner-holder">
			<div class="forex-nav">
				<div class="wrapper">
					<ul>
						<li> <router-link to="/mt4">{{$t('zuluTrade.mt4')}}</router-link> </li>
						<li class="active"> <router-link to="/zulu">{{$t('zuluTrade.zulutrade')}}</router-link> </li>
					</ul>
				</div>
			</div>

			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('zuluTrade.zulutrade')}}</h2>
				<div class="img-holder">
					<img src="assets/images/zulu-img1.webp" alt="AAAFx" title="AAAFx" width="745" height="404" class="fluid-img" />
				</div>
			</div>
		</div>
		<div class="section content-mt4 content-zulu t-left">
			<div class="wrapper">
				<div class="title-holder">
					<h5>{{$t('zuluTrade.h5Tag')}}</h5>
					<br>
					<p>{{$t('zuluTrade.pTag')}}
					<br><br>
					{{$t('zuluTrade.brTag')}}
					</p>
				</div>
				<div class="listing t-center">
					<div class="title-holder">
							<h5>{{$t('zuluTrade.startTrading')}}</h5>
					</div>
					<div class="step-zulu wow fadeIn"> 
						<img src="assets/images/step1.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						<p>{{$t('zuluTrade.openFund')}}<br>
							{{$t('zuluTrade.ecnPlus')}}</p>
					</div>
					<div class="step-zulu wow fadeIn" data-wow-delay="0.3s">
						<img src="assets/images/step2.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						<p>{{$t('zuluTrade.variousTraders')}} </p>
					</div>
					<div class="step-zulu wow fadeIn" data-wow-delay="0.6s">
						<img src="assets/images/step3.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						<p>{{$t('zuluTrade.copy')}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section forex-content">
			<div class="wrapper2">
				<div class="section">
					<div class="content-sec mt4-phone zulu-web">
						<div class="img-holder wow fadeInLeft">
							<img src="assets/images/zulu-img3.webp" alt="AAAFx" title="AAAFx" width="600" height="388" class="fluid-img" />
						</div>
						<div class="content-holder wow fadeInRight">
							<img src="assets/images/zulu-ico1.webp" alt="AAAFx" title="AAAFx" width="418" height="128" class="fluid-img" />
							<br><br>
							<p>{{$t('zuluTrade.pTag1')}}
							<br><br>
							{{$t('zuluTrade.ztp')}}</p>
							<div class="btn-holder wow fadeIn">
								<a href="#" class="btn btn-grey"> <img src="assets/images/desk-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" /> {{$t('zuluTrade.webBase')}}</a>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div class="section content-mt4">
			<div class="wrapper">
				<div class="title-holder">
					<h3>{{$t('zuluTrade.h3Tag')}}</h3>
					<br>
				</div>
				<div class="img-holder t-center">
					<img src="assets/images/zulu-img4.webp" alt="AAAFx" width="1128" height="288" class="fluid-img" />
				</div>
			</div>
		</div>
	</div>
</template>