<template>
  <div id="main-wrapper" class="inner-pages why-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{ $t("why.title") }}</h2>
        <p>{{ $t("why.honesty") }}</p>
      </div>
    </div>
    <div class="section why-bel">
      <div class="wrapper">
        <div class="content-sec wow fadeIn">
          <div class="title-holder t-center">
            <h3>{{ $t("why.believe") }}</h3>
          </div>
          <p>
            {{ $t("why.pTag") }} <br /><br />
            {{ $t("why.brTag") }}
          </p>
          <br />
          <ul class="listing">
            <li>{{ $t("why.listing.point") }}</li>
            <li>{{ $t("why.listing.point1") }}</li>
            <li>{{ $t("why.listing.point2") }}</li>
            <li>{{ $t("why.listing.point3") }}</li>
            <li>{{ $t("why.listing.point4") }}</li>
            <li>{{ $t("why.listing.point5") }}</li>
            <li>{{ $t("why.listing.point6") }}</li>
            <li>{{ $t("why.listing.point7") }}</li>
          </ul>
          <br />
          <h5>{{ $t("why.more") }}</h5>
          <ul class="half-width">
            <li>{{ $t("why.socialTrading") }}</li>
            <li>{{ $t("why.entireTeam") }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section why-bel pledge-sec">
      <div class="wrapper clearfix">
        <div class="half-width f-right wow fadeInRight">
          <div class="title-holder t-left">
            <h3>{{ $t("why.pledge") }}</h3>
          </div>
          <ul class="full-width listing">
            <li>
              {{ $t("why.liTag") }}
            </li>
            <li>
              {{ $t("why.liTag1") }}
            </li>
            <li>
              {{ $t("why.liTag2") }}
            </li>
            <li>{{ $t("why.liTag3") }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section founded-sec">
      <div class="wrapper2">
        <div class="founded-holder wow fadeIn">
          <h4>{{ $t("why.founded") }}</h4>
          <h3>{{ $t("why.number.twoThousand") }}</h3>
        </div>
        <div class="founded-holder wow fadeIn" data-wow-delay="0.3s">
          <h4>{{ $t("why.serve") }}</h4>
          <h3>{{ $t("why.number.thirty") }}</h3>
        </div>
        <div class="founded-holder wow fadeIn" data-wow-delay="0.6s">
          <h4>{{ $t("why.trade") }}</h4>
          <h3>{{ $t("why.number.twoHundred") }}</h3>
        </div>
        <div class="founded-holder wow fadeIn" data-wow-delay="0.9s">
          <h4>{{ $t("why.platform") }}</h4>
          <h3>{{ $t("why.number.three") }}</h3>
        </div>
        <div class="founded-holder wow fadeIn" data-wow-delay="1.2s">
          <h4>{{ $t("why.support") }}</h4>
          <h3>{{ $t("why.number.twentyFour") }}</h3>
        </div>
      </div>
    </div>
    <div class="section our-team">
      <div class="wrapper2">
        <div class="title-holder wow fadeIn">
          <h3>{{ $t("why.team") }}</h3>
        </div>
        <div class="content-holder t-center wow fadeIn">
          <p class="italic">
            {{ $t("why.pTag1") }}
          </p>
          <br />
          <p>
            {{ $t("why.pTag2") }}
          </p>
        </div>
        <div class="team-listing">
          <div class="team-holder wow fadeIn">
            <div class="img-holder">
              <img
                src="assets/images/team1.webp"
                alt="AAAFx"
                width="242"
                height="242"
                class="fluid-img"
              />
            </div>
            <p>
              {{ $t("why.wallStreet") }}
            </p>
          </div>
          <div class="team-holder wow fadeIn" data-wow-delay="0.3s">
            <div class="img-holder">
              <img
                src="assets/images/team2.webp"
                alt="AAAFx"
                title="AAAFx"
                width="242"
                height="242"
                class="fluid-img"
              />
            </div>
            <p>{{ $t("why.innovation") }}</p>
          </div>
          <div class="team-holder wow fadeIn" data-wow-delay="0.6s">
            <div class="img-holder">
              <img
                src="assets/images/team3.webp"
                alt="AAAFx"
                title="AAAFx"
                width="242"
                height="242"
                class="fluid-img"
              />
            </div>
            <p>
              {{ $t("why.approach") }}<br />
              {{ $t("why.weDo") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section our-team">
      <div class="wrapper2">
        <div class="title-holder wow fadeIn">
          <h3>{{ $t("why.values") }}</h3>
        </div>
        <div class="content-holder t-center wow fadeIn">
          <p>
            {{ $t("why.pTag3") }}
          </p>
          <br />
          <p class="italic">
            {{ $t("why.pTag4") }}
          </p>
        </div>
        <div class="img-holder t-center wow fadeIn" data-wow-delay="0.6s">
          <img src="assets/images/core-value.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="section happy-clients">
      <div class="wrapper">
        <div class="title-holder t-white t-center wow fadeIn">
          <h3>
            {{ $t("why.h3Tag") }}
            <strong>{{ $t("why.countries") }}</strong
            >{{ $t("why.taking") }}<br />
            {{ $t("why.worldClass") }}
          </h3>
          <p>{{ $t("why.france") }}</p>
        </div>

        <div class="img-holder">
          <img
            src="assets/images/happy-map.webp"
            alt="AAAFx"
            title="AAAFx"
            width="968"
            height="581"
            class="wow fadeIn fluid-img"
          />
          <img
            src="assets/images/happy-logo.webp"
            alt="AAAFx"
            title="AAAFx"
            width="542"
            height="105"
            class="center-div wow fadeIn fluid-img"
            data-wow-delay="0.8s"
          />
        </div>
      </div>
    </div>
    <div class="section why-bel">
      <div class="wrapper">
        <div class="content-sec wow fadeIn">
          <div class="containerFluid">
            <h5>
              <a
                href="assets/Regulatory_PDF/AAAFxCertifiedEmployees.el.pdf"
                target="_blank"
                >{{ $t("why.info") }}</a
              >
            </h5>
            <button
              class="accordion"
              @click="showTab == 1 ? (showTab = 0) : (showTab = 1)"
              :class="[{ 'is-open': showTab == 1 }]"
            >
              {{ $t("why.data") }}
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content" v-show="showTab == 1">
              <ul v-show="showTab == 1" class="regulatory">
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2024.pdf"
                    target="_blank"
                    >{{ $t("why.date") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ΟΙΚΟΝΟΜΙΚΕΣ_ΚΑΤΑΣΤΑΣΕΙΣ_31-12-2023.pdf"
                    target="_blank"
                    >{{ $t("why.thirtyFirst") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2023.pdf"
                    target="_blank"
                    >{{ $t("why.thirty") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/FINANCIAL STATEMENTS 12-31-2022.pdf"
                    target="_blank"
                    >{{ $t("why.financeStatement") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2022.pdf"
                    target="_blank"
                    >{{ $t("why.financialData") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2021.pdf"
                    target="_blank"
                    >{{ $t("why.statement") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2021A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2020.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2020Α.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag1") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2019.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag2") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2019A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag3") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2018.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag4") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2018A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag5") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2017.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag6") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2017A.pdf?3"
                    target="_blank"
                    >{{ $t("why.aTag.liTag7") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2016.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag8") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2016A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag9") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2015.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li1") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2015A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li2") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2014.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li3") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2014A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li4") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2013.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li5") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2013A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li6") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2012.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li7") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2012A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li8") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2011.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.li9") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2011A.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag10") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2010.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag11") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2015.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag12") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2014.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag13") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2013.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag14") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2012.pdf?2"
                    target="_blank"
                    >{{ $t("why.aTag.liTag15") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2011.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag16") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2010.pdf"
                    target="_blank"
                    >{{ $t("why.aTag.liTag17") }}</a
                  >
                </li>
              </ul>
            </div>
            <button
              class="accordion"
              @click="showTab == 2 ? (showTab = 0) : (showTab = 2)"
              :class="[{ 'is-open': showTab == 2 }]"
            >
              {{ $t("why.dropdown.title") }}
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content">
              <ul v-show="showTab == 2" class="regulatory">
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/PillarIIIDisclosuresTripleA2023.pdf"
                    target="_blank"
                  >
                    {{ $t("why.dropdown.aTag2.pillar2023") }}
                  </a>
                </li>

                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/PillarIIIDisclosuresTripleA2022.pdf"
                    target="_blank"
                  >
                    {{ $t("why.dropdown.aTag2.pillar2022") }}
                  </a>
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/PillarIIIDisclosuresTripleA2021.pdf"
                    target="_blank"
                  >
                    {{ $t("why.dropdown.aTag2.pillar2021") }}
                  </a>
                </li>

                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2023_α80&87_Ν4261_2014.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.regulatory2023") }}
                  </a>
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/CORPORATE GOVERNANCE AND REMUNERATION.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.corporateGovernance2022") }}
                  </a>
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2021.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2021") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2020.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2020") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2019.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2019") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2018.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2018") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2017.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2017") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2016.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2016") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2015_c.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2015") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2014.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2014") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2013.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2013") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2012.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2012") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2011.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2011") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2010.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.financial2010") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2020.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.details2020") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2019.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.details2019") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2018.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.details2018") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2017.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.details2017") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2016.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.details2016") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2015.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.details2015") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2014b.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.details2014b") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2014.pdf"
                    target="_blank"
                    >{{ $t("why.dropdown.aTag2.details2014") }}</a
                  >
                </li>
              </ul>
            </div>
            <button
              class="accordion"
              @click="showTab == 3 ? (showTab = 0) : (showTab = 3)"
              :class="[{ 'is-open': showTab == 3 }]"
            >
              {{ $t("why.announcment") }}
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content">
              <ul v-show="showTab == 3" class="regulatory">
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/AAAFx_Execution_Venues_2023.pdf"
                    target="_blank"
                    >{{ $t("why.aTag3.executionVenues2023") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/AAAFx_Execution_Venues_2022.pdf"
                    target="_blank"
                    >{{ $t("why.aTag3.executionVenues2022") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/AAAFx_Execution_Venues_2021.pdf"
                    target="_blank"
                    >{{ $t("why.aTag3.executionVenues2021") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/top_venues_2020.pdf"
                    target="_blank"
                    >{{ $t("why.aTag3.executionVenues2020") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/top_venues_2019.pdf"
                    target="_blank"
                    >{{ $t("why.aTag3.executionVenues2019") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/top_venues_2018.pdf"
                    target="_blank"
                    >{{ $t("why.aTag3.executionVenues2018") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/top_venues_2017.pdf"
                    target="_blank"
                    >{{ $t("why.aTag3.executionVenues2017") }}</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/meeting_28062013.pdf"
                    target="_blank"
                    >{{ $t("why.aTag3.generalMeetingInvite2013") }}</a
                  >
                </li>
              </ul>
            </div>
            <button
              class="accordion"
              @click="showTab == 4 ? (showTab = 0) : (showTab = 4)"
              :class="[{ 'is-open': showTab == 4 }]"
            >
              {{ $t("why.accordion") }}
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content" v-show="showTab == 4">
              <div class="d-flex justify-content-center">
                <h3>{{ $t("why.officialOrganization") }}</h3>
              </div>
              <img
                src="assets/images/COMPANY_ORGANISATION_CHART.jpeg"
                alt="COMPANY ORGANISATION CHART"
                height="500"
                class="fluid-img"
                style="width: 100%; padding: 10px"
              />
            </div>
            <button
              class="accordion"
              @click="showTab == 5 ? (showTab = 0) : (showTab = 5)"
              :class="[{ 'is-open': showTab == 5 }]"
            >
              {{ $t("why.certifiedEmploy.title") }}
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content">
              <div v-show="showTab == 5" class="regulatory">
                <div class="table-holder wow table-responsive nowrap-td">
                  <table>
                    <tr>
                      <th>{{ $t("why.name") }}</th>
                      <th>{{ $t("why.cretificate") }}</th>
                      <th>{{ $t("why.issuer") }}</th>
                      <th>{{ $t("why.year") }}</th>
                    </tr>

                    <tr>
                      <td colspan="4" class="p-0">
                        <table class="subtable">
                          <tr>
                            <td width="25%" rowspan="2">
                              {{ $t("why.table.td") }}
                            </td>
                            <td width="25%">
                              {{ $t("why.table.td1") }}
                            </td>
                            <td width="25%">{{ $t("why.table.td2") }}</td>
                            <td width="25%">2021</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td width="25%">{{$t('why.table.td0')}}</td>
                      <td width="25%">
                        {{$t('why.table.td3')}}
                      </td>
                      <td width="25%">{{$t('why.table.td2')}}</td>
                      <td width="25%">2022</td>
                    </tr>
                    <tr>
                      <td width="25%">{{$t('why.table.td4')}}</td>
                      <td width="25%">{{$t('why.table.td5')}}</td>
                      <td width="25%">{{$t('why.table.td2')}}</td>
                      <td width="25%">2022</td>
                    </tr>
                    <tr>
                      <td width="25%">{{ $t("why.table.td6") }}</td>
                      <td width="25%">
                        {{$t('why.table.td7')}}
                      </td>
                      <td width="25%">{{ $t("why.table.td2") }}</td>
                      <td width="25%">2023</td>
                    </tr>
                    <tr>
                      <td width="25%">{{ $t("why.table.td0") }}</td>
                      <td width="25%">
                        {{ $t("why.table.td3") }}
                      </td>
                      <td width="25%">{{ $t("why.table.td2") }}</td>
                      <td width="25%">2022</td>
                    </tr>

                    <tr>
                      <td width="25%">{{ $t("why.table.td12") }}</td>
                      <td width="25%">
                        {{ $t("why.table.td13") }}
                      </td>
                      <td width="25%">{{ $t("why.table.td2") }}</td>
                      <td width="25%">2022</td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <table class="subtable">
                          <tr>
                            <td width="25%" rowspan="2">
                              {{ $t("why.table.td8") }}
                            </td>
                            <td width="25%">
                              {{ $t("why.table.td9") }}
                            </td>
                            <td width="25%">{{ $t("why.table.td2") }}</td>
                            <td width="25%">2022</td>
                          </tr>
                          <tr>
                            <td width="25%">{{ $t("why.table.td10") }}</td>
                            <td width="25%">{{ $t("why.table.td11") }}</td>
                            <td width="25%">1999</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td width="25%">{{ $t("why.maria") }}</td>
                      <td width="25%">
                        {{ $t("why.investment") }}
                      </td>
                      <td width="25%">{{ $t("why.table.td2") }}</td>
                      <td width="25%">2022</td>
                    </tr>
                    <tr>
                      <td width="25%">{{ $t("why.text") }}</td>
                      <td width="25%">{{ $t("why.text1") }}</td>
                      <td width="25%"></td>
                      <td width="25%">25.4.2024</td>
                    </tr>
                    <tr>
                      <td width="25%">{{ $t("why.text2") }}</td>
                      <td width="25%">{{ $t("why.text1") }}</td>
                      <td width="25%"></td>
                      <td width="25%">25.4.2024</td>
                    </tr>
                  </table>
                </div>
                <div class="table-holder wow table-responsive nowrap-td">
                  <table>
                    <tr>
                      <th>{{ $t("why.responsive.trTag.line") }}</th>
                      <th>{{ $t("why.responsive.trTag.line1") }}</th>
                      <th>{{ $t("why.responsive.trTag.line2") }}</th>
                      <th>{{ $t("why.responsive.trTag.line3") }}</th>
                    </tr>
                    <tr>
                      <td>{{ $t("why.responsive.trTag.line4") }}</td>
                      <td>
                        {{ $t("why.responsive.trTag.line5") }}
                      </td>
                      <td>{{ $t("why.responsive.trTag.line6") }}</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td>{{ $t("why.responsive.trTag.line7") }}</td>
                      <td>{{ $t("why.responsive.trTag.line8") }}</td>
                      <td>{{ $t("why.responsive.trTag.line9") }}</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>{{ $t("why.responsive.trTag.line10") }}</td>
                      <td>
                        {{ $t("why.responsive.trTag.line5") }}
                      </td>
                      <td>{{ $t("why.responsive.trTag.line9") }}</td>
                      <td>2023</td>
                    </tr>
                    <tr>
                      <td>{{ $t("why.responsive.trTag.line11") }}</td>
                      <td>
                        {{ $t("why.responsive.trTag.line12") }}
                      </td>
                      <td>{{ $t("why.responsive.trTag.line9") }}</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>{{ $t("why.responsive.trTag.line13") }}</td>
                      <td>{{ $t("why.responsive.trTag.line14") }}</td>
                      <td>{{ $t("why.responsive.trTag.line9") }}</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>{{ $t("why.responsive.trTag.line20") }}</td>
                      <td>{{ $t("why.responsive.trTag.line21") }}</td>
                      <td>1999</td>
                    </tr>
                    <tr>
                      <td>{{ $t("why.responsive.trTag.line22") }}</td>
                      <td>{{ $t("why.responsive.trTag.line14") }}</td>
                      <td>{{ $t("why.responsive.trTag.line9") }}</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>{{ $t("why.responsive.trTag.line15") }}</td>
                      <td>{{ $t("why.responsive.trTag.line16") }}</td>
                      <td></td>
                      <td>25.4.2024</td>
                    </tr>
                    <tr>
                      <td>{{ $t("why.responsive.trTag.line23") }}</td>
                      <td>{{ $t("why.responsive.trTag.line16") }}</td>
                      <td></td>
                      <td>25.4.2024</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <button
              class="accordion"
              @click="showTab == 6 ? (showTab = 0) : (showTab = 6)"
              :class="[{ 'is-open': showTab == 6 }]"
            >
              {{ $t("why.responsive.trTag.line17") }}
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content">
              <div v-show="showTab == 6" class="regulatory">
                <ul class="regulatory">
                  <li>
                    <a
                      href="assets/Regulatory_PDF/Sfdr/Sustainability-Regulation-Disclosures-(SFDR).pdf"
                      target="_blank"
                      >{{ $t("why.responsive.trTag.line18") }}</a
                    >
                  </li>
                  <li>
                    <a
                      href="assets/Regulatory_PDF/Sfdr/Γνωστοποιήσεις-Κανονισμού-Αειφορίας-(SFDR)-και-Πολιτική-Βιωσιμότητας-(ESG).pdf"
                      target="_blank"
                      >{{ $t("why.responsive.trTag.line19") }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showTab: 0,
    };
  },
};
</script>
