<template>
  <div id="navbar" class="headerSection" :class="active ? 'fixed-header' : ''">
    <div class="top-header">
      <div class="wrapper2 clearfix">
        <div class="left-section f-left">
          <ul>
            <li>{{ $t("webtrader") }}:</li>
            <li>
              <router-link to="/mt4">{{
                $t("platforms.submenu.mt4.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/mt5">{{
                $t("platforms.submenu.mt5.title")
              }}</router-link>
            </li>
            <li>
              <router-link to="/act-trader">{{
                $t("platforms.submenu.act.name")
              }}</router-link>
            </li>
            <li>
              <router-link to="/zulu">{{
                $t("tools.submenu.zulu.title")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="right-section f-right">
          <ul>
            <li>
              <a :href="siteurl" target="_blank">
                <img src="assets/images/login-ico.webp" alt="" />
                {{ $t("login") }}
              </a>
            </li>
            <li>
              <router-link to="/contact">
                <img src="assets/images/phone-ico-b.webp" alt="" />
                {{ $t("contact") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom-header clearfix">
      <div class="wrapper2">
        <div class="logo-holder f-left">
          <router-link to="/"
            ><img src="assets/images/logo.webp" alt="AAAFx"
          /></router-link>
        </div>
        <span @click="showClass = !showClass" class="f-right mobile-nav hide"
          ><i class="fas fa-bars"></i
        ></span>
        <div class="mobile-menu" :class="showClass ? 'show' : ''">
          <ul class="login f-right">
            <li class="nav-item">
              <LanguageDropdown />
            </li>
            <li>
              <a :href="siteurl2" target="_blank" class="btn btn-yellow-b">{{
                $t("joinNow")
              }}</a>
            </li>
          </ul>
          <div class="navigation tabs f-right">
            <div class="wrapper2">
              <ul class="f-left nav-links" id="tabs-nav">
                <li class="">
                  <a href="#about">{{ $t("aboutAAAfx.name") }}</a>
                </li>
                <li class="">
                  <a href="#asset">{{ $t("trading.name") }}</a>
                </li>
                <li class="">
                  <a href="#acct">{{ $t("platforms.name") }}</a>
                </li>
                <li class="">
                  <a href="#plat">{{ $t("promotions.name") }}</a>
                </li>
                <li class="">
                  <a href="#prom">{{ $t("tools.name") }}</a>
                </li>
              </ul>
            </div>
            <div class="clearfix"></div>
            <div id="tabs-content" class="submenu-holder">
              <div class="wrapper2">
                <div id="about" class="submenu about tab-content">
                  <ul>
                    <li>
                      <router-link to="/why"
                        >{{ $t("aboutAAAfx.submenu.why.title") }}
                        <span>{{
                          $t("aboutAAAfx.submenu.why.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/safety-funds"
                        >{{ $t("aboutAAAfx.submenu.safety.title") }}
                        <span>{{
                          $t("aboutAAAfx.submenu.safety.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/our-servers"
                        >{{ $t("aboutAAAfx.submenu.servers.title") }}
                        <span>{{
                          $t("aboutAAAfx.submenu.servers.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/regulations"
                        >{{ $t("aboutAAAfx.submenu.regulations.title") }}
                        <span>{{
                          $t("aboutAAAfx.submenu.regulations.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/legal-documents"
                        >{{ $t("aboutAAAfx.submenu.legalDocs.title") }}
                        <span>
                          {{ $t("aboutAAAfx.submenu.legalDocs.tagline") }}</span
                        ></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/career"
                        >{{ $t("aboutAAAfx.submenu.careers.title") }}
                        <span>
                          {{ $t("aboutAAAfx.submenu.careers.tagline") }}</span
                        ></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contact"
                        >{{ $t("aboutAAAfx.submenu.contactUs.title")
                        }}<span>
                          {{ $t("aboutAAAfx.submenu.contactUs.tagline") }}</span
                        ></router-link
                      >
                    </li>
                  </ul>
                </div>
                <div id="asset" class="submenu about tab-content">
                  <ul>
                    <li>
                      <router-link to="/forex"
                        >{{ $t("trading.submenu.products.title")
                        }}<span>{{
                          $t("trading.submenu.products.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/account-types"
                        >{{ $t("trading.submenu.accountTypes.title")
                        }}<span>{{
                          $t("trading.submenu.accountTypes.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/trading-condition"
                        >{{ $t("trading.submenu.tradingConditions.title")
                        }}<span>{{
                          $t("trading.submenu.tradingConditions.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/dep-with"
                        >{{ $t("trading.submenu.depWithdrawal.title")
                        }}<span>{{
                          $t("trading.submenu.depWithdrawal.tagline")
                        }}</span></router-link
                      >
                    </li>
                  </ul>
                </div>
                <div id="acct" class="submenu about tab-content">
                  <ul>
                    <li>
                      <router-link to="/mt4"
                        >{{ $t("platforms.submenu.mt4.title") }}
                        <span>{{
                          $t("platforms.submenu.mt4.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/zulu"
                        >ZuluTrade
                        <span>State of the Art Technology </span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/compare-platform"
                        >{{ $t("platforms.submenu.comparison.title") }}
                        <span>{{
                          $t("platforms.submenu.comparison.tagline")
                        }}</span></router-link
                      >
                    </li>
                  </ul>
                </div>
                <div id="plat" class="submenu about tab-content">
                  <ul>
                    <li>
                      <router-link to="/vps-hosting"
                        >{{ $t("promotions.submenu.vps.title")
                        }}<span>{{
                          $t("promotions.submenu.vps.tagline")
                        }}</span></router-link
                      >
                    </li>
                  </ul>
                </div>
                <div id="prom" class="submenu about tab-content">
                  <ul>
                    <li>
                      <router-link to="/zulu-tools"
                        >{{ $t("tools.submenu.zulu.title")
                        }}<span>{{
                          $t("tools.submenu.zulu.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/trading-calculator"
                        >{{ $t("tools.submenu.calculators.title")
                        }}<span>{{
                          $t("tools.submenu.calculators.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/economic-calendar"
                        >{{ $t("tools.submenu.ecoCalendar.title")
                        }}<span>{{
                          $t("tools.submenu.ecoCalendar.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/white-label"
                        >{{ $t("tools.submenu.partnerships.title")
                        }}<span>{{
                          $t("tools.submenu.partnerships.tagline")
                        }}</span></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/faq"
                        >{{ $t("tools.submenu.faq.title")
                        }}<span>{{
                          $t("tools.submenu.faq.tagline")
                        }}</span></router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageDropdown from "../language-dropdown.vue";

export default {
  data() {
    return {
      active: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
      showClass: false,
    };
  },
  components: {
    LanguageDropdown,
  },
  mounted() {
    window.document.onscroll = () => {
      let navBar = document.getElementById("navbar");
      if (window.scrollY > navBar.offsetTop) {
        this.active = true;
      } else {
        this.active = false;
      }
    };
  },
};
</script>
