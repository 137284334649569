<template>
	<div id="main-wrapper" class="inner-pages dep-page">
		<div class="section banner-holder">

			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('depWith.heading')}}</h2>
				<p>{{$t('depWith.title')}}</p>
			</div>
		</div>
		<div class="section dep-content">
			<div class="wrapper">
				<div class="title-holder t-left wow fadeIn t-center">
					<p>{{$t('depWith.pTag')}}</p>
				</div>
				<div class="listing wow fadeIn">
					<div class="info-holder">
						<h3>{{$t('depWith.serviceFee')}}</h3>
						<p>{{$t('depWith.charge')}}</p>
					</div>
					<div class="info-holder">
						<h3>{{$t('depWith.safety')}}</h3>
						<p>{{$t('depWith.funds')}}</p>
					</div>
					<div class="info-holder">
						<h3>{{$t('depWith.quickProcessing')}}</h3>
						<p>{{$t('depWith.deposit')}}</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>$10</h3>
						<p>{{$t('depWith.minDeposit')}}</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>10+</h3>
						<p>{{$t('depWith.globalCurrency')}}</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>{{$t('depWith.control')}}</h3>
						<p>{{$t('depWith.security')}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section deposits-holder">
			<div class="wrapper">
				<div class="title-holder t-center wow fadeIn">
					<h3>{{$t('depWith.with')}}</h3>
					<p>{{$t('depWith.seamless')}}</p>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>{{$t('depWith.step')}} <br>
					<br>
					{{$t('depWith.step1')}} <br>
					{{$t('depWith.step2')}} <br>
					{{$t('depWith.step3')}} <br>
					</p>
				</div>
				<div class="deposit-nav">
					<ul>
						<li> <a href="dep-with.html">{{$t('depWith.depositText')}}</a> </li>
						<li class="active"> <a href="dep-with-with.html">{{$t('depWith.withdrawal')}}</a> </li>
					</ul>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depWith.bankCard')}}</th>
							<th>{{$t('depWith.region')}}</th>
							<th>{{$t('depWith.currencies')}}</th>
							<th>{{$t('depWith.min')}}</th>
							<th>{{$t('depWith.service')}}</th>
							<th>{{$t('depWith.processing')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep1.webp" alt="AAAFx" title="AAAFx" width="105" height="31" class="fluid-img" /> </td>
							<td>{{$t('depWith.worldWide')}}</td>
							<td>EUR, USD, CHF, GBP</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depWith.bankWire')}}</th>
							<th>{{$t('depWith.region')}}</th>
							<th>{{$t('depWith.currencies')}}</th>
							<th>{{$t('depWith.min')}}</th>
							<th>{{$t('depWith.service')}}</th>
							<th>{{$t('depWith.processing')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep2.webp" alt="AAAFx" title="AAAFx" width="94" height="28" class="fluid-img" /> </td>
							<td>{{$t('depWith.worldWide')}}</td>
							<td>USD</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.days')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep3.webp" alt="AAAFx" title="AAAFx" width="98" height="25" class="fluid-img" /> </td>
							<td>{{$t('depWith.europe')}}</td>
							<td>EUR (SEPA Only)</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.days')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep4.webp" alt="AAAFx" title="AAAFx" width="43" height="31" class="fluid-img" /> </td>
							<td>{{$t('depWith.southEast')}}</td>
							<td>MY, TH, ID , PH, VN</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep5.webp" alt="AAAFx" title="AAAFx" width="57" height="43" class="fluid-img" /> </td>
							<td>{{$t('depWith.latinAmerica')}}</td>
							<td>BR, CL, CR, EC, SV, MX, PA, PE</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep6.webp" alt="AAAFx" title="AAAFx" width="84" height="23" class="fluid-img" /> </td>
							<td>{{$t('depWith.mexico')}}</td>
							<td>MXN</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>{{$t('depWith.bankWire')}}</th>
							<th>{{$t('depWith.region')}}</th>
							<th>{{$t('depWith.currencies')}}</th>
							<th>{{$t('depWith.min')}}</th>
							<th>{{$t('depWith.service')}}</th>
							<th>{{$t('depWith.processing')}}</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/e1.webp" alt="AAAFx" title="AAAFx" width="81" height="37" class="fluid-img" /> </td>
							<td>{{$t('depWith.latinAmerica')}}</td>
							<td>CL, CR, EC, MX, PE</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e2.webp" alt="AAAFx" title="AAAFx" width="129" height="22" class="fluid-img" /> </td>
							<td>{{$t('depWith.eastAfrica')}}</td>
							<td>TAS, KES, MTN</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.days')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>{{$t('depWith.global')}}</td>
							<td>ETH</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>{{$t('depWith.global')}}</td>
							<td>BTC</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>{{$t('depWith.global')}}</td>
							<td>USDT</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e4.webp" alt="AAAFx" title="AAAFx" width="59" height="21" class="fluid-img" /> </td>
							<td>{{$t('depWith.worldWide')}}</td>
							<td>EUR, USD, JPY, CHF, GBP, AUD</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.days')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e5.webp" alt="AAAFx" title="AAAFx" width="154" height="18" class="fluid-img" /> </td>
							<td>{{$t('depWith.worldWide')}}</td>
							<td>MY, TH, ID, PH, VN</td>
							<td>$0</td>
							<td>$0</td>
							<td>{{$t('depWith.instant')}}</td>
							<td><a href="#" class="btn btn-blue">{{$t('depWith.fund')}}</a> </td>
						</tr>
					</table>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>{{$t('depWith.info')}} <br><br>
						{{$t('depWith.step4')}}
					<br><br>
					{{$t('depWith.step5')}}
					<br><br>
					{{$t('depWith.step6')}}
					</p>
				</div>
				<div class="btn-holder t-center wow fadeIn">
					<a href="#" class="btn btn-yellow">{{$t('depWith.live')}}</a>
					<a href="#" class="btn btn-blue">{{$t('depWith.demo')}}</a>
				</div>
			</div>
		</div>
	</div>
</template>