<template>
	<div id="main-wrapper" class="inner-pages depbonus-page refer-page zulutools-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('zuluTools.title')}}</h2>
				<p>{{$t('zuluTools.pTag')}}</p>
			</div>
		</div>
		<div class="section bonus-trade">
			<div class="wrappe2r">
				<div class="img-holder wow fadeInLeft col t-center">
					<img src="assets/images/zulut-img1.webp" alt="AAAFx" title="AAAFx" width="630" height="353" class="fluid-img" />
				</div>
				<div class="content-holder wow fadeInRight col">
					<div class="title-holder">
						<p>{{$t('zuluTools.para')}}
						<br><br>
						{{$t('zuluTools.bTag')}}
						<br><br>
						{{$t('zuluTools.bTag1')}}
						</p>
					</div>
				</div>

			</div>
		</div>
		<div class="section avail-bonus">
			<div class="wrapper2">
				<div class="section benefits-sec">
					<div class="benefits-holder col wow fadeInLeft">
						<img src="assets/images/zulu-ico.webp" alt="AAAFx" title="AAAFx" width="69" height="75" class="fluid-img" />
						<h3>{{$t('zuluTools.copier')}}</h3>
						<p>{{$t('zuluTools.pTag1')}}
						<br><br>
						{{$t('zuluTools.brTag')}}</p>
						<div class="btn-holder t-right">
							<a :href="siteurl2" target="_blank" class="btn btn-blue">{{$t('zuluTools.register')}}</a>
						</div>
					</div>
					<div class="benefits-holder bene-refer col wow fadeInRight">
							<img src="assets/images/zulut-ico1.webp" alt="AAAFx" title="AAAFx" width="72" height="72" class="fluid-img" />
							<h3>{{$t('zuluTools.master')}}</h3>
							<p>{{$t('zuluTools.pTag2')}}
							<br><br>
							{{$t('zuluTools.brTag1')}}</p>
							<div class="btn-holder t-right">
								<a :href="siteurl2" target="_blank" class="btn btn-white">{{$t('zuluTools.register')}}</a>
							</div>
					</div>
				</div>
				<div class="section-small ib-program">
					<div class="title-holder wow fadeIn">
						<h5>{{$t('zuluTools.offers')}}</h5>
					</div>
					<div class="listing wow fadeIn">
						<div class="zulu-offer">
							<h3>{{$t('zuluTools.platform')}}</h3>
							<p>{{$t('zuluTools.pTag3')}}</p>
							<br>
						</div>
						<div class="zulu-offer">
							<h3>{{$t('zuluTools.zuluGuard')}}</h3>
							<p>{{$t('zuluTools.pTag4')}}
							<br><br>
							{{$t('zuluTools.brTag2')}}</p>
						</div>
						<div class="zulu-offer">
							<h3>{{$t('zuluTools.automator')}}</h3>
							<p>{{$t('zuluTools.pTag5')}}</p>
						</div>
						<div class="zulu-offer">
							<h3>{{$t('zuluTools.combos')}}</h3>
							<p>{{$t('zuluTools.pTag6')}}</p>
						</div>
					</div>
				</div>
				<div class="section-small zulu-vid">
					<div class="wrapper">
						<div class="video-holder">
							<iframe width="100%" height="500px" src="https://www.youtube.com/embed/yz4qeidVkvw" frameborder="0"></iframe>
							<!-- <img src="assets/images/video-holder2.webp" alt=""> -->
						</div>
					</div>
				</div>
				<div class="section-small quick-steps mt4-page">
					<div class="title-holder">
						<h5>{{$t('zuluTools.quickSteps')}}</h5>
					</div>
					<div class="listing t-center">
						<div class="step-zulu wow fadeIn">
							<img src="assets/images/step1.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
							<p>{{$t('zuluTools.openFund')}}<br>
								{{$t('zuluTools.zuluAccount')}}</p>
						</div>
						<div class="step-zulu wow fadeIn" data-wow-delay="0.3s">
							<img src="assets/images/step2.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
							<p>{{$t('zuluTools.cred')}} </p>
						</div>
						<div class="step-zulu wow fadeIn" data-wow-delay="0.6s">
							<img src="assets/images/step3.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
							<p>{{$t('zuluTools.zuluTrade')}}</p>
						</div>
					</div>
					<div class="btn-holder t-center">
						<a :href="siteurl2" target="_blank" class="btn btn-yellow">{{$t('zuluTools.openLive')}}</a>
						<a :href="siteurl2" target="_blank" class="btn btn-blue">{{$t('zuluTools.openDemo')}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL
    };
  },
  
};
</script>
