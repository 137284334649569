<template>
  <div id="main-wrapper" class="inner-pages why-page contact-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{$t('contactUsPage.heading')}}</h2>
        <p>{{$t('contactUsPage.headingDescription')}}</p>
      </div>
    </div>
    <div class="section contact-content">
      <div class="wrapper2">
        <div class="content-holder">
          <div class="form-holder wow fadeInLeft">
            <p v-if="errorMsg" :class="msgType == 'error'? 'alert alert-danger mb-0': 'alert alert-success mb-0' ">
              {{ errorMsg }}
            </p>
            <h3>{{$t('contactUsPage.requestCallBack')}}:</h3>

            <Form class="contactUs" @submit="submit">
              <AppLoader v-if="pageLoader" />
              <ul>
                <li>
                  <label for="">{{$t('contactUsPage.FullName')}}:</label>
                  <Field
                    name="Full Name"
                    v-model="form.name"
                    rules="required"
                  />
                  <ErrorMessage name="Full Name" class="contact-field-error" />
                </li>
                <li>
                  <label for="">{{$t('contactUsPage.EmailAddress')}}:</label>
                  <Field
                    type="email"
                    name="Email Address"
                    v-model="form.email"
                    rules="required"
                  />
                  <ErrorMessage name="Email Address" class="contact-field-error" />
                </li>
                <li>
                  <label for="">{{$t('contactUsPage.PhoneNumber')}}:</label>
                  <Field
                    name="Phone Number"
                    v-model="form.number"
                    rules="required|max:15 |min:7"
                  />
                  <ErrorMessage name="Phone Number" class="contact-field-error" />
                </li>
                <li>
                  <label for="client">{{$t('contactUsPage.Are_you_an_existing_client')}}</label>
                  <label class="customRadio">
                    {{$t('contactUsPage.Yes')}}
                    <span>
                        <Field
                          type="radio"
                          name="terms"
                          v-model="form.terms"
                          :rules="existing"
                      />
                    </span>
                  </label>
                  <label class="customRadio">
                    {{$t('contactUsPage.No')}}
                    <span>
                      <Field
                        type="radio"
                        name="terms"
                        v-model="form.terms"
                        :rules="existing"
                        :value="'No'" />
                    </span>
                  </label>
                  <ErrorMessage name="terms" class="contact-field-error" />
                </li>
                <li>
                  <label for="time">{{$t('contactUsPage.Select_your_timezone')}}:</label>
                  <Field
                    class="form-control"
                    as="select"
                    name="Timezone"
                    rules="required"
                    v-model="form.timezone"
                  >
                    <option value="" disabled>{{$t('contactUsPage.Select_your_timezone')}}</option>
                    <option v-for="timezone of timezones" :value="timezone.value" :key="timezone.value">
                      {{ timezone.label }}
                    </option>
                  </Field>
                  <ErrorMessage name="Timezone" class="contact-field-error" />
                </li>
                <li>
                  <label for="">{{$t('contactUsPage.How_can_we_help_you')}}</label>
                  <Field
                    class="form-control"
                    as="select"
                    name="Department"
                    v-model="form.department"
                    rules="required"
                  >
                    <option value="" disabled>{{$t('contactUsPage.Select_a_topic')}}</option>
                    <option :value="'New Accounts'">{{$t('contactUsPage.NewAccounts')}}</option>
                    <option :value="'Customer Support'">
                      {{$t('contactUsPage.CustomerSupport')}}
                    </option>
                    <option :value="'Technical Queries'">
                      {{$t('contactUsPage.TechnicalQueries')}}
                    </option>
                    <option :value="'IB Program'">{{$t('contactUsPage.IBProgram')}}</option>
                    <option :value="'PAMM Accounts'">{{$t('contactUsPage.PAMM_Accounts')}}</option>
                    <option :value="'White Label'">{{$t('contactUsPage.WhiteLabel')}}</option>
                  </Field>
                  <ErrorMessage name="Department" class="contact-field-error" />
                </li>
                <li>
                  <label for="">{{$t('contactUsPage.BestTimeToCallMessage')}}:</label>
                  <Field
                    class="form-control"
                    as="select"
                    name="Preferred Time"
                    v-model="form.preferred_time"
                    rules="required"
                  >
                    <option value="" disabled>{{$t('contactUsPage.SelectPreferredTime')}}</option>
                    <option value="8-10">{{$t('contactUsPage.eightAM')}} - {{$t('contactUsPage.tenAM')}}</option>
                    <option value="10-12">{{$t('contactUsPage.tenAM')}} - {{$t('contactUsPage.twelveNoon')}}</option>
                    <option value="12-14">{{$t('contactUsPage.twelveNoon')}} - {{$t('contactUsPage.twoPM')}}</option>
                    <option value="14-16">{{$t('contactUsPage.twoPM')}} - {{$t('contactUsPage.fourPM')}}</option>
                    <option value="16-18">{{$t('contactUsPage.fourPM')}} - {{$t('contactUsPage.sixPM')}}</option>
                    <option value="18-20">{{$t('contactUsPage.sixPM')}} - {{$t('contactUsPage.eightPM')}}</option>
                  </Field>
                  <ErrorMessage name="Preferred Time" class="contact-field-error" />
                </li>
                <li>
                  <label for="">{{$t('contactUsPage.Message')}}:</label>
                  <Field
                    as="textarea"
                    v-model="form.message"
                    name="message"
                    rows="8"
                    cols="80"
                    rules="required"
                  ></Field>
                  <ErrorMessage name="message" class="contact-field-error" />
                </li>
                <li>
                  <div class="checkbox">
                    <Field
                      name="Checkbox"
                      type="checkbox"
                      value="Checkbox"
                      v-model="form.checkbox"
                      :rules="policy"
                    ></Field>
                    <!-- <input type="checkbox" name="" value="" /> -->
                    <span></span>
                    <p>
                      {{$t('contactUsPage.I_agree_to_AAAFx')}}
                      <a href="assets/docs/Privacy Policy.docx" style="z-index: 999; position: relative" >{{$t('contactUsPage.PrivacyPolicy')}}</a>
                    </p>
                  </div>
                </li>
                <ErrorMessage name="Checkbox" class="contact-field-error" />
              </ul>
              <div class="btn-holder">
                <input
                  type="submit"
                  name=""
                  value="SUBMIT"
                  class="btn btn-bluey"
                />
              </div>
            </Form>
          </div>
          <div class="content-info wow fadeInRight">
            <div class="img-holder">
              <img src="assets/images/contact-img.webp" alt="AAAFx" title="AAAFx" width="580" height="294" class="fluid-img" />
            </div>
            <div class="info-holder">
              <div class="icon-holder">
                <img src="assets/images/contact-ico1.webp" alt="AAAFx" title="AAAFx" width="35" height="39" class="fluid-img" />
              </div>
              <div class="text-holder">
                <h3>{{$t('contactUsPage.LiveChat')}}</h3>
                <p>{{$t('contactUsPage.AvailableMondayToFriday')}}</p>
              </div>
            </div>
            <div class="info-holder">
              <div class="icon-holder">
                <img src="assets/images/contact-ico2.webp" alt="AAAFx" title="AAAFx" width="35" height="39" class="fluid-img" />
              </div>
              <div class="text-holder clearfix">
                <h3>{{$t('contactUsPage.CallUs')}}</h3>
                <p class="f-left">
                  <!-- Central Hotline : <br /> -->
                  <!-- UK, London: <br /> -->
                  {{$t('contactUsPage.GreeceAndAthens')}}: <br />
                </p>
                <p class="f-right">
                  <!-- (+30) 213-0117-498 <br /> -->
                  <!-- (+44) 20-34112034 <br /> -->
                  <!-- (+61) 39-0084201 <br /> -->
                  {{$t('contactUsPage.GreeceAndAthensContactNumber')}}
                  <br />
                  <span>{{$t('contactUsPage.AvailableMondayToFridayTime')}}</span>
                  <!-- (+81) 3-45208926 <br />
                  (+57)1-3819344 <br /> -->
                </p>
              </div>
            </div>
            <div class="info-holder">
              <div class="icon-holder">
                <img src="assets/images/contact-ico3.webp" alt="AAAFx" title="AAAFx" width="35" height="39" class="fluid-img" />
              </div>
              <div class="text-holder">
                <h3>{{$t('contactUsPage.EmailAddresses')}}</h3>
                <p>
                  <strong>{{$t('contactUsPage.Support')}}: </strong
                  ><a href="mailto:support@aaafx.com">support@aaafx.com</a>
                </p>
                <p>
                  <strong>{{$t('contactUsPage.Complaints')}}: </strong><a href="mailto:complaints@aaafx.com">complaints@aaafx.com</a>
                </p>
              </div>
            </div>
            <div class="info-holder follow">
              <div class="icon-holder">
                <img src="assets/images/contact-ico4.webp" alt="AAAFx" title="AAAFx" width="35" height="39" class="fluid-img" />
              </div>
              <div class="text-holder">
                <h3>{{$t('contactUsPage.FollowUs')}}</h3>
                <a href="https://twitter.com/AAAFxOfficial" target="_blank"
                  ><i class="fab fa-twitter"></i
                ></a>
                <!-- <a href="#"><i class="fab fa-telegram-plane"></i></a> -->
                <a href="https://www.facebook.com/AAAFxOfficial" target="_blank"
                  ><i class="fab fa-facebook"></i
                ></a>
                <a href="https://www.instagram.com/aaafxofficial/" target="_blank"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a
                  href="https://www.linkedin.com/company/aaafx/"
                  target="_blank"
                  ><i class="fab fa-linkedin-in"></i
                ></a>
              </div>
            </div>
            <div class="info-holder">
              <div class="icon-holder">
                <img src="assets/images/contact-ico5.webp" alt="AAAFx" title="AAAFx" width="35" height="39" class="fluid-img" />
              </div>
              <div class="text-holder">
                <h3>{{$t('contactUsPage.RegisteredAddress')}}</h3>
                <p>
                  {{$t('contactUsPage.ProvidedRegisteredAddressOne')}}<br />
                  {{$t('contactUsPage.ProvidedRegisteredAddressTwo')}}<br />
                  {{$t('contactUsPage.ProvidedRegisteredAddressThree')}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map-holder"></div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
// import commonAllmixins from "@/plugins/commonAll";
import AppLoader from "../components/shared/AppLoader.vue";
import {usePiniaStore} from "../store/pinia-store.js"
export default {
  // mixins: [commonAllmixins],
  setup(){
      const store= usePiniaStore();
      return {store};
  },
  data() {
    return {
      pageLoader: false,
      errorMsg: "",
      msgType: "",
      submitted: false,
      form: {
        name: "",
        email: "",
        number: "",
        department: "",
        preferred_time: "",
        timezone: "",
        message: "",
        terms: "",
        checkbox: "",
      },
      timezones: [
        { value: "-12:00", label: "(GMT -12:00) Eniwetok, Kwajalein" },
        { value: "-11:00", label: "(GMT -11:00) Midway Island, Samoa" },
        { value: "-10:00", label: "(GMT -10:00) Hawaii" },
        { value: "-09:50", label: "(GMT -9:30) Taiohae" },
        { value: "-09:00", label: "(GMT -9:00) Alaska" },
        {
          value: "-08:00",
          label: "(GMT -8:00) Pacific Time (US &amp; Canada)",
        },
        {
          value: "-07:00",
          label: "(GMT -7:00) Mountain Time (US &amp; Canada)",
        },
        {
          value: "-06:00",
          label: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City",
        },
        {
          value: "-05:00",
          label: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima",
        },
        { value: "-04:50", label: "(GMT -4:30) Caracas" },
        {
          value: "-04:00",
          label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
        },
        { value: "-03:50", label: "(GMT -3:30) Newfoundland" },
        {
          value: "-03:00",
          label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
        },
        { value: "-02:00", label: "(GMT -2:00) Mid-Atlantic" },
        { value: "-01:00", label: "(GMT -1:00) Azores, Cape Verde Islands" },
        {
          value: "+00:00",
          label: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
        },
        {
          value: "+01:00",
          label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
        },
        { value: "+02:00", label: "(GMT +2:00) Kaliningrad, South Africa" },
        {
          value: "+03:00",
          label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
        },
        { value: "+03:50", label: "(GMT +3:30) Tehran" },
        {
          value: "+04:00",
          label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
        },
        { value: "+04:50", label: "(GMT +4:30) Kabul" },
        {
          value: "+05:00",
          label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
        },
        {
          value: "+05:50",
          label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
        },
        { value: "+05:75", label: "(GMT +5:45) Kathmandu, Pokhara" },
        { value: "+06:00", label: "(GMT +6:00) Almaty, Dhaka, Colombo" },
        { value: "+06:50", label: "(GMT +6:30) Yangon, Mandalay" },
        { value: "+07:00", label: "(GMT +7:00) Bangkok, Hanoi, Jakarta" },
        {
          value: "+08:00",
          label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
        },
        { value: "+08:75", label: "(GMT +8:45) Eucla" },
        {
          value: "+09:00",
          label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
        },
        { value: "+09:50", label: "(GMT +9:30) Adelaide, Darwin" },
        {
          value: "+10:00",
          label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
        },
        { value: "+10:50", label: "(GMT +10:30) Lord Howe Island" },
        {
          value: "+11:00",
          label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
        },
        { value: "+11:50", label: "(GMT +11:30) Norfolk Island" },
        {
          value: "+12:00",
          label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
        },
        { value: "+12:75", label: "(GMT +12:45) Chatham Islands" },
        { value: "+13:00", label: "(GMT +13:00) Apia, Nukualofa" },
        { value: "+14:00", label: "(GMT +14:00) Line Islands, Tokelau" },
      ],
    };
  },
  methods: {
    existing(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Are You Existing Client Or Not";
    },
    name(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Please Provide Us Your Full Name";
    },
    email(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Please Provide Valid Email Address";
    },
    policy(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Please Agree To Privacy Policy";
    },
    selecttimezone(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Please Select Specific Timezone";
    },
    selectdepartment(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Please Select The Department";
    },
    time(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Please Select The Best Time To Contact You";
    },
    message(value) {
      if (value && value.trim()) {
        return true;
      }
      return "Please Provide Us Your Concern";
    },
    submit(values, { resetForm }) {
      this.submitted = true;
      this.msgType = "";
      this.errorMsg = "";
      let text_data = "email : " + this.form.email + ", Name :" + this.form.name + ", phone_no :" + this.form.number + ", department : " + this.form.department + ", time : " + this.form.timezone + ", message : " + this.form.message + ", terms : " + this.form.terms;
      let input_json = {
        text: text_data,
        email: this.form.email,
        //"name":  this.form.name,
        mobile_no: this.form.number,
        reason: "13",
        fname: this.form.name.split(" ")[0],
        lname: this.form.name.split(" ")[1] ? this.form.name.split(" ")[1] : "",
        portaltype: "E",
      }
      this.store.ContactUs(input_json).then(() => {
          this.reset();
          resetForm();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    reset() {
      this.submitted = false;
      this.form = {
        name: "",
        email: "",
        number: "",
        department: "",
        preferred_time: "",
        timezone: "",
        message: "",
        terms: "",
        checkbox: "",
      };
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    AppLoader,
  },
};
</script>
