<template>
  <div id="main-wrapper" class="inner-pages forex-page mt4-page">
    <div class="section banner-holder">
      <div class="forex-nav">
        <div class="wrapper">
          <ul>
            <li><router-link to="/mt4">{{$t('mt5PageData.MT4')}}</router-link></li>
            <li class="active"><router-link to="/mt5">{{$t('mt5PageData.MT5')}}</router-link></li>
            <li><router-link to="/act-trader">{{$t('mt5PageData.ActTrader')}}</router-link></li>
            <li><router-link to="/zulu">{{$t('mt5PageData.ZuluTrade')}}</router-link></li>
          </ul>
        </div>
      </div>

      <div
        class="title-holder t-white t-center center-div wow fadeIn"
        data-wow-delay="1.6s"
      >
        <h2>{{$t('mt5PageData.MetaTrade5')}}</h2>
        <div class="img-holder">
          <img src="assets/images/mt5-img.webp" alt="AAAFx" title="AAAFx" width="765" height="414" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h6>
            {{$t('mt5PageData.MetaTrade5DescOne')}}
          </h6>
          <br />
          <p>
            {{$t('mt5PageData.MetaTrade5DescTwo')}}
          </p>
        </div>
      </div>
    </div>
    <div class="section forex-content">
      <div class="wrapper">
        <div class="content-sec">
          <div class="img-holder wow fadeInLeft">
            <img src="assets/images/mt5-img1.webp" alt="AAAFx" title="AAAFx" width="700" height="401" class="fluid-img" />
          </div>
          <div class="content-holder wow fadeInRight">
            <img src="assets/images/mt5-ico1.webp" alt="AAAFx" title="AAAFx" width="371" height="125" class="fluid-img" />
            <br /><br />
            <p>
              {{$t('mt5PageData.download_mt5_preferred_device')}}
            </p>
          </div>
          <div class="btn-holder wow fadeIn">
            <a :href="static_vars.platformLinks.mt5.desktop" class="btn btn-grey" title="MT5 Desktop">
              <img src="assets/images/desk-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{
                static_vars.platformLinks.mt5.desktop == "javascript:void(0);"
                  ? "Coming Soon"
                  : "Download MT5 Desktop"
              }}
              <br />
            </a>
            <a
              :href="static_vars.platformLinks.mt5.mac"
              class="btn btn-grey"
              title="MT5 Mac"
            >
              <img src="assets/images/desk-ico2.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{
                static_vars.platformLinks.mt5.mac == "javascript:void(0);"
                  ? "Coming Soon"
                  : "Download MT5 Mac"
              }}
            </a>
            <a
              :href="static_vars.platformLinks.mt5.web"
              class="btn btn-grey"
              title="MT5 Web"
              target="_blank"
            >
              <img src="assets/images/desk-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" />
              {{ (static_vars.platformLinks.mt5.web == "javascript:void(0);" || static_vars.platformLinks.mt5.web == "")   ? "Coming Soon"   : "Web Based MT5"}}
            </a>
          </div>
        </div>
        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="desktop ^= true" :class="[{ active: desktop }]">
              {{$t('mt5PageData.Steps_to_download_MT5_Desktop')}}
            </h3>
            <div class="content" v-show="desktop">
              <p>
                {{$t('mt5PageData.Steps_to_download_MT5_Desktop_point1')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Desktop_point2')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Desktop_point3')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Desktop_point4')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Desktop_point5')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Desktop_point6')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Desktop_point7')}}
              </p>
            </div>
            <h3 v-on:click="uninstalldesktop ^= true" :class="[{ active: uninstalldesktop }]">
              {{$t('mt5PageData.Steps_to_uninstall_MT5_Desktop')}}
            </h3>
            <div class="content" v-show="uninstalldesktop">
              <p>
                {{$t('mt5PageData.Steps_to_uninstall_MT5_Desktop_point1')}} <br />
                {{$t('mt5PageData.Steps_to_uninstall_MT5_Desktop_point2')}} <br />
                {{$t('mt5PageData.Steps_to_uninstall_MT5_Desktop_point3')}}
              </p>
            </div>
            <h3 v-on:click="web ^= true" :class="[{ active: web }]">
              {{$t('mt5PageData.Steps_to_log_into_Webbased_MT5')}}
            </h3>
            <div class="content" v-show="web">
              <p>
                {{$t('mt5PageData.Steps_to_log_into_Webbased_MT5_point1')}} <br />
                {{$t('mt5PageData.Steps_to_log_into_Webbased_MT5_point2')}} <br />
                {{$t('mt5PageData.Steps_to_log_into_Webbased_MT5_point3')}} <br />
                {{$t('mt5PageData.Steps_to_log_into_Webbased_MT5_point4')}}
              </p>
            </div>
          </div>
        </div>
        <div class="section-small">
          <div class="content-sec mt4-phone">
            <div class="content-holder wow fadeInLeft">
              <img src="assets/images/mt5-ico2.webp" alt="AAAFx" title="AAAFx" width="371" height="125" class="fluid-img" />
              <br /><br />
              <p>
                {{$t('mt5PageData.AAAFx_mobile_trading_application')}}
              </p>
              <div class="btn-holder wow fadeIn">
                <a :href="static_vars.platformLinks.mt5.android" class="btn btn-grey" title="MT5 Android">
                  <img src="assets/images/phone-ico2.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.mt5.android ==
                    "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download MT5 Android"
                  }}
                </a>
                <a
                  :href="static_vars.platformLinks.mt5.ios"
                  class="btn btn-grey"
                  title="MT5 iOS"
                >
                  <img src="assets/images/phone-ico1.webp" alt="AAAFx" title="AAAFx" width="41" height="44" class="fluid-img" />
                  {{
                    static_vars.platformLinks.mt5.ios == "javascript:void(0);"
                      ? "Coming Soon"
                      : "Download MT5 iOS"
                  }}
                </a>
              </div>
            </div>
            <div class="img-holder wow fadeInRight">
              <img src="assets/images/mt4-img2.webp" alt="AAAFx" title="AAAFx" width="650" height="392" class="fluid-img" />
            </div>
          </div>
        </div>

        <div class="section-small wow fadeIn">
          <div class="accordion-holder t-left">
            <h3 v-on:click="android ^= true" :class="[{ active: android }]">
              {{$t('mt5PageData.Steps_to_download_MT5_Android')}}
            </h3>
            <div class="content" v-show="android">
              <p>
                {{$t('mt5PageData.Steps_to_download_MT5_Android_point1')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Android_point2')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Android_point3')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_Android_point4')}}
              </p>
            </div>
            <h3 v-on:click="unistallandroid ^= true" :class="[{ active: unistallandroid }]">
              {{$t('mt5PageData.Steps_to_uninstall_MT5_Android')}}
            </h3>
            <div class="content" v-show="unistallandroid">
              <p>
                {{$t('mt5PageData.Steps_to_uninstall_MT5_Android_point1')}} <br />
                {{$t('mt5PageData.Steps_to_uninstall_MT5_Android_point2')}} <br />
                {{$t('mt5PageData.Steps_to_uninstall_MT5_Android_point3')}}
              </p>
            </div>
            <h3 v-on:click="ios ^= true" :class="[{ active: ios }]">
              {{$t('mt5PageData.Steps_to_download_MT5_iOS')}}
            </h3>
            <div class="content" v-show="ios">
              <p>
                {{$t('mt5PageData.Steps_to_download_MT5_iOS_point1')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_iOS_point2')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_iOS_point3')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_iOS_point4')}} <br />
                {{$t('mt5PageData.Steps_to_download_MT5_iOS_point5')}}
              </p>
            </div>
            <h3 v-on:click="unistallios ^= true" :class="[{ active: unistallios }]">
              {{$t('mt5PageData.Steps_to_uninstall_MT5_iOS')}}
            </h3>
            <div class="content" v-show="unistallios">
              <p>
                {{$t('mt5PageData.Steps_to_uninstall_MT5_iOS_point1')}} <br />
                {{$t('mt5PageData.Steps_to_uninstall_MT5_iOS_point2')}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section content-mt4">
      <div class="wrapper">
        <div class="title-holder">
          <h3>
            {{$t('mt5PageData.trading_experience_the_next_level')}}            
          </h3>
        </div>
        <div class="img-holder t-center">
          <img src="assets/images/mt5-img3.webp" alt="AAAFx" title="AAAFx" width="1158" height="361" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="wrapper">
        <div class="content-sec mt4-phone why-mt4 why-mt5">
          <div class="content-holder wow fadeInLeft">
            <div class="title-holder">
              <h3>{{$t('mt5PageData.Why_AAAFxs_MT5')}}</h3>
            </div>
            <p>{{$t('mt5PageData.TechnicalAnalysisTools')}}:</p>
            <ul class="listing-check">
              <li>
                {{$t('mt5PageData.TechnicalAnalysisToolsPoint1')}}
              </li>
              <li>
                {{$t('mt5PageData.TechnicalAnalysisToolsPoint2')}}
              </li>
              <li>
                {{$t('mt5PageData.TechnicalAnalysisToolsPoint3')}}
              </li>
              <li>
                {{$t('mt5PageData.TechnicalAnalysisToolsPoint4')}}
              </li>
              <li>
                {{$t('mt5PageData.TechnicalAnalysisToolsPoint5')}}
              </li>
            </ul>
            <br />
            <p>{{$t('mt5PageData.FundamentalAnalysisTools')}}:</p>
            <ul class="listing-check">
              <li>
                {{$t('mt5PageData.FundamentalAnalysisToolsPoint1')}}
              </li>
              <li>
                {{$t('mt5PageData.FundamentalAnalysisToolsPoint2')}}
              </li>
              <li>
                {{$t('mt5PageData.FundamentalAnalysisToolsPoint3')}}
              </li>
            </ul>
          </div>
          <div class="img-holder wow fadeInRight">
            <img src="assets/images/mt5-img4.webp" alt="AAAFx" title="AAAFx" width="575" height="355" class="fluid-img" />
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow" >{{$t('mt5PageData.capsOpenLiveAccount')}}</a>
              <a :href="siteurl2" target="_blank" class="btn btn-blue">{{$t('mt5PageData.capsOpenDemoAccount')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Support: false,
      Finance: false,
      Administrator: false,
      desktop: false,
      uninstalldesktop: false,
      web: false,
      android: false,
      unistallandroid: false,
      ios: false,
      unistallios: false,
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
};
</script>
