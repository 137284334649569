<template>
	<div id="main-wrapper" class="inner-pages auto-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('autoChartistPage.heading')}}</h2>
				<p>{{$t('autoChartistPage.headingDesc')}}</p>
			</div>
		</div>
		<div class="section forex-content">
			<div class="wrapper2">
				<div class="section">
					<div class="content-sec mt4-phone zulu-web">
						<div class="img-holder wow fadeInLeft">
							<img src="assets/images/auto-img1.webp" alt="AAAFx" title="AAAFx" width="665" height="346" class="fluid-img" />
						</div>
						<div class="content-holder wow fadeInRight">
							<h3>{{$t('autoChartistPage.subHeadingOne')}}</h3>
							<br>
							<p>
								{{$t('autoChartistPage.subHeadingOneP1')}}
								<br><br>
								{{$t('autoChartistPage.subHeadingOneP2')}}
							</p>
						</div>
					</div>
				</div>
				<div class="section">
					<div class="content-sec mt4-phone zulu-web">
						<div class="content-holder wow fadeInLeft">
							<h3>{{$t('autoChartistPage.subHeadingTwo')}}</h3>
							<br>
							<p>
								{{$t('autoChartistPage.subHeadingTwoP1')}}
								<br><br>
								{{$t('autoChartistPage.subHeadingTwoP2')}}
							</p>
							<ul class="listing-check listing-chart">
								<li>{{$t('autoChartistPage.ChartPatterns')}}</li>
								<li>{{$t('autoChartistPage.FibonacciPatterns')}}</li>
								<li>{{$t('autoChartistPage.KeyLevels')}}</li>
							</ul> <br>
							<p>{{$t('autoChartistPage.subHeadingTwoDesc')}}</p>
						</div>
						<div class="img-holder wow fadeInRight">
							<div class="mockup-holder wow fadeIn">
								<img src="assets/images/mockup-holder.webp" alt="AAAFx" title="AAAFx" width="665" height="393" class="fluid-img" />
								<div class="video-holder">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section market-analysis">
			<div class="wrapper">
				<div class="title-holder">
					<h3>{{$t('autoChartistPage.One_stop_solution')}}</h3>
				</div>

				<div class="slider-holder clearfix wow fadeIn">
					<ul class="slides">
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img1.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>{{$t('autoChartistPage.One_stop_solution_p1')}}</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img2.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>{{$t('autoChartistPage.One_stop_solution_p2')}}</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img3.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>{{$t('autoChartistPage.One_stop_solution_p3')}}</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img1.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>{{$t('autoChartistPage.One_stop_solution_p4')}}</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img2.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>{{$t('autoChartistPage.One_stop_solution_p2')}}</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img3.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>{{$t('autoChartistPage.One_stop_solution_p3')}}</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="listing wow fadeIn">
					<div class="market-ico">
						<img src="assets/images/market-ico1.webp" alt="AAAFx" title="AAAFx" width="90" height="90" class="fluid-img" />
						<p>{{$t('autoChartistPage.Single_Screen_access_to_everythinig')}}</p>
					</div>
					<div class="market-ico">
						<img src="assets/images/market-ico2.webp" alt="AAAFx" title="AAAFx" width="90" height="90" class="fluid-img" />
						<p>{{$t('autoChartistPage.Get_simply_plugged_in_to_MT4_and_MT5')}}</p>
					</div>
					<div class="market-ico">
						<img src="assets/images/market-ico3.webp" alt="AAAFx" title="AAAFx" width="90" height="90" class="fluid-img" />
						<p>{{$t('autoChartistPage.API_Driven_Technology_free_from_manual_errors')}}</p>
					</div>
					<div class="market-ico">
						<img src="assets/images/market-ico4.webp" alt="AAAFx" title="AAAFx" width="90" height="90" class="fluid-img" />
						<p>{{$t('autoChartistPage.All_rounder_for_all_types_of_Traders')}}</p>
					</div>
				</div>

				<div class="autochartist-holder">
					<div class="img-holder">
						<img src="assets/images/chartist-img.webp" alt="AAAFx" title="AAAFx" width="475" height="209" class="fluid-img" />
					</div>
					<h3>{{$t('autoChartistPage.Autochartist_Volatility_Analysis')}}</h3>
					<p>{{$t('autoChartistPage.Autochartist_Volatility_Analysis_p1')}}
						<br><br>
						{{$t('autoChartistPage.Autochartist_Volatility_Analysis_p2')}}
						<br><br>
						{{$t('autoChartistPage.Autochartist_Volatility_Analysis_p3')}}:- <br><br>
						</p>
						<ul class="listing-check">
							<li>{{$t('autoChartistPage.Autochartist_Volatility_Analysis_sub_p1')}}</li>
							<li>{{$t('autoChartistPage.Autochartist_Volatility_Analysis_sub_p2')}}</li>
							<li>{{$t('autoChartistPage.Autochartist_Volatility_Analysis_sub_p3')}}</li>
						</ul>
						<p>{{$t('autoChartistPage.Know_more_here')}}: <br> <a href="#">https://vimeo.com/310889082</a> </p>
				</div>
				<div class="autochartist-holder">
					<div class="img-holder">
						<img src="assets/images/chartist-img2.webp" alt="AAAFx" title="AAAFx" width="475" height="209" class="fluid-img" />
					</div>
					<h3>{{$t('autoChartistPage.AutochartistRiskCalculator')}}</h3>
					<p>
						{{$t('autoChartistPage.AutochartistRiskCalculatorP1')}}
						<br><br>
						{{$t('autoChartistPage.AutochartistRiskCalculatorP2')}}
						<br><br>
						{{$t('autoChartistPage.AutochartistRiskCalculatorP3')}}</p>
						<ul class="listing-check">
							<li>{{$t('autoChartistPage.AutochartistRiskCalculatorSubP1')}}</li>
							<li>{{$t('autoChartistPage.AutochartistRiskCalculatorSubP2')}}</li>
							<li>{{$t('autoChartistPage.AutochartistRiskCalculatorSubP3')}}</li>
						</ul>
					<p>{{$t('autoChartistPage.Know_more_here')}}: <br> <a href="#">https://vimeo.com/310889057</a> </p>
				</div>
				<div class="btn-holder t-center">
					<a href="#" class="btn btn-yellow">{{$t('autoChartistPage.CapsOpenLiveAccount')}}</a>
					<a href="#" class="btn btn-blue">{{$t('autoChartistPage.CapsOpenDemoAccount')}}</a>
				</div>
			</div>
		</div>
	</div>
</template>