import { defineStore } from 'pinia'
import * as ENDPOINTS from "@/api/endpoints";
import axios from "axios";
import moment from "moment";

export const usePiniaStore = defineStore('PiniaStore', {
	state: () => ({ 
		count: 0, 
		loading:false,
		EventList:[],
		countrylist:[]
	}),
	getters: { },
	actions: {
		getCountryList(){
			axios.get(ENDPOINTS.EP_COUNTRY_LIST).then(response => {	
				this.countrylist= response
			})
			.catch(function (error) {
				console.log("error=>",error)
			});
		},
		ContactUs(params, loader = false) {
			if (loader) {
				this.loading = true;
			}
			return new Promise((resolve, reject) => {
				axios.post(ENDPOINTS.EP_CONTACT_US, params).then(response => {	
					this.loading = false;
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},
		EconomicCalendarList(params, loader = false) {
			if (!loader) {
				this.loading = true;
			}
			return new Promise((resolve, reject) => {
				try {
					axios.post(ENDPOINTS.EP_GET_ECONOMIC_CALENDAR, params).then(response => {	
						this.EventList=response.data.data
						let economicCal = {}
						response.data.data.forEach(e => {
							let date  = moment(e.date).format('YYYY-MM-DD')
							if(!economicCal[date]){
								economicCal[date] = []
							}
							economicCal[date].push(e)
						});	
						this.loading = false;
						resolve(economicCal);
						// resolve(this.EventList);
					});
				} catch (error) {
					console.log("errro======+>",error)
					reject(error);
				}	
			});		
		},
		eventhistory(params, loader = false) {
			if (loader) {
				this.loading = true;
			}
			return new Promise((resolve, reject) => {
				axios.post(ENDPOINTS.EP_EVENT_HISTORY, params).then(response => {	
					this.loading = false;
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},
	},
})