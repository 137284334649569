<template>
	<div id="main-wrapper" class="inner-pages depbonus-page vps-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>
					{{$t('vpsHosting.banner.title')}}
					<b-button v-b-popover.hover.click title="Conditions Apply: It is not 100% free" content="It is not 100% free" >
						* 
					</b-button>
				</h2>
			</div>
		</div>
		<div class="section bonus-trade">
			<div class="wrapper">
				<div class="title-holder t-left">
					<p>{{$t('vpsHosting.bonus_trade_section.description')}}
					<br><br>
					{{$t('vpsHosting.bonus_trade_section.customer_approach')}}
					</p>
				</div>
					<div class="img-holder wow fadeInLeft col">
						<img src="assets/images/vps-img1.webp" alt="AAAFx" title="AAAFx" width="419" height="369" class="fluid-img" />
					</div>
				<div class="content-holder wow fadeInRight col">
					<div class="title-holder">
						<h3>{{$t('vpsHosting.bonus_trade_section.advantages_title')}}</h3>
						<ul class="listing-check">
							<li>{{$t('vpsHosting.bonus_trade_section.advantages_list.item_1')}}</li>
							<li>{{$t('vpsHosting.bonus_trade_section.advantages_list.item_2')}}</li>
							<li>{{$t('vpsHosting.bonus_trade_section.advantages_list.item_3')}}</li>
							<li>{{$t('vpsHosting.bonus_trade_section.advantages_list.item_4')}}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="section avail-bonus">
			<div class="wrapper">
				<div class="accordion-holder t-left">
					<h3 v-on:click="freevps ^= true" :class="[{active : freevps}]">{{$t('vpsHosting.accordion_section.how_to_get_vps.title')}}</h3>
					<div class="content" v-show="freevps">
						<p>{{$t('vpsHosting.accordion_section.how_to_get_vps.content')}}</p>
					</div>
					<h3 v-on:click="free ^= true"  :class="[{active : free}]">{{$t('vpsHosting.accordion_section.why_free_vps.title')}}</h3>
					<div class="content" v-show="free" >
						<p>{{$t('vpsHosting.accordion_section.why_free_vps.content')}}</p>
					</div>
					<h3 v-on:click="lesser ^= true"  :class="[{active : lesser}]">{{$t('vpsHosting.accordion_section.lesser_deposit.title')}}</h3>
					<div class="content" v-show="lesser" >
						<p>{{$t('vpsHosting.accordion_section.lesser_deposit.content')}}</p>
					</div>
					<h3 v-on:click="othervps ^= true"  :class="[{active : othervps}]">{{$t('vpsHosting.accordion_section.other_vps.title')}}</h3>
					<div class="content" v-show="othervps" >
						<p>{{$t('vpsHosting.accordion_section.other_vps.content')}}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section apply-vps refer-page free-vps">
			<div class="wrapper">
				<div class="title-holder t-white wow fadeIn">
					<h3>{{$t('vpsHosting.vps_configuration_section.title')}}</h3>
				</div>
				<div class="listing">
					<div class="refer-holder col wow fadeIn">
						<div class="content-holder">
							<h3>{{$t('vpsHosting.vps_configuration_section.configuration.intel.title')}}</h3>
							<img src="assets/images/vps-ico1.webp" alt="AAAFx" title="AAAFx" width="52" height="45" class="fluid-img" />
							<p>{{$t('vpsHosting.vps_configuration_section.configuration.ram.title')}}</p>
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.3s">
						<div class="content-holder">
							<h3>{{$t('vpsHosting.vps_configuration_section.configuration.intel.title')}}</h3>
							<img src="assets/images/vps-ico2.webp" alt="AAAFx" title="AAAFx" width="52" height="45" class="fluid-img" />
							<p>{{$t('vpsHosting.vps_configuration_section.configuration.ram.description')}}</p>
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.6s">
						<div class="content-holder">
							<h3>{{$t('vpsHosting.vps_configuration_section.configuration.bandwidth.title')}}</h3>
							<img src="assets/images/vps-ico3.webp" alt="AAAFx" title="AAAFx" width="52" height="45" class="fluid-img" />
							<p>{{$t('vpsHosting.vps_configuration_section.configuration.bandwidth.description')}}</p>
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.9s">
						<div class="content-holder">
							<h3>{{$t('vpsHosting.vps_configuration_section.configuration.disk_space.title')}}</h3>
							<img src="assets/images/vps-ico4.webp" alt="AAAFx" title="AAAFx" width="52" height="45" class="fluid-img" />
							<p>{{$t('vpsHosting.vps_configuration_section.configuration.disk_space.description')}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section apply-vps refer-page">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<h3>{{$t('vpsHosting.apply_vps_section.title')}}</h3>
				</div>
				<div class="listing">
					<div class="refer-holder col wow fadeIn">
						<div class="content-holder">
							<p>{{$t('vpsHosting.apply_vps_section.steps.step_1.description')}}</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer1.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.3s">
						<div class="content-holder">
							<p>{{$t('vpsHosting.apply_vps_section.steps.step_2.description')}}</p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer2.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
					<div class="refer-holder col wow fadeIn" data-wow-delay="0.6s">
						<div class="content-holder">
							<p>{{$t('vpsHosting.apply_vps_section.steps.step_3.description')}} <br> <a href="mailto:support@aaafx.com">support@aaafx.com</a></p>
						</div>
						<div class="number-holder">
							<img src="assets/images/refer3.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
        freevps:false,
        free:false,
        lesser:false,
        othervps:false,
      }
    }
  }
</script>

