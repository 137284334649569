<template>
	<div id="main-wrapper" class="web-trader">
		<div class="web-trader-inner">
			<div style="width: 100%; height: 100%">
				<iframe
				width="100%"
				src="https://trade.mql5.com/trade?servers=AAAFx-FX-Demo%2cAAAFx-FX-Live&amp;trade_server=AAAFx-FX-Demo&amp;startup_mode=create_demo&amp;lang=en"
				allowfullscreen="allowfullscreen"></iframe>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "WebTrader",
  data() {
    return {
        
      }
    }
  }

</script>