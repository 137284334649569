<template>
  <div id="main-wrapper" class="inner-pages why-page reg-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{$t('regulations.title')}}</h2>
        <p>
          {{$t('regulations.multiple')}}
        </p>
      </div>
    </div>
    <div class="section why-bel reg-sec">
      <div class="wrapper">
        <div class="content-sec wow fadeIn">
          <div class="flag-holder">
            <img src="assets/images/greece.webp" alt="AAAFx" title="AAAFx" width="25" height="17" class="fluid-img" />
          </div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>{{$t('regulations.capitalMarket')}}</h5>
              <p>
                <strong
                  >{{$t('regulations.member')}}</strong
                >
              </p>
              <div class="btn-holder">
                <a href="http://www.hcmc.gr/en_US/web/portal/orgcmc/viewinvestmentfirms?auid=2fe" target="_blank" class="btn btn-blue">View License</a>
              </div>
            </div>
          </div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>{{$t('regulations.h5Tag')}}</h5>
              <p>
                {{$t('regulations.pTag')}}
              </p>
            </div>
          </div>
          <div class="flag-holder"></div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>{{$t('regulations.about')}}</h5>
              <p>
                {{$t('regulations.adopted')}}
              </p>
            </div>
          </div>
          <div class="flag-holder"></div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>{{$t('regulations.gdpr')}}</h5>
              <p>
                {{$t('regulations.aboutGDPR')}}
              </p>
            </div>
          </div>
          <div class="flag-holder"></div>
          <div class="content-holder">
            <div class="title-holder">
              <h5>{{$t('regulations.investor')}}</h5>
              <p>
                {{$t('regulations.hcmc')}}
              </p>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="title-holder wow fadeIn">
            <h3>{{$t('regulations.group')}}</h3>
          </div>
          <div class="content-sec wow fadeIn">
            <div class="flag-holder">
              <img src="assets/images/sa.webp" alt="AAAFx" title="AAAFx" width="25" height="17" class="fluid-img" />
            </div>
            <div class="content-holder">
              <div class="title-holder">
                <h5>{{$t('regulations.sector')}}</h5>
                <p>
                  {{$t('regulations.southAfrica')}}
                </p>
                <div class="btn-holder">
                  <a href="https://www.fsca.co.za/Fais/Search_FSP.htm" target="_blank" class="btn btn-blue">{{$t('regulations.view')}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="title-holder wow fadeIn">
            <h3>{{$t('regulations.register')}}</h3>
          </div>
          <div class="content-sec wow fadeIn">
            <div
              class="juris-sec"
              v-for="registration in registrations"
              :key="registration.country"
            >
              <div class="flag-holder">
                <img
                  :src="
                    'assets/images/country_images/' +
                    registration.country.toLowerCase() +
                    '.webp'
                  "
                   alt="AAAFx" title="AAAFx" width="25" height="25" class="fluid-img"
                />
              </div>
              <div class="content-holder">
                <h5>{{ registration.country }}</h5>
                <a :href="registration.link" target="_blank" rel="noopener">{{
                  registration.label
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      registrations: [
        {
          country: "Austria",
          label: "Finanzmarktaufsicht (FMA)",
          link: "https://www.fsma.be/en",
        },
        {
          country: "Bulgaria",
          label: "Комисията за финансов надзор (FSC)",
          link: "https://www.fsc.bg/en/",
        },
        {
          country: "Croatia",
          label: "Hrvatska agencija za nadzor financijskih usluga (HANFA)",
          link: "https://www.hanfa.hr/en/",
        },
        {
          country: "Republic of Cyprus",
          label: "Cyprus Securities and Exchange Commission (CySec)",
          link: "https://www.cysec.gov.cy/en-GB/entities/investment-firms/member-states/Cross-Border/45356/",
        },
        {
          country: "Czech Republic",
          label: "Ceská národní banka (CNB)",
          link: "https://www.cnb.cz/en/",
        },
        {
          country: "Denmark",
          label: "Finanstilsynet (DFSA)",
          link: "https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn-en.html?v=E8C9DEE3-3B8A-E611-810C-00505680286D",
        },
        {
          country: "Estonia",
          label: "Finantsinspektsioon (FSA)",
          link: "https://www.fi.ee/en/investment-market/investment-firms-0/investment-market/providers-cross-border-investment-services/triple-experts-investment-services-sa-former-triple-experts-investment-services-single-member-sa",
        },
        {
          country: "Finland",
          label: "Finanssivalvonta (FSA)",
          link: "https://www.finanssivalvonta.fi/en/",
        },
        {
          country: "Germany",
          label:
            "Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) - 10128185 ",
          link: "https://www.bafin.de/EN/Homepage/homepage_node.html",
        },
        {
          country: "Hungary",
          label: "Magyar Nemzeti Bank (MNB)",
          link: "https://www.mnb.hu/web/en",
        },
        {
          country: "Ireland",
          label: "Central Bank of Ireland",
          link: "http://registers.centralbank.ie/FirmSearchResultsPage.aspx?searchEntity=Institution&searchType=Name&searchText=Triple+A+Experts&registers=32%2C33%2C58%2C59&firmType=InvestmentFirms",
        },
        {
          country: "Italy",
          label:
            "Commissione Nazionale per le Società e la Borsa (CONSOB) - 3422",
          link: "https://www.consob.it/web/consob-and-its-activities/investment-firms-without-branches#_48_INSTANCE_gV4ErCDseEwW_%3Dhttps%253A%252F%252Fwww.consob.it%252Fdocuments%252F46180%252F46181%252FCOM_115_EN.html%252F08057d62-f5ce-4a14-ac30-a1ca9c570efe%253F",
        },
        {
          country: "Latvia",
          label: "Finanšu un kapitala tirgus komisija (FKTK)",
          link: "https://www.fktk.lv/en/market/investment-service-providers/service-providers-from-the-eea/freedom-to-provide-services/triple-a-experts-investment-services-s-a/",
        },
        {
          country: "Lithuania",
          label: "Lietuvos Bankas (LB)",
          link: "https://www.lb.lt/en/",
        },
        {
          country: "Luxembourg",
          label: "Commission de Surveillance du Secteur Financier (CSSF)",
          link: "https://www.cssf.lu/en/",
        },
        {
          country: "Malta",
          label: "Malta Financial Services Authority (MFSA)",
          link: "https://www.mfsa.mt/financial-services-register/",
        },
        {
          country: "Netherlands",
          label: "Autoriteit Financiële Markten (AFM)",
          link: "https://www.afm.nl/en/professionals/registers/vergunningenregisters/beleggingsondernemingen/details?id=61147C5B-B5A7-E411-BA3E-005056BE011C",
        },
        {
          country: "Poland",
          label: "Komisji Nadzoru Finansowego (KNF)",
          link: "https://www.knf.gov.pl/en/CONSUMERS/Information_for_the_financial_market_consumers/Entities_search#3126881",
        },
        {
          country: "Portugal",
          label: "Comissão do Mercado de Valores Mobiliários (CMVM)",
          link: "https://www.cmvm.pt/en/pages/homepage.aspx",
        },
        {
          country: "Romania",
          label: "Autoritatea de Supraveghere Financiara (ASF)",
          link: "https://www.asfromania.ro/en/",
        },
        {
          country: "Slovakia",
          label: "Národná Banka Slovenska (NBS)",
          link: "https://subjekty.nbs.sk/entity/3167/",
        },
        {
          country: "Slovenia",
          label: "Agencija za trg vrednostnih papirjev (ATVP)",
          link: "https://www.a-tvp.si/eng",
        },
        {
          country: "Spain",
          label: "Comisión Nacional del Mercado de Valores (CNMV) - 3174",
          link: "https://www.cnmv.es/Portal/Consultas/ESI/ESISExtranjerasLP.aspx?tipo=CLP&numero=3174",
        },
        {
          country: "Sweden",
          label: "Finansinspektionen (FI) - 40728",
          link: "https://www.fi.se/en/our-registers/company-register/details?id=150846",
        },
      ],
    };
  },
};
</script>
