import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/web-trader',
    name: 'WebTrader',
    component: () => import('@/views/WebTrader.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/account-types',
    name: 'Account Types',
    component: () => import('@/views/AccountTypes.vue')
  },
  {
    path: '/act-trader',
    name: 'Act Trader',
    component: () => import('@/views/ActTrader.vue')
  },
  {
    path: '/auto-chartist',
    name: 'Auto Chartist',
    component: () => import('@/views/AutoChartist.vue')
  },
  {
    path: '/career',
    name: 'Career',
    component: () => import('@/views/Career.vue')
  },
  {
    path: '/commodities',
    name: 'Commodities',
    component: () => import('@/views/Commodities.vue')
  },
  {
    path: '/compare-platform',
    name: 'Compare Platform',
    component: () => import('@/views/ComparePlatform.vue')
  },
  // {
  //   path: '/compensation',
  //   name: 'Compensation',
  //   component: () => import('@/views/Compensation.vue')
  // },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/crypto',
    name: 'Crypto',
    component: () => import('@/views/Crypto.vue')
  },
  // {
  //   path: '/deposit-bonus',
  //   name: 'Deposit Bonus',
  //   component: () => import('@/views/DepositBonus.vue')
  // },
  {
    path: '/dep-with',
    name: 'Dep With',
    component: () => import('@/views/DepWith.vue')
  },
  {
    path: '/dep-with-with',
    name: 'Dep With With',
    component: () => import('@/views/DepWithWith.vue')
  },
  {
    path: '/economic-calendar',
    name: 'Economic Calendar',
    component: () => import('@/views/EconomicCalendar.vue')
  },
  {
    path: '/faq',
    name: 'Frequently Asked Questions',
    component: () => import('@/views/Faq.vue')
  },
  {
    path: '/forex',
    name: 'Forex',
    component: () => import('@/views/Forex.vue')
  },
  {
    path: '/ib',
    name: 'IB',
    component: () => import('@/views/IB.vue')
  },
  {
    path: '/indices',
    name: 'Indices',
    component: () => import('@/views/Indices.vue')
  },
  {
    path: '/legal-documents',
    name: 'Legal Documents',
    component: () => import('@/views/LegalDocuments.vue')
  },
  {
    path: '/loyalty-bonus',
    name: 'Loyalty Bonus',
    component: () => import('@/views/LoyaltyBonus.vue')
  },
  {
    path: '/mt4',
    name: 'Meta Trader 4',
    component: () => import('@/views/MT4.vue')
  },
  {
    path: '/mt5',
    name: 'Meta Trader 5',
    component: () => import('@/views/MT5.vue')
  },
  {
    path: '/our-servers',
    name: 'Our Servers',
    component: () => import('@/views/OurServers.vue')
  },
  {
    path: '/pamm',
    name: 'PAMM',
    component: () => import('@/views/Pamm.vue')
  },
  {
    path: '/refer-earn',
    name: 'Refer Earn',
    component: () => import('@/views/ReferEarn.vue')
  },
  {
    path: '/regulations',
    name: 'Regulations',
    component: () => import('@/views/Regulations.vue')
  },
  {
    path: '/safety-funds',
    name: 'Safety Funds',
    component: () => import('@/views/SafetyFunds.vue')
  },
  {
    path: '/stocks',
    name: 'Stocks',
    component: () => import('@/views/Stocks.vue')
  },
  {
    path: '/trading-calculator',
    name: 'Trading Calculator',
    component: () => import('@/views/TradingCalculator.vue')
  },
  {
    path: '/trading-condition',
    name: 'Trading Condition',
    component: () => import('@/views/TradingCondition.vue')
  },
  {
    path: '/vps-hosting',
    name: 'VPS Hosting',
    component: () => import('@/views/VpsHosting.vue')
  },
  {
    path: '/white-label',
    name: 'White Label',
    component: () => import('@/views/WhiteLabel.vue')
  },
  {
    path: '/why',
    name: 'Why',
    component: () => import('@/views/Why.vue')
  },
  {
    path: '/zulu',
    name: 'Zulu',
    component: () => import('@/views/Zulu.vue')
  },
  {
    path: '/zulu-tools',
    name: 'Zulu Tools',
    component: () => import('@/views/ZuluTools.vue')
  },
  {
    path: '/annual-losers',
    name: 'Anual Losers',
    component: () => import('@/components/shared/AnnualLosers.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: () => import('@/views/page-not-found.vue')
},

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
