<template>
    <div id="main-wrapper">
        <div class="section fx-pairs">
            <div class="links-holder tabs-forex wow fadeIn pt-5">
                <div id="forex" class="tab-holder">
                    <div class="table-holder wow fadeInRight home-table">
                        <table>
                            <tr>
                                <th></th>
                                <th>{{$t('annualLoser.winner')}}</th>
                                <th>{{$t('annualLoser.loser')}}</th>
                                <th>{{$t('annualLoser.total')}}</th>
                                <th>{{$t('annualLoser.percentage')}}</th>
                            </tr>
                            <tr>
                                <td>{{$t('annualLoser.annually')}}</td>
                                <td>136</td>
                                <td>258</td>
                                <td>394</td>
                                <td>65.4822%</td>
                            </tr>
                            <tr>
                                <td>{{$t('annualLoser.semi_annually')}}</td>
                                <td>216</td>
                                <td>297</td>
                                <td>513</td>
                                <td>57.8947%</td>
                            </tr>
                            <tr>
                                <td>{{$t('annualLoser.quaterly')}}</td>
                                <td>218</td>
                                <td>226</td>
                                <td>444</td>
                                <td>50.9009%</td>
                            </tr>
                            <tr>
                                <td>{{$t('annualLoser.monthly')}}</td>
                                <td>217</td>
                                <td>193</td>
                                <td>410</td>
                                <td>47.0732%</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.pt-5 {
    padding-top: 150px;
}
</style>