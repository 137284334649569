<template>
    <div class="d-flex align-items-center justify-content-between totalR whiteBG border-top p-3" v-if="totalRecords > 0">
        <p class="m-0">
            Total Records <span class="totalRecords">{{totalRecords}}</span>
        </p> 
        <!-- <paginate
            v-if="totalRecords > limit"
            :page-count="Math.ceil(totalRecords/limit)"
            :page-range="3"
            :click-handler="fetchData"
            :prev-text="'Prev'"
            :next-text="'Next'" 
            :container-class="'pagination'"updateHandler
            :hide-prev-next="true"
            :force-page="parseInt(pageNo)">
        </paginate> -->
        <v-pagination
            v-model="page"
            :pages="Math.ceil(totalRecords/limit)"
            :range-size="1"
            :hideFirstButton="true"
            :hideLastButton="true"
            active-color="#DCEDFF"
            @update:modelValue="fetchData"
        />
    </div>
</template>
<script>
    import VPagination from "@hennge/vue3-pagination";
    import "@hennge/vue3-pagination/dist/vue3-pagination.css";
    export default {
        name: 'PAGINATION',
        data () {
            return {
                page: 1
            }
        },
        components:{
            VPagination
        },
        props:['totalRecords','limit','pageNo'],
        methods:{
            fetchData(pageNum){
                this.$parent.page = pageNum
                try { 
                    this.$parent.listing() 
                } catch (e) { console.log(e) }
                
            }
        }
    }
</script>