<template>
  <div id="main-wrapper" class="inner-pages depbonus-page loyal-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>{{$t('LoyaltyBonusPageData.heading')}}</h2>
        <p>{{$t('LoyaltyBonusPageData.headingDescription')}}</p>
      </div>
    </div>
    <div class="section bonus-trade">
      <div class="wrapper">
        <div class="title-holder wow fadeIn">
          <h5>
            {{$t('LoyaltyBonusPageData.LoyaltyBonusDescription')}}
          </h5>
        </div>
        <br /><br />
        <div class="title-holder t-left wow fadeIn">
          <h3>{{$t('LoyaltyBonusPageData.What_is_AAAFx_loyalty_program')}}</h3>
          <br />
          <p>
            {{$t('LoyaltyBonusPageData.What_is_AAAFx_loyalty_program_descOne')}}
            <br /><br />
            {{$t('LoyaltyBonusPageData.What_is_AAAFx_loyalty_program_descTwo')}}
          </p>
          <div class="listing t-center">
            <div class="prog-holder col wow fadeIn">
              <p>{{$t('LoyaltyBonusPageData.Deposit')}}</p>
              <img src="assets/images/loyal-ico1.webp" alt="AAAFx" title="AAAFx" width="71" height="72" class="fluid-img" />
            </div>
            <div class="prog-holder col wow fadeIn" data-wow-delay="0.3s">
              <p>{{$t('LoyaltyBonusPageData.Trade')}}</p>
              <img src="assets/images/loyal-ico2.webp" alt="AAAFx" title="AAAFx" width="71" height="72" class="fluid-img" />
            </div>
            <div class="prog-holder col wow fadeIn" data-wow-delay="0.6s">
              <p>{{$t('LoyaltyBonusPageData.Earn_RPs')}}</p>
              <img src="assets/images/loyal-ico3.webp" alt="AAAFx" title="AAAFx" width="71" height="72" class="fluid-img" />
            </div>
            <div class="prog-holder col wow fadeIn" data-wow-delay="0.9s">
              <p>{{$t('LoyaltyBonusPageData.Redeem_RPs')}}</p>
              <img src="assets/images/loyal-ico4.webp" alt="AAAFx" title="AAAFx" width="71" height="72" class="fluid-img" />
            </div>
          </div>
        </div>
        <div class="section-small">
          <div class="wrapper">
            <div class="title-holder wow fadeIn">
              <h5>
                {{$t('LoyaltyBonusPageData.loyalty_program_descOne')}}
                <br />
                {{$t('LoyaltyBonusPageData.loyalty_program_descTwo')}}
                <br />
                {{$t('LoyaltyBonusPageData.loyalty_program_descThree')}}
              </h5>
            </div>
            <div class="listing t-center routes">
              <div class="prog-holder col wow fadeInLeft">
                <h3>{{$t('LoyaltyBonusPageData.ExpressRoute')}}</h3>
                <p>
                  {{$t('LoyaltyBonusPageData.ExpressRouteDesc')}}
                </p>
              </div>
              <div class="prog-holder col wow fadeInRight">
                <h3>{{$t('LoyaltyBonusPageData.SteadyRoute')}}</h3>
                <p>
                  {{$t('LoyaltyBonusPageData.SteadyRouteDesc')}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section fab-sec">
      <div class="wrapper2">
        <div class="title-holder wow fadeIn">
          <h3>{{$t('LoyaltyBonusPageData.OurFabulousFourLoyaltyTiers')}}</h3>
          <br />
          <h6>{{$t('LoyaltyBonusPageData.OurFabulousFourLoyaltyTiersDescOne')}}</h6>
          <br />
          <h6>
            {{$t('LoyaltyBonusPageData.OurFabulousFourLoyaltyTiersDescTwo')}}
          </h6>
          <br />
          <p>
            {{$t('LoyaltyBonusPageData.OurFabulousFourLoyaltyTiersDescThree')}}
            <br />
            {{$t('LoyaltyBonusPageData.OurFabulousFourLoyaltyTiersDescFour')}}
            <br />
            {{$t('LoyaltyBonusPageData.OurFabulousFourLoyaltyTiersDescFive')}}
          </p>
        </div>
        <div class="img-holder t-center wow fadeIn">
          <img src="assets/images/loyal-img3.webp" alt="AAAFx" title="AAAFx" width="1368" height="743" class="fluid-img" />
        </div>
      </div>
    </div>
    <div class="section redeem-sec">
      <div class="wrapper">
        <div class="title-holder">
          <h3>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points')}}</h3>
          <br />
          <p>{{$t('LoyaltyBonusPageData.EarnMoreRedeemMore')}}</p>
          <br />
          <p>
            {{$t('LoyaltyBonusPageData.EarnMoreRedeemMoreDesc')}}            
          </p>
        </div>
        <div class="listing redeem-list t-left">
          <div class="redeem-holder col">
            <div class="content-holder">
              <p> {{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point1')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.ten')}}</span>{{$t('LoyaltyBonusPageData.rps')}} = {{$t('LoyaltyBonusPageData.oneusd')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal1.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point2')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.sixHundred')}}</span> {{$t('LoyaltyBonusPageData.rps')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal2.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point3')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.oneThousand')}}</span>{{$t('LoyaltyBonusPageData.rps')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal3.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point4')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.twoThousand')}}</span>{{$t('LoyaltyBonusPageData.rps')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal4.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point5')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.twoThousandFivehundred')}}</span>{{$t('LoyaltyBonusPageData.rps')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal5.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point6')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.threeThousand')}}</span>{{$t('LoyaltyBonusPageData.rps')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal6.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>
                {{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point7')}}
              </p>
              <h3><span>{{$t('LoyaltyBonusPageData.tenThousand')}}</span>{{$t('LoyaltyBonusPageData.rps')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal7.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point8')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.twentyFiveThousand')}}</span>{{$t('LoyaltyBonusPageData.rps')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal8.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point9')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.seven')}}*</span> {{$t('LoyaltyBonusPageData.AdditionalCommAmount')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal9.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point10')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.sevenHundred')}}</span>{{$t('LoyaltyBonusPageData.rpsPerReferral')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal10.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point11')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.seven')}}*</span>{{$t('LoyaltyBonusPageData.Reimbursement_amount')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal11.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point12')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.two')}}*</span>{{$t('LoyaltyBonusPageData.AdvanceDepositAmount')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal12.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point13')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.two')}}*</span>{{$t('LoyaltyBonusPageData.BonusAmount')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal13.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point14')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.seven')}}*</span>{{$t('LoyaltyBonusPageData.Reimbursement_amount')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal14.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point15')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.six')}}*</span>{{$t('LoyaltyBonusPageData.Reimbursement_amount')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal15.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>{{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point16')}}</p>
              <h3><span>{{$t('LoyaltyBonusPageData.seven')}}*</span> {{$t('LoyaltyBonusPageData.Reimbursement_amount')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal16.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
          <div class="redeem-holder col">
            <div class="content-holder">
              <p>
                {{$t('LoyaltyBonusPageData.Redeem_your_Reward_Points_point17')}}
              </p>
              <h3><span>{{$t('LoyaltyBonusPageData.seven')}}*</span>{{$t('LoyaltyBonusPageData.Reimbursement_amount')}}</h3>
            </div>
            <div class="icon-holder">
              <img src="assets/images/loyal17.webp" alt="AAAFx" title="AAAFx" width="70" height="45" class="fluid-img" />
            </div>
          </div>
        </div>
        <div class="title-holder t-left">
          <p>
            {{$t('LoyaltyBonusPageData.readTermsAndConditionHere')}}
            <a href="#"> {{$t('LoyaltyBonusPageData.here')}}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
