<template>
	<div id="main-wrapper" class="inner-pages forex-page pamm-page white-page">
		<div class="section banner-holder">
			<div class="forex-nav">
				<div class="wrapper">
					<ul>
						<li class="active"><router-link to="/white-label">{{$t('whiteLabels.banner.menu.white_label')}}</router-link></li>
					</ul>
				</div>
			</div>
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('whiteLabels.banner.title')}}</h2>
				<p>{{$t('whiteLabels.banner.subtitle')}}</p>
			</div>
		</div>
		<div class="pamm-content section">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<h5>{{$t('whiteLabels.pamm_content.intro.heading')}}</h5>
				</div>
				<div class="content-holder wow fadeInLeft">
					<div class="title-holder">
						<p>{{$t('whiteLabels.pamm_content.description.paragraph_1')}}
						<br><br>
						{{$t('whiteLabels.pamm_content.description.paragraph_2')}}
						<br><br>
						{{$t('whiteLabels.pamm_content.description.paragraph_3')}}
						<br><br>
						{{$t('whiteLabels.pamm_content.description.paragraph_4')}}
						<br><br>
						{{$t('whiteLabels.pamm_content.description.paragraph_5')}}
						</p>
					</div>
				</div>
				<div class="img-holder wow fadeInRight">
					<img src="assets/images/white-img1.webp" alt="AAAFx" title="AAAFx" width="393" height="405" class="fluid-img" />
				</div>
			</div>
		</div>
		<div class="expect-sec section">
			<div class="wrapper">
				<div class="img-holder wow fadeInLeft">
					<img src="assets/images/white-img2.webp" alt="AAAFx" title="AAAFx" width="620" height="367" class="fluid-img" />
				</div>
				<div class="content-holder wow fadeInRight">
					<div class="title-holder">
						<h3>{{$t('whiteLabels.expectations_section.heading')}}</h3>
						<ul class="listing-check">
							<li>{{$t('whiteLabels.expectations_section.list.item_1')}}</li>
							<li>{{$t('whiteLabels.expectations_section.list.item_2')}}</li>
							<li>{{$t('whiteLabels.expectations_section.list.item_3')}}</li>
							<li>{{$t('whiteLabels.expectations_section.list.item_4')}}</li>
							<li>{{$t('whiteLabels.expectations_section.list.item_5')}}</li>
							<li>{{$t('whiteLabels.expectations_section.list.item_6')}}</li>
							<li>{{$t('whiteLabels.expectations_section.list.item_7')}}</li>
							<li>{{$t('whiteLabels.expectations_section.list.item_8')}}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="section bg-img">
			<div class="wrapper"></div>
		</div>
		<div class="section-small ">
			<div class="wrapper">
				<div class="title-holder">
					<h6>{{$t('whiteLabels.contact_section.title')}}</h6>
					<br><br>
					<p>
						{{$t('whiteLabels.contact_section.contact_us')}} <a href="mailto:support@aaafx.com">{{$t('whiteLabels.contact_section.email_link')}}&nbsp;</a>{{$t('whiteLabels.contact_section.email_info')}}<br>
						<a href="mailto:support@aaafx.com">{{$t('whiteLabels.contact_section.email_address')}}</a>
					</p>
				</div>
			</div>
		</div>

	</div>
</template>