module.exports = [{
        " ":
            {
                "title": "AAAFx- 15+ years of experience",
                "description": "With a strong value system, we deliver a world class trading experience. We comply with a strict Regulatory framework in order to protect your funds. We are regulated by the HCMC",
                "keywords": "Best forex broker, Fx broker, how to trade forex, AAAFx forex broker, AAAFx, Open account with AAAFx, open forex account, AAAFx fx broker, best regulated forex broker, fx broker to trade,  AAAFx broker ",
                "h1":"The Future of FOREX is...",
            },
        "login":
            {
                "title": "Login- AAAFx ",
                "description": "Login into your secure client area. Add funds, view order history, request withdrawal and much more from one place.",
                "keywords": "client portal aaafx. aaafx login, login into client portal aafx, how to add funds aaafx, client area aaafx, where to check trade history aaafx",
                "h1":"--",
            },
        "account-types":
            {
                "title": "Types of Accounts- AAAFx",
                "description": "Choose the account that suits your trading needs. We have exciting offerings for every trader, in a highly simplified manner. Enjoy raw ECN spreads and lightning-fast trade execution at very low cost. ",
                "keywords": "ECN account AAAFx, Account types AAAFx, which accounts are offered by AAAFx, Islamic account aaafx, zero commission account forex, forex account aaafx, ECN zulutrade account aaafx, best ECN forex account",
                "h1":"Account Types",
            },
        "act-trader":
            {
                "title": "ActTrader- AAAFx",
                "description": "ActTrader focuses on simplicity, readability, and challenging the existing trading traditions with robust technology such that the user can vividly focus on the trading options",
                "keywords": "Acttrader aaafx, Trading platform aaafx, acttrader brokers, broker offering acttrader platform, Acttrader trading platform",
                "h1":"ActTrader",
            },
        "auto-chartist":
            {
                "title": "Autochartist- AAAFx",
                "description": "Make informed decisions as Autochartist analyses all charts and patterns for you. One stop solution for Market Analysis",
                "keywords": "Autochartist AAAFx, Autochartist trading tool. uses of autochartist, aaafx trading tools, analysis tools aaafx ",
                "h1":"Autochartist",
            },
        "privacy-policy":
            {
                "title": "Privacy Policy- AAAFx",
                "description": "Privacy policy of AAAFx. Terms of use of website. AAAFx or any of its divisions may use cookies in connection with AAAFx International's products and services",
                "keywords": "---",
                "h1":"Privacy Policy",
            },
        "career":
            {
                "title": "Work with us - AAAFx Careers",
                "description": "At AAAFx we encourage you to be the better version of yourself. Express who you truly are; learn and succeed. Our goal is to create a conducive work environment that gives you a sense of fulfillment.",
                "keywords": "aaafx careers, job vacancy aaafx, work culture aaafx, job apply aaafx, apply job forex broker, forex broker open job position",
                "h1":"Careers",
            },
        "commodities":
            {
                "title": "Commodities- AAAFx",
                "description": "Commodities include precious metals, oil and natural gas. Build-up Your diverse portfolio by trading major commodities",
                "keywords": "Commodities aaafx, how to trade commodities, gold trading, trade commodities, AAAFx commodity trading conditions, best broker for trading commodities",
                "h1":"Commodities",
            },
        "compare-platform":
            {
                "title": "Compare Trading Platforms- AAAFx",
                "description": "AAAFx ensures to provide a one-stop and the best solution for every trader’s needs with the multiplicity of trading platforms offered.",
                "keywords": "Trading platforms AAAFx. MT4 AAAFx, MT5 AAAFx, acttrader AAAFx, which is the best trading platform. trading platform for forex, how to trade forex, trading platforms offered by AAAfx",
                "h1":"Compare Trading Platforms",
            },
        "contact":
            {
                "title": "Contact us- AAAFx",
                "description": "Customer support is available 24/5 via live chat, phone and email. Email us at: support@aaafx.com",
                "keywords": "Contact number aaafx, client support aaafx, aaafx phone number, aaafx address, live chat aaafx, call support aaafx, email aaafx, support email aaafx, how to get in touch with support aaafx",
                "h1":"Contact Us",
            },
        "crypto":
            {
                "title": "Cryptocurrencies- AAAFx",
                "description": "Trade the most powerful cryptocurrency CFDs inlcuding BTC, ETH, LTC and many more. Spreads start from 0.0 pips",
                "keywords": "trade cryptocurrencies with AAAFx, Cryptocurency AAAFx. trading conditions crypto AAAFx, crypto trading with AAAFx, how to trade cryptos, best broker to trade cryptocurrency",
                "h1":"Cryptocurrencies",
            },
        "dep-with":
            {
                "title": "Deposit and Withdrawal- AAAFx",
                "description": "Invest in your trading account by paying no extra fee. We have various reliable deposit and withdrawal options to make your trading journey smooth and easy.",
                "keywords": "Deposit and withdrawal aaafx, how to deposit funds with aaafx, bank wire transfer aaafx, crypto deposit aaafx, withdrawal time aaafx, how to deposit funds in forex account, aaafx deposit methods, aaafx withdrawal methods",
                "h1":"Deposits and Withdrawals",
            },
        "dep-with-with":
            {
                "title": "",
                "description": "",
                "keywords": "",
                "h1":"",
            },
        "economic-calendar":
            {
                "title": "Economic Calendar- AAAFx",
                "description": "Our Economic Calendar acquaint you with the all the important financial events impacting global markets. Global events at your fingertips",
                "keywords": "Economic calendar aaafx, important forex events, aaafx eco calendar, updated economic calendar, global economic calendar",
                "h1":"Economic Calendar",
            },
        "faq":
            {
                "title": "Frequently Asked Questions (FAQ)- AAAFx",
                "description": "Find answers to your questions in our knowledge centre collection. Getting Started. How to register with AAAFx and verify my account?",
                "keywords": "FAQ AAAFx, how to open aaafx account, contact support aaafx, how to deposit funds aaafx, question answer aaafx, frequently asked questions aaafx",
                "h1":"Frequently Asked Questions",
            },
        "forex":
            {
                "title": "Online Forex Trading- AAAFx",
                "description": "AAAFx offers one of the most competitive spreads to trade on over 70+ currency pairs. Start trading at 0.0 pips  and zero commission with our ECN plus account",
                "keywords": "forex trading with AAAFx, how to trade forex, trade forex aaafx, best broker to trade forex, best fx broker, forex trade aaafx. forex trading conditions aaafx, spreads for trading forex with aaafx. forex aaafx, fx trading aaafx",
                "h1":"Forex",
            },
        "ib":
            {
                "title": "Introducing Broker- AAAFx",
                "description": "Develop a permanent source of income for yourself. We offer outstanding trading conditions and tools for traders and help our IB partners grow along with their clients",
                "keywords": "afflliate program aaafx, how to earn with aaafx, IB aaafx, earn as an affiliate, best IB program forex",
                "h1":"Introducing Broker",
            },
        "indices":
            {
                "title": "Indices Trading- AAAFx",
                "description": "Trade with leading global market indices all over the word at the low commission and fast execution with our ECN account.",
                "keywords": "indices aaafx, indices trading aaafx, trade indices with aaafx, how to trade indices, best broker to trade indices, top indices trading broker",
                "h1":"Indices",
            },
        "legal-documents":
            {
                "title": "Legal Documents- AAAFx",
                "description": "We recommend you to read and understand all the necessary legal documents before starting your trading journey with us.",
                "keywords": "aaafx legal documents, aaafx legal documents online, legal documents forex broker account",
                "h1":"Legal Documents",
            },
        "loyalty-bonus":
            {
                "title": "Loyalty Program- AAAFx",
                "description": "Every trade counts in a trader's journey. Each trade you place with us goes a long way in giving you exciting returns through our Loyalty Program.",
                "keywords": "loyalty bonus, loyalty rewards, rewards point with aaafx, aaafx rewards, redeem points with aaafx, client loyalty reward aaafx, best broker with loyalty program, aaafx bonus points",
                "h1":"Loyalty Program",
            },
        "mt4":
            {
                "title": "MT4- AAAFx",
                "description": "Enjoy trading at the lowest commission with one of the most popular trading platforms, available for your desktop, mobile, and tablet with AAAFx",
                "keywords": "metatrader 4 aaafx, metatrader 4 download aaafx, meta trader, metatrader 4 download pc aaafx, mt4 aaafx, metatrader 4 pc aaafx, metatrader 4 mac, metatrader 4 app, metatrader 4 demo aaafx, metatrader 4 online aaafx, metatrader 4 web aaafx, metatrader 4 forex trading, metatrader 4 app aaafx download, download meta trader 4, best MT4 broker",
                "h1":"MetaTrader 4",
            },
        "mt5":
            {
                "title": "MT5- AAAFx",
                "description": "AAAFx MT5 platform offers an extensive list of the trading instruments including Cryptocurrency CFDs, helping the advanced platform to fulfil its futuristic outlook.",
                "keywords": "mt5 aaafx, metatrader 5 aaafx, download mt5 aaafx, download meta trader 5 aaafx, mql5 market, mt5 download for pc aaafx, mt5 trading aaafx, mt5 brokers aaafx, metatrader 5 pc, metatrader 5 pc download, metatrader 5 free download, mt5 app, best forex broker MT5",
                "h1":"MetaTrader 5",
            },
        "our-servers":
            {
                "title": "AAAFx Servers",
                "description": "We aim to provide the most reliable and fastest connectivity when it comes to trade execution and therefore, we use the world’s most advanced global network and server clusters.",
                "keywords": "aaafx servers, aaafx technology, aaafx fast connectivity, aaafx trade execution, aaafx advanced global network, aaafx ultra-fast connection, aaafx fastest execution, servers, aaafx server",
                "h1":"Our Severs",
            },
        "pamm":
            {
                "title": "PAMM/MAM Program- AAAFx",
                "description": "Join AAAFx and showcase your trading expertise to the world and earn through your followers. This also gives chance to Investors to choose the best traders to trade for them.",
                "keywords": "mam account brokers, pamm account brokers, aaafx mam trading, mam account forex, aaafx mam mt4, mam pamm forex, mam trading account aaafx, mam system forex, mam aaafx broker, mam and pamm, mam aaafx forex, mam aaafx forex, aaafx mamm account",
                "h1":"PAMM/MAM Program",
            },
        "refer-earn":
            {
                "title": "Refer and earn program- AAAFx",
                "description": "Invite your friends to join AAAFx through your unique referral link. You and your referral shall earn $25 each. Without incurring any extra cost, simply increase your income. The more friends you refer, the more you earn!",
                "keywords": "aaafx refer and earn, refer program aaafx, aaafx referral link, aaafx referral program, aaafx referral signup, referral reward aaafx, aaafx referral earnings, aaafx referral, rewards points aaafx, redeem points aaafx, redeem aaafx",
                "h1":"Refer And Earn",
            },
        "regulations":
            {
                "title": "Regulations and Licenses- AAAFx",
                "description": "We have multiple registrations and regulations around the globe including Hellenic Capital Market Commission (HCMC), Financial Sector Conduct Authority (FSCA), St. Vincent and the Grenadines (SVG) and more.",
                "keywords": "aaafx broker regulation, aaafx regulation, is aaafx regulated, aaafx license, is aaafx registered, aaafx registeration, is aaafx safe, is aaafx regulated, is aaafx trustworthy, aaafx registeration authority, aaafx global license, aaafx global regulation, fsca regulated brokers, hcmc regulated broker",
                "h1":" Regulations and Licenses",
            },
        "safety-funds":
            {
                "title": "Security of Funds- AAAFx",
                "description": "AAAFx employs cutting-edge security solutions for top-notch protection of your funds and personal data to keep your account safe and secure.",
                "keywords": "aaafx funds safety, funds security, trade safely with aaafx, is aaafx reliable, is aaafx safe, is aaafx trustworthy, aaafx reliabilty",
                "h1":"Safety of Funds",
            },
        "stocks":
            {
                "title": "Stocks - AAAFx",
                "description": "Trade in over 100+ stocks including apple, amazon, tesla and more. Trade at the best possible conditions. Min. 0.075% Commission",
                "keywords": "aaafx stocks, aaafx stock CFDs, aafx stock trading, aaafx stock cfds trading, stock commission aaafx",
                "h1":"Stocks",
            },
        "trading-calculator":
            {
                "title": "Trading Calculator - AAAFx",
                "description": "Make accurate assessments in blink of an eye by using our Trading Calculators. Available calculators- Swap Calculator, Margin Calculator, Pip Calculator ",
                "keywords": "aaafx pip calculator, aaafx position size calculatorm aaafx forex profit calculator, aaafx profit calculator, aaafx calculator forex, aaafx trading calculators, aaafx stop loss calculator, aaafx forex margin calculator, aaafx forex leverage calculator",
                "h1":"Trading Calculators",
            },
        "trading-condition":
            {
                "title": "Trading Conditions - AAAFx",
                "description": "AAAFx offers trading terms and conditions suitable for every trader - Be it a beginner or an expert. ECN Trading with Deep liquidity along with zero commission and razor thin spreads.",
                "keywords": "aaafx fast execution, aaafx zero commission, aaafx zero spreads, aaafx razor thin spreads, ecn spreads, ecn broker, liquidity providers, aaafx trading instruments",
                "h1":"Trading Conditions",
            },
        "vps-hosting":
            {
                "title": "VPS Hosting - AAAFx ",
                "description": "Trade from anywhere in the world conveniently without connection interruptions. Avail free VPS with AAAFx. VPS offers anonymity, privacy and security ",
                "keywords": "AAAFX VPS, free vps, vps hosting, free vps, vps server, cheap vps, windows vps, virtual private server, cloud vps, best vps hosting, best vps, free vps server, aaafx vps, aaafx vps hosting,",
                "h1":"Free VPS Hosting",
            },
        "white-label":
            {
                "title": "White Label - AAAFx",
                "description": "AAAFx white label program offers clients full flexibility to design their products & services, so that you can get the best opportunity to flourish your venture.",
                "keywords": "white label aaafx, white label pricing, white label products, whitelabeling, white label business, best whitelabel, aaafx whitelabeling",
                "h1":"White Label",
            },
        "why":
            {
                "title": "Why Choose Us? | AAAFx",
                "description": "AAAFx has a decade long history of providing global clients with reliable and excellent service. We are proud to be one of the most trusted brokers in forex market, serving retail as well as institutional clients.",
                "keywords": "why aaafx, choose aaafx, why trade with aaafx, why join aaafx, benefits of aaafx forex broker, why trade with aaafx, who is aaafx, aaafx forex broker.",
                "h1":"Why AAAFx?",
            },
        "zulu":
            {
                "title": "ZuluTrade with AAAFx",
                "description": "ZuluTrade is an automated social trading platform allowing a user to gain insight and establish a system of copying the trades and earning the advantage. AAAFx provides exceptional trading experience combined with the world class ZTP’s features.",
                "keywords": "zulutrade brokers, aaafx zulutrade, zulutrade traders, zulutrade copy trading aaafx, zulutrade signal provider, zulutrade fees, zulutrade supported brokers aaafx, zulutrade aaafx demo, zulutrade app, zulutrade aaafx demo account, zulutrade mt4, aaafx zulutrade",
                "h1":"ZuluTrade",
            },
        "zulu-tools":
            {
                "title": "ZuluTrade Tools - AAAFx",
                "description": "At AAAFx, you get a chance to trade institutional grade liquidity and make use of our superior trading conditions. Zulu offers a variety of features and products including Zulu Social Platform, ZuluGuard, Automator, Combos and much more.",
                "keywords": "aaafx copytrading, aaafx zulutrade, zuluguard, zulutrade copier, zulutrade aaafx account, zulu aaafx, zulutrade  automator",
                "h1":"ZuluTrade",
            },
        "web-trader":
            {
                "title": "AAAFx Web Trader",
                "description": "WebTrader AAAFx. Login to trade. Trade in more than 500+ instruments at one place.",
                "keywords": "Web trader aaafx, trading platform aaafx, web aaafx",
                "h1":"--",
            }
        
}]