/**
 * global runtime constants for the plugin
 */
export const STORE_MODULE_NAME = "vue-provider-cache"
export const REF = 'vpc'

export const Const = {

  ////////////////////////// globals ////////////////////////////


  /**
   * router config for the remote
   * blockmaze-price-feed-microservice host 
   */
  WS_PRICE_FEED_DOMAIN: {
    HOST: 'https://devpricefeed.capitalwallet.com:2087', //to access locally 
    // HOST: 'https://wtpricefeed.sysfx.com', //to access Live 
    // HOST: 'https://dev.finvasia.com:5000', //to access locally 
    // HOST: 'https://pricefeed.fxview.com:2087',
    // HOST: 'https://localhost:5000',
    //HOST: 'https://50.28.50.136:5000', 
    //HOST: 'https://turnkeyforex.com:5000', 
    EP_API_SEARCH_BY: '/api/ws-price-feed/search-by',
    EP_API_CONFIG: '/api/ws-price-feed/config'
  },


  ////////////////////////// actions ////////////////////////////

  // load global config 
  RUN_REGISTER_PROVIDERS: STORE_MODULE_NAME + '/' + REF + '/RUN_REGISTER_PROVIDERS',

  // run heartbeat for s single cachetag 
  RUN_HERARTBEAT: STORE_MODULE_NAME + '/' + REF + '/RUN_HERARTBEAT',

  //////////////////// mutations //////////////////////////////

  // start and register providers
  SET_REGISTER_PROVIDERS: STORE_MODULE_NAME + '/' + REF + '/SET_REGISTER_PROVIDERS',

  // update heartbeat for single provider
  SET_HEARTBEAT: STORE_MODULE_NAME + '/' + REF + '/SET_HEARTBEAT',

  //////////////////// getters //////////////////////////////

  // get heartbeats from all providers
  GET_HEARTBEATS: STORE_MODULE_NAME + '/' + REF + '/GET_HEARTBEATS',
  // get all providers
  GET_PROVIDERS: STORE_MODULE_NAME + '/' + REF + '/GET_PROVIDERS'
}
export const ConstCache = {

  ////////////////////////// globals ////////////////////////////

  STORE_MODULE_NAME: "vue-provider-cache",
  REF: 'vpc',

  /**
   * router config for the remote
   * blockmaze-price-feed-microservice host 
   */
  WS_PRICE_FEED_DOMAIN: {
    HOST: 'https://dev.finvasia.com:5000',
    // HOST: 'https://pricefeed.fxview.com:2087', 
    // HOST: 'https://localhost:5000',
    //HOST: 'https://50.28.50.136:5000', 
    //HOST: 'https://turnkeyforex.com:5000', 
    EP_API_SEARCH_BY: '/api/ws-price-feed/search-by',
    EP_API_CONFIG: '/api/ws-price-feed/config'
  },


  ////////////////////////// actions ////////////////////////////

  // load global config 
  RUN_REGISTER_PROVIDERS: STORE_MODULE_NAME + '/' + REF + '/RUN_REGISTER_PROVIDERS',

  // run heartbeat for s single cachetag 
  RUN_HERARTBEAT: STORE_MODULE_NAME + '/' + REF + '/RUN_HERARTBEAT',

  //////////////////// mutations //////////////////////////////

  // start and register providers
  SET_REGISTER_PROVIDERS: STORE_MODULE_NAME + '/' + REF + '/SET_REGISTER_PROVIDERS',

  // update heartbeat for single provider
  SET_HEARTBEAT: STORE_MODULE_NAME + '/' + REF + '/SET_HEARTBEAT',

  //////////////////// getters //////////////////////////////

  // get heartbeats from all providers
  GET_HEARTBEATS: STORE_MODULE_NAME + '/' + REF + '/GET_HEARTBEATS',
  // get all providers
  GET_PROVIDERS: STORE_MODULE_NAME + '/' + REF + '/GET_PROVIDERS'
}