<template>
	<div id="main-wrapper" class="inner-pages tradcond-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>{{$t('tradingCondition.heading')}}</h2>
				<p>{{$t('tradingCondition.pTag')}} </p>
			</div>
		</div>
		<div class="section tradcond-content">
			<div class="wrapper">
				<div class="title-holder t-left wow fadeIn">
					<h5>{{$t('tradingCondition.h5Tag')}}</h5>
					<p>{{$t('tradingCondition.content')}} </p>
				</div>
				<div class="listing-cond">
					<div class="content-sec wow fadeIn">
						<div class="text-holder">
							<h3>{{$t('tradingCondition.deepLiquidity')}}</h3>
							<p>{{$t('tradingCondition.buySell')}}
							<br><br>
							{{$t('tradingCondition.brTag')}}
							</p>
						</div>
						<div class="img-holder">
							<img src="assets/images/cond1.webp" alt="AAAFx" title="AAAFx" width="455" height="205" class="fluid-img" />
						</div>
					</div>
					<div class="content-sec wow fadeIn">
						<div class="img-holder">
							<img src="assets/images/cond2.png" alt="AAAFx" title="AAAFx" width="410" height="184" class="fluid-img" />
						</div>
						<div class="text-holder">
							<h3>{{$t('tradingCondition.powerfulArsenal')}} </h3>
							<p>{{$t('tradingCondition.arsenalContent')}} </p>
						</div>
					</div>
					<div class="content-sec">
						<div class="text-holder">
							<h3>{{$t('tradingCondition.zeroCommission')}} </h3>
							<p>{{$t('tradingCondition.commissionContent')}} </p>
						</div>
						<div class="img-holder">
							<img src="assets/images/cond3.webp" alt="AAAFx" title="AAAFx" width="376" height="99" class="fluid-img" />
						</div>
					</div>
					<div class="content-sec">
						<div class="img-holder">
							<img src="assets/images/cond4.webp" alt="AAAFx" title="AAAFx" width="176" height="182" class="fluid-img" />
						</div>
						<div class="text-holder">
							<h3>{{$t('tradingCondition.fastExecution')}} </h3>
							<p>{{$t('tradingCondition.executionContent')}} </p>
						</div>
					</div>
					<div class="content-sec">
						<div class="img-holder">
							<img src="assets/images/cond6.png" alt="AAAFx" title="AAAFx" width="416" height="166" class="fluid-img" />
						</div>
						<div class="text-holder">
							<h3>{{$t('tradingCondition.fundingOptions')}} </h3>
							<p>{{$t('tradingCondition.fundingContent')}}
							</p>
						</div>
					</div>
					
					<div class="content-sec charges-sec">
						<div class="text-holder">
							<h3>{{$t('tradingCondition.lowRollover')}} </h3>
							<p>{{$t('tradingCondition.rolloverContent')}} </p>
						</div>
						<div class="img-holder">
							<img src="assets/images/cond7.webp" alt="AAAFx" title="AAAFx" width="137" height="137" class="fluid-img" />
						</div>
					</div>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>{{$t('tradingCondition.doubt')}} <a :href="siteurl2" target="_blank" >{{$t('tradingCondition.demoAccount')}}</a> <br>
						{{$t('tradingCondition.stayAware')}} <router-link to="/trading-calculator">{{$t('tradingCondition.calculate')}}</router-link> {{$t('tradingCondition.getAll')}}
					</p>
					<div class="btn-holder t-center wow fadeIn">
						<a :href="siteurl2" target="_blank" class="btn btn-yellow">{{$t('tradingCondition.liveAccount')}}</a>
						<a :href="siteurl2" target="_blank" class="btn btn-blue">{{$t('tradingCondition.openAccount')}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
       siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL, 
    };
  },
  
};
</script>